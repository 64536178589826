import React from 'react';

const OhjeetValtuudet = ({ lang, title }) => {
  switch (lang) {
    case 'sv-FI':
      return (
        <>
          <h1>{title}</h1>

          <ol className="toc">
            <li> <a href="/ohjeet/valtuudet#authorize">Organisationer: befullmäktiga användare av den elektroniska ärendehanteringstjänsten på Materialtorget</a></li>
            <li>
              <ol>
                <li><a href="/ohjeet/valtuudet#who">Vem kan bevilja fullmakt?</a></li>
                <li><a href="/ohjeet/valtuudet#where">Var beviljas fullmakt?</a></li>
                <li><a href="/ohjeet/valtuudet#how">Hur ges fullmakt?</a></li>
                <li><a href="/ohjeet/valtuudet#request">Hur ber du om fullmakt?</a></li>
              </ol>
            </li>
            <li>
              <a href="/ohjeet/valtuudet#register">Hur registrerar och loggar du in på Materialtorget?</a>
            </li>
            <li>
              <ol>
                <li><a href="/ohjeet/valtuudet#role">Välj användarroll</a></li>
              </ol>
            </li>
          </ol>

          <p>
            I juni 2022 införs en tjänst för befullmäktigande i tjänsten Materiaalitori.fi. Efter det sker inloggningen på Materialtorget alltid via Suomi.fi-identifikation med nätbankskoder, mobilcertifikat eller certifikatkort.
          </p>

          <p>
            Fullmakt för att använda Materialtorget ges av en person med firmateckningsrätt i företaget/organisationen. Fullmakten kan ges redan i förväg från och med den 18.5.2022.
          </p>

          <p>
            Denna anvisning behandlar följande åtgärder för att ge fullmakt:
          </p>

          <ol className="numberList">
            <li>Fullmaktsgivaren befullmäktigar personer och/eller organisationer med nödvändiga fullmakter: för att befullmäktiga en person finns det två alternativ: ärendefullmakt eller ärendefullmakt med firmateckningsrätt.</li>
            <li>Den befullmäktigade personen i organisationen registrerar sig på Materialtorget och fyller då i de ytterligare uppgifter som Materialtorget kräver. Om personen inte har organisationens fullmakt, kan hen begära fullmakt via <a href="https://www.suomi.fi/fullmakter/" target={"_blank"} rel="noopener noreferrer">Suomi.fi.</a></li>
            <li>I fortsättningen ska personen varje gång logga in på Materialtorget via Suomi.fi-identifikation och tjänsten kontrollerar personens fullmakter varje gång hen loggar in.</li>
            <li>Fullmaktsgivaren kan radera personens eller organisationens fullmakt via <a href="https://www.suomi.fi/fullmakter/" target={"_blank"} rel="noopener noreferrer">Suomi.fi</a>, varefter personen/organisationen inte har tillgång till organisationens uppgifter i tjänsten.</li>
          </ol>

          <h2 id="authorize">Organisationer: befullmäktiga användare av den elektroniska ärendehanteringstjänsten på Materialtorget</h2>

          <p>
            Det är endast organisationens en <a href="https://www.suomi.fi/anvisningar-och-stod/information-om-fullmakter/utrattande-av-arenden-for-ett-foretag-eller-ett-samfund" target={"_blank"} rel="noopener noreferrer">utsedd</a> representant som utan separat fullmakt kan uträtta ärenden på Materialtorget för företaget eller organisationen. Andra behöver en separat fullmakt. Fullmakt kan beviljas till en person eller en annan organisation i tjänsten <a href="https://www.suomi.fi/fullmakter/" target={"_blank"} rel="noopener noreferrer">Suomi.fi.</a>
          </p>

          <p>
            Om fullmakt beviljas till en annan organisation, kan denna organisation i sin tur befullmäktiga en person/personer att uträtta den ursprungliga organisationens ärenden. Då begränsas den befullmäktigades tillgång till uppgifter om anbud i annonser till organisationen till att omfatta erbjudanden som den befullmäktigade utarbetat själv. Ytterligare information finns i punkten ”Uträttande av ärenden för en annan person”.
          </p>

          <h3 id="who">Vem kan bevilja fullmakt?</h3>

          <p>
          Fullmakt kan beviljas endast av:
          </p>
          
          <ul>
            <li>verkställande direktören</li>
            <li>verkställande direktörens ställföreträdare</li>
            <li>en tjänsteman med firmateckningsrätt i företaget</li>
            <li>en enskild näringsidkare</li>
            <li>en person som har beviljats fullmaktsrätt av någon av de ovannämnda.</li>
          </ul>          

          <h3 id="where">Var beviljas fullmakt?</h3>

          <p>
            Fullmakt beviljas i tjänsten <a href="https://www.suomi.fi/fullmakter/" target={"_blank"} rel="noopener noreferrer">Suomi.fi-fullmakter</a>. De flesta organisationer kan själva bevilja fullmakter i tjänsten. Vissa organisationer beviljar fullmakt <a href="https://www.suomi.fi/service/tjansten-for-tjanstemannabefullmaktiganden-i-suomi-fi-fullmakter-myndigheten-for-digitalisering-och-befolkningsdata/8d592763-8e25-4230-b4bb-cbcab7d213c7" target="_blank" rel="noopener noreferrer">med hjälp av en tjänsteman.</a>
          </p>

          <h3 id="how">Hur ges fullmakt?</h3>
          
          <ol className="numberList">
            <li>Identifiera dig på <a href="https://www.suomi.fi/fullmakter/" target={"_blank"} rel="noopener noreferrer">Suomi.fi</a> med ditt personliga identifieringsverktyg.</li>
            <li>Välj “Fullmakter” i övre menyn.</li>
            <li>Välj “Företagets fullmakter”.
              <ul>
                <li>Välj företag/organisation som du vill uträtta ärenden för.</li>
                <li>Klicka på Ge fullmakter.</li>
              </ul>
            </li>
            <li>Välj fullmaktstyp:
                <p>Fullmakter för att uträtta din organisations ärenden, välj:</p>
                <p><b><i>Ärendefullmakt: Personen eller företaget har rätt att uträtta ärenden för företaget (till exempel det egna företagets anställda).</i></b></p>
                <p><b><i>Representationsfullmakt: Personen har rätt att sköta ärenden för ett sådant företag, som har befullmäktigat företaget att sköta ärenden på företagets vägnar (t.ex. Disponentbyråns arbetstagare). </i></b></p>
            </li>
            <li>Parter
              <p><b>Lägg till person</b>, när du ger en anställd i din organisation fullmakt. <b>Du behöver personens namn och personbeteckning.</b> Om du ger samma fullmakter till flera personer, kan du lägga till personerna på en och samma gång.</p>
              <p><b>Lägg till företag </b>när du ger fullmakt till någon utanför din organisation, till exempel en disponentbyrå eller ett konsultföretag. Du behöver företagets namn och FO-nummer.</p>
            </li>
            <li id="types">Fullmaktsärenden
              <p>
                Inhämta fullmakter för Materialtorget antingen med sökordet ”Materiaalitori” (”Materialtorget”) eller med namnet på en enskild fullmakt.
              </p>
              <p>
                Välj fullmakt:
              </p>
              <p>
                <b>Ärenden som gäller avfallshantering och utbyte av material</b>
              </p>
  
                Med denna fullmakt kan den befullmäktigade för fullmaktsgivarens räkning sköta ärenden som gäller utbyte av avfall och produktionsbiflöden samt tillhörande tjänster, till exempel
              <ul>
                <li>söka efter eller tillhandahålla avfallshanterings- och experttjänster</li>
                <li>leta efter eller erbjuda material som kan återvinnas</li>
                <li>se på, lämna och godkänna erbjudanden</li>
                <li>se på och upprätta avtal</li>
                <li>uppdatera fullmaktsgivarens uppgifter.</li>
              </ul>
              <p>
                När du vill ge en representationsfullmakt åt en person, skall du använda den här fullmakten. Personen med representationfullmakt har åtkomst endast till organisationens annonser. Personen kan inte redigera fullmaktsgivarens uppgifter eller underteckna avtal.
              </p>
  
              <p>
                <b>Underteckna avtal om avfallshantering och utbyte av material</b>
              </p>
                Med denna fullmakt kan den befullmäktigade <b><i>för fullmaktsgivarens räkning underteckna avtal om avfallshantering, cirkulär ekonomi och tillhörande tjänster</i></b> och sköta ärenden som gäller utbyte av avfall och produktionsbiflöden samt tillhörande tjänster, till exempel
              <ul>
              <li>söka efter eller tillhandahålla avfallshanterings- och experttjänster</li>
                <li>leta efter eller erbjuda material som kan återvinnas</li>
                <li>se på, lämna och godkänna erbjudanden</li>
                <li>se på och upprätta avtal</li>
                <li>uppdatera fullmaktsgivarens uppgifter.</li>
              </ul>

              <p>
                <b>Se på uppgifter om en kommunal avfallshanteringstjänst i andra hand</b>            
              </p>
              
              <p>
                Den här fullmakten är endast för myndigheternas bruk.  <i>Med denna fullmakt kan den befullmäktigade för fullmaktsgivarens räkning se på uppgifter om en kommunal avfallshanteringstjänst i andra hand, till exempel avtalsuppgifter.</i>
              </p>
            </li>
            <li>Fastställ giltighet
              <p>
                Ange när fullmakten träder i kraft och när fullmakten går ut genom att välja slutdatum eller 1,5,15,20 eller 25 år<br/>
                Fullmakten kan när som helst återkallas. 
              </p>
            </li>
            <li>Kontrollera sammandraget och bekräftelsen
              <p>
                Kontrollera att uppgifterna i fullmakten är korrekta och bekräfta fullmakten.<br/>
                Du får ett meddelande om kvittering av att bekräftelsen av fullmakten har lyckats.<br/>
                De fullmakter som ditt företag har gett visas i punkten Beviljade fullmakter efter bekräftelsen.<br/>
              </p>
            </li>
          </ol>   

          <h3 id="request">Hur ber du om fullmakt?</h3>

          <ol className="numberList">
            <li>Identifiera dig på <a href="https://www.suomi.fi/fullmakter/" target={"_blank"} rel="noopener noreferrer">Suomi.fi</a> med ditt personliga identifieringsverktyg.</li>
            <li>Välj “Fullmakter” i övre menyn</li>
            <li>
              <ol>
                <li>a) Välj “Företagets fullmakter” när du vill be om agerandefullmakt för företaget och vidare ge representationsfullmakt åt företagets personal. Välj därefter företag/organisation som du vill uträtta ärenden för.</li>
                <li>b) Välj “Personliga fullmakter” när du vill be om agerandefullmakt för dig själv.</li>
              </ol>
            </li>
            <li>Klicka på Be om fullmakter.</li>
            <li>Mata i textfältet FO-numret på det företaget av vilket du vill be om fullmakt. Välj företaget från menyn som öppnas. Klicka därefter “Följande”.</li>
            <li>Välj de fullmaktsärenden som behövs, kolla <a href="/ohjeet/valtuudet#types">Välj fullmaktstyp</a></li>
            <li>Ange giltighetstid
              <p>
                Ange när fullmakten träder i kraft och när fullmakten går ut genom att välja slutdatum eller 1,5,15,20 eller 25 år 
              </p>
            </li>
            <li>Kolla sammanfattningen och bekräftelsen</li>
            <p>
              Kolla, att fullmaktens uppgifter är rätt och bekräfta fullmaktsbegäret. Efter att fullmaktsbegäran skickats får representanten för organisationen i fråga en begäran om att godkänna eller avkasta fullmaktsbegäran.
            </p>
          </ol>

          <h2 id="register">Hur registrerar och loggar du in på Materialtorget?</h2>

          <p>
            Registreringen i tjänsten börjar genom stark autentisering på Suomi.fi med bankkoder, mobilcertifikat eller certifikatkort. De uppgifter som vid identifieringen förmedlas om den som registrerar sig är förnamn och efternamn samt personbeteckning.
          </p>

          <p>
            Efter identifieringen väljer du företag, förening eller annat samfund som du vill uträtta ärenden för. Om organisationen du vill uträtta ärenden för inte finns i menyn har du inte behövliga rättigheter för att uträtta organisationens ärenden. Du kan be om fullmakt i <a href="https://www.suomi.fi/fullmakter/" target={"_blank"} rel="noopener noreferrer">Suomi.fi-tjänsten.</a>            
          </p>

          <p>
            När du har valt en organisation och tidigare inte har uträttat ärenden med den organisationens fullmakter, går du till en sida där du ska välja en användarroll där du kommer att använda tjänsten samt mata in din e-postadress och telefonnummer.
          </p>

          <h3 id="role">Välj användarroll</h3>
          
          <p>
            <b>Användaren av tjänsten väljer vilken användarroll användaren ska ha när hen använder tjänsten.</b>
          </p>

          <p>
            <b>Företag eller offentlig organisation:</b> befullmäktigad som uträttar ärenden för ett företag eller en annan organisation: om du inte är representant för avfallsanläggningen nedan och inte heller en regional samordnare eller tillsynsmyndighet, väljer du via detta val befullmäktigande av ett företag eller en annan offentlig organisation. Beroende på fullmakt har användaren rätt att uträtta ärenden eller rätt att uträtta ärenden och rätt att teckna firmanamn på Materialtorget.
          </p>

          <p>
            <b>Kommunal avfallsanläggning:</b> endast de som uträttar ärenden för kommunala avfallsanläggningar använder tjänsten via detta. Beroende på fullmakt har användaren rätt att uträtta ärenden eller rätt att uträtta ärenden och rätt att teckna firmanamn på Materialtorget.
          </p>

          <p>
            <b>Regionala samordnare:</b> : endast regionala samordnare i tjänsten för industriell symbios. Motiva Ab kontrollerar om den befullmäktigade är en regional samordnare.
          </p>

          <p>
            <b>Myndighet:</b> en myndighet som fastställs i avfallslagen och som har rätt att få uppgifter om kommunal avfallshanteringstjänst i andra hand för att kunna sköta nödvändiga lagstadgade uppgifter. När användningen inleds, anges de avfallsanläggningar vilkas uppgifter myndigheten har rätt att se.
          </p> 
        </>
      );

    default: {
      return (
        <>
          <h1>{title}</h1>

          <p>
            Tässä ohjeessa käydään läpi, kuinka annat ja pyydät valtuutuksia Materiaalitorin käyttöön sekä kuinka rekisteröidyt ja kirjaudut Materiaalitorille. 
          </p>

          <ol className="toc">
            <li> <a href="/ohjeet/valtuudet#authorize">Organisaatio: valtuuta Materiaalitorin sähköisen asiointipalvelun käyttäjät</a></li>
            <li>
              <ol>
                <li><a href="/ohjeet/valtuudet#who">Kuka voi valtuuttaa?</a></li>
                <li><a href="/ohjeet/valtuudet#where">Missä valtuutus tehdään?</a></li>
                <li><a href="/ohjeet/valtuudet#how">Kuinka valtuutus tehdään?</a></li>
                <li><a href="/ohjeet/valtuudet#request">Kuinka pyydät valtuuksia?</a></li>
              </ol>
            </li>
            <li>
              <a href="/ohjeet/valtuudet#register">Miten rekisteröidyt ja kirjaudut Materiaalitorille?</a>
            </li>
            <li>
              <ol>
                <li><a href="/ohjeet/valtuudet#role">Käyttäjäroolin valinta</a></li>
                <li><a href="/ohjeet/valtuudet#registeredUserLogin">Rekisteröityneen käyttäjän kirjautuminen Materiaalitorille</a></li>
              </ol>
            </li>
          </ol>

          <p>
            Materiaalitori.fi-palvelussa käytetään suomi.fi-valtuutuspalvelua. Kirjautuminen Materiaalitorille tapahtuu aina Suomi.fi-tunnistautumisen kautta verkkopankkitunnuksilla, mobiilivarmenteella tai varmennuskortilla.
          </p>

          <p>
            Valtuuden Materiaalitorin käyttöön antaa yrityksen/organisaation nimenkirjoitusoikeudellinen henkilö.
          </p>

          <p>
            Tässä ohjeessa käydään läpi valtuutuksen antaminen, toimenpiteet ovat:
          </p>

          <ol className="numberList">
            <li>Valtuuttaja valtuuttaa henkilöitä ja/tai organisaatioita tarpeellisin valtuuksin: henkilövaltuutuksissa vaihtoehtoja on kaksi: asiointivaltuutus tai asiointivaltuutus allekirjoitusoikeudella</li>
            <li>Organisaation valtuutettu henkilö kirjautuu Materiaalitorille. Mikäli henkilöllä ei ole organisaation valtuutusta, hän voi pyytää sitä <a href="https://www.suomi.fi/valtuudet/" target={"_blank"} rel="noopener noreferrer">Suomi.fi-palvelun</a> kautta.</li>
            <li>Jatkossa henkilö kirjautuu Materiaalitorille aina Suomi.fi-tunnistautumisen kautta, jolloin palvelu tarkistaa henkilön valtuudet.</li>
            <li>Valtuuttaja voi poistaa henkilön tai organisaation valtuutuksen <a href="https://www.suomi.fi/valtuudet/" target={"_blank"} rel="noopener noreferrer">Suomi.fi-palvelun</a> kautta, minkä jälkeen henkilö/organisaatio ei pääse organisaation tietoihin palvelussa.</li>
          </ol>

          <h2 id="authorize">Organisaatio: valtuuta Materiaalitorin sähköisen asiointipalvelun käyttäjät</h2>

          <p>
            Materiaalitori.fi-palvelussa voi yrityksen tai organisaation puolesta ilman erillistä valtuutta toimia vain organisaation <a href="https://www.suomi.fi/ohjeet-ja-tuki/valtuudet/organisaation-puolesta-asiointi/suomalaisen-yrityksen-tai-elinkeinonharjoittajan-puolesta-asiointi-ilman-erikseen-annettua-valtuutta" target={"_blank"} rel="noopener noreferrer">nimetty edustaja</a>. Muut tarvitsevat erillisen valtuuden. Valtuus voidaan myöntää henkilölle tai toiselle organisaatiolle <a href="https://www.suomi.fi/valtuudet/" target={"_blank"} rel="noopener noreferrer">Suomi.fi</a>-palvelussa.
          </p>

          <p>
            Jos valtuutus myönnetään toiselle organisaatiolle, tämä organisaatio voi edelleen valtuuttaa henkilön/henkilöitä hoitamaan alkuperäisen organisaation asioita. Tällöin valtuutetun pääsyä organisaatiolle tehtyjen ilmoitusten tarjoustietoihin rajataan koskemaan valtuutetun organisaation tekemiä ilmoituksia. Tällöin valtuutettu pääsee katsomaan vain oman organisaationsa tekemien ilmoitusten saamia tarjouksia. Tällä estetään mahdollisten luottamuksellisten tietojen siirtymiset kilpailijoille. 
          </p>

          <h3 id="who">Kuka voi valtuuttaa?</h3>

          <p>
            Valtuuden voi myöntää ainoastaan:
          </p>
          
          <ul>
            <li>yrityksen toimitusjohtaja</li>
            <li>yrityksen toimitusjohtajan sijainen</li>
            <li>yrityksen toimihenkilö, jolla on nimenkirjoitusoikeus</li>
            <li>yksityinen elinkeinonharjoittaja</li>
            <li>henkilö, jolle joku edellisistä on myöntänyt valtuutusoikeuden.</li>
          </ul>          

          <h3 id="where">Missä valtuutus tehdään?</h3>

          <p>
            Valtuudet myönnetään <a href="https://www.suomi.fi/valtuudet/" target={"_blank"} rel="noopener noreferrer">Suomi.fi- valtuudet</a> palvelussa. Poikkeustapauksissa (esim. Ulkomainen toimija tai nimenkirjoitusoikeus on vain kahdella hallituksen jäsenellä yhdessä) valtuus myönnetään <a href="https://www.suomi.fi/palvelut/suomi-fi-valtuuksien-virkailijavaltuuttamispalvelu-digi-ja-vaestotietovirasto/8d592763-8e25-4230-b4bb-cbcab7d213c7" target="_blank" rel="noopener noreferrer">virkailija-avusteisesti</a>.
          </p>

          <h3 id="how">Kuinka valtuutus tehdään?</h3>
          
          <ol className="numberList">
            <li>Tunnistaudu <a href="https://www.suomi.fi/valtuudet/" target={"_blank"} rel="noopener noreferrer">Suomi.fi-palveluun</a>.</li>
            <li>Valitse ylävalikosta <b>"Valtuudet"</b></li>
            <li>Valitse <b>"Yrityksen valtuudet"</b>.
              <ul>
                <li>Valitse yritys/organisaatio, jonka puolesta haluat asioida.</li>
                <li>Klikkaa Anna valtuuksia -painiketta.</li>
              </ul>
            </li>
            <li>Valitse valtuustyyppi:
                <p>Valtuudet, jotka liittyvät oman organisaatiosi asiointiin, valitse:</p>
                <p><i><b>Asiointivaltuus:</b> Henkilöllä tai yrityksellä on oikeus asioida yrityksen puolesta (esim. oman yrityksen työntekijä).</i></p>
                <p><i><b>Edustamisvaltuus:</b> Henkilöllä on oikeus asioida sellaisen yrityksen puolesta, jolta yritys itse on saanut asiointivaltuuden (esim. isännöintitoimiston työntekijä)</i></p>            
            </li>
            <li>Osapuolet
              <p><b>Lisää Henkilö</b>, kun annat valtuutuksen oman organisaation työntekijälle. <b>Tarvitset henkilön nimen ja henkilötunnuksen.</b> Jos annat samat valtuudet useammalle henkilölle, voit lisätä kaikki henkilöt samalla kertaa.</p>
              <p><b>Lisää Yritys</b>, kun annat valtuutuksen oman organisaation ulkopuolelle esim. isännöintitoimistolle tai konsulttiyritykselle. Tarvitset yrityksen nimen ja Y-tunnuksen.</p>
            </li>
            <li id="types">Valtuusasiat
              <p>
                Hae Materiaalitorin valtuudet joko hakusanalla ”Materiaalitori” tai yksittäisen valtuuden nimellä.
              </p>
              <p>
              Valitse valtuus:
              </p>
              <p>
                <b>Jätehuoltoa ja materiaalien vaihdantaa koskeva asiointi</b>
              </p>
  
              Tällä valtuudella valtuutettu voi valtuuttajan puolesta hoitaa jätteiden ja tuotannon sivuvirtojen sekä niihin liittyvien palvelujen vaihdantaa koskevia asioita, kuten 
              <ul>
                <li>etsiä tai tarjota jätehuolto- tai asiantuntijapalveluita</li>
                <li>etsiä tai tarjota hyödynnettäviä materiaaleja</li>
                <li>katsella, tehdä ja hyväksyä tarjouksia</li>
                <li>katsella ja laatia sopimuksia</li>
                <li>ylläpitää valtuuttajan tietoja.</li>
              </ul>
              <p>
                Kun olet myöntämässä edustamisvaltuutta henkilölle, niin käytä tätä valtuutta. Edustamisvaltuudella asioiva käyttäjä ei pääse muiden kuin oman organisaationsa tekemien ilmoitusten tietoihin. Hän ei voi myöskään muokata valtuuttajaorganisaation tietoja eikä allekirjoittaa sopimuksia.
              </p>
  
              <p>
                <b>Jätehuoltoa ja materiaalien vaihdantaa koskevien sopimusten allekirjoittaminen</b>
              </p>
  
              Tällä valtuudella valtuutettu voi valtuuttajan puolesta allekirjoittaa jätehuollon, kiertotalouden ja näihin liittyvien palvelujen sopimuksia sekä hoitaa jätteiden ja tuotannon sivuvirtojen sekä niihin liittyvien palvelujen vaihdantaa koskevia asioita, kuten
              <ul>
                <li>etsiä tai tarjota jätehuolto- tai asiantuntijapalveluita</li>
                <li>etsiä tai tarjota hyödynnettäviä materiaaleja</li>
                <li>katsella, tehdä ja hyväksyä tarjouksia</li>
                <li>katsella ja laatia sopimuksia</li>
                <li>ylläpitää valtuuttajan tietoja.</li>
              </ul>

              <p>
                Jätelain mukaisen viranomaisen valtuutuksessa valitaan:
              </p>
              <p>
                <b>Kunnan toissijaiseen jätehuoltopalveluun liittyvien tietojen katselu</b>            
              </p>
              
              <p>
                Tämä valtuus on vain viranomaisten käytössä. <i>Tällä valtuudella valtuutettu voi valtuuttajan puolesta katsella kunnan toissijaiseen jätehuoltopalveluun liittyviä tietoja, kuten sopimustietoja.</i>
              </p>
            </li>
            <li>Määritä voimassaolo
              <p>
                Määrittele valtuuden alkamis- ja päättymispäivä joko valitsemalla päättymispäivä tai 1, 5, 15, 20 tai 25 vuotta.<br/>
                Valtuuden voi peruuttaa milloin tahansa. 
              </p>
            </li>
            <li>Tarkista yhteenveto ja vahvistus
              <p>
                Tarkista, että valtuuden tiedot ovat oikein ja vahvista valtuutus. <br/>
                Saat kuittauksen, että valtuuden vahvistaminen on onnistunut.  <br/>
                Yrityksesi antamat valtuudet näkyvät vahvistuksen jälkeen kohdassa "Annetut valtuudet". <br/>
              </p>
            </li>
          </ol>          

          <h3 id="request">Kuinka pyydät valtuuksia?</h3>

          <ol className="numberList">
            <li>Tunnistaudu <a href="https://www.suomi.fi/valtuudet/" target={"_blank"} rel="noopener noreferrer">Suomi.fi-palveluun</a>.</li>
            <li>Valitse ylävalikosta <b>"Valtuudet"</b></li>
            <li>
              <ol>
                <li>a) Valitse <b>”Yrityksen valtuudet”</b>, kun haluat pyytää yritykselle asiointivaltuutta ja valtuuttaa edelleen yrityksesi työntekijöitä edustamisvaltuuksin. Sen jälkeen valitse yritys/organisaatio, jonka puolesta haluat asioida.</li>
                <li>b) Valitse <b>”Henkilökohtaiset valtuudet”</b> kun haluat pyytää itsellesi asiointivaltuutta.</li>
              </ol>
            </li>
            <li>Klikkaa ”Pyydä valtuuksia”.</li>
            <li>Syötä tekstikenttään sen yrityksen y-tunnus, jolta haluat valtuuksia pyytää ja valitse yritys avautuvasta valinnasta. Sen jälkeen klikkaa ”Seuraava”.</li>
            <li>Valitse pyydettävät valtuusasiat, kts: <a href="/ohjeet/valtuudet#types">valitse valtuustyyppi</a></li>
            <li>Määritä voimassaolo
              <p>
                Määrittele valtuuden alkamis- ja päättymispäivä joko valitsemalla päättymispäivä tai 1, 5, 15, 20 tai 25 vuotta.
              </p>
            </li>
            <li>Tarkista yhteenveto ja vahvistus</li>
            <p>
              Tarkista, että valtuuden tiedot ovat oikein ja vahvista valtuuspyyntö. Valtuuspyynnön lähettämisen jälkeen pyynnön kohteena olevan organisaation edustajan on vielä hyväksyttävä/hylättävä valtuuspyyntö.
            </p>
          </ol>

          <h2 id="register">Miten rekisteröidyt ja kirjaudut Materiaalitorille?</h2>

          <p>
            Palveluun rekisteröityminen alkaa vahvalla tunnistautumisella Suomi.fi-palvelussa pankkitunnuksilla, mobiilivarmenteella tai varmennuskortilla. Tunnistautumisen yhteydessä rekisteröityjältä välitettävät tiedot ovat etu- ja sukunimi sekä henkilötunnus.
          </p>

          <p>
            Tunnistautumisen jälkeen valitset yrityksen, yhdistyksen tai muun yhteisön, jonka nimissä haluat asioida. Mikäli organisaatiota, jonka puolesta haluat asioida ei ole valittavissa, ei sinulla ole tarvittavia valtuuksia asiointia varten. Valtuuksia voi pyytää <a href="https://www.suomi.fi/valtuudet/" target={"_blank"} rel="noopener noreferrer">Suomi.fi-palvelussa</a>, kts. <a href="/ohjeet/valtuudet#request">”Kuinka pyydät valtuuksia?”</a>.
          </p>

          <p>
            Valittuasi organisaation, jonka valtuuksilla et ole aiemmin asioinut, tulet kenttään, jossa valitset käyttäjäroolin, jossa tulet palvelua käyttämään.
          </p>

          <h3 id="role">Käyttäjäroolin valinta</h3>
          
          <p>
            <b>Palvelun käyttäjä valitsee, missä käyttäjäroolissa hän palvelua käyttää.</b>
          </p>

          <p>
            <b>Yritys tai julkinen organisaatio:</b> yrityksen tai muun organisaation nimissä toimiva valtuutettu: jos et ole alla mainittu jätelaitoksen edustaja, aluekoordinaattori tai valvova viranomainen, valitset tämän valinnan kautta yrityksen tai muun organisaation valtuutuksella. Valtuutuksesta riippuen käyttäjällä on asiointioikeus tai asiointioikeus ja allekirjoitusoikeus Materiaalitorilla.
          </p>

          <p>
            <b>Kunnan jätelaitos:</b> vain kunnallisten jätelaitosten asioita hoitavat käyttävät palvelua tätä kautta. Valtuutuksesta riippuen käyttäjällä on asiointioikeus tai asiointioikeus ja allekirjoitusoikeus Materiaalitorilla.
          </p>

          <p>
            <b>Aluekoordinaattorit:</b> vain teollisten symbioosien palvelun aluekoordinaattorit. Motiva Oy tarkastaa valtuutettavan toimimisen aluekoordinaattorina. 
          </p>

          <p>
            <b>Viranomainen:</b> jätelaissa määritetty viranomainen, joilla on oikeus saada laissa säädettyjä välttämättömiä tehtävien hoitamiseksi kunnan toissijaiseen jätehuoltopalveluun liittyviä tietoja. Käytön aloittamisen yhteydessä ilmoitetaan ne jätelaitokset, joiden tietoihin viranomaisella on katseluoikeus. 
          </p> 

          <h3 id="registeredUserLogin">Rekisteröityneen käyttäjän kirjautuminen Materiaalitorille</h3>
            
          <p>
            Materiaalitoriin kirjautuminen tapahtuu palvelun oikeassa ylänurkassa olevan ”Kirjaudu/Rekisteröidy” -valinnan kautta. Valinta johtaa suomi.fi-tunnistautumiseen, minkä jälkeen pääset valitsemaan sen organisaation, jonka asioita haluat Materiaalitorilla hoitaa. Mikäli valittavaksi ei tule haluamaasi organisaatiota, sinulla ei ole kyseisen organisaation valtuutusta Materiaalitorin käyttöön. Valittuasi organisaation, Materiaalitori avautuu käyttöösi. 
          </p>
        </>
      );
    }
  }
};

export default OhjeetValtuudet;
