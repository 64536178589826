import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/date-utils';
import styles from './SimpleListView.module.css';
import { KYSELY } from '../../routes';

const linkToQuestionnaire = (id, title) => {
  return <Link to={`${KYSELY}/${id}`}>{title}</Link>;
};

const questionnaireItem = (questionnaire, t) => {

  return (
    <div key={questionnaire.id}  className={styles.item}>
      <h4>{linkToQuestionnaire(questionnaire.id, questionnaire.rfo.Title)}</h4>
      <div>
        {questionnaire.rfo.Materials &&
          questionnaire.rfo.Materials.map((material, idx) => {
            return (
              material.Classification && (
                <div key={idx}>
                  <span>{t(material.Classification)}</span>
                  <span>&nbsp;|&nbsp;</span>
                  <span>
                    {material.Continuity === 'onetime' && t('Kertaerä')}
                    {material.Continuity === 'continuous' && t('Jatkuva tuotanto')}
                  </span>
                </div>
              )
            );
          })}
        {questionnaire.rfo.MaterialsWanted &&
          questionnaire.rfo.MaterialsWanted.map((material, idx) => {
            return (
              material.Classification && (
                <div key={idx}>
                  <span>{t(material.Classification)}</span>
                </div>
              )
            );
          })}
        {questionnaire.rfo.Service &&
          questionnaire.rfo.Service.ServiceIds &&
          questionnaire.rfo.Service.ServiceIds.map((serviceId, idx) => {
            const isFirstServiceId = !idx;
            return (
              <span key={idx}>
                {!isFirstServiceId && <>{' | '}</>}
                <span>{t(serviceId)}</span>
              </span>
            );
          })}
      </div>
      
      {questionnaire.rfo.Company && questionnaire.rfo.Company.Name && (
        <div className={styles.validUntil}>
          <span>
            {questionnaire.rfo.Company.Name}
          </span>
          
          <span>{' | '}{t('Vastattu')}</span>&nbsp;<span>{formatDate(new Date(questionnaire.statusDate))}</span>
        </div>
      )}
      
    </div>
  );
}

const SimpleQuestionnaireListView = ({ questionnaires, t }) => {  
  return questionnaires
    .sort((a, b) => { return new Date(b.statusDate) - new Date(a.statusDate); })
    .map(questionnaire => questionnaireItem(questionnaire, t));
};

export default withNamespaces()(SimpleQuestionnaireListView);
