export const HANDLE_TSV_FORM_CHANGE = 'tsv/HANDLE_TSV_FORM_CHANGE';
export const CLEAR_TSV_FORM = 'tsv/CLEAR_TSV_FORM';
export const TSV_POPULATE_FACILITIES = 'tsv/TSV_POPULATE_FACILITIES';
export const LOADING_TSV = 'tsv/LOADING_TSV';
export const ADD_TSV_NEEDED_DATA_TO_TSV = 'tsv/ADD_TSV_NEEDED_DATA_TO_TSV';
export const CLEAR_TSV_GENERAL = 'tsv/CLEAR_TSV_GENERAL';
export const TSV_FORM_HAS_CHANGES = 'tsv/TSV_FORM_HAS_CHANGES';
export const ADD_CONTRACT_DRAFT = 'tsv/ADD_CONTRACT_DRAFT';
export const HANDLE_CONTRACT_CHANGE = 'tsv/HANDLE_CONTRACT_CHANGE';
export const DELETE_FILE = 'TSV/DELETE_FILE';
export const ADD_FILES = 'TSV/ADD_FILES';
export const ADD_CONTRACT_TO_VIEW = 'tsv/ADD_CONTRACT_TO_VIEW';
export const ADD_TSV_REQUEST_TO_REQUEST_VIEW = 'tsv/ADD_TSV_REQUEST_TO_REQUEST_VIEW';
export const HANDLE_TSV_VIEW_FORM_CHANGE = 'tsv/HANDLE_TSV_VIEW_FORM_CHANGE';
export const CLEAR_TSV_VIEW = 'tsv/CLEAR_TSV_VIEW';
export const ADD_RFO_TO_TSV = 'tsv/ADD_RFO_TO_TSV';
export const CLEAR_RFO_FROM_TSV = 'tsv/CLEAR_RFO_FROM_TSV';
export const FETCH_CONTRACTS = 'tsv/FETCH_CONTRACTS';
