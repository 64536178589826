export const groupSubServices = (searchServices, services, t) => {
  const subServiceIds = searchServices.filter(serv => services.map(service => service.id).indexOf(serv) === -1);

  let serviceGroups = [];

  services.forEach(service => {
    if (service.subServices)
    {
      subServiceIds.forEach(subServiceId => {
        const subService = service.subServices.find(x => x.id === subServiceId);

        if (subService)
        {
          let existingGroup = serviceGroups.find(item => item.subServices.indexOf(subServiceId) > -1);
          if (existingGroup)
          {
            let index = serviceGroups.indexOf(existingGroup);
            if (existingGroup.serviceIds.indexOf(service.id) === -1)
            {
              serviceGroups[index].name = serviceGroups[index].name + `, ${t(service.id)}`;
              serviceGroups[index].serviceIds.push(service.id);
            }
          } else {
            let existingGroup = serviceGroups.find(item => item.serviceIds.indexOf(service.id) > -1);
            if(existingGroup)
            {
              let index = serviceGroups.indexOf(existingGroup);
              serviceGroups[index].subServices.push(subService.id);
            } else {
              serviceGroups.push({
                name: t(service.id),
                subServices: [subService.id],
                serviceIds: [service.id]
              });
            }
          }
        }
      });
    }
  });
  return serviceGroups;
}

export const groupSubMaterials = (searchClassifications, classifications, t) => {
  const subMaterialIds = searchClassifications.filter(cls => classifications.map(classification => classification.id).indexOf(cls) === -1);

  let materialGroups = [];

  classifications.forEach(classification => {
    if (classification.subMaterialTypes)
    {
      subMaterialIds.forEach(subMaterialId => {
        const subMaterial = classification.subMaterialTypes.find(x => x.id === subMaterialId);
        if (subMaterial)
        {
          let existingGroup = materialGroups.find(item => item.subMaterials.indexOf(subMaterialId) > -1);
          if (existingGroup)
          {
            if (existingGroup.subMaterialTypes.indexOf(classification.id) === -1)
            {
              let index = materialGroups.indexOf(existingGroup);
              materialGroups[index].name = materialGroups[index].name + `, ${t(classification.id)}`;
            }
          } else {
            let existingGroup = materialGroups.find(item => item.materialIds.indexOf(classification.id) > -1);
            if(existingGroup)
            {
              let index = materialGroups.indexOf(existingGroup);
              materialGroups[index].subMaterials.push(subMaterial.id);
            } else {
              materialGroups.push({
                name: t(classification.id),
                subMaterials: [subMaterial.id],
                materialIds: [classification.id]
              });
            }
          }
        }
      });
    }
  });

  return materialGroups;
}
