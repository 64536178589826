import * as API_ENDPOINTS from '../../../services/endpoints';
import { getJsonData } from '../../../services/ApiService';
import { 
  addQuestionnaireToView, 
  handleQuestionnaireFormChange, 
  setQuestionnaireHasChanges,
  setQuestionnaireNotFound,
  setLoadingQuestionnaires,
  setQuestionnaires
 } from "./actions";

const fetchOpenQuestionnaires = () => (dispatch) => {
  dispatch(setLoadingQuestionnaires(true));

  const fetchUrl = API_ENDPOINTS.FETCH_OPEN_QUESTIONNAIRES;
  
  getJsonData(fetchUrl)
  .then((data) => {
    dispatch(setQuestionnaires(data.payload.result));
  })
  .catch((error) => {
    console.log(error);
  }).finally(() => { dispatch(setLoadingQuestionnaires(false)); })
}

const fetchQuestionnaireToView = (id) => (dispatch) => {
  
  const fetchUrl = API_ENDPOINTS.FETCH_QUESTIONNAIRE + '/' + id;

  getJsonData(fetchUrl)
    .then((data) => dispatch(addQuestionnaireToView(data.payload)))
    .catch((error) => {
      const { response } = error;

      if (response && response.status === 404) {        
        dispatch(setQuestionnaireNotFound(true));
      }

      console.log(error);
    })
};

export {
  fetchOpenQuestionnaires,
  fetchQuestionnaireToView,
  handleQuestionnaireFormChange,
  setQuestionnaireHasChanges,
  setQuestionnaireNotFound,
  setLoadingQuestionnaires,
  setQuestionnaires
}