import React, { Component } from 'react';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import cx from 'classnames';
import { Container, Row, Col } from '../Layout/Layout';
import { withNamespaces } from 'react-i18next';
import styles from './RfoSummary.module.css';
import { compose, without, prop, either, map } from 'ramda';
import { connect } from 'react-redux';
import { getYearRange } from '../../utils/date-utils';
import qs from 'qs';
import { getJsonData, getExportData } from '../../services/ApiService';
import { FETCH_QUESTIONNAIRES, EXPORT_QUESTIONNAIRES } from '../../services/endpoints';
import Loader from '../Loader/Loader';
import { SEARCH_TYPES, MATERIAL_TYPES, CONTINUITY_TYPES } from './types';
import withCancelToken from '../CancelToken/withCancelToken';
import QuestionnaireSummaryResultView from './QuestionnaireSummaryResultView';
import { getMaterialSearch, getReceivingMaterialSearch, getServiceSearch, getMaterialSelector, getServiceSelector, getContinuityTypeSelector, getLocationSelector, getReceivingServicesSearch, getReceivingServicesSelector } from './summary-util';

const initialSearchState = {
  materialType: MATERIAL_TYPES.WASTE,
  classifications: [],
  service: [],
  continuityType: CONTINUITY_TYPES.ALL,
  timePeriod: '',
  regions: []
};

class QuestionnaireSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: SEARCH_TYPES.ALL,
      ...initialSearchState,
      results: {
        searchType: undefined,
        resultSet: []
      },
      loading: false
    };
  }

  onExport = () => {
    
    const config = this.getConfig();    
    config.responseType = 'arraybuffer';

    this.setState({
      loading: true
    });

    getExportData(EXPORT_QUESTIONNAIRES, config)
    .then(res => {

      var filename = "";
      
      var disposition = res.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }

      var downloadUrl =''
      try {
        downloadUrl = URL.createObjectURL(new Blob([res.data], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
      } catch (err)
      {
        console.log(err);        
      }
            
      if (filename) {
        var a = document.createElement("a");
        if (typeof a.download === 'undefined') {
            window.location.href = downloadUrl;
        } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
        }
      }
    })
    .catch(thrown => {
      console.log(thrown);
      if (!this.props.isCancel(thrown)) {
        this.setState({ loading: false });
      }
    })
    .finally(() => {
      this.setState({
        loading: false
      });
    });
  }

  onSubmit = () => {
    const config = this.getConfig();

    this.setState({
      loading: true,
      results: {
        resultSet: [],
        searchType: this.state.searchType
      }
    });

    getJsonData(FETCH_QUESTIONNAIRES, config)
      .then(res => {
        this.setState({
          loading: false,
          results: {
            ...this.state.results,
            resultSet: res.payload
          }
        });
      })
      .catch(thrown => {
        if (!this.props.isCancel(thrown)) {
          this.setState({ loading: false });
        }
      });
  };

  getConfig = () => {
    let queryParams = {
      searchType: this.state.searchType,
      year: this.state.timePeriod,
      locations: map(either(prop('id'), prop('regionId')), this.state.regions)
    };

    if (this.state.searchType === SEARCH_TYPES.OFFERING_MATERIALS) {
      queryParams = {
        ...queryParams,
        materialType: this.state.materialType,
        classifications: this.state.classifications,
        continuityType: this.state.continuityType
      };
    }

    if (
      (this.state.searchType === SEARCH_TYPES.OFFERING_MATERIALS &&
       this.state.materialType === MATERIAL_TYPES.WASTE) ||
       this.state.searchType === SEARCH_TYPES.OFFERING_SERVICES ||
       this.state.searchType === SEARCH_TYPES.RECEIVING_SERVICES
    ) {
      queryParams = {
        ...queryParams,
        service: this.state.service
      };
    }

    if (this.state.searchType === SEARCH_TYPES.RECEIVING_MATERIALS) {
      queryParams = {
        ...queryParams,
        classifications: this.state.classifications
      };
    }   

    return {
      params: queryParams,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
      cancelToken: this.props.cancelTokenSource.token
    };
  }

  updateSearchTerm = (name, value) => {
    if (this.state[name].indexOf(value) > -1) {
      this.setState({ [name]: without([value], this.state[name]) });
    } else {
      this.setState({
        [name]: this.state[name].concat([value])
      });
    }
  };

  handleSearchTypeChange = event => {
    const { target } = event;
    const { name, value } = target;
    this.setState({
      [name]: value,
      ...initialSearchState
    });
  };

  toggleSearchTerm = event => {
    const { target } = event;
    const { name, value } = target;
    this.updateSearchTerm(name, value);
  };

  deleteSearchTerm = name => value => {
    this.updateSearchTerm(name, value);
  };

  toggleRegion = region => {
    if (!region) {
      this.setState({
        regions: []
      });
    } else {
      this.updateSearchTerm('regions', region);
    }
  };

  handleChange = event => {
    const { target } = event;
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  searchTermsView = (name, terms) =>
    terms.map(term => {
      return (
        <button
          key={term}
          type="button"
          className={'searchTerms__Button'}
          onClick={() => this.deleteSearchTerm(name)(term)}
        >
          {this.props.t(term)}
          <span className={'searchTerms__Button__x'} aria-label={this.props.t('Poista hakuehto')}>
            &times;
          </span>
        </button>
      );
    });

render() {
  const { t, services } = this.props;
  const {
    regions,
    classifications,
    service,
    searchType,
    materialType,
    continuityType
  } = this.state;
  const yearRange = getYearRange(2022, new Date());

  return (
    <>
      <Header />
      <Navigation />
      <Container className={cx('flex-grow-1')}>
        <Row options={{ center: true }}>
          <Col span={10} sm={12} xs={12}>
            <h1 className={cx('textCenter')}>{t('Palautekyselyt')}</h1>
            <div className={cx('divider')} />
          </Col>
        </Row>

        <Row options={{ center: true }}>
          <Col span={10} sm={12} xs={12}>
            <div className={cx(styles.searchContainer)}>
              <Row className={cx(styles.verticalMargin1em)}>
                <Col span={12} sm={12} xs={12}>
                  <h2 className={'textCenter'}>{t('Hae ilmoitustyypin perusteella')}</h2>
                </Col>
              </Row>
              <form className={cx(styles.searchForm)}>
                <Row className={cx(styles.verticalMargin1em)}>
                  <Col span={2} sm={2} xs={12}>
                    <label>
                      <input
                        type="radio"
                        name="searchType"
                        className="radioButton"
                        value={SEARCH_TYPES.ALL}
                        onChange={this.handleSearchTypeChange}
                        checked={searchType === SEARCH_TYPES.ALL}
                      />
                      <span className='radioButtonLabel'>
                        {t('Kaikki')}
                      </span>
                    </label>
                  </Col>
                  <Col span={2} sm={2} xs={12}>
                    { getMaterialSearch(searchType, materialType, this.handleChange, this.handleSearchTypeChange, t) }
                  </Col>
                  <Col span={2} sm={2} xs={12}>
                    { getReceivingMaterialSearch(searchType, this.handleSearchTypeChange, t) }
                  </Col>
                  <Col span={3} sm={3} xs={12}>
                    { getServiceSearch(searchType, this.handleSearchTypeChange, t) }
                  </Col>
                  <Col span={3} sm={3} xs={12}>
                    { getReceivingServicesSearch(searchType, this.handleSearchTypeChange, t) }
                  </Col>
                </Row>

                <Row className={cx(styles.verticalMargin1em)}>
                  <Col span={6} sm={6} xs={12}>
                    {searchType !== SEARCH_TYPES.OFFERING_SERVICES && searchType !== SEARCH_TYPES.RECEIVING_SERVICES && searchType !== SEARCH_TYPES.ALL && (
                      <>
                        { getMaterialSelector(this.toggleSearchTerm, this.searchTermsView, classifications, this.state['classifications'], t) }
                      </>
                    )}
                  </Col>

                  <Col span={6} sm={6} xs={12}>
                    {((searchType === SEARCH_TYPES.OFFERING_MATERIALS &&
                      materialType === MATERIAL_TYPES.WASTE) ||
                      searchType === SEARCH_TYPES.OFFERING_SERVICES) && (
                      <>
                        { getServiceSelector(service, services, this.toggleSearchTerm, this.searchTermsView, this.state['service'], t) }
                      </>
                    )}
                  </Col>

                  <Col span={6} sm={6} xs={12}></Col>
                  <Col span={6} sm={6} xs={12}>
                    {(searchType === SEARCH_TYPES.RECEIVING_SERVICES) && (
                      <>
                        { getReceivingServicesSelector(service, services, this.toggleSearchTerm, this.searchTermsView, this.state['service'], t) }
                      </>
                    )}
                  </Col>
                </Row>

                <Row className={cx(styles.verticalMargin1em)}>
                  <Col span={3} sm={4} xs={12}>
                    {searchType === SEARCH_TYPES.OFFERING_MATERIALS && (
                      <>
                        { getContinuityTypeSelector(continuityType, this.handleChange, t) }
                      </>
                    )}
                  </Col>
                  <Col span={3} sm={4} xs={12} />
                  <Col span={3} sm={1} xs={12} />
                  <Col span={3} sm={3} xs={12} />
                </Row>

                <Row className={cx(styles.verticalMargin1em)}>
                  <Col span={6} sm={6} xs={12}>
                    <>
                      { getLocationSelector(this.state.searchType, regions, this.toggleRegion, t) }
                    </>
                  </Col>
                  <Col span={6} sm={6} xs={12}>
                    <label>
                      <span className={styles.defaultLabelSpan}>
                        <strong>{t('Ajankohta')}</strong>
                      </span>
                      <select
                        onChange={this.handleChange}
                        name="timePeriod"
                        value={this.state.timePeriod}
                      >
                        <option value="" disabled hidden>
                          {t('Valitse ajankohta')}
                        </option>
                        <option value="0">{t('Kaikki')}</option>
                        {yearRange.map(year => {
                          return (
                            <option key={year.toString()} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </Col>
                </Row>
                <Row options={{ center: true }}>
                  <Col span={3} sm={4} xs={8} className={styles.centerInlineChildren}>
                    <button type="button" className={cx('buttonStyle')} onClick={this.onSubmit}>
                      {t('Hae tiedot')}
                    </button>
                  </Col>
                  <Col span={3} sm={4} xs={8} className={styles.centerInlineChildren}>
                    <button type="button" className={cx('buttonStyle')} onClick={this.onExport}>
                      {t('Vie tiedot')}
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>

        <Row options={{ center: true }}>
          <Col span={10} sm={12} xs={12}>
            <Loader loading={this.state.loading}>
              {this.state.results.resultSet.length > 0 && (
                <QuestionnaireSummaryResultView
                  searchType={this.state.results.searchType}
                  data={this.state.results.resultSet}
                />
              )}
              {!this.state.loading &&
                this.state.results.searchType &&
                this.state.results.resultSet.length === 0 && (
                  <div className={styles.margin2emPaddingLeft10px}>{t('Ei hakutuloksia.')}</div>
                )}
            </Loader>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
}

const mapStateToProps = state => ({
  services: state.generalState.configurations.services
});

export default compose(
  withCancelToken,
  connect(mapStateToProps),
  withNamespaces()
)(QuestionnaireSummary);