import cx from 'classnames';
import { clone, compose } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postJson } from '../../services/ApiService';
import { SAVE_SEARCH } from '../../services/endpoints';
import { addToastNotification, ToastTypes } from '../../services/ToastService';
import { searchOperations } from '../../state/ducks/search';
import { Col, Container, Row } from '../Layout/Layout';
import Loader from '../Loader/Loader';
import styles from './AddSavedSearch.module.css';
import { groupSubServices, groupSubMaterials } from './Search.util.js';

class AddSavedSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: '',
      interval: '',
      search: props.search,
    };
  }

  handleChange = (event) => {
    const target = event.target;
    let draft = {};
    draft[target.name] = target.value;
    this.setState(draft);
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const postUrl = SAVE_SEARCH;
    let payload = {
      name: this.state.name,
      interval: this.state.interval,
      search: clone(this.state.search),
    };
    if (Array.isArray(this.state.search.location)) {
      payload.search.location = payload.search.location.map((loc) => {
        return loc.id || loc.regionId;
      });
    }
    postJson(postUrl, payload)
      .then((res) => {
        this.props.addSavedSearch(res.data);
        this.props.done(res.data);

        addToastNotification(this.props.t('Hakuvahti tallennettu.'), ToastTypes.SUCCESS);
      })
      .catch((err) => {
        addToastNotification(this.props.t('Hakuvahdin tallennus epäonnistui.'), ToastTypes.WARNING);
      });
  };

  isFilled = () => {
    return (
      this.state.name && this.state.name.length && this.state.interval && this.state.interval.length
    );
  };

  renderSearchTerms = (label, terms, termKey, termValue) =>
    Array.isArray(terms) &&
    terms.length > 0 && (
      <Col span={6} xs={12}>
        <h3>{label}</h3>
        <ul key={label+'list'}>
          {terms.map((term) => (
            <li key={termKey(term)}>
              {termValue(term)}
            </li>
          ))}
        </ul>
      </Col>
    );

  getServicesFromFlatList = (services) => {
    let servicesToShow = [];
      services.forEach(srv => {
      const service = this.props.services.find(s => s.id === srv);
        if(service && service.subServices)
      {
        servicesToShow.push(service.id)
      }     
    });
      return servicesToShow;
  }

  getClassificationsFromFlatList = (classifications) => {
    let classificationsToShow = [];

    classifications.forEach(cls => {
      const classification = this.props.materials.find(m => m.id === cls);

      if(classification && classification.subMaterialTypes)
      {
        classificationsToShow.push(classification.id)
      }       
    });

    return classificationsToShow;
  }

  handleScroll = (e) => {
    this.setState({ top: window.scrollY * -1 });
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.handleScroll);
  }

  render() {
    //  required ? 'requiredIndicator' : null
    const { t, search } = this.props;
    return (
      <>
        <div className={styles.modalOverlay} />
        <form className={styles.form} style={{ top: this.state.top }}>
          <Container className={cx('flex-grow-1', styles.formContainer)}>
            <Row className={cx(styles.formRow, styles.formRowHeader)}>
              <Col span={12} className={'textCenter'}>
                {t('Tallenna hakuvahti')}
              </Col>
            </Row>
            <Row className={cx(styles.formRow)}>
              <Col span={12}>
                {t('Hakuvahdit näkyvät valikossa hakukoneen alareunassa sekä listana Oma sivu -osion etusivulla.')}
              </Col>
            </Row>
            <Row className={cx(styles.formRow)}>
              <Col span={12}>
                <div className={styles.nameLabel}><span><b>{t('Nimeä haku')}</b></span> <span>({t('nimi näkyy vahdin otsikkona hakuvahtien listauksissa')})</span></div>
              </Col>
              <Col span={8} xs={12}>
                <input
                  className={styles.modalTextInput}
                  type="text"
                  name="name"
                  value={this.state.name || ''}
                  onChange={this.handleChange}
                  maxLength={100}
                />
              </Col>
            </Row>
            <Row className={cx(styles.formRow)}>
            {search.text && (              
                <Col span={6} xs={12}>
                  <h3>{t('Hakusana')}</h3>
                  <div>{search.text}</div>
                </Col>              
              )}
              {this.renderSearchTerms(
                t('Ilmoituksen tyyppi'),
                search.rfoType,
                (rfoType) => {
                  return rfoType;
                },
                (rfoType) => {
                  return t(rfoType + '-title');
                }
              )}
            </Row>
            <Row className={cx(styles.formRow)}>
              {this.renderSearchTerms(
                t('Materiaali'),
                this.getClassificationsFromFlatList(search.classification).filter(mat => this.props.materials.map(material => material.id).indexOf(mat) > -1),                
                (classification) => {
                  return classification;
                },
                (classification) => {
                  return t(classification);
                }
              )}
              <Col span={6} xs={12}>
                <h3>{t('Materiaalin tarkenne')}</h3>
                { groupSubMaterials(search.classification, this.props.materials, t).map(group => {
                  return (
                    <div key={group.name}>
                      <div className={styles.groupLabel}>{group.name}:</div>
                      <ul key={group.name+'list'}>
                      {group.subMaterials && group.subMaterials.map(material => {                        
                        return (
                          <li key={material}>{t(material)}</li>
                        )
                        })}                        
                      </ul>
                    </div>
                  )
                })}
              </Col>
            </Row>
            <Row className={cx(styles.formRow)}>
              {this.renderSearchTerms(
                t('Palvelut'),
                this.getServicesFromFlatList(search.service).filter(serv => this.props.services.map(service => service.id).indexOf(serv) > -1),
                (service) => {
                  return service;
                },
                (service) => {
                  return t(service);
                }
              )}

              <Col span={6} xs={12}>
                <h3>{t('Palvelun tarkenne')}</h3>
                { groupSubServices(search.service, this.props.services, t).map(group => {
                  return (
                    <div key={group.name}>
                      <div className={styles.groupLabel}>{group.name}:</div>
                      <ul key={group.name+'list'}>
                      {group.subServices && group.subServices.map(service => {
                        
                        return (
                          <li key={service}>{t(service)}</li>
                        )
                        })}
                        
                      </ul>
                    </div>
                  )
                })}
              </Col>                     
            </Row>
            <Row className={cx(styles.formRow)}>
              {
              
              this.renderSearchTerms(
                t('Sijainnit'),
                search.location,
                (location) => {
                  return location;
                },
                (location) => {
                  const loc = this.props.municipalitiesAndRegions.find(x => x.id === location || (!x.id && x.regionId === location));
                  return t(loc.nameFi || loc.regionNameFi);
                }
              )}
            </Row>
            <Row className={cx(styles.formRow)}>
              <Col span={12}>
                <h3>{t('Haluan viestin uusista hakuosumista sähköpostiini')}</h3>
                <div className={styles.watcherRadioContainer} onChange={this.handleChange.bind(this)}>
                  <input
                    type="radio"
                    name="interval"
                    value="0"
                    id="freq-0"
                    className={'radioButton'}
                  />
                  <label htmlFor="freq-0" className={cx(['radioButtonLabel', styles.watcherRadioButton])}>
                    {t('Ei koskaan (tallennettu haku)')}
                  </label>

                  <input
                    type="radio"
                    name="interval"
                    value="100"
                    id="freq-100"
                    className={'radioButton'}
                  />
                  <label htmlFor="freq-100" className={cx(['radioButtonLabel', styles.watcherRadioButton])}>
                    {t('Heti')}
                  </label>

                  <input
                    type="radio"
                    name="interval"
                    value="200"
                    id="freq-200"
                    className={'radioButton'}
                  />
                  <label htmlFor="freq-200" className={cx(['radioButtonLabel', styles.watcherRadioButton])}>
                    {t('Kerran päivässä')}
                  </label>

                  <input
                    type="radio"
                    name="interval"
                    value="300"
                    id="freq-300"
                    className={'radioButton'}
                  />
                  <label htmlFor="freq-300" className={cx(['radioButtonLabel', styles.watcherRadioButton])}>
                    {t('Kerran viikossa')}
                  </label>
                </div>
              </Col>
            </Row>

            <Row className={cx(styles.formRow)}>
              <Col span={12} className={cx('textCenter', styles.buttonContainer)}>
                <button
                  type="button"
                  onClick={this.props.done}
                  className={cx('cancel-blue-white', styles.marginRight1rem)}
                >
                  {t('Peruuta')}
                </button>
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  disabled={this.state.loading || !this.isFilled()}
                >
                  {t('Tallenna')}
                </button>
                <Loader loading={this.state.loading} />
              </Col>
            </Row>
          </Container>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isTouchDevice: state.generalState.isTouchDevice,
    municipalitiesAndRegions: state.generalState.location.regionsAndMunicipalities,
    services: state.generalState.configurations.services,
    materials: state.generalState.configurations.materials,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSavedSearch: (savedSearch) => dispatch(searchOperations.addSavedSearch(savedSearch)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddSavedSearch);
