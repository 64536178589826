import cx from 'classnames';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ILMOITUKSET, LISAA_ILMOITUS } from '../../routes';
import * as AuthService from '../../services/AuthService';
import '../../state/store.js';
import commonStyles from '../../styles/common.module.css';
// import { getAuthServerUrl } from '../../utils/config-utils';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { Col, Container, Row } from '../Layout/Layout';
import Navigation from '../Navigation/Navigation';
import FetchRfos from '../RequestForOffer/FetchRfos';
import SimpleListView from '../RequestForOffer/SimpleListView';
import BasicStatistics from './BasicStatistics';
import styles from './Home.module.css';
import RssFeed from '../RssFeed/RssFeed';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  login = () => {
    this.setState({ loading: true });
    return AuthService.login(AuthService.userManager, {
      redirectTo: this.props.location.pathname,
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { t } = this.props;

    // const registrationUrl = getAuthServerUrl();

    const demandParams = {
      PageSize: 3,
      rfoType: ['offeringWaste', 'offeringMaterial'],
    };
    const offerParams = {
      PageSize: 3,
      rfoType: ['receivingMaterial'],
    };
    const serviceParams = {
      PageSize: 3,
      rfoType: ['offeringServices'],
    };
    const receivingServicesParams = {
      PageSize: 3,
      rfoType: ['receivingServices'],
    };

    const rfoColumn = (rfoFetchParams, title) => {
      return (
      <Col span={3} xs={12} className={commonStyles.rfoSimpleList}>
        <div className={commonStyles.rfoSimpleListLayout}>
            <FetchRfos
              title={title}
              rfosParams={rfoFetchParams}
              renderRfos={(rfos) => <SimpleListView rfos={rfos} fullHeightList={true} noResultsText={t('Ei ilmoituksia.')} />}
            />
        </div>
      </Col>
      )
    }

    return (
      <>
        <Header />
        <Navigation />
        <div className="flex-grow-1">
          <Container className={cx('flex-grow-1')}>
            <Row className={styles.heroRow}>
              <div>
                <Col span={6} sm={12} xs={12}>
                  <div className={cx(styles.hero)}>
                    <h1>
                      {t('Materiaalitori vauhdittaa kiertotaloutta')}
                    </h1>

                    <p className={styles.liftElement__bodyText}>
                      {t(
                        'Materiaalitori edistää jätteiden, sivuvirtojen ja ylijäämämateriaalien sekä uudelleen käytettävien osien hyötykäyttöä tarjoamalla kohtaamispaikan, jossa materiaalien tarjoajat ja tarvitsijat voivat löytää toisensa. Materiaalitorilla voi myös etsiä ja tarjota näihin materiaaleihin liittyviä palveluja, kuten jätehuolto- ja asiantuntijapalveluja. Materiaalitorilla voi tarjota ja hakea myös kiertotalouden asiantuntijapalveluita ja osaamista.'
                      )}
                    </p>
                    <p className={styles.liftElement__bodyText}>
                      {t(
                        'Materiaalien kierrätyksellä ja uudelleenkäytöllä voidaan vähentää luonnonvarojen kulutusta ja vähentää ympäristökuormitusta. Kiertotaloutta edistää erityisesti se, että hyödynnettävissä olevat materiaalit ovat nähtävissä ennakoidusti ja alueellisesti. Tämä mahdollistaa kustannustehokkaan toiminnan ja uusien hyödyntämistapojen ja liiketoiminnan kehittämisen.'
                      )}
                    </p>
                    <p className={styles.liftElement__bodyText}>
                      {t(
                        'Materiaalitori on tarkoitettu yritysten ja organisaatioiden jätteiden ja sivuvirtojen ammattimaiseen vaihdantaan ja näihin liittyviin palveluihin. Palvelun omistaa ympäristöministeriö ja sitä ylläpitää Motiva Oy.'
                      )}
                    </p>
                    <p className={styles.liftElement__bodyText}>
                      {t('Materiaalitorin info-sivut kertovat lisää käyttömahdollisuuksista.')}
                    </p>
                    <p className={styles.liftElement__bodyText}>
                      <a href="https://info-materiaalitori.fi/">
                        {t(
                          'Materiaalitorin infosivuille.'
                        )}
                      </a>
                    </p>
                  </div>
                </Col>
              <Col span={6} className={cx('flex-grow-1', styles.heroContainer)} options={{ hiddenXs: true , hiddenSm: true}}></Col>
              </div>
            </Row>
          </Container>
          <Container className={cx(styles.marginTop2em, styles.marginBottom3em)}>
            <h1 className={'textCenter'}>{t('Uusimmat ilmoitukset Materiaalitorissa')}</h1>
            <Row>
              {rfoColumn(offerParams, t('Etsitään materiaalia'))}
              {rfoColumn(demandParams, t('Tarjotaan materiaalia'))}
              {rfoColumn(serviceParams, t('Tarjotaan palveluja'))}
              {rfoColumn(receivingServicesParams, t('Etsitään palveluja'))}
            </Row>
          </Container>
          <Container className={cx('flex-grow-1')}>
            <Row>
              <Col span={12}>
                <div className={cx(['textCenter', styles.buttonContainer])}>
                  <Link className={cx('buttonStyle', styles.buttonMargin)} to={ILMOITUKSET}>
                    {t('Hae & selaa ilmoituksia')}
                  </Link>
                  <Link className={cx('buttonStyle', styles.buttonMargin)} to={LISAA_ILMOITUS}>
                    {t('+ Lisää ilmoitus')}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className={cx('flex-grow-1')}>
            <Row className={styles.heroRowBlue}>
              <Col span={6} sm={12} xs={12}>
                <div className={styles.heroWrapper}>
                  <div className={cx(styles.hero)}>
                    <h1>
                      {t('Rekisteröidy Materiaalitoriin ja aloita käyttö')}
                    </h1>

                    <p className={styles.liftElement__bodyText}>
                      {t(
                        'Materiaalitorille rekisteröityäksesi tarvitset edustamasi organisaation valtuutuksen.'
                      )}
                    </p>
                    <p className={styles.liftElement__bodyText}>
                      {t(
                        'Rekisteröidyttyäsi Materiaalitoriin pääset laatimaan ilmoituksia, vastaamaan ilmoituksiin ja asettamaan hakuvahteja. Ilman rekisteröitymistä voit selata ilmoituksia.'
                      )}
                    </p>
                    <p>
                    <button className={cx(styles.actionButton)} type="button" onClick={this.login}>
                      <span className={styles.actionButton__link}>
                        {`${t('Rekisteröidy')}`}
                      </span>
                    </button>
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={6} className={cx('flex-grow-1', styles.heroContainerWide)} options={{ hiddenXs: true , hiddenSm: true}}></Col>
            </Row>
          </Container>
          <Container className={cx('flex-grow-1')}>
            <Row>
              <Col span={6} xs={12}>
                <div className={cx(styles.liftElement)}>

                  <h1>{t('Jätehuollon kilpailutus ja kunnan toissijaisen jätehuoltopalvelun pyytäminen')}</h1>                  
                  <p className={styles.liftElement__bodyText}>
                    {t(
                      'Materiaalitorilla voidaan kilpailuttaa jätehuoltopalvelut ja pyytää kunnan toissijaista palvelua, jos markkinaehtoista palvelua ei ole saatavilla. Materiaalitori tuo läpinäkyvyyttä kunnan toissijaisen jätehuoltopalvelun käyttöön ja sen edellytyksenä olevan muun palvelutarjonnan puutteen osoittamiseen. Jätelaki velvoittaa Materiaalitorin käyttöön jätteen haltijat, jotka tarvitsevat kunnan toissijaista jätehuoltopalvelua vuodessa yli 2000 euron arvosta.'
                    )}
                  </p>
                  <p>
                    <a href="https://info-materiaalitori.fi/jatehuoltopalvelut/jatehuollon-kilpailutus/">
                      {t('Lisätietoa')}
                    </a>
                  </p>

                  <h1 className={styles.marginTop2em}>{t('Materiaalitorin infosivut')}</h1>
                  <p>
                  <a href="https://info-materiaalitori.fi/">
                      {t(
                        'Lisää tietoa Materiaalitorin käytöstä löydät täältä.'
                      )}
                    </a>
                  </p>
                </div>
              </Col>
              <Col span={6} xs={12}>
                <div className={cx(styles.liftElement)}>
                  <h1>{t('Ilmoita ja etsi hyödynnettäviä materiaaleja')}</h1>
                  <p className={styles.liftElement__bodyText}>
                    {t(
                      'Materiaalitori tarjoaa avoimen vaihdanta-alustan yritysten ylijäämämateriaaleille, jätteille ja sivuvirroille. Yritykset voivat tuoda Materiaalitorissa näkyviin vielä hyödyntämättömät hukat ja ylijäämät, sillä niille voidaan löytää käyttöä.'
                    )}
                  </p>
                  <p>
                    <a href="https://info-materiaalitori.fi/materiaalihaku/">
                      {t('Lisätietoa')}
                    </a>
                  </p>
                  <h1 className={styles.marginTop2em}>{t('Jätehuoltoon ja kiertotalouteen liittyvät palvelut')}</h1>
                  <p className={styles.liftElement__bodyText}>
                    {t(
                      'Materiaalitorissa tarjotaan materiaalien kuljetukseen, käsittelyyn ja varastointiin liittyvien palveluiden lisäksi myös asiantuntija- ja analyysipalveluita. Asiantuntijapalveluita voi tarjota ja etsiä palveluilmoituksilla. Asiantuntijapalveluiden hakutoimintoja hyödynnetään Kiertotalous-Suomessa ja teollisten symbioosien palveluissa. '
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className={cx('flex-grow-1')}>
            <Row>
              <Col span={1} options={{ hiddenXs: true }}></Col>
              <Col span={10} xs={12}>
                <div className={cx(styles.liftElement)}>
                  <h1>{t('Ajankohtaista')}</h1>
                  <RssFeed></RssFeed>
                  <p>
                    <a target="_blank" rel="noopener noreferrer" href="https://info-materiaalitori.fi/ajankohtaista/">
                      {t(
                        'Lue lisää ajankohtaisista asioista'
                      )}
                    </a>
                  </p>
                </div>
              </Col>
              <Col span={1} options={{ hiddenXs: true }}></Col>
            </Row>
          </Container>

          <Container className={cx('flex-grow-1')}>
          <Row>
            <Col span={12} className={styles.heroContainerLeft} options={{ hiddenSm: true, hiddenXs: true }}>
            <div className={styles.heroRowRecycle}>
              <div>
                <Col span={6} sm={4} />
                <Col span={6} sm={8}>
                    <h2>
                      {t('Materiaalitori osana Kiertotalous-Suomea')}
                    </h2>

                    <p className={styles.liftElement__bodyText}>
                      {t(
                        'Kiertotalous-Suomi on osaamisen ja tiedon solmukohta, joka yhdistää ratkaisuja etsivät toimijat ja niiden tarjoajat. Verkosto tukee yrityksiä, kuntia, oppilaitoksia, tutkimuslaitoksia ja kiertotalouden parissa toimivia oman kiertotalouspolun löytämisessä.'
                      )}
                    </p>                   
                    <p>
                      <a className={styles.underline} target="_blank" rel="noopener noreferrer" href="https://kiertotaloussuomi.fi/">
                        {t('Tutustu Kiertotalous-Suomeen')}
                      </a>
                    </p>
                </Col>
              </div>
            </div>
            </Col>

            <Col span={12} options={{ hiddenMd: true }}>
              <div className={styles.heroRowRecycle}>
                  <div>
                    <h1>
                      {t('Materiaalitori osana Kiertotalous-Suomea')}
                    </h1>

                    <p className={styles.liftElement__bodyText}>
                      {t(
                        'Kiertotalous-Suomi on osaamisen ja tiedon solmukohta, joka yhdistää ratkaisuja etsivät toimijat ja niiden tarjoajat. Verkosto tukee yrityksiä, kuntia, oppilaitoksia, tutkimuslaitoksia ja kiertotalouden parissa toimivia oman kiertotalouspolun löytämisessä.'
                      )}
                    </p>
                    <p className={styles.liftElement__bodyText}>
                      {t(
                        'Kiertotalous-Suomi toivottaa uudet toimijat tervetulleiksi mukaan.'
                      )}
                    </p>
                    <p>
                      <a className={styles.underline} target="_blank" rel="noopener noreferrer" href="https://kiertotaloussuomi.fi/">
                        Tutustu Kiertotalous-Suomeen
                      </a>
                    </p>
                  </div>
              </div>
            </Col>
            </Row>
          </Container>

          <BasicStatistics />
        </div>
        <Footer />
      </>
    );
  }
}

export default withNamespaces()(Home);
