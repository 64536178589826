import React, { Component } from 'react';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import cx from 'classnames';
import { withNamespaces } from 'react-i18next';
import { Row, Container, Col } from '../Layout/Layout';
import styles from './NotAuthorized.module.css';
import * as AuthService from '../../services/AuthService';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

 login = () => {
    this.setState({ loading: true });
    return AuthService.login(AuthService.userManager, {
      redirectTo: this.props.location.pathname,
    }).catch((err) => {
      this.setState({ loading: false });
      console.error(err);
    });
  };

render = () => {

  const { t } = this.props;
  return (
    <>
      <Header />
      <Navigation />
      <Container className={cx('flex-grow-1')}>
        <Row className={styles.row}>
          <Col span={12} className={styles.flexColumn}>
            {console.log('wis',window.location.pathname)}
            {window.location.pathname.includes('/ilmoitukset/lisaa') ? 
              (
                <>
                <h1 className={'textCenter'}>{t('Kirjaudu sisään lisätäksesi ilmoituksen')}</h1>
                  <button className={cx(styles.actionButton, 'textCenter')} type="button" onClick={this.login}>
                  <span className={styles.actionButton__link}>
                    {`${t('Kirjaudu')}`}
                  </span>
                  </button>
                </>
              ) : 
              (
                <h1 className={'textCenter qa-unauthorized'}>{t('Ei pääsyoikeutta, kirjaudu sisään')}</h1>
              )
            }
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
  }
}

export default withNamespaces()(NotFound);
