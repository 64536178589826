import * as types from './types';

export const addQuestionnaireToView = questionnaire => ({ type: types.QUESTIONNAIRE_VIEW, payload: questionnaire });

export const handleQuestionnaireFormChange = (key, value) => ({
  type: types.HANDLE_QUESTIONNAIRE_FORM_CHANGE,
  key,
  value
});

export const setLoadingQuestionnaires = status => ({ type: types.LOADING_QUESTIONNAIRES, status });

export const setQuestionnaireHasChanges = status => ({ type: types.QUESTIONNAIRE_FORM_HAS_CHANGES, status });

export const setQuestionnaireNotFound = value => ({ type: types.QUESTIONNAIRE_NOT_FOUND, value });

export const setQuestionnaires = questionnaires => ({ type: types.OPEN_QUESTIONNAIRES, questionnaires });
