export const ADD_SEARCH_TERM = 'search/ADD_SEARCH_TERM';
export const DELETE_SEARCH_TERM = 'search/DELETE_SEARCH_TERM';
export const TOGGLE_SEARCH_TERM = 'search/TOGGLE_SEARCH_TERM';
export const HANDLE_GENERIC_CHANGE = 'search/HANDLE_GENERIC_CHANGE';

export const SET_SEARCH = 'search/SET_SEARCH';
export const UPDATE_SAVED_SEARCH_LIST = 'search/UPDATE_SAVED_SEARCH_LIST';
export const SET_LOADING_SAVED_SEARCHES = 'search/SET_LOADING_SAVED_SEARCHES';
export const REMOVE_SAVED_SEARCH_FROM_LIST = 'search/REMOVE_SAVED_SEARCH_FROM_LIST';
export const ADD_SAVED_SEARCH_TO_LIST = 'search/ADD_SAVED_SEARCH_TO_LIST';
export const UPDATE_CONTINUATION_TOKEN = 'search/UPDATE_CONTINUATION_TOKEN';

export const SET_SEARCH_SORT = 'search/SET_SEARCH_SORT';
