import React from 'react';

const Kayttoehdot = ({ lang }) => {
  switch (lang) {
    case 'sv-FI':
      return (
        <>
          <h1>Användningsvillkor för tjänsten Materialtorget</h1>

          <section>
            <h2>1 Allmänt</h2>
            <p>
              Materialtorget är en nättjänst som produceras av miljöministeriet och som fungerar som en elektronisk 
              dataplattform avsedd för yrkesmässigt utbyte av företags och organisationers avfall och 
              produktionsbiflöden (”Tjänst”). Syftet med tjänsten är att främja utnyttjandet av avfall och biflöden 
              samt möjliggöra verifieringen bristande utbud i privata tjänster samt för att begära kommunal 
              avfallshantering i andra hand (”TSV-tjänst”) på det sätt som avses i avfallslagen (646/2011) och ändringen 
              av den (438/2019).
            </p>
            <p>
              Syftet med dessa användarvillkor är att avtala om användningen av Tjänsten mellan miljöministeriet och 
              den innehavare av avfall eller biflöden, leverantör av avfallshanteringstjänster, aktörer som utnyttjar 
              biflöden eller någon annan organisation som använder Tjänsten (”Verksamhetsutövare”). Respektive 
              Verksamhetsutövare använder Tjänsten med den förutsättningen att minst en användare som representerar 
              den aktuella Verksamhetsutövaren (”Användare”) har registrerat sig i tjänsten. I dessa användarvillkor 
              fastställs även respektive Användares rättigheter och skyldigheter i anslutning till användning av Tjänsten.
            </p>
            <p>
              Motiva Oy ("Motiva") svarar för tjänstens administration och underhåll.
            </p>
          </section>

          <section>
            <h2>2 Inloggning i tjänsten</h2>

            <h3>2.1 Användarkonto</h3>
            <p>
              Inloggning i Tjänsten krävs för att lägga till annonser i Tjänsten och svara på annonser. Inloggningen i 
              tjänsten görs via Suomi.fi-tjänsten genom att använda nätbankkoder eller mobilcertifikat, vilket betyder 
              att Användaren också ska godkänna villkoren för Suomi.fi-tjänsten. Ibruktagandet av tjänsten kräver att 
              Användaren har organisationens fullmakt. Fullmakten görs i tjänsten suomi.fi. Inloggningen i Tjänsten 
              och användningen av Tjänsten är avgiftsfri. Genom att logga in i Tjänsten godkänner Användaren dessa 
              användningsvillkor.
            </p>
            <p>
              Inloggningen sker genom stark autentisering. 
            </p>
            <p>
              I samband med registreringen skapas ett användarkonto för Användaren i Tjänsten. Varje användarkonto är 
              personligt, och Användaren har inte rätt att överföra användarbehörigheten till kontot till någon annan person. 
            </p>
            <p>
              Om Användaren slutar använda Tjänsten till exempel då han eller hon blir anställd av en annan arbetsgivare, 
              ska Användaren ta bort sitt användarkonto från Tjänsten genom att informera Motiva om detta (se punkt 7 
              Kontaktuppgifter). Den som beviljat fullmakten kan ta bort fullmakten från personen via fullmaktstjänsten, 
              varefter Användaren inte längre kan använda Tjänsten.
            </p>
            <p>
              Motiva har rätt att utan skyldighet till gottgörelse eller ersättning till Verksamhetsutövaren ta bort ett 
              användarkonto för en Användare som representerar den aktuella Verksamhetsutövaren antingen tillfälligt 
              eller permanent, om:
            </p>
            <p>
              a) Användaren har använt Tjänsten i strid med dessa användningsvillkor och inte korrigerar sin förseelse 
              inom fem (5) vardagar från Motivas skriftliga anmälan om detta;
            </p>
            <p>
              b) otiva har grundad anledning att misstänka att Användarens åtgärder kan orsaka skada, olägenhet eller 
              rättsliga påföljder för Verksamhetsutövaren, andra Användare, miljöministeriet, Motiva eller andra som 
              deltar i att genomföra Tjänsten; eller
            </p>
            <p>
              c) Motiva är skyldig att göra detta på grund av lagstiftningen eller en myndighetsföreskrift.
            </p>
            <p>
              Om Användare som representerar Verksamhetsutövaren upprepade gånger använder Tjänsten i strid med dessa 
              användningsvillkor, har Motiva rätt att utöver ta bort enskilda användarkonton även rätt att förvägra 
              Verksamhetsutövaren att registrera nya Användare antingen tillfälligt eller permanent.
            </p>

            <h3>2.2 Verksamhetsutövarens sidor</h3>
            <p>
              Användaren ska vid registrering i tjänsten välja namnet på och FO-numret för den Verksamhetsutövare som 
              Användaren representerar. Efter registrering kan Användaren, om så önskas, lägga till andra basuppgifter 
              om Verksamhetsutövaren i Tjänsten samt uppgifter om eventuella tillstånd och registreringar.
            </p>
            <p>
              Uppgifter som Användarna lägger till samt Användare som representerar Verksamhetsutövaren visas på 
              Verksamhetsutövarens sidor i Tjänsten. Användare som representerar en Verksamhetsutövare kan vid behov 
              redigera Verksamhetsutövarens adressuppgifter direkt i Tjänsten. - Om Verksamhetsutövarens FO-nummer 
              ändras, kan Användaren befullmäktigas i enlighet med fullmaktsanvisningarna i organisationen enligt 
              det nya FO-numret. Om man vill överföra uppgifter från det tidigare FO-numret, såsom meddelanden eller 
              avtal till det nya FO-numret, ska Användaren meddela Motiva om detta för att korrigera uppgiften i 
              Tjänsten (se punkt 7 Kontaktuppgifter).
            </p>
          </section>

          <section>
            <h2>3 Användning av Tjänsten</h2>

            <h3>3.1 Annonser och offerter</h3>
            <p>
              Verksamhetsutövare kan i Tjänsten göra annonser och offerter om avfall och avfallshanteringstjänster samt 
              avtala om avfallshanteringstjänster inom ramen för sina fullmakter. Därutöver kan Verksamhetsutövare göra 
              fritt formulerade annonser och förfrågningar som gäller utnyttjande av biflöden i Tjänsten, och i detta 
              avseende fungerar Tjänsten endast som en plattform för utbyte av information.
            </p>
            <p>
              Verksamhetsutövare som innehar avfall kan i Tjänsten göra annonser om avfallet och godkänna eller avslå 
              offerter som de mottagit. Innehavaren av avfall ansvarar för bedömningen av offerter som mottagits och av 
              deras villkor. Innan ett avtal om avfallshantering ingås är innehavaren i enlighet med 29 § i avfallslagen 
              även skyldig att säkerställa att avfall överlämnas endast till en sådan leverantör av 
              avfallshanteringstjänster som har ett myndighetstillstånd som verksamheten förutsätter eller tillräcklig 
              sakkunskap samt ekonomisk och teknisk beredskap att ta emot det aktuella avfallet.
            </p>
            <p>
              Verksamhetsutövare som tillhandahåller avfallshanteringstjänster kan i Tjänsten söka avfall som kan utnyttjas 
              samt göra offerter som gäller deras avfallshanteringstjänster. Om en offert som en Verksamhetsutövare som 
              tillhandahåller avfallshanteringstjänster gör i Tjänsten godkänns, förbinder sig den berörda 
              Verksamhetsutövaren att tillhandahålla avfallshanteringstjänsten i enlighet med de villkor som anges i 
              samband med offerten.
            </p>
            <p>
              Respektive Verksamhetsutövare svarar själv för annonser och offerter som lagts till i dess namn i Tjänsten 
              samt för villkoren i dem och eventuella avtal som ingås på basis av dem. Miljöministeriet eller Motiva 
              ansvarar inte till någon del för innehållet eller riktigheten i annonser, offerter eller annat material som 
              läggs till i Tjänsten. Miljöministeriet eller Motiva ansvarar inte heller för huruvida de tillhandahållare 
              av avfallshanteringstjänster som använder Tjänsten har rätt att ta emot avfall.
            </p>

            <h3>3.2 Begäran och avtal om kommunal avfallshantering i andra hand (nedan TSV-tjänsten)</h3>
            <p>
              Ifall ingen tjänst som motsvarar anbudsbegäran kan fås på rimliga villkor inom tidsramen på minst 14 dygn 
              som Verksamhetsutövaren utfärdat, kan Verksamhetsutövaren i Tjänsten göra en begäran om avfallshantering i 
              andra hand till den kommun eller kommunala avfallsanläggning som Verksamhetsutövaren valt. Behovet av 
              TSV-tjänsten ska motiveras i samband med begäran. Ett anbud som kommit in i anbudsförfrågan kan förkastas 
              oskäligt bl.a. på basis av höga priser eller långa transportavstånd. Avtalet om TSV-tjänsten kan göras i 
              Tjänsten genom att utnyttja Tjänstens färdiga avtalsmall eller utanför Tjänsten. När den färdiga avtalsmallen 
              används ska avtalsvillkoren för TSV-tjänsten läggas till. Avtalet bekräftas i tjänsten av sådana företrädare 
              för parterna som har fullmakt att underteckna avtal. Begäran kan framföras av avfallsinnehavaren eller av en 
              Verksamhetsutövare som tillhandahåller en avfallshanteringstjänst till avfallsinnehavaren.
            </p>
          </section>

          <section>
            <h2>4 Tjänstens användbarhet</h2>
            <p>
              Syftet är att tillhandahålla Tjänsten så att den kan användas i enlighet med dessa användningsvillkor vare 
              dag på året och alla tider på dygnet (24/7).
            </p>
            <p>
              Motiva har alltid rätt att avbryta tillhandahållandet av tjänsten under service- och underhållsåtgärder eller 
              på grund av någon annan teknisk orsak. Motiva strävar efter att informera om planerade driftavbrott antingen 
              via Tjänsten eller på något annat sätt som anses vara ändamålsenligt.
            </p>
            <p>
              Såväl miljöministeriets som Motivas ansvar för eventuella störningar eller avbrott i Tjänsten begränsas till 
              att de korrigeras utan ogrundat dröjsmål. Miljöministeriet eller Motiva ansvarar inte för skador eller 
              förluster som orsakas av driftavbrott i Tjänsten eller motsvarande tekniska störningar.
            </p>
            <p>
              Miljöministeriet och Motiva förbehåller sig rätten att göra ändringar i Tjänsten. Verksamhetsutövarna informeras 
              om ändringar via Tjänsten eller på något annat sätt som anses vara ändamålsenligt.
            </p>
          </section>

          <section>
            <h2>5 Rättigheter till Tjänsten</h2>
            <p>
              Alla upphovsrätter samt äganderätter och övriga immateriella rättigheter till Tjänsten hör uteslutande till 
              miljöministeriet eller dess samarbetspartner. För tydlighetens skull konstateras att på eventuella tredje 
              parters programvaror och tjänster som hör till Tjänsten tillämpas endast deras egna licens- och tjänstevillkor.
            </p>
            <p>
              Miljöministeriet beviljar Verksamhetsutövaren och Användare som representerar den behörighet att använda 
              Tjänsten i enlighet med dessa Användningsvillkor under förutsättning att Verksamhetsutövaren och respektive 
              Användare:
            </p>
            <p>
              a) förbinder sig att använda Tjänsten endast i ett syfte som stämmer överens med dessa användningsvillkor och 
              endast i en omfattning i enlighet med dessa användningsvillkor;
            </p>
            <p>
              b) inte laddar upp eller publicerar i Tjänsten något sådant innehåll som kränker en tredje parts immateriella 
              eller andra rättigheter eller något annat innehåll som den inte har rätt att ladda upp eller publicera;
            </p>
            <p>
              c) inte publicerar i Tjänsten något sådant material eller innehåll som i fråga om innehållet är kränkande, 
              förnedrande, diskriminerande eller på annat sätt osakligt eller olämpligt;
            </p>
            <p>
              d) använder Tjänsten endast för lagliga syften och inte använder Tjänsten på ett sätt som kan hindra eller 
              störa dess normala funktion, och inte försöker kringgå eller ta bort tekniska åtgärder i anslutning till 
              Tjänsten eller dess innehåll;
            </p>
            <p>
              e) inte överför eller laddar upp till Tjänsten material som innehåller virus, skadliga program eller spionprogram 
              eller andra motsvarande program eller programvaror vars syfte är att störa tjänstens funktion eller 
              tillgänglighet eller möjliggöra olaglig åtkomst till Tjänsten; och
            </p>
            <p>f) använder Tjänsten i enlighet med vid var tid gällande lagstiftning.</p>
            <p>
              Verksamhetsutövaren eller en Användare som representerar den beviljas inte någon annan behörighet till Tjänsten än 
              behörighet att använda Tjänsten och dess innehåll i enlighet med dessa användningsvillkor.
            </p>
          </section>

          <section>
            <h2>6. Övriga villkor</h2>

            <h3>6.1 Behandling av personuppgifter</h3>
            <p>
              I Tjänsten behandlas uppgifter från Suomi.fi-systemet som Användarna matat in i Tjänsten och som behövs för att 
              genomföra Tjänsten. Motiva svarar för administrationen och underhållet av Tjänsten och är personuppgiftsansvarig 
              för de personuppgifter som behandlas i Tjänsten i enlighet med 143 a § i avfallslagen. Motiva behandlar 
              personuppgifter i Tjänsten i enlighet med den tillgängliga dataskyddsbeskrivningen.
            </p>

            <h3>6.2 Myndigheters rätt att få uppgifter</h3>
            <p>
              De myndigheter som fastställs i avfallslagen har i enlighet med 143 b § i avfallslagen rätt att få uppgifter 
              från Tjänsten i anslutning till TSV-tjänsten från och med 1.1.2020.
            </p>

            <h3>6.3 Överföring och ändring av användningsvillkoren</h3>
            <p>
              Miljöministeriet har efter eget gottfinnande rätt att överföra ett avtal i enlighet med dessa användningsvillkor 
              till Motiva, varvid avtalet fortsätter gälla med samma innehåll mellan Verksamhetsutövaren och Motiva.
            </p>
            <p>
              Miljöministeriet har efter eget gottfinnande rätt att ändra dessa användningsvillkor. Ändringar meddelas via 
              Tjänsten eller på annat sätt som miljöministeriet anser vara bäst. På avtalet mellan miljöministeriet och 
              Verksamhetsutövaren tillämpas alltid den aktuella versionen av användningsvillkoren, och Verksamhetsutövaren 
              är skyldig att se till att den tar del av de vid var tid gällande användningsvillkoren och deras innehåll. 
              Verksamhetsutövaren anses ha godkänt ändringarna i användningsvillkoren när Verksamhetsutövaren fortsätter 
              använda Tjänsten.
            </p>

            <h3>6.4 Länkar till tredje parters webbplatser</h3>
            <p>
              Tjänsten kan innehålla länkar till en tredje parts webbplats eller webbtjänster som inte administreras av 
              miljöministeriet och för vars innehåll eller rutiner miljöministeriet inte ansvarar.
            </p>

            <h3>6.5 Tillämplig lag och meningsskiljaktigheter</h3>
            <p>
              På tjänsten och dessa användningsvillkor tillämpas Finlands lag. Meningsskiljaktigheter i anslutning till 
              Tjänsten och dessa användningsvillkor, vilka inte kan avgöras i godo, avgörs slutgiltigt i ett 
              skiljeförfarande i enlighet med Centralhandelskammarens regler för skiljeförfarande. Skiljenämnden har en 
              ledamot. Orten för skiljeförfarandet är Helsingfors och dess språk är finska.
            </p>
          </section>

          <section>
            <h2>7 Gränssnitt</h2>

            <h3>7.1 Allmänt</h3>
            <p>
              På webbplatsen Materiaalitori.fi finns gränssnitt för att läsa annonsuppgifter (läsargränssnitt) och 
              skicka annonsuppgifter (skrivgränssnitt).
            </p>
            <p>
              Via läsargränssnittet kan du söka annonser som publicerats i tjänsten materiaalitori.fi, via skrivgränssnittet 
              kan du publicera annonser i tjänsten.
            </p>
            <p>Alla API-begäran görs på adressen https://www.materiaalitori.fi</p>
            <p>
              Det krävs ingen identifiering för att kunna läsa annonserna. För att kunna lägga till annonser 
              krävs identifiering.
            </p>
            <p>
              (Identifiering för API-användare är ännu inte tillgänglig. Mer information om detta fås på adressen 
              materiaalitori@motiva.fi)
            </p>
            <p>
              Beskrivning av gränssnitten finns på adressen  https://www.materiaalitori.fi/apidoc.pdf
            </p>
            <p>Det är avgiftsfritt att använda gränssnitten.</p>

            <h3>7.2 Användningsvillkor</h3>
            <p>
              Användningen av gränssnittet följs upp. Om applikationen belastar gränssnittet i alltför stor utsträckning, 
              har Motiva Oy rätt att begränsa användningen eller frånta innehavaren av API-nyckeln rätten att använda 
              gränssnittet.
            </p>
            <p>
              Motiva Oy följer upp användningen av gränssnittet och kan publicera applikationsspecifik statistik över 
              användningen av gränssnittet.
            </p>
            <p>
              När externa applikationer skickar annonser, överlåter de alla rättigheter till annonserna 
              (inklusive rätten till vidareöverlåtelse).
            </p>
            <p>
              Avsikten är att de externa applikationerna endast skickar annonser som överensstämmer med anvisningarna 
              på webbplatsen materiaalitori.fi.
            </p>
            <p>
              Oavsiktlig eller avsiktlig publicering eller spridning av API-nyckeln till andra betraktas som avtalsbrott.
            </p>

            <h3>7.3 Brott mot användningsvillkoren</h3>
            <p>
              Innehavaren av en API-nyckel förbinder sig att iaktta dessa användningsvillkor. Om Motiva Oy anser att 
              användningsvillkoren har överträtts, kan det leda till att användarrättigheterna till gränssnittet 
              omedelbart upphör och att API-nyckeln stängs.
            </p>

            <h3>7.4 Ansvarsbegränsning</h3>
            <p>
              API-gränssnittet används av innehavare av API-nycklar i det skick det befinner sig i och Motiva Oy ger 
              inga garantier för dess funktion.
            </p>
            <p>
              Motiva Oy är inte i någon situation ansvarig för någon skada eller olägenhet som har samband med eller 
              beror på användningen av gränssnittet.
            </p>

            <h3>7.5 Ändring av användningsvillkoren</h3>
            <p>
              Motiva Oy förbehåller sig rätten att göra ändringar i användningsvillkoren. Avsikten är att innehavarna 
              av API-nycklar på förhand ska informeras om ändringarna. Information om ändringarna skickas till den 
              e-postadress som angetts i samband med ansökan om API-nyckeln.
            </p>

            <h3>7.5 Övriga villkor</h3>
            <p>
              På dessa användningsvillkor och på användningen av Tjänsten tillämpas finsk lag. Eventuella 
              meningsskiljaktigheter avgörs vid Helsingfors tingsrätt.
            </p>
          </section>

          <section>
            <h2>8 Kontaktuppgifter</h2>
            <p>
              I frågor som gäller Tjänsten eller dessa användningsvillkor ber vi att ni kontaktar Motivas kundtjänst:
            </p>
            <p>
              Motiva Oy
              <br /> PB 489, 00101 Helsingfors <br /> materiaalitori@motiva.fi
              <br /> (09) 6122 5080 (tisdagar kl. 9–11 och torsdagar kl. 13–15)
              <br />
            </p>
          </section>
        </>
      );

    default: {
      return (
        <>
          <h1>Materiaalitori-palvelun käyttöehdot</h1>

          <section>
            <h2>1 Yleistä</h2>
            <p>
              Materiaalitori on ympäristöministeriön tuottama verkkopalvelu, joka toimii yritysten ja organisaatioiden 
              jätteiden ja tuotannon sivuvirtojen ammattimaiseen vaihdantaan tarkoitettuna sähköisenä tietoalustana 
              ("Palvelu"). Palvelun tarkoituksena on edistää jätteiden ja sivuvirtojen hyötykäyttöä sekä  
              mahdollistaa yksityisen palvelun tarjonnan puutteiden todentaminen kunnan toissijaisen jätehuoltopalvelun 
              (”TSV-palvelu”) pyytämiseksi jätelain (646/2011) ja sitä koskevan lakimuutoksen (438/2019) tarkoittamalla 
              tavalla.
            </p>
            <p>
              Näiden käyttöehtojen tarkoituksena on sopia Palvelun käytöstä ympäristöministeriön ja Palvelua käyttävän 
              jätteen tai sivuvirtojen haltijan, jätehuoltopalvelujen tarjoajan, sivuvirtojen hyödyntäjän tai muun 
              organisaation ("Toiminnanharjoittaja") välillä. Kukin Toiminnanharjoittaja voi käyttää Palvelua sillä 
              edellytyksellä, että Palveluun on rekisteröitynyt vähintään yksi kyseistä Toiminnanharjoittajaa edustava 
              käyttäjä (”Käyttäjä”). Näissä käyttöehdoissa määritellään myös kunkin Käyttäjän Palvelun käyttöön 
              liittyvät oikeudet ja velvollisuudet.
            </p>
            <p>Palvelun hallinnoinnista ja ylläpidosta vastaa Motiva Oy ("Motiva").</p>
          </section>

          <section>
            <h2>2 Palveluun kirjautuminen</h2>

            <h3>2.1 Käyttäjätili</h3>
            <p>
              Ilmoitusten lisääminen Palveluun sekä ilmoituksiin vastaaminen edellyttää Palveluun kirjautumista. 
              Palveluun kirjaudutaan Suomi.fi-palvelun välityksellä verkkopankkitunnuksia tai mobiilivarmennetta käyttäen, 
              mikä tarkoittaa, että Käyttäjän tulee hyväksyä myös Suomi.fi-palvelun ehdot. Palvelun käyttöönotto vaatii 
              Käyttäjältä organisaation valtuutuksen. Valtuutus tehdään suomi.fi-palvelussa. Palveluun kirjautuminen ja 
              Palvelun käyttäminen on maksutonta. Kirjautumalla Palveluun Käyttäjä hyväksyy nämä käyttöehdot. 
              Kirjautuminen tapahtuu vahvasti tunnistautumalla. 
            </p>
            <p>
              Rekisteröitymisen yhteydessä Käyttäjälle luodaan käyttäjätili Palveluun. Jokainen käyttäjätili on 
              henkilökohtainen, eikä Käyttäjällä ole oikeutta siirtää tilin käyttöoikeuttaan toiselle henkilölle. 
            </p>
            <p>
              Mikäli Käyttäjä lopettaa Palvelun käytön esimerkiksi siirtyessään uuden työnantajan palvelukseen, tulee 
              Käyttäjän poistaa käyttäjätilinsä Palvelusta ilmoittamalla asiasta Motivalle (ks. kohta 7 Yhteystiedot). 
              Valtuutuksen myöntäjä voi poistaa antamansa valtuuden henkilöltä valtuutuspalvelun kautta, minkä jälkeen 
              Käyttäjä ei voi käyttää Palvelua.
            </p>
            <p>
              Motivalla on oikeus ilman hyvitys- tai korvausvelvollisuutta Toiminnanharjoittajalle poistaa kyseistä 
              Toiminnanharjoittajaa edustavan Käyttäjän käyttäjätili käytöstä joko tilapäisesti tai pysyvästi, jos:
            </p>
            <p>
              a) Käyttäjä on käyttänyt Palvelua näiden käyttöehtojen vastaisesti, eikä korjaa rikkomustaan viiden (5) 
              arkipäivän kuluessa Motivan tätä koskevasta kirjallisesta ilmoituksesta;
            </p>
            <p>
                b) Motivalla on perusteltu syy epäillä, että Käyttäjän toimenpiteet voivat aiheuttaa vahinkoa, haittaa 
                tai oikeudellisia seuraamuksia Toiminnanharjoittajalle, muille Käyttäjille, ympäristöministeriölle, 
                Motivalle tai muille Palvelun toteuttamiseen osallisille; tai
            </p>
            <p>
              c) Motivalla on tähän lainsäädäntöön tai viranomaismääräykseen perustuva velvollisuus.
            </p>
            <p>
              Mikäli Toiminnanharjoittajaa edustavat Käyttäjät toistuvasti käyttävät Palvelua näiden käyttöehtojen 
              vastaisesti, Motivalla on yksittäisten käyttäjätilien poistamisen lisäksi oikeus evätä kyseiseltä 
              Toiminnanharjoittajalta oikeus uusien Käyttäjien rekisteröimiseen joko tilapäisesti tai pysyvästi.
            </p>

            <h3>2.2 Toiminnanharjoittajan omat sivut</h3>
            <p>
              Käyttäjän on rekisteröitymisen yhteydessä valittava edustamansa Toiminnanharjoittajan nimi ja y-tunnus. 
              Rekisteröitymisen jälkeen Käyttäjä voi halutessaan lisätä Palveluun muita Toiminnanharjoittajaa koskevia 
              perustietoja sekä tiedot mahdollisista luvista ja rekisteröinneistä.
            </p>
            <p>
              Käyttäjien lisäämät tiedot sekä Toiminnanharjoittajaa edustavat Käyttäjät ovat nähtävissä Palvelussa 
              Toiminnanharjoittajan omilla sivuilla. Toiminnanharjoittajaa edustavat Käyttäjät pystyvät tarvittaessa 
              muokkaamaan Toiminnanharjoittajan nimi- ja osoitetietoja suoraan Palvelussa. Mikäli Toiminnanharjoittajan 
              y-tunnus muuttuu. Käyttäjän voi valtuuttaa uuden y-tunnuksen mukaiseen organisaatioon valtuutusohjeiden 
              mukaisesti. Mikäli aiempaan y-tunnukseen liittyviä tietoja, kuten ilmoituksia tai sopimuksia. halutaan siirtää uudelle y-tunnukselle, tulee Käyttäjän ilmoittaa tästä Motivalle tiedon korjaamiseksi Palveluun (ks. kohta 7 Yhteystiedot).
            </p>
          </section>

          <section>
            <h2>3 Palvelussa asioiminen</h2>

            <h3>3.1 Ilmoitukset ja tarjoukset</h3>

            <p>
              Toiminnanharjoittajat voivat tehdä Palvelussa jätettä ja jätehuoltopalveluja koskevia ilmoituksia ja 
              tarjouksia sekä sopia jätehuoltopalveluista saamansa valtuuden rajoissa. Lisäksi Toiminnanharjoittajat 
              voivat tehdä Palvelussa sivuvirtojen hyödyntämistä koskevia vapaamuotoisia ilmoituksia ja tiedusteluja, ja 
              Palvelu toimii tältä osin ainoastaan alustana tietojen vaihdolle.
            </p>
            <p>
              Jätteen haltijan asemassa olevat Toiminnanharjoittajat voivat tehdä Palvelussa ilmoituksia jätteestä ja 
              hyväksyä tai hylätä saamiaan tarjouksia. Jätteen haltija on vastuussa vastaanottamiensa tarjousten ja niiden 
              ehtojen arvioinnista. Ennen jätehuoltopalvelua koskevan sopimuksen solmimista jätteen haltija on jätelain 
              29 §:n mukaisesti velvollinen myös varmistamaan, että se luovuttaa jätettä vain sellaiselle jätehuoltopalvelujen 
              tarjoajalle, jolla on toiminnan edellyttämä viranomaishyväksyntä taikka riittävä asiantuntemus sekä 
              taloudelliset ja tekniset valmiudet vastaanottaa kyseistä jätettä.
            </p>
            <p>
              Jätehuoltopalveluja tarjoavat Toiminnanharjoittajat voivat Palvelussa etsiä hyödynnettävää jätettä sekä tehdä 
              jätehuoltopalveluitaan koskevia tarjouksia. Mikäli jätehuoltopalveluja tarjoavan Toiminnanharjoittajan Palvelussa 
              tekemä tarjous hyväksytään, kyseinen Toiminnanharjoittaja sitoutuu tarjoamaan jätehuoltopalvelun tarjouksensa 
              yhteydessä ilmoittamiensa ehtojen mukaisesti.
            </p>
            <p>
              Kukin Toiminnanharjoittaja vastaa itse sen nimissä Palveluun lisätyistä ilmoituksista ja tarjouksista sekä 
              niiden ehdoista ja niiden pohjalta mahdollisesti tehtävistä sopimuksista. Ympäristöministeriö tai Motiva ei 
              ole miltään osin vastuussa Palveluun syötettyjen ilmoitusten, tarjousten tai muiden materiaalien sisällöstä 
              tai niiden oikeellisuudesta. Ympäristöministeriö tai Motiva ei myöskään ole vastuussa siitä, onko Palvelua 
              käyttävillä jätehuoltopalvelujen tarjoajilla oikeus vastaanottaa jätteitä.
            </p>

            <h3>3.2 Pyyntö ja sopimus kuntien toissijaisesta jätehuoltopalvelusta (myöhemmin TSV-palvelusta)</h3>
            <p>
              Toiminnanharjoittaja voi tehdä Palvelussa pyynnön TSV-palvelusta valitsemalleen kunnalle tai kunnan 
              jätelaitokselle, mikäli tarjouspyyntöä vastaavaa palvelua ei ole kohtuullisesti saatavilla 
              Toiminnanharjoittajan asettamassa, vähintään 14 vuorokauden määräajassa. TSV-palvelun tarve on perusteltava 
              pyynnön yhteydessä. Tarjouspyyntöön tullut tarjous voidaan hylätä kohtuuttomana mm. korkean hinnan tai 
              pitkien kuljetusetäisyyksien perusteella. TSV-palvelua koskeva sopimus voidaan tehdä Palvelussa hyödyntäen 
              valmista sopimuspohjaa tai Palvelun ulkopuolella. Valmista sopimuspohjaa käytettäessä tulee siihen lisätä 
              TSV-palvelua koskevat sopimusehdot. Sopimuksen vahvistavat palvelussa sellaiset osapuolten edustajat, 
              joille on valtuutus sopimusten allekirjoittamiseen. TSV-sopimuksen voi tehdä jätteen haltija tai tälle 
              jätehuoltopalvelua tarjoava Toiminnanharjoittaja.
            </p>
          </section>

          <section>
            <h2>4 Palvelun käytettävyys</h2>
            <p>
              Palvelu pyritään tarjoamaan siten, että se on käytettävissä näiden käyttöehtojen mukaisesti vuoden jokaisena 
              päivänä ja kaikkina vuorokauden aikoina (24/7).
            </p>
            <p>
              Motivalla on aina oikeus keskeyttää Palvelun tarjoaminen huolto- ja ylläpitotoimenpiteiden ajaksi tai muun 
              teknisen syyn perusteella. Suunnitelluista käyttökatkoista pyritään ilmoittamaan etukäteen joko Palvelussa 
              tai muulla asianmukaiseksi katsotulla tavalla.
            </p>
            <p>
              Sekä Ympäristöministeriön että Motivan vastuu Palvelussa mahdollisesti esiintyvistä häiriöistä tai katkoista 
              rajoittuu niiden korjaamiseen ilman aiheetonta viivästystä. Ympäristöministeriö tai Motiva ei ole vastuussa 
              Palvelun käyttökatkoksista tai vastaavista teknisistä häiriöistä aiheutuvista vahingoista tai menetyksistä.
            </p>
            <p>
              Ympäristöministeriö ja Motiva varaavat oikeuden tehdä muutoksia Palveluun. Muutoksista ilmoitetaan 
              Toiminnanharjoittajille Palvelussa tai muulla asianmukaiseksi katsotulla tavalla.
            </p>
          </section>

          <section>
            <h2>5 Oikeudet Palveluun</h2>
            <p>
              Kaikki tekijänoikeudet sekä omistus- ja muut immateriaalioikeudet Palveluun kuuluvat yksinomaan 
              ympäristöministeriölle tai sen yhteistyökumppaneille. Selvyyden vuoksi todetaan, että Palveluun 
              mahdollisesti kuuluviin kolmansien osapuolten ohjelmistoihin ja palveluihin sovelletaan ainoastaan 
              niiden omia lisenssi- ja palveluehtoja.
            </p>
            <p>
              Ympäristöministeriö myöntää Toiminnanharjoittajalle ja sitä edustaville Käyttäjille oikeuden käyttää 
              Palvelua näiden Käyttöehtojen mukaisesti edellyttäen, että Toiminnanharjoittaja ja kukin Käyttäjä:
            </p>
            <p>
              a) sitoutuu käyttämään Palvelua vain näiden käyttöehtojen mukaiseen tarkoitukseen ja vain näiden 
              käyttöehtojen mukaisessa laajuudessa;
            </p>
            <p>
              b) ei lataa Palveluun tai julkaise Palvelussa mitään sellaista sisältöä, joka loukkaa kolmannen 
              osapuolen immateriaali- tai muita oikeuksia tai muutakaan sisältöä, jonka lataamiseen tai julkaisuun 
              sillä ei ole oikeutta;
            </p>
            <p>
              c) ei julkaise Palvelussa mitään sellaista aineistoa tai sisältöä, joka sisältönsä puolesta on 
              loukkaavaa, halventavaa, syrjivää tai muulla tavoin epäasiallista tai sopimatonta;
            </p>
            <p>
              d) käyttää Palvelua ainoastaan laillisiin tarkoituksiin, eikä käytä Palvelua tavalla, joka voi 
              estää tai haitata sen normaalia toimintaa, eikä pyri kiertämään tai poistamaan Palveluun tai 
              sen sisältöön liittyviä teknisiä toimenpiteitä;
            </p>
            <p>
              e) ei siirrä tai lataa Palveluun aineistoa, joka sisältää viruksia, haitta- tai vakoiluohjelmia 
              tai muita vastaavia ohjelmia tai ohjelmistoja, joiden tarkoituksena on häiritä Palvelun toimintaa 
              tai saatavilla oloa taikka mahdollistaa laiton pääsy Palveluun; ja
            </p>
            <p>f) käyttää Palvelua kulloinkin voimassa olevan lainsäännön mukaisesti.</p>
            <p>
              Toiminnanharjoittajalle tai sitä edustavalle Käyttäjälle ei myönnetä mitään muuta oikeutta Palveluun, 
              kuin oikeus käyttää Palvelua ja sen sisältöä näiden käyttöehtojen mukaisesti.
            </p>
          </section>

          <section>
            <h2>6 Muut ehdot</h2>

            <h3>6.1 Henkilötietojen käsittely </h3>
            <p>
              Palvelussa käsitellään Suomi.fi-järjestelmästä tulevia tietoja, jotka ovat tarpeen Palvelun toteuttamiseksi. 
              Motiva vastaa Palvelun hallinnoinnista ja ylläpidosta ja on jätelain 143 a §:n määrittelyn mukaisesti 
              Palvelussa käsiteltävien henkilötietojen rekisterinpitäjä. Motiva käsittelee henkilötietoja 
              Palvelussa saatavilla olevan tietosuojaselosteensa mukaisesti.
            </p>

            <h3>6.2 Viranomaisten tiedonsaantioikeus</h3>
            <p>
              Jätelaissa määritellyillä viranomaisilla on 1.1.2020 alkaen oikeus saada Palvelusta kunnan TSV-palvelun 
              TSV-palveluun liittyviä tietoja jätelain 143 b §:n mukaisesti.
            </p>

            <h3>6.3 Käyttöehtojen siirtäminen ja muuttaminen</h3>
            <p>
              Ympäristöministeriöllä on harkintansa mukaan oikeus siirtää näiden käyttöehtojen mukainen sopimus Motivalle, 
              jolloin sopimus jatkuu samansisältöisenä voimassa Toiminnanharjoittajan ja Motivan välisenä.
            </p>
            <p>
              Ympäristöministeriöllä on oikeus harkintansa mukaan muuttaa näitä käyttöehtoja. Muutoksista ilmoitetaan 
              Palvelussa tai muulla ympäristöministeriön parhaaksi katsomalla tavalla. Ympäristöministeriön ja 
              Toiminnanharjoittajan väliseen sopimukseen sovelletaan aina käyttöehtojen ajantasaista versiota, ja 
              Toiminnanharjoittaja on velvollinen huolehtimaan siitä, että se tutustuu kulloinkin voimassa oleviin 
              käyttöehtoihin ja niiden sisältöön. Jatkamalla Palvelun käyttöä Toiminnanharjoittajan katsotaan hyväksyneen 
              käyttöehtojen muutokset.
            </p>

            <h3>6.4 Linkit kolmannen osapuolen verkkosivuille</h3>
            <p>
              Palvelu saattaa sisältää linkkejä kolmannen osapuolen verkkosivuille tai -palveluihin, jotka eivät ole 
              ympäristöministeriön hallinnoimia, ja joiden sisällöstä tai käytännöistä ympäristöministeriö ei ole vastuussa.
            </p>

            <h3>6.5 Sovellettava laki ja erimielisyydet</h3>
            <p>
              Palveluun ja näihin käyttöehtoihin sovelletaan Suomen lakia. Palveluun ja näihin käyttöehtoihin liittyvät 
              erimielisyydet, joita ei pystytä ratkaisemaan sovinnollisesti, ratkaistaan lopullisesti välimiesmenettelyssä 
              Keskuskauppakamarin välimiesmenettelysääntöjen mukaisesti. Välimiesoikeus on yksijäseninen. Välimiesmenettelyn 
              paikka on Helsinki ja kieli suomi.
            </p>
          </section>

          <section>
            <h2>7 Rajapinnat</h2>

            <h3>7.1 Yleistä</h3>
            <p>
              Materiaalitori.fi -sivustolla on rajapinnat ilmoitustietojen lukemiseen (lukurajapinta) ja ilmoitustietojen 
              lähettämiseen (kirjoitusrajapinta).
            </p>
            <p>
              Lukurajapinnan kautta saat haettua materiaalitori.fi palvelussa julkaistuja ilmoituksia, kirjoitusrajapinnan 
              kautta voit julkaista ilmoituksia materiaalitori.fi palvelussa.
            </p>
            <p>Kaikki API-pyynnöt tehdään osoitteeseen https://www.materiaalitori.fi</p>
            <p>
              Ilmoitusten lukeminen ei vaadi tunnistautumista. Ilmoitusten luominen vaatii tunnistautumisen.
            </p>
            <p>
              (Tunnistautumista API-käyttäjille ei ole avattu käyttöön, lisätietoja avauksesta saa materiaalitori@motiva.fi 
              sähköpostiosoitteesta)
            </p>
            <p>Rajapinnat on kuvattu osoitteessa https://www.materiaalitori.fi/apidoc.pdf</p>
            <p>Rajapintojen käyttö on maksutonta.</p>

            <h3>7.2 Käyttöehdot</h3>
            <p>
              Rajapinnan käyttöä seurataan ja jos sovellus kuormittaa rajapintaa liiallisissa määrin, Motiva Oy:llä on oikeus 
              rajoittaa käyttöä tai poistaa API avaimen haltijalta rajapinnan käyttöoikeudet.
            </p>
            <p>
              Motiva Oy seuraa rajapinnan käyttöä ja voi julkaista sovelluskohtaista tilastotietoa rajapinnan käytöstä.
            </p>
            <p>
              Lähettäessään ilmoituksia ulkoiset sovellukset luovuttavat kaikki oikeudet (mukaan lukien 
              edelleenluovutusoikeuden) ilmoituksiin.
            </p>
            <p>
              Ulkoiset sovellukset pyrkivät lähettämään vain ilmoituksia, jotka ovat materiaalitori.fi -sivuston ohjeiden 
              mukaisia.
            </p>
            <p>
              API avaimen tahaton tai tahallinen julkaiseminen tai levittäminen muille nähdään sopimusrikkomuksena.
            </p>

            <h3>7.3 Käyttöehtojen rikkominen</h3>
            <p>
              API-avaimen haltija sitoutuu noudattamaan näitä käyttöehtoja. Jos Motiva Oy katsoo käyttöehtoja rikottaneen, 
              se voi johtaa rajapinnan käyttöoikeuksien välittömään päättymiseen ja API-avaimen sulkemiseen.
            </p>

            <h3>7.4 Vastuunrajoitus</h3>
            <p>
              API rajapinta on API-avaimen haltijoiden käytössä siinä kunnossa kuin on, eikä Motiva Oy anna sen toiminnasta 
              mitään takuita.
            </p>
            <p>
              Motiva Oy ei ole missään tilanteessa vastuussa mistään rajapinnan käyttöön liittyvästä tai siitä johtuvasta 
              vahingosta tai haitasta.
            </p>

            <h3>7.5 Käyttöehtojen muuttaminen</h3>
            <p>
              Motiva Oy pidättää itsellään oikeuden tehdä muutoksia käyttöehtoihin. Muutoksista pyritään tiedottamaan 
              API-avainten haltijoille ennakkoon. Muutoksista tiedotetaan API-avaimen anomisen yhteydessä annettuun 
              sähköpostiosoitteeseen.
            </p>

            <h3>7.6 Muut ehdot</h3>
            <p>
              Näihin käyttöehtoihin ja Palvelun käyttöön sovelletaan Suomen lakia. Mahdolliset erimielisyydet ratkaistaan 
              Helsingin käräjäoikeudessa.
            </p>
          </section>

          <section>
            <h2>8 Yhteystiedot</h2>
            <p>
              Palveluun tai näihin käyttöehtoihin liittyvien kysymysten osalta pyydämme ottamaan yhteyttä Motivan 
              asiakaspalveluun:
            </p>
            <p>
              Motiva Oy
              <br /> PL 489, 00101 Helsinki <br /> materiaalitori@motiva.fi
              <br /> (09) 6122 5080 (tiistaisin 9-11 ja torstaisin 13-15)
              <br />
            </p>
          </section>
        </>
      );
    }
  }
};

export default Kayttoehdot;
