import React from 'react';

const Ohjeet = ({ lang, title }) => {
  switch (lang) {
    case 'sv-FI':
      return (
        <>
          <h1>{title}</h1>

          <p>
            I tjänsten kan du bläddra, söka och läsa annonser och sammanfattningar utan att vara inloggad. 
            För att lägga till eller svara på annonser ska du logga in i tjänsten.
          </p>

          <h3>1. Inloggning och registrering i tjänsten</h3>

          <p>
            Man loggar in i tjänsten Materiaalitori.fi via tjänsten Suomi.fi. I samband med inloggningen 
            kontrolleras att användaren har organisationens fullmakt att använda tjänsten <a target="_blank" rel="noopener noreferrer" href="/ohjeet/valtuudet">Anvisningar för att ge fullmakt och logga in.</a>
          </p>

          <h3>2. Kontroll av organisationsuppgifterna</h3>

          <p>
            Efter registrering dirigeras du till organisationens sida, där du kan uppdatera uppgifterna om 
            organisationen.
          </p>

          <p>
          Det är bra för organisationer som tillhandahåller avfallsbehandlings- eller 
          transporttjänster att lägga till referensuppgifter om {' '}
            <a href="/tietoa-palvelusta#ymparistoluvista">
              {' '}
              miljötillstånd och avfallshanteringsregistret 
            </a>{' '}
            i uppgifterna om organisationen.
          </p>

          <h3>3. Läsa och söka annonser</h3>

          <p>
            För en inloggad användare visas alla uppgifter om annonserna. Med hjälp av sökfunktionen 
            kan du söka annonser genom ordsökning och begränsa annonserna enligt material, service och 
            läge. Du kan spara sökningen och aktivera en annonsbevakning, vilket innebär att du får 
            information om nya annonser som fyller sökkriterierna i din e-post.
          </p>

          <h3>4. Besvara annonser</h3>
          <p>
            Du kan svara på annonserna och lämna offerter. De inlämnade svaren och offerterna gäller 
            mellan annonsören och den som svarat, och de visas inte för andra användare.
          </p>

          <h3>5. Lägga till annons</h3>

          <p>
            Du kan lägga till en egen annons med knappen ”Lägg till annons”. Först väljer du en annonstyp 
            som lämpar sig för situationen, varefter rätt annonsblankett som du ska fylla i öppnas. Om 
            du väljer ”Jag söker en mottagare för material”, ska du också definiera om det är frågan om 
            avfall och om du vill fylla i annonsen så att du vid behov kan gå vidare för att begära {' '}
            <a href="/tietoa-palvelusta#tsv">kommunal avfallshantering i andra hand</a>{' '}
            (TSV-tjänst). Beakta när du gör en annons att kommunal avfallshantering i andra hand endast 
            kan begäras om annonsen har skapats genom att definiera att materialet är avfall och att 
            tjänsten kan begäras först när annonsen har upphört att gälla.
          </p>
          <p>
            Annonsblanketten ska ifyllas enligt anvisningen. Ju fler och mer exakta uppgifter du kan ge 
            om materialet, desto lättare är det att svara på annonsen och lämna offerter. Den färdiga 
            annonsen publiceras på sidan ”Annonser” på Materialtorget.
          </p>
          <p>
            En annons kan publiceras för högst ett år. För att begära en TSV-tjänst ska annonsen 
            vara i tjänsten oredigerad i minst 14 dygn och TSV-begäran kan göras först när annonsen har stängts.
          </p>
          <p>Du kan lägga till bilder och andra bilagor om annonsen.</p>

          <h3>6. Redigera och ta bort annons</h3>

          <p>
            En publicerad annons återfinns på sidan ”Mina annonser”. Där kan du redigera eller ta bort 
            annonsen, även om giltighetstiden inte har gått ut.
          </p>

          <h3>7. Mottagning och visning av svar och offerter</h3>

          <p>
            Du kan läsa svar eller offerter som inkommit på en annons via sidan ”Mina annonser”. Inkomna 
            annonser kan godkännas eller avslås. Fortsatt kontakt med en partner som hittats görs utanför 
            Materialtorget.
          </p>

          <h3>8. Begäran om kommunal avfallshantering i andra hand (TVS-begäran)</h3>

          <p>
            Om det inte lämnas några anbud på en annons om avfall eller om anbuden avslås för att de är 
            olämpliga, är det möjligt att på Materialtorget begära om kommunal avfallshantering i andra 
            hand. <strong>När annonsen har upphört att gälla</strong> aktiveras på sidan ”Mina annonser” en knapp för annonsen 
            som man kan klicka på för att göra en begäran om kommunal avfallshantering i andra hand. Begäran 
            ska göras inom tolv månader från det att annonsen upphört att gälla. Observera att annonstypen 
            måste vara rätt för att begäran ska kunna göras (se punkt 5).
          </p>

          <p>
            Eventuella avslag på anbud ska motiveras. Den som framför begäran väljer vilken kommun eller 
            kommunal avfallsanläggning begäran ska skickas till. Avfallsanläggningen ser bedömningen av 
            bristande utbud av tjänster och bedömer huruvida avfallet lämpar sig för kommunens avfallshanteringssystem. 
            Om avfallsanläggningen avslår begäran om kommunal avfallshantering i andra hand, kan begäran skickas 
            till en annan avfallsanläggning.
          </p>

          <p>
            Den som undertecknar TSV-avtalet ska ha en fullmakt i suomi.fi-tjänsten att underteckna avtal, eller 
            så ska hen vara en person med firmateckningsrätt i sin organisation. Om den som bereder TSV-avtalet 
            inte har rätt att underteckna avtalet, ska avtalet styras vidare till en behörig undertecknare.
          </p>
          <h3>9. Borttagning, arkivering och publicering på nytt av en annons</h3>

          <p>
            Annonserna tas bort från de publicerade annonserna automatiskt efter att giltighetstiden gått ut. 
            Giltighetstiden kan vara högst tolv månader. Borttagna annonser arkiveras i annonsörens egna annonser, 
            varifrån det också är möjligt att publicera dem på nytt vid behov.
          </p>

          <h3>Anvisningar för områdeskoordinatorer</h3>
          <p>1. Registrering i tjänsten</p>
          <p>
            Vid registrering väljs rollen ”Områdeskoordinator” och registreringen görs enligt beskrivningen ovan. 
            När du registrerar dig som områdeskoordinator granskar och godkänner Motiva Oy dock din registrering.
          </p>
          <p>2. Lämnande av annons i någon annan än din egen organisations namn</p>
          <p>
            Som områdeskoordinator kan du också göra annonser om material som erbjuds eller tas emot i någon annan 
            än din organisations namn. I detta fall kan du i fältet för kontaktuppgifter lägga till uppgifter om 
            det företag, för vars räkning du gjort annonsen. Du kan också själv fungera som kontaktperson, om 
            annonsören önskar det. Då behöver företaget som erbjuder eller önskar material inte nämnas i annonsen, 
            om företaget inte önskar det. Uppgifter om ett företag i vars namn annonsen har gjorts kan ges även i 
            fritextfältet.
          </p>
          <p>
            Områdeskoordinatorn kan inte göra en annons om avfall för ett annat företags räkning.
          </p>

          <h3>Anvisningar för upphandlande enheter: Hur upphandlande enheter kan begära om kommunal avfallshantering i andra hand på Materialtorget</h3>

          <p>
            Denna anvisning gäller sådana upphandlande enheter som avses i 5 § i lagen om offentlig upphandling och 
            koncession (1397/2016) och 5 § i lagen om upphandling och koncession inom sektorerna vatten, energi, 
            transporter och posttjänster (1398/2016). 
          </p>

          <p>
            Upphandlande enheter är skyldiga att använda Materialtorget för att begära om kommunal avfallshantering i 
            andra hand. Om avfallshanteringen har konkurrensutsatts i Hilma-tjänsten behöver man inte publicera en 
            separat annons om behovet av avfallshantering på Materialtorget, utan begäran om avfallshantering i andra 
            hand kan göras utifrån annonsen i Hilma. Om tjänsten inte konkurrensutsätts i Hilma-systemet 
            (upphandlingar under 60 000 euro), kan den upphandlande enheten genomföra upphandlingen på samma sätt 
            som andra organisationer genom att publicera en annons på Materialtorget i minst 14 dygn.
          </p>

          <h4>Behovet av avfallshantering understiger upphandlingsgränsen och tjänsten konkurrensutsätts inte i Hilma:</h4>

          <p>
            Den upphandlande enheten kan söka en avfallshanteringstjänst på marknadsvillkor via Materialtorget om det 
            uppskattade värdet på behovet av avfallshantering understiger det tröskelvärde som anges i lagen.
          </p>

          <p>
            I detta fall kan den upphandlande enheten söka en tjänst direkt bland annonserna på Materialtorget 
            eller också göra en annons på samma sätt som andra organisationer (punkt 1–7 i anvisningen).
          </p>

          <p>
            Om det inte lämnas några anbud på en annons om avfall eller om anbuden avslås för att de är olämpliga, 
            är det möjligt att på Materialtorget begära om kommunal avfallshantering i andra hand. När annonsen har 
            upphört att gälla aktiveras på sidan ”Mina annonser” en knapp för annonsen som man kan klicka på för 
            att göra en begäran om kommunal avfallshantering i andra hand. Begäran ska göras inom sex månader från 
            det att annonsen upphört att gälla. Observera att annonstypen måste vara rätt för att begäran ska 
            kunna göras (se punkt 5).
          </p>

          <p>
            Eventuella avslag på anbud ska motiveras. Den som framför begäran väljer vilken kommun eller kommunal 
            avfallsanläggning begäran ska skickas till. Avfallsanläggningen ser bedömningen av bristande utbud av 
            tjänster och bedömer huruvida avfallet lämpar sig för kommunens avfallshanteringssystem. Om 
            avfallsanläggningen avslår begäran om kommunal avfallshantering i andra hand, kan begäran skickas till 
            en annan avfallsanläggning.
          </p>

          <h4>Avfallshanteringen konkurrensutsätts i Hilma. Inga anbud fås eller anbuden avslås, den upphandlande enheten begär om kommunal tjänst i andra hand:</h4>
          
          <p>
            Användaren loggar in och väljer i den meny som öppnas vid användarens namn uppe i högra hörnet ”Begär om 
            kommunal avfallshantering i andra hand”. I det nya fönstret väljs ”Begär kommunal avfallshantering i 
            andra hand utifrån en annons i Hilma”, varefter man kan söka information om upphandlingsannonsen utifrån 
            annonsens nummer i Hilma. Därefter ifylls alla uppgifter enligt Materialtorgets anvisningar och 
            uppgifterna i begäran sparas.
          </p>

          <p>
            När uppgifterna har sparats skickas den egentliga begäran till en kommunal avfallsanläggning. Eventuella 
            avslag på anbud ska motiveras. Den som framför begäran väljer vilken kommun eller kommunal avfallsanläggning 
            begäran ska skickas till. Avfallsanläggningen ser bedömningen av bristande utbud av tjänster och bedömer 
            huruvida avfallet lämpar sig för kommunens avfallshanteringssystem. Om avfallsanläggningen avslår begäran 
            om kommunal avfallshantering i andra hand, kan begäran skickas till en annan avfallsanläggning.
          </p>

          <p>
            Den som loggar in på Materialtorget och gör en begäran ska representera den organisation (FO-nummer) för 
            vilken begäran om avfallshantering görs enligt anbudsbegäran i Hilma. 
          </p>
        </>
      );

    default: {
      return (
        <>
          <h1>{title}</h1>
    
          <p>
            Palvelussa voi selata, etsiä ja katsella ilmoituksia ja koostetietoja kirjautumatta. Lisätäkseen ilmoituksen 
            tai vastatakseen ilmoituksiin tulee kirjautua palveluun.
          </p>

          <h3>1. Kirjautuminen ja rekisteröityminen palveluun</h3>

          <p>
            Materiaalitori.fi-palveluun kirjaudutaan suomi.fi-palvelun kautta. Kirjautumisen yhteydessä tarkastetaan, 
            että käyttäjällä on organisaation valtuutus palvelun käyttöön. <a target="_blank" rel="noopener noreferrer" href="/ohjeet/valtuudet">Ohjeet valtuutuksen antamiseen ja kirjautumiseen.</a>
          </p>

          <h3>2. Organisaatiotietojen tarkistaminen</h3>

          <p>
            Rekisteröitymisen jälkeen sinut ohjataan organisaation sivulle, jossa voi päivittää organisaation 
            tietoja. Organisaation tietoja voi päivittää myös myöhemmin.
          </p>

          <p>
          Jätteen käsittely- tai kuljetuspalveluja tarjoavien organisaatioiden on hyvä lisätä organisaation 
          tietoihin viitetiedot {' '}
            <a href="/tietoa-palvelusta#ymparistoluvista">
              ympäristöluvista ja jätehuoltorekisteristä
            </a>
            .
          </p>

          <h3>3. Ilmoitusten katselu ja haku</h3>

          <p>
            Kirjautuneena käyttäjälle näkyvät ilmoituksista kaikki tiedot. Ilmoitusten hakutoiminnoilla ilmoituksia 
            voi hakea sanahaun ja rajata materiaalien, palvelun ja sijainnin mukaan. Haun voi tallentaa hakuvahdiksi, 
            jolloin uusista haun täyttävistä ilmoituksista tulee tieto käyttäjän sähköpostiin.
          </p>

          <h3>4. Ilmoituksiin vastaaminen</h3>
          <p>
            Ilmoituksiin voi vastata ja jättää tarjouksia. Jätetyt vastaukset ja tarjoukset ovat ilmoittajan ja 
            vastaajan välisiä eivätkä ne näy muille käyttäjille.
          </p>

          <h3>5. Ilmoitusten lisääminen</h3>

          <p>
            Oman ilmoituksen voi tehdä ”Lisää ilmoitus” -painikkeella. Ensin valitaan tilanteeseen sopiva ilmoitustyyppi, 
            jonka jälkeen avautuu täytettäväksi oikeanlainen ilmoituslomake. Jos valitaan ilmoitustyyppi 
            ”Etsin materiaalille vastaanottajaa”, tulee vielä määritellä, onko kyseessä jäte ja haluaako täyttää 
            ilmoituksen niin, että voi tarvittaessa edetä pyytämään {' '}
            <a href="/tietoa-palvelusta#tsv">kunnan toissijaista jätehuoltopalvelua</a>{' '}
            (TSV-palvelu). Huomioi ilmoitusta luodessasi, että TSV-palvelua voi pyytää vain, jos ilmoituksessa on 
            valittu, että materiaali on jätettä, ja että TSV-palvelua voi pyytää vasta ilmoituksen voimassaolon 
            umpeuduttua.
          </p>
          <p>
            Ilmoituslomake täytetään ohjeiden mukaan. Mitä enemmän ja tarkempia tietoja pystyy materiaalista tai 
            palvelusta antamaan, sitä helpompi siihen on vastata ja tehdä tarjouksia. Valmis ilmoitus julkaistaan 
            löydettäväksi Materiaalitorin ”Ilmoitukset”-sivulle.
          </p>
          <p>
            Ilmoituksen voi julkaista pisimmillään vuodeksi. TSV-palvelun pyytämiseksi, ilmoituksen tulee olla 
            palvelussa vähintään 14 vuorokautta muokkaamattomana ja TSV-pyynnön voi tehdä vasta ilmoituksen 
            sulkeuduttua.
          </p>
          <p>Ilmoitukseen voi lisätä kuvia ja muita ilmoitusta koskevia liitteitä.</p>

          <h3>6. Ilmoituksen muokkaaminen ja poistaminen</h3>

          <p>
            Julkaistu ilmoitus löytyy ”Omat ilmoitukset” –sivulta. Siellä ilmoitusta on mahdollista muokata 
            tai sen voi poistaa, vaikka voimassaoloaikaa olisi vielä jäljellä.
          </p>

          <h3>7. Vastausten ja tarjousten vastaanottaminen ja niiden katselu</h3>

          <p>
            Ilmoitukseen tulleita vastauksia tai tarjouksia voi katsella ”Omat ilmoitukset” –sivulla. Tulleet 
            tarjoukset voi hyväksyä tai hylätä. Yhteydenpito löydetyn kumppanin kanssa jatkuu tästä eteenpäin 
            Materiaalitorin ulkopuolella.
          </p>

          <h3>8. Kunnan toissijaisen jätehuoltopalvelun pyytäminen eli TSV-pyynnön tekeminen</h3>

          <p>
            Mikäli jätteestä tehtyyn ilmoitukseen ei tule yhtään tarjousta tai ne hylätään sopimattomina, on 
            Materiaalitorissa mahdollista pyytää kunnan toissijaista jätehuoltopalvelua. <strong>Ilmoituksen voimassaoloajan 
            umpeuduttua</strong> ”Omat ilmoitukset”-sivulla ilmoituksen kohdalle aktivoituu painike, josta TSV-pyynnön 
            pääsee tekemään. Pyyntö tulee tehdä 6 kuukauden sisällä ilmoituksen sulkeutumisesta. Huomioi, että 
            ilmoitustyypin on oltava oikeanlainen, jotta TSV-pyynnön voi tehdä (ks. kohta 5).
          </p>

          <p>
            Mahdolliset tarjousten hylkäykset on perusteltava. Pyynnön tekijä valitsee, mille kunnalle tai kunnan 
            jätelaitokselle pyyntö lähtee. Jätelaitos näkee palvelutarjonnan puutteen arvioinnin sekä arvioi jätteen 
            soveltumisen kunnan jätehuoltojärjestelmään. Jätelaitoksen hylätessä TSV-pyynnön, pyynnön voi osoittaa 
            toiselle jätelaitokselle.
          </p>

          <p>
            TSV-sopimuksen allekirjoittajalla tulee olla suomi.fi-palvelussa annettu valtuutus sopimusten 
            allekirjoittamiseen, tai hänen tulee olla organisaationsa allekirjoitusoikeudellinen henkilö. Mikäli 
            TSV-sopimuksen valmistelijalla ei ole allekirjoitusoikeutta, tulee sopimus ohjata edelleen 
            allekirjoitettavaksi.
          </p>

          <h3>9. Ilmoituksen poistuminen, arkistoituminen ja uudelleenjulkaisu</h3>

          <p>
            Ilmoitukset poistuvat julkaistujen ilmoitusten joukosta automaattisesti niiden voimassaolon päätyttyä. 
            Voimassaoloaika voi olla enintään kaksitoista kuukautta. Poistuneet ilmoitukset arkistoituvat ilmoittajan 
            omiin ilmoituksiin ja niitä on mahdollisuus julkaista sieltä tarvittaessa uudelleen.
          </p>

          <h3>Ohjeet aluekoordinaattoreille</h3>
          <p>1. Rekisteröityminen palveluun</p>
          <p>
            Rekisteröityessä valitaan rooliksi ”Aluekoordinaattori”, ja rekisteröidytään kuten yllä. 
            Rekisteröityessäsi aluekoordinaattorina Motiva Oy kuitenkin tarkistaa ja hyväksyy rekisteröitymisesi.
          </p>
          <p>2. Ilmoitusten jättäminen muun kuin oman organisaatiosi nimissä</p>
          <p>
            Aluekoordinaattorina voit tehdä ilmoituksia myös muiden kuin oman organisaatiosi nimissä tarjottavista 
            tai vastaanotettavista materiaaleista. Tällöin voit laittaa yhteystietokenttään tiedot yrityksestä, jonka 
            puolesta ilmoitus on tehty. Voit myös toimia itse yhteyshenkilönä - ilmoittajan niin halutessa. Silloin 
            materiaalia tarjoavaa tai haluavaa yritystä ei tarvitse mainita ilmoituksessa, mikäli yritys ei sitä 
            halua. Yrityksen, jonka nimissä ilmoitus on tehty, tietoja voi ilmoittaa myös ilmoituksen vapaassa 
            tekstikentässä.
          </p>
          <p>Aluekoordinaattori ei voi tehdä toisen yrityksen puolesta ilmoitusta jätteestä.</p>

          <h3>Ohjeet hankintayksiköille: Kuinka hankintayksiköt voivat pyytää kunnan toissijaista jätehuoltopalvelua Materiaalitorilla</h3>

          <p>
            Tämä ohje koskee julkisista hankinnoista ja käyttöoikeussopimuksista annetun lain (1397/2016) 5 §:ssä ja 
            vesi- ja energiahuollon, liikenteen ja postipalvelujen alalla toimivien yksiköiden hankinnoista ja 
            käyttöoikeussopimuksista annetun lain (1398/2016) 5 §:ssä tarkoitettua hankintayksikköä. 
          </p>
          
          <p>
            Myös hankintayksiköt ovat velvollisia käyttämään Materiaalitoria kunnan toissijaisen jätehuoltopalvelun 
            pyytämiseen. Mikäli jätehuoltopalvelu on kilpailutettu Hilma-palvelussa, ei jätehuoltotarpeesta tarvitse 
            tehdä erillistä ilmoitusta Materiaalitoriin, vaan toissijaisen jätehuoltopalvelun pyynnön voi tehdä 
            Hilma-ilmoituksen perusteella. Jos kilpailutusta ei tehdä Hilma-järjestelmässä 
            (alle 60 000 euron kilpailutusraja), hankintayksikkö voi tehdä kilpailutuksen samalla tavalla kuin 
            muut organisaatiot, julkaisemalla ilmoituksen Materiaalitorissa vähintään 14 vuorokauden ajaksi.
          </p>
          
          <p>
            Mikäli jätteestä tehtyyn ilmoitukseen ei tule yhtään tarjousta tai ne hylätään sopimattomina, on 
            Materiaalitorissa mahdollista pyytää kunnan toissijaista jätehuoltopalvelua. Ilmoituksen voimassaoloajan 
            umpeuduttua ”Omat ilmoitukset”-sivulla ilmoituksen kohdalle aktivoituu painike, josta TSV-pyynnön pääsee 
            tekemään. Pyyntö tulee tehdä kuuden kuukauden sisällä ilmoituksen sulkeutumisesta. Huomioi, että ilmoitustyypin 
            on oltava oikeanlainen, jotta TSV-pyynnön voi tehdä (ks. kohta 5).
          </p>

          <p>
            Mahdolliset tarjousten hylkäykset on perusteltava. Pyynnön tekijä valitsee, mille kunnalle tai kunnan 
            jätelaitokselle pyyntö lähtee. Jätelaitos näkee palvelutarjonnan puutteen arvioinnin sekä arvioi jätteen 
            soveltumisen kunnan jätehuoltojärjestelmään. Jätelaitoksen hylätessä TSV-pyynnön, pyynnön voi osoittaa 
            toiselle jätelaitokselle.
          </p>

          <h4>
            Jätehuolto kilpailutettu Hilma-järjestelmässä. Kilpailutuksessa ei saatu ollenkaan tarjouksia, tai ne hylättiin, 
            hankintayksikkö pyytää kunnan toissijaista palvelua:
          </h4>

          <p>
            Kirjautunut käyttäjä valitsee palvelun oikeasta ylänurkasta nimensä kohdalta avautuvasta valikosta 
            “Pyydä kunnan toissijaista jätehuoltopalvelua”. Avautuvalla sivulla valitaan ”Pyydä kunnan toissijaista 
            jätehuoltopalvelua Hilma-järjestelmän ilmoituksesta”, minkä jälkeen päästään hakemaan tietoa 
            hankintailmoituksesta Hilma-järjestelmän ilmoitusnumeron perusteella. Tämän jälkeen täytetään 
            tiedot Materiaalitorin antamien ohjeiden mukaan ja pyynnön tiedot tallennetaan. 
          </p>

          <p>
            Tallennuksen jälkeen tehdään varsinainen pyyntö kunnalliselle jätelaitokselle. Mahdolliset tarjousten 
            hylkäykset on perusteltava. Pyynnön tekijä valitsee, mille kunnalle tai kunnan jätelaitokselle pyyntö 
            lähtee. Jätelaitos näkee palvelutarjonnan puutteen arvioinnin sekä arvioi jätteen soveltumisen kunnan 
            jätehuoltojärjestelmään. Jätelaitoksen hylätessä TSV-pyynnön, pyynnön voi osoittaa toiselle jätelaitokselle.
          </p>

          <p>
            Pyyntöä tekevän Materiaalitorille kirjautuneen käyttäjän tulee edustaa sitä organisaatiota (y-tunnus), 
            jonka Hilma-järjestelmässä olevan tarjouspyynnön perusteella jätehuoltoa pyydetään. 
          </p>
        </>
      );
    }
  }
};

export default Ohjeet;
