import React from 'react';
import { CONTINUITY_TYPES, MATERIAL_TYPES, SEARCH_TYPES } from "./types"
import styles from './RfoSummary.module.css';
import MaterialOptionList from '../Material/MaterialOptionList';
import { flattenServices } from '../../utils/service-utils';
import RegionSelect from '../RegionSelect/RegionSelect';
import formStyles from '../Layout/Form.module.css';

export const getMaterialSearch = (searchType, materialType, handleChange, handleSearchTypeChange, t) => {
  return (
    <>
    <div>
        <label>
          <input
            type="radio"
            name="searchType"
            className='radioButton'
            value={SEARCH_TYPES.OFFERING_MATERIALS}
            onChange={handleSearchTypeChange}
            checked={searchType === SEARCH_TYPES.OFFERING_MATERIALS}
          />
          <span className='radioButtonLabel'>
            {t('Tarjotut materiaalit')}
          </span>
        </label>
      </div>
      {searchType === SEARCH_TYPES.OFFERING_MATERIALS && (
        <div className={styles.subRadioSelection}>
          <div>
            <label>
              <input
                type="radio"
                name="materialType"
                className='radioButton'
                value={MATERIAL_TYPES.WASTE}
                onChange={handleChange}
                checked={materialType === MATERIAL_TYPES.WASTE}
              />
              <span className='radioButtonLabel'>
                {t('Vain jätteet')}
              </span>
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="materialType"
                className='radioButton'
                value={MATERIAL_TYPES.MATERIAL}
                onChange={handleChange}
                checked={materialType === MATERIAL_TYPES.MATERIAL}
              />
              <span className='radioButtonLabel'>
                {t('Vain muut materiaalit ja sivuvirrat')}
              </span>
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="materialType"
                className='radioButton'
                value={MATERIAL_TYPES.ALL}
                onChange={handleChange}
                checked={materialType === MATERIAL_TYPES.ALL}
              />
              <span className='radioButtonLabel'>
                {t('Kaikki')}
              </span>
            </label>
          </div>
        </div>
      )}
  </>)
}

export const getReceivingMaterialSearch = (searchType, handleSearchTypeChange, t) => {
  return (
    <>
      <label>
        <input
          type="radio"
          name="searchType"
          className='radioButton'
          value={SEARCH_TYPES.RECEIVING_MATERIALS}
          onChange={handleSearchTypeChange}
          checked={searchType === SEARCH_TYPES.RECEIVING_MATERIALS}
        />
        <span className='radioButtonLabel'>
          {t('Etsityt materiaalit')}
        </span>
      </label>    
    </>
  )
}

export const getServiceSearch = (searchType, handleSearchTypeChange, t) => {
  return (
    <>
      <label>
        <input
          type="radio"
          name="searchType"
          className='radioButton'
          value={SEARCH_TYPES.OFFERING_SERVICES}
          onChange={handleSearchTypeChange}
          checked={searchType === SEARCH_TYPES.OFFERING_SERVICES}
        />
        <span className='radioButtonLabel'>
          {t('Tarjotut palvelut')}
        </span>
      </label>    
    </>
  )
}

export const getReceivingServicesSearch = (searchType, handleSearchTypeChange, t) => {
  return (
    <>
      <label>
        <input
          type="radio"
          name="searchType"
          className='radioButton'
          value={SEARCH_TYPES.RECEIVING_SERVICES}
          onChange={handleSearchTypeChange}
          checked={searchType === SEARCH_TYPES.RECEIVING_SERVICES}
        />
        <span className='radioButtonLabel'>
          {t('Etsityt palvelut')}
        </span>
      </label>
    </>
  )
}

export const getMaterialSelector = (toggleSearchTerm, searchTermsView, classifications, selectedClassifications, t) => {
  return (
  <>
    <label>
      <span className={styles.defaultLabelSpan}>
        <strong>{t('Materiaali')}</strong>
      </span>
      <select
        name="classifications"
        value=""
        onChange={toggleSearchTerm}
        className={(selectedClassifications.length === 0 ? formStyles.placeholder : '' )}
      >
        <option value="" disabled hidden>
          {t('Valitse...')}
        </option>
        <MaterialOptionList includeWaste={true} includeMaterials={true} />
      </select>
    </label>
    <div className={styles.searchTerms__Container}>
      {searchTermsView('classifications', classifications)}
    </div>
    </>    
  )
}

export const getServiceSelector = (service, services, toggleSearchTerm, searchTermsView, selectedServices, t) => {
  return (
    <>
      <label>
        <span className={styles.defaultLabelSpan}>
          <strong>{t('Palvelu')}</strong>
        </span>
        <select onChange={toggleSearchTerm} name="service" value="" className={(selectedServices.length === 0 ? formStyles.placeholder : '' )}>
          <option value="" disabled hidden>
            {t('Valitse...')}
          </option>

          {flattenServices(services).map((serviceGroup, i) => {
            return (
              <optgroup key={`serviceGroup-${i}`} label="---">
                {serviceGroup.map((service, idx) => {
                  return (
                    <option key={service.id} value={service.id}>
                      {t(service.id)}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </select>
      </label>
      <div className={styles.searchTerms__Container}>
        {searchTermsView('service', service)}
      </div>
    </>
  )
}

export const getReceivingServicesSelector = (service, services, toggleSearchTerm, searchTermsView, selectedServices, t) => {
  return (
    <>
      <label>
        <span className={styles.defaultLabelSpan}>
          <strong>{t('Palvelu')}</strong>
        </span>
        <select onChange={toggleSearchTerm} name="service" value="" className={(selectedServices.length === 0 ? formStyles.placeholder : '' )}>
          <option value="" disabled hidden>
            {t('Valitse...')}
          </option>

          {flattenServices(services.filter(x => x.id === 'asiantuntijapalvelu')).map((serviceGroup, i) => {
            return (
              <optgroup key={`serviceGroup-${i}`} label="---">
                {serviceGroup.map((service, idx) => {
                  return (
                    <option key={service.id} value={service.id}>
                      {t(service.id)}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </select>
      </label>
      <div className={styles.searchTerms__Container}>
        {searchTermsView('service', service)}
      </div>
    </>
  )
}

export const getContinuityTypeSelector = (continuityType, handleChange, t) => {
  return (
    <>
      <div>
        <label>
          <input
            type="radio"
            name="continuityType"
            className='radioButton'
            value={CONTINUITY_TYPES.ONETIME}
            onChange={handleChange}
            checked={continuityType === CONTINUITY_TYPES.ONETIME}
          />
          <span className='radioButtonLabel'>
            {t('Kertaerä')}
          </span>
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            name="continuityType"
            className='radioButton'
            value={CONTINUITY_TYPES.CONTINUOUS}
            onChange={handleChange}
            checked={continuityType === CONTINUITY_TYPES.CONTINUOUS}
          />
          <span className='radioButtonLabel'>
            {t('Jatkuva')}
          </span>
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            name="continuityType"
            className='radioButton'
            value={CONTINUITY_TYPES.ALL}
            onChange={handleChange}
            checked={continuityType === CONTINUITY_TYPES.ALL}
          />
          <span className='radioButtonLabel'>
            {t('Kaikki')}
          </span>
        </label>
      </div>
    </>
  )
}

export const getLocationSelector = (searchType, regions, toggleRegion, t) => {
  return (
    <>
      <label>
        <span className={styles.defaultLabelSpan}>
          <strong>{t('Sijainti')}</strong>
        </span>
        <RegionSelect
          handleChange={toggleRegion}
          onRemove={toggleRegion}
          value={regions}
          single={
            searchType === SEARCH_TYPES.OFFERING_SERVICES ||
            searchType === SEARCH_TYPES.RECEIVING_MATERIALS
          }
        />
      </label>
    </>
  )
}