import {
  handleRfoFormChange,
  handleMaterialFormChange,
  clearRfoForm,
  addMaterial,
  deleteMaterial,
  addRfoToView,
  addRfoToEditView,
  clearRfoView,
  addFiles,
  deleteFile,
  deleteFiles,
  addRegion,
  toggleRegion,
  deleteRegion,
  addSubService,
  deleteSubService,
  deleteAllSubServices,
  deleteSubClassification,
  setLoadingRfo,
  setLoadingRfos,
  setLoadingMapRfos,
  setRfoNotFound,
  updateRfoList,
  appendRfoList,
  updateMapRfoList,
  setRfoHasChanges,
} from './actions';
import { updateContinuationToken } from '../search/actions';
import { getRaw } from '../../../services/ApiService';
import * as API_ENDPOINTS from '../../../services/endpoints';
import qs from 'qs';
import { getJsonData } from '../../../services/ApiService';

const fetchRfos = (queryParams = {}, append = false) => (dispatch) => {
  dispatch(setLoadingRfos(true));
  if (append === false) {
    dispatch(setLoadingMapRfos(true));
  }
  const config = {
    params: queryParams,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
  };

  getRaw(API_ENDPOINTS.FETCH_RFOS, config)
    .then((result) => {
      dispatch(append ? appendRfoList(result.data) : updateRfoList(result.data));
      dispatch(updateContinuationToken(result.headers['tietoalusta-continuation-token']));
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(setLoadingRfos(false));
    });
  if (append === false) {
    getJsonData(API_ENDPOINTS.FETCH_RFOS_TO_MAP, config)
      .then((result) => dispatch(updateMapRfoList(result)))
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(setLoadingMapRfos(false));
      });
  }
};

const fetchRfoForEditOrCopy = (id, regions, copy) => (dispatch) => {
  dispatch(setLoadingRfo(true));
  const fetchUrl = API_ENDPOINTS.FETCH_RFOS + '/' + id;

  getJsonData(fetchUrl)
    .then(
      copy
        ? (data) => {
            dispatch(addRfoToEditView(data, regions));
            dispatch(deleteFiles());
          }
        : (data) => dispatch(addRfoToEditView(data, regions))
    )
    .catch((error) => {
      const { response } = error;

      if (response && response.status === 404) {
        dispatch(setRfoNotFound(true));
      }

      console.log(error);
    })
    .finally(() => dispatch(setLoadingRfo(false)));
};

const fetchRfoForEdit = (id, regions) => {
  return fetchRfoForEditOrCopy(id, regions, false);
};

const fetchRfoForCopy = (id, regions) => {
  return fetchRfoForEditOrCopy(id, regions, true);
};

const fetchRfoToView = (id) => (dispatch) => {
  dispatch(setLoadingRfo(true));
  const fetchUrl = API_ENDPOINTS.FETCH_RFOS + '/' + id;

  getJsonData(fetchUrl)
    .then((data) => dispatch(addRfoToView(data)))
    .catch((error) => {
      const { response } = error;

      if (response && response.status === 404) {
        dispatch(setRfoNotFound(true));
      }

      console.log(error);
    })
    .finally(() => dispatch(setLoadingRfo(false)));
};

const addAllRegions = (regions) => (dispatch) => {
  regions.forEach((region) => {
    dispatch(addRegion(region));
  });
};

export {
  handleRfoFormChange,
  handleMaterialFormChange,
  clearRfoForm,
  addMaterial,
  deleteMaterial,
  addRfoToView,
  clearRfoView,
  addFiles,
  deleteFile,
  deleteFiles,
  addRegion,
  toggleRegion,
  deleteRegion,
  addAllRegions,
  addSubService,
  deleteAllSubServices,
  deleteSubService,
  deleteSubClassification,
  setLoadingRfo,
  setLoadingRfos,
  setRfoNotFound,
  updateRfoList,
  updateMapRfoList,
  fetchRfos,
  fetchRfoToView,
  fetchRfoForEdit,
  fetchRfoForCopy,
  setRfoHasChanges,
};
