import React from 'react';
import { Helmet } from 'react-helmet';
import ListRequestForOffer from './List';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import cx from 'classnames';
import styles from './RequestForOffer.module.css';
import homeStyles from '../Home/Home.module.css';
import { Row, Container, Col } from '../Layout/Layout';
import { withNamespaces } from 'react-i18next';
import SearchWithParams from '../Search/SearchWithParams';
import ListView from './ListView';
import ListRequestForOfferOnMap from './ListMap';
import GetMoreRfos from '../Search/GetMoreRfos';

export const ListAllRfos = ({ t }) => {
  return (
    <>
      <Helmet>
        <title>{t('Hae ilmoituksia - Materiaalitori')}</title>
      </Helmet>
      <Header />
      <Navigation />
      <Container className={cx('flex-grow-1', 'customContentPage')}>
        <Row options={{ center: true }}>
          <Col span={10} className={cx(styles.container, homeStyles.marginTop2em)}>
            <h1>{t('Hae ilmoituksia')}</h1>
            <div className={'subHeading'}>
              {/* TODO: this will be moved to navigation bar */}
              {/* <PrivateComponent isAuthorizedToManageCompanyDocuments={true}>
                <div className={styles.addRfoButtonWrap}>
                  <Link className={cx('buttonStyle')} to={LISAA_ILMOITUS}>
                    {t('+ Lisää ilmoitus')}
                  </Link>
                </div>
              </PrivateComponent> */}
            </div>
            <SearchWithParams />
          </Col>
        </Row>
      </Container>
      <Container>
        <ListRequestForOfferOnMap />
      </Container>
      <Container>
        <Row options={{ center: true }}>
          <Col span={10} className={cx(styles.container, styles.alteringBackground)}>
            <ListRequestForOffer render={(rfos) => <ListView rfos={rfos} />} />
            <GetMoreRfos />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default withNamespaces()(ListAllRfos);
