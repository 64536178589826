import React from 'react';
import formStyles from '../Layout/Form.module.css';
import UnitOptionList from '../Material/UnitOptionList';
import { formatDate } from '../../utils/date-utils';
import i18n from '../../i18n';

export const getServiceState = (t, service) => {
  const services = { };
  services['serviceFromMateriaalitori'] = t('Materiaalitorin ilmoituksen perusteella');
  services['gotServiceFromMateriaalitori'] = t('Sain palvelun Materiaalitorin ilmoituksen perusteella');
  services['serviceOutsideMateriaalitori'] = t('Sain palvelun Materiaalitorin ulkopuolelta');
  services['didNotGetService'] = t('En saanut palvelua');
  services['noNeedForService'] = t('Palvelun tarve poistui');
  services['noContacts'] = t('En saanut Materiaalitorista yhteydenottoja');
  services['quitService'] = t('Lopetin palveluntarjonnan');
  services['rfoExpired'] = t('Ilmoitus sulkeutui määräajan umpeuduttua');
  services['republishExpiredRfo'] = t('Ilmoitus sulkeutui määräajan umpeuduttua ja julkaisen sen uudelleen');  
  services['other'] = t('Muu syy');

  return services[service];
}

export const getPaymentState = (t, paymentType) => {
  const paymentTypes = { };
  paymentTypes['payingForMaterial'] = t('Maksan materiaalista');
  paymentTypes['receivedPayment'] = t('Sain maksun vastaanottamisesta');
  paymentTypes['noPayment'] = t('Sain maksutta');
  paymentTypes['gotPaidForMaterial'] = t('Sain maksun materiaalista');
  paymentTypes['paidForReceivingMaterial'] = t('Maksoin materiaalin vastaanottamisesta');
  paymentTypes['gaveMaterialWithoutPayment'] = t('Luovutin materiaalin maksutta');

  return paymentTypes[paymentType];
}

export const getRfoCloseDate = (viewForm) => {
  let closeDate = viewForm.rfo.StatusName === 'hidden' || viewForm.rfo.StatusName === 'closed' ? viewForm.rfo.StatusDate : viewForm.rfo.Expires;

  return formatDate(new Date(closeDate))
}

export const getLocation = (material, regions) => {
      
  let locations = [material.Location.City, material.Location.Region];
  locations.push(regions.map(regions => i18n.language === 'fi-FI' ? regions.NameFi : regions.NameSv));

 return locations.filter(location => !!location).join(', ');
}

export const QuantityUnitField = React.memo(({ t, material, handleMaterialChange }) => (
  <>
    <label>
      <span className={formStyles.defaultLabelSpan}>{t('Määrän yksikkö')}</span>{' '}
      <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
      <select
        name="quantityUnit"
        value={material.quantityUnit || ''}
        onChange={handleMaterialChange}
      >
        <option value="" disabled hidden>
          {t('Valitse yksikkö')}
        </option>
        <UnitOptionList t={t} />
      </select>
    </label>
  </>
));