import cx from 'classnames';
import { addDays, format } from 'date-fns';
import { fi, sv } from 'date-fns/locale';
import { is } from 'ramda';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDate, formatDateForSaving } from '../../utils/date-utils';
import InfoBox from '../InfoBox/InfoBox';
import formStyles from '../Layout/Form.module.css';
import { Col, Row } from '../Layout/Layout';
import Location from '../Map/Location';
import { SingleMaterialEdit, SingleReceiveMaterialEdit, SingleWasteEdit } from '../Material/Edit';
import Select from '../Select/Select';
import styles from './RequestForOffer.module.css';
import { isClosed, isHidden } from './rfo-utils';
import * as rfoTypes from './types';
import { withNamespaces } from 'react-i18next';

export const RfoTitleFragment = ({ t, rfo, classnameForSidemark }) => {
  return (
    <>
    <div className={styles.rfoTypeHeadingCaps}>
      {t(rfo.rfoType + '-title')}
    </div>
    <h1>
      {rfo.title}
      {isClosed(rfo) && (
        <span className={classnameForSidemark}>
          {t('Suljettu') + ' ' + formatDate(new Date(rfo.statusDate))}
        </span>
      )}
      {isHidden(rfo) && <span className={classnameForSidemark}>{t('Tarjous hyväksytty')}</span>}
    </h1>
    </>
  );
};

export const LocationFromMaterialFragment = ({ material }) => {
  const location = material.location;

  return (
    <>
      {location.name && (
        <>
          <span>{location.name}</span>
          <br />
        </>
      )}
      {location.address && (
        <>
          <span>{location.address}</span>
          <br />
        </>
      )}
      <span>
        {location.postalCode} {location.city}
      </span>
    </>
  );
};

export const TypeSelectFragment = ({ t, rfo, handleChange, disabled, readMoreAboutWasteExpanded, toggleReadMoreAboutWasteExpanded }) => (
  <div className={styles.typeSelect}>
    <h2>
      {t('Valitse ilmoitustyyppi')}{' '}
    </h2>
    <p className={styles.typeDescriptionParagraph}>
      {t('Aloita valitsemalla ilmoituksen tyyppi.')}
    </p>
    <div className={styles.rfoTypeSelect}>
      <div className={styles.typeWrapper__firstLevel}>
        <div className={styles.rfoTypeRow}>
          <div className={styles.rfoTypeRowItem}>
            <div className={cx([styles.rfoTypeRowItemTwoFifths])}>
              <label className={cx('textSemiBold')}>
                <input
                  onChange={handleChange}
                  type="radio"
                  name="type"
                  value={'wasteOrMaterial'}
                  disabled={disabled}
                  className={cx(                    
                    'radioButton',                    
                    rfo.type === rfoTypes.RFO_OFFERING_WASTE || rfo.type === rfoTypes.RFO_OFFERING_MATERIAL
                      ? 'radioButtonChecked'
                      : null
                  )}
                  checked={rfo.type === 'wasteOrMaterial'}
                />
                <span className={cx('radioButtonLabel', 'qa-wasteOrMaterial')}>{t('Etsin materiaalille vastaanottajaa')}</span>
              </label>
            </div>
            <div className={cx([styles.rfoTypeRowItemThreeFifths, styles.rfoTypeDescription])}>
              <Trans i18nKey="ilmoitussivun_ilmoitus_tyyppi_tarjoanmateriaalia">
                Sinulla on <strong>tarjolla olevaa jättettä tai muuta materiaalia</strong>, kuten tuotannon sivuvirtaa. Valitse
                seuraavaksi, onko ilmoittamasi materiaali jätettä vai muuta materiaalia. Jos kyseessä on
                jäte, voit määritellä ilmoituksessa lisäksi, millaista palvelua etsit jätteelle.
              </Trans>
            </div>
        </div>
      </div>
          {(rfo.type === 'wasteOrMaterial' ||
          rfo.type === rfoTypes.RFO_OFFERING_WASTE ||
          rfo.type === rfoTypes.RFO_OFFERING_MATERIAL) && (
        <div>

          <div className={styles.typeWrapper__secondLevel}>
            <div className={styles.rfoTypeRowItem}>
              <div className={cx([styles.rfoTypeRowItemTwoFifthsNarrow])}>
                <label>
                  <span className={cx(styles.typeWrapper__secondLevelOption, 'textSemiBold')}>
                    {t('Onko materiaali jätettä?')}
                  </span>
                </label>
                <label className={cx(styles.typeWrapper__secondLevelOption)}>
                <input
                  onChange={handleChange}
                  type="radio"
                  name="type"
                  disabled={disabled}
                  value={rfoTypes.RFO_OFFERING_WASTE}
                  checked={rfo.type === rfoTypes.RFO_OFFERING_WASTE}
                  className={cx(
                    'radioButton',
                    rfo.type === rfoTypes.RFO_OFFERING_WASTE
                      ? 'radioButtonChecked'
                      : null
                  )}
                />
                <span className={cx('radioButtonLabel', 'qa-offeringWaste')}>{t('Kyllä')}</span>
                </label>

                <label className={cx(styles.typeWrapper__secondLevelOption)}>
                  <input
                    onChange={handleChange}
                    type="radio"
                    name="type"
                    disabled={disabled}
                    value={rfoTypes.RFO_OFFERING_MATERIAL}
                    checked={rfo.type === rfoTypes.RFO_OFFERING_MATERIAL}
                    className={cx(
                      'radioButton',
                      rfo.type === rfoTypes.RFO_OFFERING_MATERIAL
                        ? 'radioButtonChecked'
                        : null
                    )}
                  />
                  <span className={cx('radioButtonLabel', 'qa-offeringMaterial')}>{t('Ei')}</span>
                </label>
              </div>
          
              <div className={cx([styles.rfoTypeRowItemThreeFifths, styles.rfoTypeDescription])}>
                <strong>
                  {t('Määritä, onko materiaalisi jätettä vai muuta materiaalia:')}
                </strong>
                <br/><br/>
                  {t('Jäte on mistä tahansa toiminnasta syntyvää sen tuottajalle käyttökelvotonta materiaalia. Jätelain (646/2011) mukaan jätettä on aine tai esine, jonka haltija on poistanut tai aikoo poistaa käytöstä taikka on velvollinen poistamaan käytöstä. Esimerkkiluettelo jätteistä löytyy jäteasetuksen (179/2012) liitteestä 4 ”Jäteluettelo: Yleisimmät jätteet sekä vaaralliset jätteet”.')}
                
                {!readMoreAboutWasteExpanded && (
                  <div className={styles.showMore} onClick={toggleReadMoreAboutWasteExpanded}>{t('Näytä lisää')}</div>
                )}
                {readMoreAboutWasteExpanded && (
                  <div>
                    {t('Lähtökohtaisesti toiminnanharjoittaja vastaa materiaalin luokittelusta jätteeksi. Ympäristöluvanvaraisessa toiminnassa syntyvät jätteet kirjataan yleensä ympäristölupaan. Apua esineen ja aineen jäteluonteen arvioinnissa voi tarvittaessa pyytää toiminnanharjoittajan sijaintipaikan mukaiselta jätelain valvontaviranomaiselta (kunnan ympäristönsuojeluviranomainen tai elinkeino-, liikenne- ja ympäristökeskus). \n\n On tärkeää tunnistaa, onko ilmoittamasi materiaali jätettä. Materiaalin luokittelusta jätteeksi seuraa jätelaista tulevia velvoitteita. Materiaalitorissakin jätteestä tulee antaa enemmän tietoja kuin muusta materiaalista. Jätelaissa määritellään myös, milloin aine tai esine ei ole jäte vaan sivutuote.  \n\n Kunnan toissijaista jätehuoltopalvelua voi muun palvelutarjonnan puutteen vuoksi pyytää vain jätteelle.')}
                  </div>
                )}
                {readMoreAboutWasteExpanded && (
                  <div className={styles.showLess} onClick={toggleReadMoreAboutWasteExpanded}>{t('Vähemmän')}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        )}


      </div>

    <div className={cx(styles.typeWrapper__firstLevel)}>
      <div className={styles.rfoTypeRow}>
        <div className={styles.rfoTypeRowItem}>
          <div className={cx([styles.rfoTypeRowItemTwoFifths])}>
            <label className={'textSemiBold'}>
              <input
                onChange={handleChange}
                type="radio"
                name="type"
                disabled={disabled}
                value={rfoTypes.RFO_RECEIVING_MATERIAL}
                checked={rfo.type === rfoTypes.RFO_RECEIVING_MATERIAL}
                className={'radioButton'}
              />
              <span className={cx('radioButtonLabel', 'qa-receivingMaterial')}>{t('Etsin hyödynnettäviä materiaaleja')}</span>
            </label>
          </div>
          <div className={cx([styles.rfoTypeRowItemThreeFifths, styles.rfoTypeDescription])}>
            <Trans i18nKey="ilmoitussivun_ilmoitus_tyyppi_vastaanotanmateriaalia">
              Haluat <strong>vastaanottaa materiaalia.</strong> Materiaali voi olla jätettä tai sivuvirtaa.
            </Trans>
          </div>
        </div>
      </div>
    </div>
    <div className={cx(styles.typeWrapper__firstLevel)}>
      <div className={styles.rfoTypeRow}>
        <div className={styles.rfoTypeRowItem}>
          <div className={cx([styles.rfoTypeRowItemTwoFifths])}>
            <label className='textSemiBold'>
              <input
                onChange={handleChange}
                type="radio"
                name="type"
                disabled={disabled}
                value={rfoTypes.RFO_OFFERING_SERVICES}
                checked={rfo.type === rfoTypes.RFO_OFFERING_SERVICES}
                className={'radioButton'}
              />
              <span className={cx('radioButtonLabel', 'qa-offeringServices')}>
                {t('Tarjoan jätehuolto- tai asiantuntijapalvelua')}
              </span>
            </label>
        </div>
        <div className={cx([styles.rfoTypeRowItemThreeFifths, styles.rfoTypeDescription])}>
          <Trans i18nKey="ilmoitussivun_ilmoitus_tyyppi_tarjoanpalveluita">
            Mikäli haluat <strong>tarjota jätehuolto- tai asiantuntijapalvelua</strong>, valitse tämä. Silloin voit ilmoittaa
            tarjoamastasi materiaalien käsittely- tai kuljetuspalvelusta, analysointipalvelusta tai
            materiaaleihin liittyvistä muista asiantuntijapalveluista.
          </Trans>
        </div>
      </div>
      </div>
    </div>
    <div className={cx(styles.typeWrapper__firstLevel)}>
      <div className={styles.rfoTypeRow}>
        <div className={styles.rfoTypeRowItem}>
          <div className={cx([styles.rfoTypeRowItemTwoFifths])}>
            <label className='textSemiBold'>
              <input
                onChange={handleChange}
                type="radio"
                name="type"
                disabled={disabled}
                value={rfoTypes.RFO_RECEIVING_SERVICES}
                checked={rfo.type === rfoTypes.RFO_RECEIVING_SERVICES}
                className={'radioButton'}
              />
              <span className={cx('radioButtonLabel', 'qa-receivingServices')}>
                {t('Etsin asiantuntijapalvelua tai osaamista')}
              </span>
            </label>
          </div>
          <div className={styles.rfoTypeDescription}>
            <Trans i18nKey="ilmoitussivun_ilmoitus_tyyppi_etsinpalveluita">
              Mikäli etsit <strong>asiantuntijapalvelua tai osaamista</strong>, valitse tämä. Voit etsiä asiantuntijoita 
              esimerkiksi tutkimus- tai kehityshankkeiden toteutukseen tai vaikka asiantuntijapuheenvuoron 
              pitäjäksi. Ilmoitukseen valitaan valikosta toivotut osaamisalueet ja niitä voi tarkentaa tekstin ja liitteiden avulla. 
            </Trans>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
);

export const TitleFragment = ({ t, rfo, handleChange }) => (
  <Row className={formStyles.formRow}>
    <Col span={12} className={formStyles.formInputContainer}>
      <input
        name="title"
        id="title"
        type="text"
        value={rfo.title || ''}
        onChange={handleChange}
        className="qa-rfo-title"
        maxLength={1000}
      />
    </Col>
  </Row>
);

export const LocationWithPublicityCheckboxFragment = ({
  t,
  rfo,
  handleChange,
  handleMunicipality,
  handleMapLocation,
}) => LocationPublicityCheckboxFragment({t, rfo, handleChange, handleMunicipality, handleMapLocation, customRequiredText: t('(pakollinen, mikäli aluetta ei annettu)')});

export const LocationWithPublicityCheckboxFragmentWithoutCustomRequiredText = ({
  t,
  rfo,
  handleChange,
  handleMunicipality,
  handleMapLocation,
}) => LocationPublicityCheckboxFragment({t, rfo, handleChange, handleMunicipality, handleMapLocation, customRequiredText: ' '});

const LocationPublicityCheckboxFragment = ({
  t,
  rfo,
  handleChange,
  handleMunicipality,
  handleMapLocation,
  customRequiredText
}) => (
  <>
    {!rfo.hilmaId && (
      <Row className={formStyles.formRow}>
        <Col span={6} sm={8} xs={12} className={formStyles.formInputContainer}>
          <>
            <input
              className="checkboxInput"
              type="checkbox"
              id="locationIsPrivate"
              name="locationIsPrivate"
              checked={rfo.locationIsPrivate || ''}
              onChange={handleChange}
            />
            <label htmlFor="locationIsPrivate" className="checkboxLabel">
              {t('Näytä tarkemmat sijaintitiedot vain kirjautuneille käyttäjille.')}
            </label>
          </>
        </Col>
      </Row>
    )}
    <LocationFragment
      t={t}
      form={rfo}
      handleChange={handleChange}
      handleMunicipality={handleMunicipality}
      handleMapLocation={handleMapLocation}
      customRequiredText={customRequiredText}
    />
  </>
);

export const LocationFragment = ({
  t,
  form,
  handleChange,
  handleMunicipality,
  handleMapLocation,
  customRequiredText,
}) => (
  <>
    <Row className={formStyles.formRow}>
      <Col span={6} sm={8} xs={12} className={formStyles.formInputContainer}>
        <label>
          <span className={formStyles.defaultLabelSpan}>
            {t('Kohteen nimi')}
            <InfoBox
              infoText={t(
                'Kirjoita esimerkiksi laitosalueen tai työmaan nimi tai muu vapaa tunniste.'
              )}
            />
          </span>
          <input
            type="text"
            name="locationName"
            value={form.locationName || ''}
            onChange={handleChange}
            maxLength={100}
          />
        </label>
      </Col>
    </Row>
    <Location
      municipalitiesOnly={true}
      handleChange={handleChange}
      handleMunicipality={handleMunicipality}
      handleMapLocation={handleMapLocation}
      rfo={form}
      customRequiredText={customRequiredText}
    />
  </>
);

export const ContactWithPublicityCheckboxFragment = ({ t, rfo, handleChange }) => (
  <>
    <ContactFragment t={t} form={rfo} handleChange={handleChange} />
    {/* {!rfo.hilmaId && (
      <Row className={formStyles.formRow}>
        <Col span={6} sm={8} xs={12} className={formStyles.formInputContainer}>
          <>
            <input
              className="checkboxInput"
              id="contactIsPrivate"
              name="contactIsPrivate"
              type="checkbox"
              checked={rfo.contactIsPrivate || ''}
              onChange={handleChange}
            />
            <label htmlFor="contactIsPrivate" className="checkboxLabel">
              {t('Näytä organisaation tiedot vain Materiaalitorin kirjautuneille käyttäjille.')}
            </label>
          </>
        </Col>
      </Row>
    )} */}
  </>
);

export const ContactFragment = ({ t, form, handleChange }) => (
  <>
    <Row className={formStyles.formRow}>
      <Col span={6} sm={8} xs={12} className={formStyles.formInputContainer}>
        <label>
          <span className={formStyles.defaultLabelSpan}>{t('Nimi')}</span>{' '}
          <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
          <input
            name="contact_name"
            id="contact_name"
            type="text"
            value={form.contact_name || ''}
            onChange={handleChange}
            maxLength={100}
          />
        </label>
      </Col>
    </Row>
    <Row className={formStyles.formRow}>
      <Col span={6} sm={8} xs={12} className={formStyles.formInputContainer}>
        <label>
          <span className={formStyles.defaultLabelSpan}>{t('Rooli organisaatiossa')}</span>
          <input
            name="contact_title"
            id="contact_title"
            type="text"
            value={form.contact_title || ''}
            onChange={handleChange}
            maxLength={100}
          />
        </label>
      </Col>
    </Row>
    <Row className={formStyles.formRow}>
      <Col span={2} sm={2} xs={12} className={formStyles.formInputContainer}>
        <label>
          <span className={formStyles.defaultLabelSpan}>{t('Puhelinnumero')}</span>
          <input
            name="contact_phone"
            id="contact_phone"
            type="phone"
            value={form.contact_phone || ''}
            onChange={handleChange}
            maxLength={100}
          />
        </label>
      </Col>
      <Col span={4} sm={4} xs={12} className={formStyles.formInputContainer}>
        <label>
          <span className={formStyles.defaultLabelSpan}>{t('Sähköpostiosoite')}</span>{' '}
          <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
          <input
            name="contact_email"
            id="contact_email"
            type="email"
            value={form.contact_email || ''}
            onChange={handleChange}
            maxLength={100}
          />
        </label>
      </Col>
    </Row>
  </>
);

export const CompanyIntroductionFragment = ({ t, form, handleChange }) => (
  <>
    <Row className={formStyles.formRow}>
    <Col span={12} sm={12} xs={12} className={formStyles.formInputContainer}></Col>
      <label>
        <span className={formStyles.defaultLabelSpan}>{t('Organisaation esittely')}</span>{' '}
        <textarea
          name="company_introduction"
          type="text"
          rows={6}
          value={form.company_introduction || ''}
          onChange={handleChange}
          maxLength={1000}
        />
      </label> 
    </Row>
    <Row className={formStyles.formRow}>
    <Col span={12} sm={12} xs={12} className={formStyles.formInputContainer}></Col>
      <label>
        <span className={formStyles.defaultLabelSpan}>{t('Muuta huomioitavaa')}</span>{' '}
        <textarea
          name="other_notices"
          type="text"
          rows={6}
          value={form.other_notices || ''}
          onChange={handleChange}
          maxLength={1000}
        />
      </label> 
    </Row>
  </>
);

export const ServiceListFragment = ({
  t,
  form,
  handleChange,
  onSubServiceAdd,
  onSubServiceRemove,
  services,
  currentSubServices,
  infoBox,
  label,
  subLabel,
  hidePrimarySelector,
  subLabelInfo
}) => (
  <Row className={formStyles.formRow}>
    <Col span={8} sm={12} xs={12} className={formStyles.formInputContainer}>
      {label && (
        <label>
          <span className={formStyles.defaultLabelSpan}>
            {label}{' '} <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
            {infoBox && <InfoBox infoText={t(infoBox)} />}
          </span>
        </label>
      )}
      <ServiceSelectorFragment
        t={t}
        form={form}
        handleChange={handleChange}
        mainLabel={'Valitse...'}
        subLabel={subLabel}
        onSubServiceAdd={onSubServiceAdd}
        onSubServiceRemove={onSubServiceRemove}
        services={services}
        currentSubServices={currentSubServices}
        hidePrimarySelector={hidePrimarySelector}
        subLabelInfo={subLabelInfo}
      />
    </Col>
  </Row>
);

export const ServiceDescriptionFragment = ({
  t,
  form,
  handleChange,
  infoBox,
  required,
  labelKey = 'Palvelun kuvaus',
  maxLength = 10000,
}) => (
  <Row className={formStyles.formRow}>
    <Col span={12} sm={12} xs={12} className={formStyles.formInputContainer}>
      <label>
        <span className={formStyles.defaultLabelSpan}>
          {t(labelKey)} {infoBox && <InfoBox infoText={t(infoBox)} />}{' '}
          {required && <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>}
        </span>
        <textarea
          name="serviceDescription"
          rows={6}
          value={form.serviceDescription}
          onChange={handleChange}
          maxLength={maxLength}
        />
      </label>
    </Col>
  </Row>
);

const searchSubServiceFn = (t) => (searchString) => (value) => {
  return (
    value.id.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
    t(value.id).toLowerCase().indexOf(searchString.toLowerCase()) > -1
  );
};

const subServiceIdsToSubServiceObj = (currentAvailableSubServices, selectedSubServiceIds) => {
  return currentAvailableSubServices.filter((subService) =>
    (selectedSubServiceIds || []).some(
      (selectedSubServiceId) => selectedSubServiceId === subService.id
    )
  );
};

export const ServiceSelectorFragment = ({
  t,
  form,
  handleChange,
  mainLabel,
  subLabel,
  onSubServiceAdd,
  onSubServiceRemove,
  services,
  currentSubServices,
  hidePrimarySelector,
  subLabelInfo
}) => (
  <>
    <select onChange={handleChange} name="serviceName" value={form.serviceName || ''} hidden={hidePrimarySelector} className={(!form.serviceName ? formStyles.placeholder : '' )}>
      <option value="" disabled hidden>
        {t(mainLabel)}
      </option>
      {services.map((service, i) => {
        return (
          <option key={service.id} value={service.id}>
            {t(service.id)}
          </option>
        );
      })}
    </select>
    {currentSubServices &&
      currentSubServices.length > 0 && ( // this needs to be changed to Henris multiselect component, changing top level service should clear all selected subservices
        <div className={'qa-subservice-selector'}>
          <label>
            {t(subLabel)} <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
            {subLabelInfo && <InfoBox infoText={t(subLabelInfo)} />}
          </label>
          <Select
            values={currentSubServices}
            onAdd={onSubServiceAdd}
            single={false}
            value={subServiceIdsToSubServiceObj(currentSubServices, form.subService)}
            displayFn={(value) => t(value.id)}
            searchFn={searchSubServiceFn(t)}
            keyFn={(val) => val.id}
            showAllOnFocus={true}
            onRemove={onSubServiceRemove}
          />
        </div>
      )}
  </>
);

export const ServiceFragment = ({
  t,
  rfo,
  handleChange,
  onSubServiceAdd,
  onSubServiceRemove,
  services,
  currentSubServices,
}) => (
  <>
    <Row className={formStyles.formRow}>
      <Col span={6} sm={8} xs={12} className={formStyles.formInputContainer}>
        <label>
          <span className={formStyles.defaultLabelSpan}>
            {t('Palvelu')}{' '}
            {rfo.type === rfoTypes.RFO_OFFERING_WASTE && (
              <span className={'requiredIndicator'}>
                {rfo.hilmaId ? t('(pakollinen)') : t('(pakollinen, mikäli pyydetään TSV-palvelua)')}
              </span>
            )}
            <InfoBox
              infoText={
                rfo.hilmaId
                  ? t('Ensisijaisesti pyydetty palvelu.')
                  : t('Ensisijaisesti haettu palvelu.')
              }
            />
          </span>
        </label>
        <ServiceSelectorFragment
          t={t}
          form={rfo}
          handleChange={handleChange}
          mainLabel={
            rfo.hilmaId
              ? 'Valitse palvelu, jota ensisijaisesti pyydät jätteelle'
              : 'Valitse palvelu, jota ensisijaisesti haet jätteelle'
          }
          subLabel={rfo.hilmaId ? 'Tarkenna pyytämääsi palvelua' : 'Tarkenna hakemaasi palvelua'}
          onSubServiceAdd={onSubServiceAdd}
          onSubServiceRemove={onSubServiceRemove}
          services={services}
          currentSubServices={currentSubServices}
        />
      </Col>
    </Row>
    <Row className={formStyles.formRow}>
      <Col span={12} sm={12} xs={12} className={formStyles.formInputContainer}>
        <label>
          <span className={formStyles.defaultLabelSpan}>
            {rfo.hilmaId ? t('Pyydettävän palvelun kuvaus') : t('Haettavan palvelun kuvaus')}{' '}
            <span className={'requiredIndicator'}>
              {rfo.hilmaId ? t('(pakollinen)') : t('(pakollinen, mikäli pyydetään TSV-palvelua)')}
            </span>
            <InfoBox
              infoText={
                rfo.hilmaId
                  ? t(
                      'Vaatimukset ja toiveet pyydettävälle palvelulle tai muuta kuvausta palvelusta.'
                    )
                  : t(
                      'Vaatimukset ja toiveet haettavalle palvelulle tai muuta kuvausta palvelusta.'
                    )
              }
            />
          </span>
          <textarea
            name="serviceRequirements"
            rows={6}
            value={rfo.serviceRequirements}
            onChange={handleChange}
            maxLength={1000}
          />
        </label>
      </Col>
    </Row>
    <Row className={formStyles.formRow}>
      <Col span={12} sm={12} xs={12} className={formStyles.formInputContainer}>
        <label>
          <span className={formStyles.defaultLabelSpan}>
            {t('Palvelun toivottu ajankohta')}{' '}
            <span className={'requiredIndicator'}>
              {rfo.hilmaId ? t('(pakollinen)') : t('(pakollinen, mikäli pyydetään TSV-palvelua)')}
            </span>{' '}
            <InfoBox
              infoText={
                rfo.hilmaId
                  ? t(
                      'Pyydettävän palvelun alku- ja päättymisaika, sopimuskauden pituus tai muu palvelunajankohtaan liittyvä tarkennus.'
                    )
                  : t(
                      'Haettavan palvelun alku- ja päättymisaika, sopimuskauden pituus tai muu palvelunajankohtaan liittyvä tarkennus.'
                    )
              }
            />
          </span>
          <textarea
            rows={6}
            name="serviceDuration"
            value={rfo.serviceDuration}
            onChange={handleChange}
            maxLength={1000}
          />
        </label>
      </Col>
    </Row>
  </>
);

export const DatePickerFragment = withNamespaces()(
  ({
    t,
    value,
    name,
    label,
    suffix,
    isTouchDevice,
    handleChange,
    required,
    stylesOverride,
    minDate,
    minDateModifier,
    maxDate,
    maxDateModifier,
    i18n,
  }) => {
    registerLocale('fi-FI', fi);
    registerLocale('sv-FI', sv);

    const myStyles = { ...styles, ...stylesOverride };

    const getDate = (originalDate, dateModifier) => {
      const initialDate = originalDate ? new Date(originalDate) : new Date();
      const date =
        dateModifier && is(Number, dateModifier) ? addDays(initialDate, dateModifier) : initialDate;
      return date;
    };

    const touchInput = (
      <label className={myStyles.datePickerWrap}>
        <span className={cx(myStyles.datePickerLabel)}>
          {t(label)} {required && <span className={'requiredIndicator'}>{t('(pakollinen)')} </span>}
        </span>

        <input
          className={myStyles.datePickerInput}
          name={name}
          onChange={handleChange}
          type="date"
          min={format(getDate(minDate, minDateModifier), 'yyyy-MM-dd')}
          max={
            maxDate || maxDateModifier
              ? format(getDate(maxDate, maxDateModifier), 'yyyy-MM-dd')
              : null
          }
          value={value || ''}
        />
        <span className={cx(myStyles.datePickerLabel)}>{t(suffix)}</span>
      </label>
    );

    const handleDatePickerChange = (date) => {
      const evnt = { target: { value: formatDateForSaving(date), name: name } };
      handleChange(evnt);
    };

    const nonTouchInput = (
      <label onClick={(e) => e.preventDefault()} className={myStyles.datePickerWrap}>
        <span className={cx(myStyles.datePickerLabel)}>
          {t(label)} {required && <span className={'requiredIndicator'}>{t('(pakollinen)')} </span>}
        </span>

        <DatePicker
          autoComplete="off"
          locale={i18n.language}
          name={name}
          dateFormat="d.M.yyyy"
          onChange={handleDatePickerChange}
          selected={value ? new Date(value) : null}
          minDate={getDate(minDate, minDateModifier)}
          maxDate={maxDate || maxDateModifier ? getDate(maxDate, maxDateModifier) : null}
          disabledKeyboardNavigation
          className={myStyles.datePickerInput}
        />
        <span className={cx(myStyles.datePickerLabel)}>{t(suffix)}</span>
      </label>
    );

    return (
      <>
        <Row className={formStyles.formRow}>
          <Col span={6} sm={12} xs={12} className={formStyles.formInputContainer}>
            {isTouchDevice ? touchInput : nonTouchInput}
          </Col>
        </Row>
      </>
    );
  }
);

export const WasteFragment = ({ materials, handleChange, ewcs, popWastes, rfo, currentSubClassifications }) =>
materials.map((val, idx) => {
    return (
      <React.Fragment key={idx}>
        <SingleWasteEdit
          material={val}
          handleMaterialChange={handleChange(idx)}
          ewcs={ewcs}
          popWastes={popWastes}
          rfo={rfo}
          currentSubClassifications={currentSubClassifications}
        />
      </React.Fragment>
    );
  });

export const MaterialFragment = ({ materials, handleChange, currentSubClassifications }) =>
  materials.map((val, idx) => {
    return (
      <React.Fragment key={idx}>
        <SingleMaterialEdit material={val} handleMaterialChange={handleChange(idx)} currentSubClassifications={currentSubClassifications} />
      </React.Fragment>
    );
  });

export const ReceiveMaterialFragment = ({ materials, handleChange, currentSubClassifications }) =>
  materials.map((val, idx) => {
    return (
      <React.Fragment key={idx}>
        <SingleReceiveMaterialEdit material={val} handleMaterialChange={handleChange(idx)} currentSubClassifications={currentSubClassifications} />
      </React.Fragment>
    );
  });

export const TsvFragment = ({ t, materials, handleChange, user }) =>
  materials.map((material, index) => {
    return (
      <Row key={`material-${index}`} className={cx(formStyles.formRow)}>
        <Col span={12} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <>
            <h2>TSV-palvelu</h2>
            <div>
              <strong>{t('Mikä on TSV?')}</strong><br/>
                <Trans i18nKey="ilmoitussivun_tsv">
                    Mikäli et ilmoituksesta huolimatta löydä jätteellesi vastaanottajaa, sinun on
                    mahdollista pyytää kunnan jätelaitokselta jätehuoltopalvelua (kunnan
                    toissijainen jätehuoltopalvelu eli ns. TSV-palvelu). Jos valitset tämän,
                    täytettäväksesi tuleva ilmoituspohja täyttää jätelainsäädännössä ilmoitukselle
                    asetetut vaatimukset (mm. ilmoituksen 14 vuorokauden voimassaoloaika).
                    Lisätietoa
                    <Link to="/tietoa-palvelusta#tsv" target="_blank">
                      kunnan toissijaisesta jätehuoltopalvelusta
                    </Link>
                    .
                </Trans>
            </div>
            <div className={styles.marginTop2em}>
              <div className={styles.rfoTypeRow}>
                <div className={styles.tsvTypeRowItem}>
                <label className={'qa-useTsv'}>
                  <input
                    onChange={handleChange(index)}
                    type="radio"
                    name="useTsv"
                    value={'true'}
                    checked={material.useTsv === true}
                    className={'radioButton'}
                  />
                  <span className={'radioButtonLabel'}><strong>{t('Haluan TSV-palvelun.')}</strong> {t('Voin ilmoituksen voimassaolon jälkeen tarvittaessa pyytää kunnan toissijaista jätehuoltopalvelua eli ns. TSV-palvelua.')}</span>
                </label>
                </div>
              </div>
              <div className={styles.rfoTypeRow}>
                <div className={styles.tsvTypeRowItem}>
                <label>
                  <input
                    onChange={handleChange(index)}
                    type="radio"
                    name="useTsv"
                    value={'false'}
                    checked={material.useTsv === false}
                    className={'radioButton'}
                  />
                  <span className={'radioButtonLabel'}><strong>{t('En tarvitse TSV-palvelua.')}</strong> {t('Saan jätteen tai materiaalin käsittelyn muuta kautta.')}</span>
                </label>
                </div>
              </div>
            </div>
            <div className='divider'/>
          </>
        </Col>
      </Row>
    );
  });

const getRfoTypeTitle = (rfoType) => {
  switch (rfoType) {
    case rfoTypes.RFO_OFFERING_WASTE: return 'offeringWaste-title';
    case rfoTypes.RFO_OFFERING_MATERIAL: return 'offeringMaterial-title';
    case rfoTypes.RFO_RECEIVING_MATERIAL: return 'receivingMaterial-title';
    case rfoTypes.RFO_OFFERING_SERVICES: return 'offeringServices-title';
    case rfoTypes.RFO_RECEIVING_SERVICES: return 'receivingServices-title';
    default: return '';
  }
}

export const RfoTypeFragment = ({t, rfoType}) => {
  return (
    <>
      <Row className={cx(formStyles.formRow, styles.rfoTypeLabel)}>
        {t('Ilmoitustyyppi')}
      </Row>
      <Row className={cx(formStyles.formRow, styles.rfoTypeHeading)}>
        {t(getRfoTypeTitle(rfoType))}
      </Row>
    </>
  )
}
