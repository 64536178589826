import React from 'react';
import cx from 'classnames';
import { Col, Row } from '../Layout/Layout';
import formStyles from '../Layout/Form.module.css';
import styles from './Questionnaire.module.css';
import { ServiceListFragment } from '../RequestForOffer/FormFragments';
import { getCurrentSubServices} from '../RequestForOffer/rfo-utils';
import {
  allPass,
  both,
  complement,
  isEmpty,
  isNil,
  propSatisfies
} from 'ramda';
import InfoBox from '../InfoBox/InfoBox';
import { getRfoCloseDate, getServiceState, QuantityUnitField } from './questionnaire-utils';
import { getLocation } from './questionnaire-utils';

const isNotEmpty = complement(isEmpty);
const isNotNil = complement(isNil);

const serviceFromMateriaalitori = (viewForm) => {
  return viewForm.result.result === 'gotServiceFromMateriaalitori';
}

const isOtherReason = (viewForm) => {
  return viewForm.result.result === 'other';
}

const isValidForm = (viewForm, services) => {
  const currentSubServices = viewForm.result.serviceName ? getCurrentSubServices(viewForm.result.serviceName, services) : [];
  const subServiceIsNeeded = currentSubServices && currentSubServices.length > 0;
  const subServiceIsSelected = viewForm.result.subService && viewForm.result.subService.length > 0;

  if (serviceFromMateriaalitori(viewForm)
    && (!viewForm.result.amount
    || !viewForm.result.quantityUnit
    || !allPass([propSatisfies(both(isNotEmpty, isNotNil), 'serviceName'), () => !subServiceIsNeeded || (subServiceIsNeeded && subServiceIsSelected),
    ])(viewForm.result)
  )) {
    return false;
  } else if (isOtherReason(viewForm) && !viewForm.result.otherReason) {
    return false;
  } else {
    return viewForm.result.result;
  }  
}

const send = (viewForm, sendForm) => {
  const serviceFromMato = serviceFromMateriaalitori(viewForm)

  const payload = {
    id: viewForm.id,
    type: viewForm.type,
    result: {
      result: viewForm.result.result,
      amount: serviceFromMato ? viewForm.result.amount : undefined,
      quantityUnit: serviceFromMato ? viewForm.result.quantityUnit : undefined,
      serviceName: serviceFromMato ? viewForm.result.serviceName : undefined,
      subService: serviceFromMato ? viewForm.result.subService : undefined,
      feedback: viewForm.result.feedback,
      otherReason: isOtherReason(viewForm) ? viewForm.result.otherReason : undefined,
    },
    rfo: viewForm.rfo
  }
  
  sendForm(payload);
}

const materialQuantity = (quantity) => (
  <span>
    {quantity.Amount} {quantity.UnitOfMeasure}
  </span>
);

const acceptDecimalNumberInput = (e) => {
  if (e.currentTarget)
  {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9,]/g, '').replace(/(,.*),/g, '$1');
  }
}

export const OfferingWaste = ({ t, viewForm, handleChange, sendForm, services, addSubService, deleteSubService }) => {
  return viewForm.statusName === 'unanswered' 
         ? editForm(t, viewForm, handleChange, sendForm, services, addSubService, deleteSubService) 
         : readForm(t, viewForm);
};

const getRfoInfo = (t, viewForm) => {
  const [material] = viewForm.rfo.Materials;

  return (
    <div className={styles.detailWrapper}>
    <div className={styles.detailContainerHalf}>
      <p>
        <strong>{t('Sulkeutunut')}:</strong> {getRfoCloseDate(viewForm)}
      </p>
      <p>
        <strong>{t('Jäte')}:</strong> {t(material.Classification)}
        {material.SubClassification && <span>, {t(material.SubClassification)}</span>}
      </p>
      <p>
        <strong>{t('Määrä')}:</strong> {materialQuantity(material.Quantity)}{' '}
        <span>
          {material.Continuity === 'onetime' ? t('Kertaerä') : t('Jatkuva tuotanto')}
        </span>
      </p>
      <p>
        <strong>{t('Ilmoitukseesi saadut tarjoukset')}:</strong> {viewForm.rfo.Offers.length}
      </p>
    </div>
    <div className={styles.detailContainerHalf}>      
      <p>
        <strong>{t('Toimiala')}:</strong> {t(material.Industry)}
      </p>
      <p>
        <strong>{t('Sijainti')}:</strong> {getLocation(material, viewForm.rfo.Regions)}
      </p>
      <p>
        <strong>{t('Palvelu')}:</strong> {material.Service.ServiceIds && material.Service.ServiceIds.map((serviceId) => t(serviceId)).join(', ')}
      </p>
    </div>
  </div>
  );
}

const readForm = (t, viewForm)  => {
  
  const answers = viewForm.result.offeringWasteQuestionnaire; 
  const services = answers.subService && answers.subService.length > 0 ? answers.subService : [answers.serviceName];

  return (
    <Row className={cx(formStyles.formRow)}>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        {getRfoInfo(t, viewForm)}
      </Col>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <div className={styles.detailWrapper}>
          <div className={styles.detailContainerHalf}>
          <p>
            <strong>{t('Syy sulkeutumiselle')}:</strong> {getServiceState(t, answers.result)}
          </p>
          {answers.result === 'other' &&(
            <p>
              <strong>{t('Muu syy')}:</strong> {answers.otherReason}
            </p>
          )}
            {answers.result === 'gotServiceFromMateriaalitori' && (
              <div>
                <p>
                  <strong>{t('Määrä')}:</strong> {answers.amount} {answers.quantityUnit}
                </p>
                <p>
                  <strong>{t('Saatu palvelu')}:</strong> {services.map((serviceId) => t(serviceId)).join(', ')}
                </p>
              </div>
            )}
            <p>
              <strong>{t('Lisätietoa saamastasi palvelusta tai vapaata palautetta Materiaalitori-palvelusta')}: </strong>{answers.feedback}
            </p>
          </div>
        </div>
      </Col>
    </Row>
  )
};

const editForm = (t, viewForm, handleChange, sendForm, services, addSubService, deleteSubService) => {
  const showAdditionalOptions = viewForm.result && viewForm.result.result === 'gotServiceFromMateriaalitori';
  const showOtherReasonTextBox = viewForm.result && viewForm.result.result === 'other';
  const currentSubServices = getCurrentSubServices(viewForm.result.serviceName, services);

  return (  
  <Row className={cx(formStyles.formRow)}>
    <Col span={12} className={cx(formStyles.formInputContainer)}>

      {getRfoInfo(t, viewForm)}

      <h4>{t('Syy sulkeutumiselle')}:</h4>
      <div className={styles.typeWrapper__firstLevel}>
        <label className={'qa-serviceFromMateriaalitori'}>
          <input
            onChange={handleChange}
            type="radio"
            name="result"
            value={'gotServiceFromMateriaalitori'}
            className={cx('radioButton')}
          />
          <span className={'radioButtonLabel'}>{t('Sain palvelun Materiaalitorin ilmoituksen perusteella')}</span>

          {showAdditionalOptions && (
            <div className={styles.typeWrapper__secondLevel}>
            <div className={styles.marginTop1em}>

            <Col span={12} className={[cx(formStyles.formInputContainer), styles.marginTop1em]}>
              <label htmlFor="service">
               <ServiceListFragment
                   t={t}
                   handleChange={handleChange}
                   form={viewForm.result}
                   services={services}
                   currentSubServices={currentSubServices}
                   onSubServiceAdd={addSubService}
                   onSubServiceRemove={deleteSubService}
                   label={t('Saatu palvelu')}
                   subLabel={'Tarkenna saamaasi palvelua'}
                 />                 
              </label>
           </Col>

            <Col span={8} xs={12} className={cx(formStyles.formInputContainer)}>
              <label htmlFor="amount">
                <span className={cx(formStyles.defaultLabelSpan, 'qa-waste-amount')}>
                  {t('Arvio palveluun toimitettavan jätteen määrästä')}{' '}
                  <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                  <InfoBox
                    infoText={t('Jos kyse oli kertaerästä, ilmoita koko määrä valituissa yksiköissä, jos jatkuvasti syntyvästä materiaalista, arvioi koko sopimuskautena toimitettava materiaali.')}
                  />
                </span>

                <input
                  name="amount"
                  id="amount"
                  type="text"
                  onChange={ e => { acceptDecimalNumberInput(e); handleChange(e) }}
                  maxLength={30}
                />
              </label>
            </Col>
            <Col span={4} xs={12} className={cx(formStyles.formInputContainer)}>
              <QuantityUnitField t={t} material={viewForm.result} handleMaterialChange={handleChange} />
            </Col>
            </div>
            </div>
          )}
        </label>

        <label className={cx(styles.typeWrapper__firstLevel)}>
          <input
            onChange={handleChange}
            type="radio"
            name="result"
            value={'serviceOutsideMateriaalitori'}
            className={cx('radioButton', 'qa-serviceOutsideMateriaalitori')}
          />
          <span className={'radioButtonLabel'}>{t('Sain palvelun Materiaalitorin ulkopuolelta')}</span>
        </label>

        <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'rfoExpired'}
              className={cx('radioButton', 'qa-expired')}
            />
            <span className={'radioButtonLabel'}>{t('Ilmoitus sulkeutui määräajan umpeuduttua')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'republishExpiredRfo'}
              className={cx('radioButton', 'qa-republishReason')}
            />
            <span className={'radioButtonLabel'}>{t('Ilmoitus sulkeutui määräajan umpeuduttua ja julkaisen sen uudelleen')}</span>
          </label>

        <label className={cx(styles.typeWrapper__firstLevel)}>
          <input
            onChange={handleChange}
            type="radio"
            name="result"
            value={'didNotGetService'}
            className={cx('radioButton')}
          />
          <span className={'radioButtonLabel'}>{t('En saanut palvelua')}</span>
        </label>

        <label className={cx(styles.typeWrapper__firstLevel)}>
          <input
            onChange={handleChange}
            type="radio"
            name="result"
            value={'noNeedForService'}
            className={cx('radioButton')}
          />
          <span className={'radioButtonLabel'}>{t('Palvelun tarve poistui muusta syystä')}</span>
        </label>

        <label className={cx(styles.typeWrapper__firstLevel)}>
          <input
            onChange={handleChange}
            type="radio"
            name="result"
            value={'other'}
            className={cx('radioButton', 'qa-otherReason')}
          />
          <span className={'radioButtonLabel'}>{t('Muu syy')}</span>
        </label>         
      </div>

      {showOtherReasonTextBox && (
          <div className={styles.marginTop1em}>
            <label htmlFor="otherReason">
              <span className={formStyles.defaultLabelSpan}>
                {t('Muu syy')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              </span>              
              <textarea
                name="otherReason"
                id="otherReason"
                type="text"
                rows={6}            
                onChange={handleChange}
                maxLength={1000}
              />
            </label>
          </div>
        )}
    </Col>

    <Col span={12} className={cx(formStyles.formInputContainer)}>
      <label htmlFor="feedback">
        <h4>{t('Lisätietoa tai palautetta Materiaalitori-palvelusta')}:</h4>
        <textarea
          name="feedback"
          id="feedback"
          type="text"
          rows={6}            
          onChange={handleChange}
          maxLength={1000}
        />
      </label>
    </Col>
      
    <Col span={12} className={cx(formStyles.formInputContainer)}>
      <button
        className={cx(styles.submitButton, "qa-submit-questionnaire")}
        type="button"
        onClick={() => send(viewForm, sendForm)}
        disabled={!isValidForm(viewForm, services)}>
        {t('Lähetä')}
      </button>
    </Col>
  </Row>
  )
};
