import cx from 'classnames';
import { differenceInDays } from 'date-fns';
import {
  allPass,
  anyPass,
  both,
  complement,
  compose,
  isEmpty,
  isNil,
  path,
  pathEq,
  pathSatisfies,
  propSatisfies,
  replace,
} from 'ramda';
import React, { PureComponent } from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Prompt, Redirect } from 'react-router-dom';
import { ILMOITUKSET, LISAA_ILMOITUS, LISAA_ILMOITUS_HILMA, LISAA_ILMOITUS_JATE, MUOKKAA_ILMOITUS } from '../../routes';
import { postJson, putJson } from '../../services/ApiService';
import { addToastNotification, ToastTypes } from '../../services/ToastService';
import { rfoOperations } from '../../state/ducks/rfo';
import { userSelectors } from '../../state/ducks/user';
import { questionnaireOperations } from '../../state/ducks/questionnaire';
import { dateIsInThePast } from '../../utils/date-utils';
import { handlePrefills } from '../../utils/user-utils';
import Attachment from '../Attachment/Attachment';
import PrivateComponent from '../Auth/PrivateComponent';
import suspendUntilAuthorized from '../Auth/suspendUntilAuthorized';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import InfoBox from '../InfoBox/InfoBox';
import { Col, Container, Row } from '../Layout/Layout';
import Loader from '../Loader/Loader';
import Navigation from '../Navigation/Navigation';
import RegionSelect from '../RegionSelect/RegionSelect';
import CloseRfoButton from './CloseRfoButton';
import {
  CompanyIntroductionFragment,
  ContactWithPublicityCheckboxFragment,
  DatePickerFragment,
  LocationWithPublicityCheckboxFragment,
  LocationWithPublicityCheckboxFragmentWithoutCustomRequiredText,
  MaterialFragment,
  ReceiveMaterialFragment,
  RfoTypeFragment,
  ServiceDescriptionFragment,
  ServiceFragment,
  ServiceListFragment,
  TitleFragment,
  TsvFragment,
  TypeSelectFragment,
  WasteFragment,
} from './FormFragments';
import styles from './RequestForOffer.module.css';
import { getCurrentSubServices, getCurrentSubClassifications, isClosed, rfoStateToRequestPayload } from './rfo-utils';
import * as rfoTypes from './types';
import Preview from './View';
import { getRaw } from '../../services/ApiService';
import { GET_HILMA_NOTIFICATION } from '../../services/endpoints';
import withCancelToken from '../CancelToken/withCancelToken';
import HilmaTsvSteps from '../TSV/HilmaTsvSteps';
import QuestionnaireListView from '../Questionnaire/QuestionnaireListView';


const isNotEmpty = complement(isEmpty);
const isNotNil = complement(isNil);

const handleTypePreSelection = (path, key, handleChange) => {
  if (path === LISAA_ILMOITUS_JATE) {
    handleChange(key, rfoTypes.RFO_OFFERING_WASTE);
  }
};

const handleHilmaPreSelection = (path, key, handleChange) => {
  if (path === LISAA_ILMOITUS_HILMA + '/:id') {
    handleChange(key, rfoTypes.RFO_OFFERING_WASTE);
  }
};

const handleHilmaPrefills = (form, handlePrefillChange, text, id, expired, created) => {
  if (!form.hilmaId) {
    handlePrefillChange('hilmaId', id);
  }
  if (!form.title) {
    handlePrefillChange('title', text);
  }
  if (!form.expires) {
    handlePrefillChange('expires', expired);
  }
  if (!form.hilmaCreated) {
    handlePrefillChange('hilmaCreated', created);
  }
};

const isTsv = (rfo) => {
  return rfo.type === rfoTypes.RFO_OFFERING_WASTE && pathEq(['materials', 0, 'useTsv'], true)(rfo);
};

const isInFuture = (expires) => {
  if (!expires) return false;
  return !dateIsInThePast(new Date(expires));
};

const isEnoughAhead = (expires) => {
  if (!expires) return false;
  return differenceInDays(new Date(expires), new Date()) >= 14;
};

const validForm = (rfo, loading = false, services) => {
  const errors = {};
  let passes = false;

  if (loading) {
    return [false, errors];
  }

  const currentSubServices = getCurrentSubServices(rfo.serviceName, services);
  const subServiceIsNeeded = currentSubServices && currentSubServices.length > 0;
  const subServiceIsSelected = rfo.subService && rfo.subService.length > 0;

  switch (rfo.type) {
    case rfoTypes.RFO_OFFERING_WASTE: {
      if (rfo.hilmaId) {
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'classification'])(rfo)) {
          errors['classification'] = 'Jäte';
        }
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'industry'])(rfo)) {
          errors['industry'] = 'Toimiala, jossa jäte syntyy';
        }        
        if (!pathSatisfies(isNotEmpty, ['materials', 0, 'useTsv'])(rfo)) {
          errors['useTsv'] = 'TSV-palvelu';
        }
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'type'])(rfo)) {
          errors['type'] = 'Onko jäte vaarallista?';
        }
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'permanentWasteType'])(rfo)) {
          errors['permanentWasteType'] = 'Onko jäte pysyvää?';
        }
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'popWasteType'])(rfo)) {
          errors['popWasteType'] = 'Onko jäte POP-jätettä?';
        }
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'description'])(rfo)) {
          errors['description'] = 'Jätteen kuvaus';
        }
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'continuity'])(rfo)) {
          errors['continuity'] = 'Kertaerä vai jatkuvasti syntyvä jäte';
        }
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'quantityAmount'])(rfo)) {
          errors['quantityAmount'] = 'Arvio jätteen määrästä';
        }
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'quantityUnit'])(rfo)) {
          errors['quantityUnit'] = 'Määrän yksikkö';
        }
        if (
          !allPass([
            propSatisfies(both(isNotEmpty, isNotNil), 'serviceName'),
            () => !subServiceIsNeeded || (subServiceIsNeeded && subServiceIsSelected),
          ])(rfo)
        ) {
          errors['serviceName'] = 'Palvelu';
        }
        if (!propSatisfies(both(isNotEmpty, isNotNil), 'serviceRequirements')(rfo)) {
          errors['serviceRequirements'] = 'Pyydettävän palvelun kuvaus';
        }
        if (!propSatisfies(both(isNotEmpty, isNotNil), 'serviceDuration')(rfo)) {
          errors['serviceDuration'] = 'Palvelun toivottu ajankohta';
        }
        if (
          !anyPass([
            propSatisfies(both(isNotEmpty, isNotNil), 'locationCity'),
            propSatisfies(both(isNotEmpty, isNotNil), 'regions'),
          ])(rfo)
        ) {
          errors['locationCity tai regions'] = 'Kunta tai alueet';
        }
        if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_name')(rfo)) {
          errors['contact_name'] = 'Nimi';
        }
        if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_email')(rfo)) {
          errors['contact_email'] = 'Sähköpostiosoite';
        }
        if (rfo.popWasteType === 'popWaste')
        {
          if (!propSatisfies(both(isNotEmpty, isNotNil), 'popWastes')(rfo)) {
            errors['popWastes'] = 'POP-yhdisteet';
          }
        }

        if (isEmpty(errors)) {
          passes = true;
        }

        return [passes, errors];
      }

      if (!propSatisfies(both(isNotEmpty, isNotNil), 'title')(rfo)) {
        errors['title'] = 'Ilmoituksen otsikko';
      }
      if (!pathSatisfies(isNotEmpty, ['materials', 0, 'useTsv'])(rfo)) {
        errors['useTsv'] = 'TSV-palvelu';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'classification'])(rfo)) {
        errors['classification'] = 'Jäte';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'industry'])(rfo)) {
        errors['industry'] = 'Toimiala, jossa jäte syntyy';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'type'])(rfo)) {
        errors['type'] = 'Onko jäte vaarallista?';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'permanentWasteType'])(rfo)) {
        errors['permanentWasteType'] = 'Onko jäte pysyvää?';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'popWasteType'])(rfo)) {
        errors['popWasteType'] = 'Onko jäte POP-jätettä?';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'description'])(rfo)) {
        errors['description'] = 'Jätteen kuvaus';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'continuity'])(rfo)) {
        errors['continuity'] = 'Kertaerä vai jatkuvasti syntyvä jäte';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'quantityAmount'])(rfo)) {
        errors['quantityAmount'] = 'Arvio jätteen määrästä';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'quantityUnit'])(rfo)) {
        errors['quantityUnit'] = 'Määrän yksikkö';
      }
      if (
        !anyPass([
          propSatisfies(both(isNotEmpty, isNotNil), 'locationCity'),
          propSatisfies(both(isNotEmpty, isNotNil), 'regions'),
        ])(rfo)
      ) {
        errors['locationCity tai regions'] = 'Kunta tai alueet';
      }
      if (
        !propSatisfies(both(isNotEmpty, isNotNil), 'expires')(rfo) &&
        !propSatisfies(isInFuture, 'expires')(rfo)
      ) {
        errors['expires'] = 'Ilmoituksen voimassaoloaika';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_name')(rfo)) {
        errors['contact_name'] = 'Nimi';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_email')(rfo)) {
        errors['contact_email'] = 'Sähköpostiosoite';
      }

      if (rfo.materials[0].popWasteType === 'popWaste')
      {
        if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'popWastes'])(rfo)) {
          errors['popWastes'] = 'POP-yhdisteet';
        }
      }

      if (isTsv(rfo)) {
        if (
          !allPass([
            propSatisfies(both(isNotEmpty, isNotNil), 'serviceName'),
            () => !subServiceIsNeeded || (subServiceIsNeeded && subServiceIsSelected),
          ])(rfo)
        ) {
          errors['serviceName'] = 'Palvelu';
        }
        if (!propSatisfies(both(isNotEmpty, isNotNil), 'serviceRequirements')(rfo)) {
          errors['serviceRequirements'] = 'Haettavan palvelun kuvaus';
        }
        if (!propSatisfies(both(isNotEmpty, isNotNil), 'serviceDuration')(rfo)) {
          errors['serviceDuration'] = 'Palvelun toivottu ajankohta';
        }
        if (!propSatisfies(isEnoughAhead, 'expires')(rfo)) {
          errors['expires'] = 'Ilmoituksen voimassaoloaika';
        }
      }

      if (isEmpty(errors)) {
        passes = true;
      }

      return [passes, errors];
    }
    case rfoTypes.RFO_OFFERING_MATERIAL: {
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'title')(rfo)) {
        errors['title'] = 'Ilmoituksen otsikko';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'classification'])(rfo)) {
        errors['classification'] = 'Materiaali';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'industry'])(rfo)) {
        errors['industry'] = 'Toimiala, jossa materiaali syntyy';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'continuity'])(rfo)) {
        errors['continuity'] = 'Kertaerä vai jatkuvasti syntyvä materiaali';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'quantityAmount'])(rfo)) {
        errors['quantityAmount'] = 'Arvio materiaalin määrästä';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'quantityUnit'])(rfo)) {
        errors['quantityUnit'] = 'Määrän yksikkö';
      }
      if (
        !anyPass([
          propSatisfies(both(isNotEmpty, isNotNil), 'locationCity'),
          propSatisfies(both(isNotEmpty, isNotNil), 'regions'),
        ])(rfo)
      ) {
        errors['locationCity tai regions'] = 'Kunta tai alueet';
      }
      if (
        !propSatisfies(both(isNotEmpty, isNotNil), 'expires')(rfo) &&
        !propSatisfies(isInFuture, 'expires')(rfo)
      ) {
        errors['expires'] = 'Ilmoituksen voimassaoloaika';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_name')(rfo)) {
        errors['contact_name'] = 'Nimi';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_email')(rfo)) {
        errors['contact_email'] = 'Sähköpostiosoite';
      }

      if (isEmpty(errors)) {
        passes = true;
      }

      return [passes, errors];
    }
    case rfoTypes.RFO_RECEIVING_MATERIAL: {
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'title')(rfo)) {
        errors['title'] = 'Ilmoituksen otsikko';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'classification'])(rfo)) {
        errors['classification'] = 'Materiaali';
      }
      if (!pathSatisfies(both(isNotEmpty, isNotNil), ['materials', 0, 'description'])(rfo)) {
        errors['description'] = 'Materiaalin ja tarpeen kuvaus';
      }
      if (
        !propSatisfies(both(isNotEmpty, isNotNil), 'expires')(rfo) &&
        !propSatisfies(isInFuture, 'expires')(rfo)
      ) {
        errors['expires'] = 'Ilmoituksen voimassaoloaika';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_name')(rfo)) {
        errors['contact_name'] = 'Nimi';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_email')(rfo)) {
        errors['contact_email'] = 'Sähköpostiosoite';
      }

      if (isEmpty(errors)) {
        passes = true;
      }

      return [passes, errors];
    }
    case rfoTypes.RFO_OFFERING_SERVICES: {
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'title')(rfo)) {
        errors['title'] = 'Ilmoituksen otsikko';
      }
      if (
        !allPass([
          propSatisfies(both(isNotEmpty, isNotNil), 'serviceName'),
          () => !subServiceIsNeeded || (subServiceIsNeeded && subServiceIsSelected),
        ])(rfo)
      ) {
        errors['serviceName'] = 'Tarjottava palvelu';
      }
      if (
        !anyPass([
          propSatisfies(both(isNotEmpty, isNotNil), 'locationCity'),
          propSatisfies(both(isNotEmpty, isNotNil), 'regions'),
        ])(rfo)
      ) {
        errors['locationCity tai regions'] = 'Kunta tai alueet';
      }
      if (
        !propSatisfies(both(isNotEmpty, isNotNil), 'expires')(rfo) &&
        !propSatisfies(isInFuture, 'expires')(rfo)
      ) {
        errors['expires'] = 'Ilmoituksen voimassaoloaika';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_name')(rfo)) {
        errors['contact_name'] = 'Nimi';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_email')(rfo)) {
        errors['contact_email'] = 'Sähköpostiosoite';
      }

      if (isEmpty(errors)) {
        passes = true;
      }

      return [passes, errors];
    }

    case rfoTypes.RFO_RECEIVING_SERVICES: {
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'title')(rfo)) {
        errors['title'] = 'Ilmoituksen otsikko';
      }
      if (
        !allPass([
          propSatisfies(both(isNotEmpty, isNotNil), 'serviceName'),
          () => !subServiceIsNeeded || (subServiceIsNeeded && subServiceIsSelected),
        ])(rfo)
      ) {
        errors['serviceName'] = 'Etsittävä palvelu tai osaaminen';
      }
      if (
        !anyPass([
          propSatisfies(both(isNotEmpty, isNotNil), 'locationCity'),
          propSatisfies(both(isNotEmpty, isNotNil), 'regions'),
        ])(rfo)
      ) {
        errors['locationCity tai regions'] = 'Kunta tai alueet';
      }
      if (
        !propSatisfies(both(isNotEmpty, isNotNil), 'expires')(rfo) &&
        !propSatisfies(isInFuture, 'expires')(rfo)
      ) {
        errors['expires'] = 'Ilmoituksen voimassaoloaika';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_name')(rfo)) {
        errors['contact_name'] = 'Nimi';
      }
      if (!propSatisfies(both(isNotEmpty, isNotNil), 'contact_email')(rfo)) {
        errors['contact_email'] = 'Sähköpostiosoite';
      }

      if (isEmpty(errors)) {
        passes = true;
      }

      return [passes, errors];
    }

    default: {
      return [true, errors];
    }
  }
};

export const promptMessageFn = (t) => (location) => {
  const allowed = /^\/ilmoitukset\/(muokkaa\/)?[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}((\/teetsv+)+|\/?)$/;
  return location.pathname.match(allowed) ? true : t('Muutokset menetetään, jos poistut sivulta.');
};

export class EditRfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: undefined,
      loading: false,
      editMode: false,
      previewMode: false,
      rfoId: undefined,
      hilmaMode: false,
      hilmaId: undefined,
      hilmaTitle: undefined,
      hilmaExpDate: undefined,
      hilmaCreatedDate: undefined,
      hilmaOrganisationNumber: undefined,
      ownBusinessId: undefined,
      currentStep: 1,
      loadingQuestionnaires: false,
      readMoreAboutWasteExpanded: false,
    };
  }

  setModeAsync = () => {
    // In editing:
    if (this.props.match.path === MUOKKAA_ILMOITUS + '/:id') {
      const rfoId = path(['match', 'params', 'id'])(this.props);
      return new Promise((resolve) => {
        this.setState({ editMode: true, rfoId: rfoId }, resolve);
      });
    }

    // In copying (creating a new rfo by copying an existing rfo):
    if (this.props.match.path === LISAA_ILMOITUS + '/:id') {
      const rfoId = path(['match', 'params', 'id'])(this.props);
      return new Promise((resolve) => {
        this.setState({ rfoId: rfoId }, resolve);
      });
    }

    // In creating a new rfo based on a Hilma notice
    if (this.props.match.path === LISAA_ILMOITUS_HILMA + '/:id') {
      const hilmaId = path(['match', 'params', 'id'])(this.props);
      return new Promise((resolve) => {
        this.setState({ hilmaMode: true, hilmaId: hilmaId }, resolve);
      });
    }

    // Else in creating a new rfo:
    return new Promise((resolve) => {
      resolve();
    });
  };

  isInEditMode = () => {
    return this.state.editMode === true && this.state.rfoId !== undefined;
  };

  isInCopyMode = () => {
    return this.state.editMode === false && this.state.rfoId !== undefined;
  };

  isInCreateNewMode = () => {
    return this.state.editMode === false && this.state.rfoId === undefined;
  };

  isInEditModeOrCopyMode = () => {
    return this.state.rfoId !== undefined;
  };

  isInHilmaMode = () => {
    return this.state.hilmaMode === true && this.state.hilmaId !== undefined;
  };

  fetchRfo(rfoId, regionsAndMunicipalities) {
    if (this.isInCopyMode()) {
      this.props.fetchRfoForCopy(rfoId, regionsAndMunicipalities);
    } else {
      this.props.fetchRfoForEdit(rfoId, regionsAndMunicipalities);
    }
  }

  getHilmaNoticeInfo = hilmaId => {
    this.setState({ loading: true});

    const config = {
      cancelToken: this.props.cancelTokenSource.token
    };

    const fetchUrl = replace('{0}', hilmaId, GET_HILMA_NOTIFICATION);

    getRaw(fetchUrl, config)
      .then(response => {
        response.data.forEach((notice) => {
          this.setState({
            hilmaTitle: notice.projectTitle,
            hilmaExpDate: notice.expirationDate,
            hilmaCreatedDate: notice.datePublished,
            hilmaOrganisationNumber: notice.organisationNationalRegistrationNumber,
            loading: false
          });
        })
        handleHilmaPrefills(this.props.rfo, this.props.handleChange, this.state.hilmaTitle, this.state.hilmaId, this.state.hilmaExpDate, this.state.hilmaCreatedDate);
      })
      .catch(thrown => {
        if (!this.props.isCancel(thrown)) {
          this.setState({ loading: false });
        }
      });
  };

  async componentDidMount() {
    await this.setModeAsync();
    
    if (this.isInEditMode() || this.isInCopyMode()) {
      if (this.props.configurationsFetched) {
        this.fetchRfo(this.state.rfoId, this.props.regionsAndMunicipalities);
      }
    }

    if (this.isInCreateNewMode()) {
      
      this.props.fetchOpenQuestionnaires();      

      if (this.props.rfo.materials.length === 0) {
        this.props.addMaterial();
        this.props.rfo.materials[0].useTsv = '';
      }
      
      handleTypePreSelection(this.props.match.path, 'type', this.props.handleChange);
      handlePrefills(this.props.rfo, this.props.user, this.props.handleChange);

      if (this.isInHilmaMode()){
        this.getHilmaNoticeInfo(this.state.hilmaId);
        handleHilmaPreSelection(this.props.match.path, 'type', this.props.handleChange);
        var businessId = userSelectors.getOwnBusinessId(this.props.user);
        this.setState({ownBusinessId: businessId});
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Fetch rfo if the configurations were not ready before but are ready now and we are either editing or copying:
    if (
      prevProps.configurationsFetched !== this.props.configurationsFetched &&
      this.isInEditModeOrCopyMode()
    ) {
      this.fetchRfo(this.state.rfoId, this.props.regionsAndMunicipalities);
    }
  }

  componentWillUnmount() {
    this.props.clearForm();
    this.props.setRfoHasChanges(false);
  }

  // Handles material input field changes:
  handleMaterialChange = (index) => (event) => {
    const target = event.target? event.target : event;
    const value = target.type === 'radio' && target.name === 'useTsv' ? target.value === 'true' ? true : false : target.value;
    const name = target.name;
    if (target.name === 'classification') {
      this.props.deleteSubClassification();
    }
    this.props.handleMaterialChange(index, name, value);
  };

  // Handles input field changes of this file's inputs:
  handleChange = (event) => {
    const target = event.target;
    //For receiving service type rfo, set service always to 'asiantuntijapalvelut'
    if (target.name === 'type' && target.value === 'receivingServices')
    {
      this.props.handleChange('serviceName', 'asiantuntijapalvelu');
    }
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.props.handleChange(name, value);
    if (!this.props.formHasChanges) {
      this.props.setRfoHasChanges(true);
    }
  };

  handleMunicipalityChange = (municipality) => {
    if (municipality) {
      this.props.handleChange('locationCity', municipality);
    } else {
      this.props.handleChange('locationCity', undefined);
    }
  };

  handleMapLocation = (location) => {
    this.props.handleChange('mapLocation', location);
  };

  addRegion = (region) => {
    this.props.toggleRegion(region);
  };

  deleteRegion = (region) => {
    this.props.deleteRegion(region);
  };

  addSubService = (service) => {
    this.props.addSubService(service);
  };

  deleteSubService = (service) => {
    this.props.deleteSubService(service);
  };

  deleteAllSubServices = (serviceId) => {
    this.props.deleteAllSubServices(serviceId);
  };

  deleteSubClassification = () => {
    this.props.deleteSubClassification();
  };

  addOwnCompanyData = (data) => {
    const ownBusinessId = userSelectors.getOwnBusinessId(this.props.user);
    const ownCompanyName = userSelectors.getOwnCompanyName(this.props.user);
    data.company = { businessId: ownBusinessId, name: ownCompanyName };
  };

  preview = (event) => {
    const { type, data } = rfoStateToRequestPayload(this.props.rfo);
    const previewableData = {
      rfoType: type,
      ...data,
    };

    if (type === rfoTypes.RFO_RECEIVING_MATERIAL) {
      previewableData.materialsWanted = previewableData.materials;
    }

    this.addOwnCompanyData(previewableData);

    window.scrollTo(0, 0);
    this.setState({ previewData: previewableData, previewMode: true, currentStep: 2 });
  };

  submit = () => {
    const postUrl = '/api/rfo';
    const payload = rfoStateToRequestPayload(this.props.rfo);

    if (!this.state.editMode) {
      postJson(postUrl, payload)
        .then((res) => {
          const rfoGuid = res.data.id;

          if (this.state.hilmaMode) {
            addToastNotification(this.props.t('Tiedot tallennettu onnistuneesti.'), ToastTypes.SUCCESS);
          } else {
            addToastNotification(this.props.t('Ilmoitus luotu.'), ToastTypes.SUCCESS);
          }

          this.props.clearForm();

          if (this.state.hilmaMode){
            this.setState({ redirectTo: `${ILMOITUKSET}/${rfoGuid}/teetsv`, loading: false });
          } else {
            this.setState({ redirectTo: `${ILMOITUKSET}/${rfoGuid}`, loading: false });
          }
          
        })
        .catch((err) => {
          addToastNotification(this.props.t('Ilmoituksen luonti epäonnistui.'), ToastTypes.WARNING);

          this.setState({ loading: false });
        });
    } else {
      const rfoId = this.state.rfoId;

      putJson(postUrl + '/' + rfoId, payload)
        .then((res) => {
          const rfoGuid = res.data.id;

          addToastNotification(this.props.t('Muutokset tallennettu.'), ToastTypes.SUCCESS);

          this.props.clearForm();
          this.props.clearRfoFromView(); // Clear the rfo from view since we will direct to the view and we want to see the updated data

          this.setState({ redirectTo: `${ILMOITUKSET}/${rfoGuid}`, loading: false });
        })
        .catch((err) => {
          addToastNotification(this.props.t('Tallennus epäonnistui.'), ToastTypes.WARNING);

          this.setState({ loading: false });
        });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    return this.submit();
  };

  addMaterial = () => {
    this.props.addMaterial();
  };

  // @TODO: Remove window confirm propmpt and use some other.
  deleteMaterial = (t, index, description) => {
    if (
      window.confirm(
        t('Haluatko varmasti poistaa materiaalin?', {
          number: index + 1,
          description: description,
        })
      )
    ) {
      this.props.deleteMaterial(index);
    }
  };

  handleServiceChange = (event) => {
    this.handleChange(event);
    if (event.target.name === 'serviceName') {
      this.props.deleteAllSubServices();
    }
  };

  toggleReadMoreAboutWaste = () => {
    this.setState({readMoreAboutWasteExpanded: !this.state.readMoreAboutWasteExpanded});
  }

  render() {
    const { t, isTouchDevice, rfo, services, ewcs, popWastes, regionsAndMunicipalities, materials, user } = this.props;
    const { redirectTo, loading, editMode, hilmaMode, readMoreAboutWasteExpanded } = this.state;

    const getForm = (type) => {
      const dateInfoTextKey = isTsv(rfo)
        ? 'Ilmoita päivämäärä, mihin saakka ilmoitus on voimassa. Ilmoitus on nähtävissä Materiaalitorissa ja siihen voi vastata voimassaolon aikana. Voimassaoloaika tulee olla vähintään 14 vuorokautta ja enintään 12 kuukautta, jonka jälkeen ilmoitus poistuu Materiaalitorin ilmoituksista, mutta arkistoituu ilmoittajan omille sivuille, josta se on mahdollista tarvittaessa julkaista uudelleen. Mikäli ilmoitukseen ei ole voimassaoloaikana tullut hyväksyttäviä vastauksia, on mahdollista tehdä pyyntö kunnan toissijaisesta jätehuoltopalvelusta Materiaalitorin kautta. Materiaalitori ilmoittaa, kun pyynnön tekeminen on mahdollista.'
        : 'Ilmoita päivämäärä, mihin saakka ilmoitus on voimassa. Ilmoitus on nähtävissä Materiaalitorissa ja siihen voi vastata voimassaolon aikana. Voimassaoloaika voi olla enintään 12 kuukautta, jonka jälkeen ilmoitus poistuu Materiaalitorin ilmoituksista, mutta arkistoituu ilmoittajan omille sivuille, josta se on mahdollista tarvittaessa julkaista uudelleen.';

      switch (rfo.type) {
        case rfoTypes.RFO_OFFERING_WASTE: {
          const currentSubServices = getCurrentSubServices(rfo.serviceName, services);
          const currentSubClassifications = getCurrentSubClassifications(rfo.materials[0].classification, materials);

          return (
            <>
            <RfoTypeFragment rfoType={rfo.type} t={t}/>
            <div className={'divider'} />

            {!hilmaMode && !rfo.hilmaId && (
              <TsvFragment
                t={t}
                materials={rfo.materials}
                handleChange={this.handleMaterialChange}
                user={user}
              />
            )}
              {rfo.hilmaId && (
              <>
                <h4 name="hilmaId">{t('Hilma-ilmoitusnumero')}: {rfo.hilmaId}</h4>
                <h4 name="title">{t('Ilmoituksen otsikko')}: {rfo.title}</h4>
              </>
              )}
              {!rfo.hilmaId && (
              <>
                <h2>
                  <label htmlFor="title">
                    {t('Ilmoituksen otsikko')}{' '}
                    <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                  </label>
                </h2>
                <ul>
                  <li>{t('Kirjoita materiaalin tai palvelun vapaamuotoinen ja kuvaava nimi. Tee otsikosta selkeä ja kuvaava sillä se on hakulistauksissa, ilmoituksen sivulla yms. kaikista näkyvin elementti.')}</li>
                  <li>{t('Vältä otsikossa ”tarjotaan” –tyyppisiä termejä, koska se käy lukijalle hakuehdoista ja sivun tiedoista ilmi muutenkin.')}</li>
                  <li>{t('Otsikon muotoilussa on hyvä ottaa huomioon, että ilmoituksia voidaan hakea myös otsikossa olevien sanojen perusteella.')}</li>
                </ul>
                <TitleFragment t={t} handleChange={this.handleChange} rfo={rfo} />
              </>
              )}
              <div className='divider'/>
              <h2>{t('Jätteen kuvaus')}</h2>
              <WasteFragment
                materials={rfo.materials}
                handleChange={this.handleMaterialChange}
                ewcs={ewcs}
                popWastes={popWastes}
                rfo={rfo}
                currentSubClassifications={currentSubClassifications}
              />

              <div className='divider'/>
              <h2>
                {t('Liitteet ja kuvat')}{' '}
              </h2>
              
              <ul>
                <li>{t('Voit lisätä ilmoitukseen lisätietoja kuvina ja muina liitetiedostoina')}</li>
                <li>{t('Liitetiedostotyyppi tulee olla: JPG / PNG / GIF DOC(x) / XLS(x) /PPT(x)')}</li>
              </ul>

              <Attachment
                t={t}
                addFiles={this.props.addFiles}
                attachments={rfo.attachments}
                deleteFile={this.props.deleteFile}
                multiple={true}
              />

              <div className='divider'/>

              <h2>
                {rfo.hilmaId ? t('Pyydettävä palvelu ja sen kesto') : t('Haettava palvelu ja sen kesto')}{' '}
                <InfoBox
                  infoText={rfo.hilmaId 
                    ? t('Palvelua pyydettäessä on syytä huomioida jätelain mukainen velvollisuus noudattaa etusijajärjestystä. \n\n Etusijajärjestyksen mukaan ensisijaisesti on vähennettävä syntyvän jätteen määrää ja haitallisuutta. Jos jätettä kuitenkin syntyy, jätteen haltijan on ensisijaisesti valmisteltava jäte uudelleenkäyttöä varten tai toissijaisesti kierrätettävä se. Jos kierrätys ei ole mahdollista, jätteen haltijan on hyödynnettävä jäte muulla tavoin, mukaan lukien hyödyntäminen energiana. Jos hyödyntäminen ei ole mahdollista, jäte on loppukäsiteltävä.')
                    : t('Jätteen saa luovuttaa vain vastaanottajalle, jolla on jätelain mukainen hyväksyntä vastaanottaa jätettä. Jätteen haltijan tulee varmistaa ennen jätehuoltopalvelua koskevan sopimuksen tekemistä, että kyseisellä palveluntarjoajalla on oikeus vastaanottaa jäte. \n\n Palvelua haettaessa on syytä huomioida jätelain mukainen velvollisuus noudattaa etusijajärjestystä. \n\n Etusijajärjestyksen mukaan ensisijaisesti on vähennettävä syntyvän jätteen määrää ja haitallisuutta. Jos jätettä kuitenkin syntyy, jätteen haltijan on ensisijaisesti valmisteltava jäte uudelleenkäyttöä varten tai toissijaisesti kierrätettävä se. Jos kierrätys ei ole mahdollista, jätteen haltijan on hyödynnettävä jäte muulla tavoin, mukaan lukien hyödyntäminen energiana. Jos hyödyntäminen ei ole mahdollista, jäte on loppukäsiteltävä.'
                  )}
                />
              </h2>
              <ServiceFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleServiceChange}
                onSubServiceAdd={this.addSubService}
                onSubServiceRemove={this.deleteSubService}
                services={services}
                currentSubServices={currentSubServices}
              />
              <div className='divider'/>
              <h2>
                {t('Jätteen sijainti')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                <InfoBox
                  infoText={rfo.hilmaId
                    ? t('Jätteen sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa erityisesti jätteen kuljetuspalvelua pyydettäessä.')
                    : t('Jätteen sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa erityisesti jätteen kuljetuspalvelua haettaessa. Voit halutessasi valita, että tarkka katuosoitetieto näkyy vain Materiaalitoriin kirjautuneille käyttäjille. Sijainti näytetään joka tapauksessa vähintään kunnan tarkkuudella myös Materiaalitoriin kirjautumattomille käyttäjille.'
                  )}
                />
              </h2>
              <LocationWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
                handleMunicipality={this.handleMunicipalityChange}
                handleMapLocation={this.handleMapLocation}
              />
              <p>
                <strong>
                  {t('Valitse alueet')}{' '}
                  <span className={'requiredIndicator'}>
                    {t('(pakollinen, mikäli kuntaa ei annettu)')}
                  </span>{' '}
                  <InfoBox
                    infoText={t(
                      'Voit ilmoittaa useita sijainteja lisäämällä kuntia tai maakuntia. Aloita kirjoittamalla kenttään kunnan tai maakunnan nimeä ja saat valmiita ehdotuksia, joista voit valita.'
                    )}
                  />
                </strong>
              </p>
              <Col span={6} sm={8} xs={12}>
                <RegionSelect
                  handleChange={this.addRegion}
                  value={rfo.regions}
                  onRemove={region => this.deleteRegion(region)}
                />
              </Col>

              {!hilmaMode && !rfo.hilmaId && (
                <>
                <div className='divider'/>
              <h2>
                {t('Ilmoituksen voimassaoloaika')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                <InfoBox infoText={t(dateInfoTextKey)} />
              </h2>
              <div className={styles.bottomPadding}>
                <Trans i18nKey='validityInfo'>
                  Huom: voimassaoloaika tarkoittaa <b>ilmoituksen voimassaoloa Materiaalitorissa</b>, ei sopimuksen voimassaoloaikaa.
                </Trans>
              </div>
              <DatePickerFragment
                isTouchDevice={isTouchDevice}
                minDateModifier={isTsv(rfo) ? 15 : 1}
                value={rfo.expires || ''}
                name={'expires'}
                handleChange={this.handleChange}
                label={'Voimassaoloaika'}
                suffix={' asti'}
                t={t}
                maxDateModifier={365}
              />
              </>
              )}
              <div className='divider'/>
              <h2>{t('Yhteyshenkilön tiedot')}</h2>
              <ContactWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
              />
              <div className={'divider'} />
            </>
          );
        }
        case rfoTypes.RFO_OFFERING_MATERIAL: {
          const currentSubClassifications = getCurrentSubClassifications(rfo.materials[0].classification, materials);
          return (
            <>
              <RfoTypeFragment rfoType={rfo.type} t={t}/>
              <div className={'divider'} />

              <h2>
                <label htmlFor="title">
                  {t('Ilmoituksen otsikko')}{' '}
                  <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                </label>
              </h2>
              <ul>
                <li>{t('Kirjoita materiaalin tai palvelun vapaamuotoinen ja kuvaava nimi. Tee otsikosta selkeä ja kuvaava sillä se on hakulistauksissa, ilmoituksen sivulla yms. kaikista näkyvin elementti.')}</li>
                <li>{t('Vältä otsikossa ”tarjotaan” –tyyppisiä termejä, koska se käy lukijalle hakuehdoista ja sivun tiedoista ilmi muutenkin.')}</li>
                <li>{t('Otsikon muotoilussa on hyvä ottaa huomioon, että ilmoituksia voidaan hakea myös otsikossa olevien sanojen perusteella.')}</li>
              </ul>
              <TitleFragment t={t} handleChange={this.handleChange} rfo={rfo} />
              <h2>{t('Materiaalin kuvaus')}</h2>
              <MaterialFragment
                materials={rfo.materials}
                handleChange={this.handleMaterialChange}
                currentSubClassifications={currentSubClassifications}
              />
              <h2>
                {t('Liitteet ja kuvat')}{' '}
              </h2>
              <ul>
                <li>{t('Voit lisätä ilmoitukseen lisätietoja kuvina ja muina liitetiedostoina')}</li>
                <li>{t('Liitetiedostotyyppi tulee olla: JPG / PNG / GIF DOC(x) / XLS(x) /PPT(x)')}</li>
              </ul>
              <Attachment
                t={t}
                addFiles={this.props.addFiles}
                attachments={rfo.attachments}
                deleteFile={this.props.deleteFile}
                multiple={true}
              />
              <h2>
                {t('Materiaalin sijainti')}
                <InfoBox
                  infoText={t(
                    'Materiaalin sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa. Voit halutessasi valita, että tarkka katuosoitetieto näkyy vain Materiaalitoriin kirjautuneille käyttäjille. Sijainti näytetään joka tapauksessa vähintään kunnan tarkkuudella myös Materiaalitoriin kirjautumattomille käyttäjille'
                  )}
                />
              </h2>
              <LocationWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
                handleMunicipality={this.handleMunicipalityChange}
                handleMapLocation={this.handleMapLocation}
              />
              <p>
                <strong>
                  {t('Valitse alueet')}{' '}
                  <span className={'requiredIndicator'}>
                    {t('(pakollinen, mikäli kuntaa ei annettu)')}
                  </span>{' '}
                  <InfoBox
                    infoText={t(
                      'Voit ilmoittaa useita sijainteja lisäämällä kuntia tai maakuntia. Aloita kirjoittamalla kenttään kunnan tai maakunnan nimeä ja saat valmiita ehdotuksia, joista voit valita.'
                    )}
                  />
                </strong>
              </p>
              <RegionSelect
                handleChange={this.addRegion}
                value={rfo.regions}
                onRemove={region => this.deleteRegion(region)}
              />
              <h2>
                {t('Ilmoituksen voimassaoloaika')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                <InfoBox infoText={t(dateInfoTextKey)} />
              </h2>
              <div className={styles.bottomPadding}>
              <Trans i18nKey='validityInfo'>
                  Huom: voimassaoloaika tarkoittaa <b>ilmoituksen voimassaoloa Materiaalitorissa</b>, ei sopimuksen voimassaoloaikaa.
                </Trans>
              </div>
              <DatePickerFragment
                isTouchDevice={isTouchDevice}
                value={rfo.expires || ''}
                name={'expires'}
                handleChange={this.handleChange}
                label={'Voimassaoloaika'}
                suffix={' asti'}
                t={t}
                minDateModifier={1}
                maxDateModifier={365}
              />
              <h2>{t('Yhteyshenkilön tiedot')}</h2>
              <ContactWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
              />
              <div className={styles.divider} />
              <div className={'divider'} />
            </>
          );
        }
        case rfoTypes.RFO_RECEIVING_MATERIAL: {
          const currentSubClassifications = getCurrentSubClassifications(rfo.materials[0].classification, materials);

          return (
            <>
              <RfoTypeFragment rfoType={rfo.type} t={t}/>
              <div className={'divider'} />
              
              <h2>
                <label htmlFor="title">
                  {t('Ilmoituksen otsikko')}{' '}
                  <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                </label>
              </h2>
              <ul>
                <li>{t('Kirjoita materiaalin tai palvelun vapaamuotoinen ja kuvaava nimi. Tee otsikosta selkeä ja kuvaava sillä se on hakulistauksissa, ilmoituksen sivulla yms. kaikista näkyvin elementti.')}</li>
                <li>{t('Vältä otsikossa ”tarjotaan” –tyyppisiä termejä, koska se käy lukijalle hakuehdoista ja sivun tiedoista ilmi muutenkin.')}</li>
                <li>{t('Otsikon muotoilussa on hyvä ottaa huomioon, että ilmoituksia voidaan hakea myös otsikossa olevien sanojen perusteella.')}</li>
              </ul>
              <TitleFragment t={t} handleChange={this.handleChange} rfo={rfo} />
              <h2>{t('Materiaalin ja tarpeen kuvaus')}</h2>
              <ReceiveMaterialFragment
                materials={rfo.materials}
                handleChange={this.handleMaterialChange}
                currentSubClassifications={currentSubClassifications}
              />
              <h2>
                {t('Materiaalin sijainti')}
                <InfoBox infoText={t('Kunnat tai maakunnat, joiden alueelta etsit materiaalia. Voit antaa tämän lisäksi myös osoitteen, jossa materiaalia tarvitaan.')} />
              </h2>
              <LocationWithPublicityCheckboxFragmentWithoutCustomRequiredText
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
                handleMunicipality={this.handleMunicipalityChange}
                handleMapLocation={this.handleMapLocation}
              />
              <p>
                <strong>{t('Valitse alueet')}</strong>
              </p>
              <button
                className={styles.marginBottom1em}
                type="button"
                onClick={() => this.props.addAllRegions(this.props.regions)}
              >
                {t('Valitse koko Suomi')}
              </button>
              <div className={'qa-region-selector'}>
                <RegionSelect
                  handleChange={this.addRegion}
                  value={rfo.regions}
                  onRemove={(region) => this.deleteRegion(region)}
                />
              </div>

              <h2>
                {t('Liitteet ja kuvat')}{' '}
              </h2>
              <ul>
                <li>{t('Voit lisätä ilmoitukseen lisätietoja kuvina ja muina liitetiedostoina')}</li>
                <li>{t('Liitetiedostotyyppi tulee olla: JPG / PNG / GIF DOC(x) / XLS(x) /PPT(x)')}</li>
              </ul>
              <Attachment
                t={t}
                addFiles={this.props.addFiles}
                attachments={rfo.attachments}
                deleteFile={this.props.deleteFile}
                multiple={true}
              />
              <h2>
                {t('Ilmoituksen voimassaoloaika')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                <InfoBox infoText={t(dateInfoTextKey)} />
              </h2>
              <div className={styles.bottomPadding}>
                <Trans i18nKey='validityInfo'>
                  Huom: voimassaoloaika tarkoittaa <b>ilmoituksen voimassaoloa Materiaalitorissa</b>, ei sopimuksen voimassaoloaikaa.
                </Trans>
              </div>
              <DatePickerFragment
                isTouchDevice={isTouchDevice}
                value={rfo.expires || ''}
                name={'expires'}
                handleChange={this.handleChange}
                label={'Voimassaoloaika'}
                suffix={' asti'}
                t={t}
                minDateModifier={1}
                maxDateModifier={365}
              />
              <h2>{t('Yhteyshenkilön tiedot')}</h2>
              <ContactWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
              />
              <div className={styles.divider} />
              <div className={'divider'} />
            </>
          );
        }
        case rfoTypes.RFO_OFFERING_SERVICES: {
          const currentSubServices = getCurrentSubServices(rfo.serviceName, services);
          return (
            <>
              <RfoTypeFragment rfoType={rfo.type} t={t}/>
              <div className={'divider'} />

              <h2>
                <label htmlFor="title">
                  {t('Ilmoituksen otsikko')}{' '}
                  <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                </label>
              </h2>
              <ul>
                <li>{t('Kirjoita materiaalin tai palvelun vapaamuotoinen ja kuvaava nimi. Tee otsikosta selkeä ja kuvaava sillä se on hakulistauksissa, ilmoituksen sivulla yms. kaikista näkyvin elementti.')}</li>
                <li>{t('Vältä otsikossa ”tarjotaan” –tyyppisiä termejä, koska se käy lukijalle hakuehdoista ja sivun tiedoista ilmi muutenkin.')}</li>
                <li>{t('Otsikon muotoilussa on hyvä ottaa huomioon, että ilmoituksia voidaan hakea myös otsikossa olevien sanojen perusteella.')}</li>
              </ul>
              <TitleFragment t={t} handleChange={this.handleChange} rfo={rfo} />
              <h2>{t('Palvelun kuvaus')} </h2>
              <ServiceListFragment
                t={t}
                handleChange={this.handleServiceChange}
                form={rfo}
                onSubServiceAdd={this.addSubService}
                onSubServiceRemove={this.deleteSubService}
                services={services}
                currentSubServices={currentSubServices}
                infoBox={t(
                  'Valitse tarjottavaa palvelua mahdollisimman hyvin kuvaava palvelu. Palveluun sisältyvää jätteen käsittelyä voit tarvittaessa tarkentaa. Eri käsittelymenetelmiä voi valita useita. Valmiiden ilmoitusten hakua voi rajata tämän palveluluokittelun perusteella. ”Kiinteistön kokonaispalvelu” tarkoittaa palvelua, jossa huolehditaan kiinteistöllä syntyvien eri jätteiden jätehuollon järjestämisestä kokonaisuutena.'
                )}
                label={t('Tarjottava palvelu')}
                subLabel={'Tarkenna tarjoamaasi palvelua'}
              />
              <ServiceDescriptionFragment
                t={t}
                handleChange={this.handleChange}
                form={rfo}
                infoBox={t(
                  'Mahdollisimman selkeä kuvaus tarjoamastasi palvelusta. Yksilöi myös tarvittaessa, mille jätteille tai materiaaleille palvelu soveltuu.'
                )}
                required={false}
              />
              <h2>
                {t('Liitteet ja kuvat')}
              </h2>
              <ul>
                <li>{t('Voit lisätä ilmoitukseen lisätietoja kuvina ja muina liitetiedostoina')}</li>
                <li>{t('Liitetiedostotyyppi tulee olla: JPG / PNG / GIF DOC(x) / XLS(x) /PPT(x)')}</li>
              </ul>
              <Attachment
                t={t}
                addFiles={this.props.addFiles}
                attachments={rfo.attachments}
                deleteFile={this.props.deleteFile}
                multiple={true}
              />
              <h2>
                {t('Palvelun sijainti')}{' '}
                <InfoBox
                  infoText={t(
                    'Palvelun sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa erityisesti mm. käsittelypalveluja ja varastointia tarjotessa. Voit halutessasi valita, että tarkka katuosoitetieto näkyy vain Materiaalitoriin kirjautuneille käyttäjille. Sijainti näytetään joka tapauksessa vähintään kunnan tarkkuudella myös Materiaalitoriin kirjautumattomille käyttäjille. Mikäli sijainteja on useita tai tarjoat esimerkiksi kuljetuspalvelua tietyllä alueella, voit valita sijainteja alueet-valikosta.'
                  )}
                />{' '}
              </h2>
              <LocationWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
                handleMunicipality={this.handleMunicipalityChange}
                handleMapLocation={this.handleMapLocation}
              />
              <p>
                <strong>
                  {t('Valitse alueet')}
                  <InfoBox
                    infoText={t(
                      'Voit ilmoittaa useita sijainteja lisäämällä kuntia tai maakuntia, joiden alueella tarjoat palvelua. Aloita kirjoittamalla kenttään kunnan tai maakunnan nimeä ja saat valmiita ehdotuksia, joista voit valita.'
                    )}
                  />
                </strong>
              </p>
              <button
                className={styles.marginBottom1em}
                type="button"
                onClick={() => this.props.addAllRegions(this.props.regions)}
              >
                {t('Valitse koko Suomi')}
              </button>
              <div className={'qa-region-selector'}>
                <RegionSelect
                  handleChange={this.addRegion}
                  value={rfo.regions}
                  onRemove={(region) => this.deleteRegion(region)}
                />
              </div>
              <h2>
                {t('Ilmoituksen voimassaoloaika')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                <InfoBox infoText={t(dateInfoTextKey)} />
              </h2>
              <div className={styles.bottomPadding}>
                <Trans i18nKey='validityInfo'>
                  Huom: voimassaoloaika tarkoittaa <b>ilmoituksen voimassaoloa Materiaalitorissa</b>, ei sopimuksen voimassaoloaikaa.
                </Trans>
              </div>
              <DatePickerFragment
                isTouchDevice={isTouchDevice}
                value={rfo.expires || ''}
                name={'expires'}
                handleChange={this.handleChange}
                label={'Voimassaoloaika'}
                suffix={' asti'}
                t={t}
                minDateModifier={1}
                maxDateModifier={365}
              />
              <h2>{t('Yhteyshenkilön tiedot')}</h2>
              <ContactWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
              />
              <div className={'divider'} />
            </>
          );
        }
        case rfoTypes.RFO_RECEIVING_SERVICES: {
          const currentSubServices = getCurrentSubServices(rfo.serviceName, services);
          return (
            <>
              <RfoTypeFragment rfoType={rfo.type} t={t}/>
              <div className={'divider'} />

              <h2>
                <label htmlFor="title">
                  {t('Ilmoituksen otsikko')}{' '}
                  <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                </label>
              </h2>
              <ul>
                <li>{t('Lisää otsikoksi kuvaava nimi etsittävälle palvelulle tai osaamiselle.')}</li>
              </ul>
              <TitleFragment t={t} handleChange={this.handleChange} rfo={rfo} />
              <h2>{t('Etsittävä palvelu tai osaaminen')} </h2>
              <ServiceListFragment
                t={t}
                handleChange={this.handleServiceChange}
                form={rfo}
                onSubServiceAdd={this.addSubService}
                onSubServiceRemove={this.deleteSubService}
                services={services}
                currentSubServices={currentSubServices}
                subLabel={'Valitse palvelu tai osaaminen, voit valita useita'}
                hidePrimarySelector={true}
                subLabelInfo={t(
                  'Ilmoituksia voi suodattaa palvelun tai osaamisen kuvauksen perusteella.'
                )}
              />
              <ServiceDescriptionFragment
                t={t}
                handleChange={this.handleChange}
                form={rfo}
                infoBox={t(
                  'Kuvaile mahdollisimman selkeästi etsimääsi palvelua tai osaamista: tehtävänkuva ja kesto, asiantuntijalta vaadittavat taidot ja kokemus, minkälaista henkilöä haette, kielitaitovaatimukset, määräaikainen/jatkuva/projektiluonteinen tehtävä, mitä organisaatio tarjoaa haettavalle henkilölle tai organisaatiolle.'
                )}
                required={false}
                labelKey={'Palvelun tai osaamisen kuvaus'}
              />
              <h2>
                {t('Liitteet ja kuvat')}
              </h2>
              <ul>
                <li>{t('Voit lisätä ilmoitukseen lisätietoja kuvina ja muina liitetiedostoina')}</li>
                <li>{t('Liitetiedostotyyppi tulee olla: JPG / PNG / GIF DOC(x) / XLS(x) /PPT(x)')}</li>
              </ul>
              <Attachment
                t={t}
                addFiles={this.props.addFiles}
                attachments={rfo.attachments}
                deleteFile={this.props.deleteFile}
                multiple={true}
              />

              <h2>
                {t('Alueet, joilla palvelua tai osaamista tarvitaan')}
                <InfoBox
                  infoText={t(
                    'Rajaa tarvittaessa alueet, joilla palvelua tai osaamista tarvitaan. Ilman asetettuja rajauksia ilmoitus koskee koko Suomea.'
                  )}
                />
              </h2>
              
              <button
                className={styles.marginBottom1em}
                type="button"
                onClick={() => this.props.addAllRegions(this.props.regions)}
              >
                {t('Valitse koko Suomi')}
              </button>
              <div className={'qa-region-selector'}>
                <RegionSelect
                  handleChange={this.addRegion}
                  value={rfo.regions}
                  onRemove={(region) => this.deleteRegion(region)}
                />
              </div>

              <h2>
                {t('Sijainti, jossa osaamista tai palvelua tarvitaan')}{' '}
                <InfoBox
                  infoText={t(
                    'Rajaa tarvittaessa alueet, joilla palvelua tai osaamista tarvitaan. Ilman asetettuja rajauksia ilmoitus koskee koko Suomea.'
                  )}
                />{' '}
              </h2>
              <LocationWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
                handleMunicipality={this.handleMunicipalityChange}
                handleMapLocation={this.handleMapLocation}
              />              

              <h2>
                {t('Ilmoituksen voimassaoloaika')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
                <InfoBox infoText={t('Ilmoita, mihin päivämäärään asti ilmoitus on voimassa. Voimassaoloaika on enintään 12 kuukautta. Voimassaolon  jälkeen ilmoituksen voi uusia Materiaalitorissa ilmoittajan ”omien sivujen” kautta.')} />
              </h2>
              <div className={styles.bottomPadding}>
                <Trans i18nKey='validityInfo'>
                  Huom: voimassaoloaika tarkoittaa <b>ilmoituksen voimassaoloa Materiaalitorissa</b>, ei sopimuksen voimassaoloaikaa.
                </Trans>
              </div>
              <DatePickerFragment
                isTouchDevice={isTouchDevice}
                value={rfo.expires || ''}
                name={'expires'}
                handleChange={this.handleChange}
                label={'Voimassaoloaika'}
                suffix={' asti'}
                t={t}
                minDateModifier={1}
                maxDateModifier={365}
              />
              <h2>{t('Yhteyshenkilön tiedot')}</h2>
              <ContactWithPublicityCheckboxFragment
                t={t}
                rfo={rfo}
                handleChange={this.handleChange}
              />
              <h2>{t('Lisätietoja')}</h2>
              <CompanyIntroductionFragment t={t}
                handleChange={this.handleChange}
                form={rfo} 
              />

              <div className={'divider'} />
            </>
          );
        }

        default:
          return null;
      }
    };

    return (
      <>
        <Header />
        <Navigation />
        <Container className={cx('flex-grow-1')}>
          <Loader loading={this.isLoading()}>

            {this.hasOpenQuestionnaires() && (
              <>
                <Row options={{ center: true }}>
                  <Col span={6} sm={8} xs={12}>
                    <Col span={12}>
                      <h2>{t('Avoimet kyselyt')}</h2>
                      <p>{t('Sinulla on avoimia kyselyitä. Päästäksesi lisäämään uusia ilmoituksia, vastaa ensin avoimiin kyselyihin.')}</p>                  
                    </Col>
                  </Col>
                </Row>
                
                <Row options={{ center: true }}>
                  <Col span={6} sm={8} xs={12}>
                    <QuestionnaireListView
                      questionnaires={this.props.openQuestionnaires}>
                    </QuestionnaireListView>
                  </Col>
                </Row> 
              </>
            )}

          {!this.isLoading() && !this.hasOpenQuestionnaires() && (
            <Row className={`${styles.container}`} options={{ center: true }}>

            {hilmaMode && this.state.ownBusinessId !== this.state.hilmaOrganisationNumber ? (
              <h2>{t('Hilma-ilmoitus kuuluu toiselle organisaatiolle')}</h2>
              ) : (
               <>
              {rfo.hilmaId && (
                <Col span={6} sm={6} xs={12}>
                  <HilmaTsvSteps t={t} currentStep={this.state.currentStep} />
                </Col>
              )}

              <Col span={10} sm={10} xs={12}>
                
                <h1 className={styles.mainHeading}>
                  {this.state.previewMode && hilmaMode ? t('Esikatsele ja tallenna tiedot') : t('Lisää ilmoitus') && hilmaMode ? t('Täydennä tiedot') : t('Lisää ilmoitus') && editMode && rfo.hilmaId ? t('Muokkaa tietoja') : t('Muokkaa ilmoitusta')  && editMode ? t('Muokkaa ilmoitusta') : t('Lisää ilmoitus')}
                </h1>
                {/* {!editMode && !hilmaMode && (
                  <p className={styles.subHeading}>
                    {t(
                      'Lisää ilmoitus Materiaalitoriin valitsemalla tilanteeseesi sopiva ilmoitustyyppi.'
                    )}
                  </p>
                )} */}

                {editMode && !this.isLoading() && (
                  <PrivateComponent doesNotBelongToBusiness={path(['businessId'], rfo)}>
                    <h2>{t('Et voi muokata toisen yrityksen ilmoitusta.')}</h2>
                  </PrivateComponent>
                )}

                <PrivateComponent
                  {...(editMode ? { belongsToBusiness: path(['businessId'], rfo) } : {})}
                >
                  <>
                    {isClosed(rfo) ? (
                      <h2>{t('Et voi muokata suljettua ilmoitusta.')}</h2>
                    ) : (
                      <>
                        {this.state.previewMode && (
                          <>
                            <Preview
                              rfo={this.state.previewData}
                              t={t}
                              isInPreviewMode={true}
                              regionsAndMunicipalities={regionsAndMunicipalities}
                              ewcs={ewcs}
                              popWastes={popWastes}
                            />
                            <button
                              className={cx(styles.submitButton)}
                              type="button"
                              onClick={() => this.setState({ previewMode: false, currentStep: 1 })}
                            >
                              {t('Takaisin')}
                            </button>
                            <button
                              className={cx(styles.submitButton, 'qa-submit-rfo')}
                              type="button"
                              onClick={this.handleSubmit}
                              disabled={!validForm(rfo, loading, services)[0]}
                            >
                              {hilmaMode 
                              ? t('Tallenna tiedot') 
                              : t('Julkaise ilmoitus') 
                              && 
                              !editMode
                                ? t('Julkaise ilmoitus')
                                : rfo.rfoHasExpired && rfo.hilmaId
                                ? t('Tallenna tiedot')
                                : rfo.rfoHasExpired  
                                ? t('Julkaise uudelleen')
                                : t('Tallenna ilmoitus')}
                            </button>
                          </>
                        )}
                        {!this.state.previewMode && (
                          <form
                            onSubmit={this.handleSubmit}
                            autoComplete="off"
                            style={{ position: 'relative' }}
                          >
                            {!hilmaMode && !rfo.hilmaId && (
                            <TypeSelectFragment
                              t={t}
                              handleChange={this.handleChange}
                              rfo={rfo}
                              disabled={editMode}
                              readMoreAboutWasteExpanded={readMoreAboutWasteExpanded}
                              toggleReadMoreAboutWasteExpanded={this.toggleReadMoreAboutWaste}
                            />
                            )}

                            <div className={'divider'} />

                            {getForm(rfo.type)}
                            <div className={cx(styles.rfoFormButtonsWrapper, styles.buttonWrap)}>
                              {rfo.type && rfo.type !== 'wasteOrMaterial' && this.state.editMode && (
                                <>
                                  <Link
                                    className="buttonStyle"
                                    to={`${ILMOITUKSET}/${this.state.rfoId}`}
                                  >
                                    {rfo.hilmaId ? t('Takaisin') : t('Takaisin ilmoitukseen')}
                                  </Link>
                                </>
                              )}

                              {rfo.type && rfo.type !== 'wasteOrMaterial' && (
                                <>
                                  <button
                                    className={cx(styles.submitButton, 'qa-preview-rfo')}
                                    type="button"
                                    onClick={this.preview}
                                    disabled={!validForm(rfo, loading, services)[0]}
                                  >
                                    {rfo.hilmaId ? t('Esikatsele tiedot') : t('Esikatsele ilmoitusta')}
                                  </button>
                                  {!validForm(rfo, loading, services)[0] && (
                                    <div className={cx(styles.fieldErrorContainer)}>
                                      <strong>{t('Tarkasta puuttuvat tai virheelliset pakolliset tiedot')}:</strong>
                                      <ul>
                                        {Object.entries(validForm(rfo, loading, services)[1]).map(([key, value]) => (
                                          <li key={key}>{t(value)}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </>
                              )}
                              {rfo.type && this.state.editMode && (
                                <CloseRfoButton
                                  t={t}
                                  rfoId={path(['id'])(rfo)}
                                  rfoBusinessId={path(['company', 'businessId'])(rfo)}
                                  closingRfoFn={() => {
                                    this.props.setRfoHasChanges(false);
                                  }}
                                />
                              )}
                            </div>
                          </form>
                        )}
                      </>
                    )}
                  </>
                </PrivateComponent>

                {redirectTo && <Redirect to={redirectTo} />}
                <Prompt when={this.props.formHasChanges} message={promptMessageFn(t)} />
              </Col>
              </>
            )}
            </Row>
          )}
          </Loader>
        </Container>
        <Footer />
      </>
    );
  }

  hasOpenQuestionnaires = () => {
    return this.props.openQuestionnaires && this.props.openQuestionnaires.length > 0;
  }

  isLoading = () => {    
    return this.props.loadingRfo || this.props.loadingQuestionnaires;
  }
}

const mapStateToProps = (state) => ({
  isTouchDevice: state.generalState.isTouchDevice,
  rfo: state.rfoState.form,
  user: state.userState.user,
  services: state.generalState.configurations.services,
  ewcs: state.generalState.configurations.ewcs,
  popWastes: state.generalState.configurations.popWastes,
  regionsAndMunicipalities: state.generalState.location.regionsAndMunicipalities,
  regions: state.generalState.location.regions,
  configurationsFetched: state.generalState.configurationsFetched,
  loadingRfo: state.rfoState.status.loadingRfo,
  formHasChanges: state.rfoState.status.formHasChanges,
  materials: state.generalState.configurations.materials,
  loadingQuestionnaires: state.questionnaireState.status.loadingQuestionnaires,
  openQuestionnaires: state.questionnaireState.status.openQuestionnaires
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (key, value) => dispatch(rfoOperations.handleRfoFormChange(key, value)),
  handleMaterialChange: (index, key, value) =>
  dispatch(rfoOperations.handleMaterialFormChange(index, key, value)),
  clearForm: () => dispatch(rfoOperations.clearRfoForm()),
  clearRfoFromView: () => dispatch(rfoOperations.clearRfoView()),
  addMaterial: () => dispatch(rfoOperations.addMaterial()),
  deleteMaterial: (index) => dispatch(rfoOperations.deleteMaterial(index)),
  addFiles: (files) => dispatch(rfoOperations.addFiles(files)),
  deleteFile: (fileId) => dispatch(rfoOperations.deleteFile(fileId)),
  toggleRegion: (region) => dispatch(rfoOperations.toggleRegion(region)),
  deleteRegion: (region) => dispatch(rfoOperations.deleteRegion(region)),
  addAllRegions: (regions) => dispatch(rfoOperations.addAllRegions(regions)),
  addSubService: (service) => dispatch(rfoOperations.addSubService(service)),
  deleteSubService: (service) => dispatch(rfoOperations.deleteSubService(service)),
  deleteAllSubServices: () => dispatch(rfoOperations.deleteAllSubServices()),
  deleteSubClassification: () => dispatch(rfoOperations.deleteSubClassification()),
  fetchRfoForEdit: (id, regions) => dispatch(rfoOperations.fetchRfoForEdit(id, regions)),
  fetchRfoForCopy: (id, regions) => dispatch(rfoOperations.fetchRfoForCopy(id, regions)),
  setRfoHasChanges: (value) => dispatch(rfoOperations.setRfoHasChanges(value)),
  fetchOpenQuestionnaires: () => dispatch(questionnaireOperations.fetchOpenQuestionnaires())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(),
  withCancelToken,
  suspendUntilAuthorized
)(EditRfo);
