import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './Questionnaire.module.css';
import { KYSELY, ILMOITUKSET} from '../../routes';
import { Col } from '../Layout/Layout';
import cx from 'classnames';

const linkToRfo = (id, title) => {
  return <Link to={`${ILMOITUKSET}/${id}`}>{title}</Link>;
};

const linkToQuestionnaire = (t, questionnaire) => {
  return (
    <div className={styles.buttonWrap}>
      <Link className={cx('buttonStyle', 'qa-questionnaire-button')} to={`${KYSELY}/${questionnaire.id}`}>
        {t('Vastaa kyselyyn')}
      </Link>
    </div>
  );
};

const questionnaireItem = (questionnaire, t) => {

  return (
    <div key={questionnaire.id}  className={styles.item}>
      <Col span={7}>
      <h4>{linkToRfo(questionnaire.rfo.id, questionnaire.rfo.Title)}</h4>
      <div>
        {questionnaire.rfo.Materials &&
          questionnaire.rfo.Materials.map((material, idx) => {
            return (
              material.Classification && (
                <div key={idx}>
                  <span>{t(material.Classification)}</span>
                  <span>&nbsp;|&nbsp;</span>
                  <span>
                    {material.Continuity === 'onetime' && t('Kertaerä')}
                    {material.Continuity === 'continuous' && t('Jatkuva tuotanto')}
                  </span>
                </div>
              )
            );
          })}
        {questionnaire.rfo.MaterialsWanted &&
          questionnaire.rfo.MaterialsWanted.map((material, idx) => {
            return (
              material.Classification && (
                <div key={idx}>
                  <span>{t(material.Classification)}</span>
                </div>
              )
            );
          })}
        {questionnaire.rfo.Service &&
          questionnaire.rfo.Service.ServiceIds &&
          questionnaire.rfo.Service.ServiceIds.map((serviceId, idx) => {
            const isFirstServiceId = !idx;
            return (
              <span key={idx}>
                {!isFirstServiceId && <>{' | '}</>}
                <span>{t(serviceId)}</span>
              </span>
            );
          })}
      </div>
      <div>
        {questionnaire.rfo.Company && questionnaire.rfo.Company.Name && (
          <span>
            {questionnaire.rfo.Company.Name}
          </span>
        )}
      </div>
      </Col>
      <Col span={5}>
        {linkToQuestionnaire(t, questionnaire)}
      </Col>
    </div>
  );
}

const QuestionnaireListView = ({ questionnaires, t }) => {  
  return questionnaires.map(questionnaire => questionnaireItem(questionnaire, t));
};

export default withNamespaces()(QuestionnaireListView);
