import React from 'react';
import cx from 'classnames';
import { Col, Row } from '../Layout/Layout';
import formStyles from '../Layout/Form.module.css';
import styles from './Questionnaire.module.css';
import { formatDate } from '../../utils/date-utils';
import { getPaymentState, getRfoCloseDate, getServiceState, QuantityUnitField } from './questionnaire-utils';
import { getLocation } from './questionnaire-utils';

const serviceFromMateriaalitori = (viewForm) => {
  return viewForm.result.result === 'serviceFromMateriaalitori';
}

const isValidForm = (viewForm) => {
  const serviceFromMato = serviceFromMateriaalitori(viewForm);
  return ((viewForm.result.result && !serviceFromMato) || 
    (serviceFromMato 
    && viewForm.result.supplierCount 
    && viewForm.result.receivedAmount 
    && viewForm.result.paymentType
    && viewForm.result.quantityUnit));
}

const send = (viewForm, sendForm) => {
  const serviceFromMato = serviceFromMateriaalitori(viewForm);
  const payload = {
    id: viewForm.id,
    type: viewForm.type,
    result: {
      result: viewForm.result.result,
      supplierCount: serviceFromMato ? viewForm.result.supplierCount : undefined,
      paymentType: serviceFromMato ? viewForm.result.paymentType : undefined,
      receivedAmount: serviceFromMato ? viewForm.result.receivedAmount : undefined,
      quantityUnit: serviceFromMato ? viewForm.result.quantityUnit : undefined,
      feedback: viewForm.result.feedback
    },
    rfo: viewForm.rfo
  }

  sendForm(payload);
}

const acceptDecimalNumberInput = (e) => {
  if (e.currentTarget)
  {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9,]/g, '').replace(/(,.*),/g, '$1');
  }
}

export const ReceivingMaterial = ({ t, viewForm, handleChange, sendForm }) => {  
  return viewForm.statusName === 'unanswered' ? editForm(t, viewForm, handleChange, sendForm) : readForm(t, viewForm);
};

const getRfoInfo = (t, viewForm) => {
  const [material] = viewForm.rfo.MaterialsWanted;

  return (
    <div className={styles.detailWrapper}>
      <div className={styles.detailContainerHalf}>
        <p>
          <strong>{t('Sulkeutunut')}:</strong> {getRfoCloseDate(viewForm)}
        </p>
        <p>
          <strong>{t('Materiaali')}:</strong> {t(material.Classification)}
          {material.SubClassification && <span>, {t(material.SubClassification)}</span>}
        </p>
        <p>
          <strong>{t('Sijainti')}:</strong> {getLocation(material, viewForm.rfo.Regions)}
        </p>
      </div>
    </div>
  );
}

const readForm = (t, viewForm) => {
  
  const answers = viewForm.result.receivingMaterialQuestionnaire;

  return (
    <Row className={cx(formStyles.formRow)}>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        {getRfoInfo(t, viewForm)}
      </Col>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <div className={styles.detailWrapper}>
          <div className={styles.detailContainerHalf}>
            <p>
              <strong>{t('Vastattu')}:</strong> {formatDate(new Date(viewForm.statusDate))}
            </p>
            <p>
              <strong>{t('Löysin materiaalin')}:</strong> {getServiceState(t, answers.result)}
            </p>
            {(answers.result === 'serviceFromMateriaalitori') && (
              <>
                <p>
                  <strong>{t('Maksu')}:</strong> {getPaymentState(t, answers.paymentType)}
                </p>
                <p>
                  <strong>{t('Kuinka monelta toimijalta sait materiaalia')}: </strong>{answers.supplierCount}
                </p>
                <p>
                  <strong>{t('Materiaalin kokonaismäärä')}: </strong>{answers.receivedAmount} {' '} {answers.quantityUnit}
                </p>
              </>
            )}           
            <p>
              <strong>{t('Lisätietoa saamastasi materiaalista tai vapaata palautetta Materiaalitori-palvelusta')}: </strong>{answers.feedback}
            </p>
          </div>
        </div>
      </Col>
    </Row>
  )
}

const editForm = (t, viewForm, handleChange, sendForm) => 
{
  const showPaymentOptions = viewForm.result && viewForm.result.result === 'serviceFromMateriaalitori';
  
  const [material] = viewForm.rfo.MaterialsWanted;

  return (  
    <Row className={cx(formStyles.formRow)}>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <div className={styles.detailWrapper}>
          <div className={styles.detailContainerHalf}>
            <p>
              <strong>{t('Sulkeutunut')}:</strong> {formatDate(new Date(viewForm.rfo.Expires))}
            </p>
            <p>
              <strong>{t('Materiaali')}:</strong> {t(material.Classification)}
              {material.SubClassification && <span>, {t(material.SubClassification)}</span>}
            </p>
            <p>
              <strong>{t('Sijainti')}:</strong> {getLocation(material, viewForm.rfo.Regions)}
            </p>
          </div>
        </div>
      </Col>
      
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <h4>{t('Löysin materiaalin')}:</h4>
        <div className={styles.typeWrapper__firstLevel}>
        
          <label className={'qa-serviceFromMateriaalitori'}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'serviceFromMateriaalitori'}
              className={cx('radioButton')}
            />
            <span className={'radioButtonLabel'}>{t('Materiaalitorin ilmoituksen perusteella')}</span>

          {showPaymentOptions && (
            <div className={styles.typeWrapper__secondLevel}>
              <div className={styles.marginTop1em}>
                <Col span={12} className={cx(formStyles.formInputContainer)}>
                  <label className={cx(styles.typeWrapper__secondLevel)}>

                    <label>
                      {t('Jos sait materiaalia')}{t(' ')}
                      <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                    </label>
                    <input
                      onChange={handleChange}
                      type="radio"
                      name="paymentType"
                      value={'payingForMaterial'}
                      className={cx('radioButton')}
                      checked={viewForm.result && viewForm.result.paymentType === 'payingForMaterial'}
                    />
                    <span className={'radioButtonLabel'}>{t('Maksan materiaalista')}</span>
                  </label>

                  <label className={cx(styles.typeWrapper__secondLevel)}>
                    <input
                      onChange={handleChange}
                      type="radio"
                      name="paymentType"
                      value={'receivedPayment'}
                      className={cx('radioButton', 'qa-receivedPayment')}
                      checked={viewForm.result && viewForm.result.paymentType === 'receivedPayment'}
                    />
                    <span className={'radioButtonLabel'}>{t('Sain maksun vastaanottamisesta')}</span>
                  </label>

                  <label className={cx(styles.typeWrapper__secondLevel)}>
                    <input
                      onChange={handleChange}
                      type="radio"
                      name="paymentType"
                      value={'noPayment'}
                      className={cx('radioButton')}
                      checked={viewForm.result && viewForm.result.paymentType === 'noPayment'}
                    />
                    <span className={'radioButtonLabel'}>{t('Sain maksutta')}</span>
                  </label>
                </Col>
              </div>

              <div className={styles.marginTop1em}>
                <Col span={12} className={cx(formStyles.formInputContainer)}>
                  <label htmlFor="SupplierCount">
                    <span className={formStyles.defaultLabelSpan}>
                      {t('Kuinka monelta toimijalta sait materiaalia')}{' '}
                      <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                    </span>

                    <input
                      id="supplierCount"
                      name="supplierCount"
                      type="text"
                      onChange={ e => { acceptDecimalNumberInput(e); handleChange(e) }}
                      maxLength={30}
                    />
                  </label>
                </Col>

                <Col span={8} xs={12} className={cx(formStyles.formInputContainer)}>
                  <label htmlFor="ReceivedAmount">
                    <span className={formStyles.defaultLabelSpan}>
                      {t('Saamasi materiaalin kokonaismäärä')}{' '}
                      <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                    </span>

                    <input
                      id="receivedAmount"
                      name="receivedAmount"
                      type="text"
                      onChange={ e => { acceptDecimalNumberInput(e); handleChange(e) }}
                      maxLength={30}
                    />
                  </label>
                </Col>
                <Col span={4} xs={12} className={cx(formStyles.formInputContainer)}>
                  <QuantityUnitField t={t} material={viewForm.result} handleMaterialChange={handleChange} />
                </Col>
              </div>
            </div>            
            )}
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'serviceOutsideMateriaalitori'}
              className={cx('radioButton', 'qa-serviceOutsideMateriaalitori')}
            />
            <span className={'radioButtonLabel'}>{t('Sain palvelun Materiaalitorin ulkopuolelta')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'noNeedForService'}
              className={cx('radioButton')}
            />
            <span className={'radioButtonLabel'}>{t('Ilmoituksen tarve poistui muusta syystä')}</span>
          </label>                    
        </div>
      </Col> 

      <Col span={12} className={[cx(formStyles.formInputContainer), styles.marginTop1em]}>
        <label htmlFor="feedback">
          <h4>{t('Lisätietoa saamastasi materiaalista tai vapaata palautetta Materiaalitori-palvelusta')}:
          </h4>
          <textarea
            name="feedback"
            id="feedback"
            type="text"
            rows={6}            
            onChange={handleChange}
            maxLength={1000}
          />
        </label>
      </Col>

      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <button
          className={cx(styles.submitButton, "qa-submit-questionnaire")}
          type="button"
          onClick={() => send(viewForm, sendForm)}
          disabled={!isValidForm(viewForm)}>
          {t('Lähetä')}
        </button>    
      </Col>    
      
    </Row>
)}
