import React, { Component } from 'react';

class CookieDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
        language: this.props.language
    };
  }

  componentDidMount() {
    var cookieDeclarationWrapper = document.getElementById("CookieDeclarationWrapper");
    var cookieScript = document.createElement('script');
    cookieScript.id = "CookieDeclaration";
    cookieScript.src = "https://consent.cookiebot.com/2eb2e24c-140d-44c7-b30d-0e83883fbfe1/cd.js";
    cookieScript.type = "text/javascript";
    cookieScript.async = true;
    cookieScript.setAttribute("data-culture", this.state.language);
    cookieDeclarationWrapper.appendChild(cookieScript);
  }

  render() {
    return <div id="CookieDeclarationWrapper"></div>;
  }
}

export default CookieDeclaration;