import cx from 'classnames';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDate, formatDateNumber } from '../../utils/date-utils';
import { isClosed, isHidden, shortenText, isTsvAllowed, isExpiringSoon } from '../RequestForOffer/rfo-utils';
import styles from './MyPage.module.css';
import { ILMOITUKSET } from '../../routes';
import { Calender } from '../Icon/icons';

const linkToRfo = (id, title) => {
  return <Link to={`${ILMOITUKSET}/${id}`}>{shortenText(title, 120)}</Link>;
};

const RfoRow = ({ enableExpireWarning, rfo, t }) => {
  const rfoExpiresDate = new Date(rfo.expires);
  const rfoExpiresSoon = isExpiringSoon(rfo);

  return (
    <tr>
      <td>
        <div className={cx(styles.uppercase, styles.verticalMarginHalfem)}>
          {t(rfo.rfoType + '-title')}{' '}
          {isClosed(rfo) && (
            <span className={styles.greenSidemark}>
              {t('Suljettu') + ' ' + formatDate(new Date(rfo.statusDate))}
            </span>
          )}
          {isHidden(rfo) && <span className={styles.greenSidemark}>{t('Tarjous hyväksytty')}</span>}
        </div>
        <div className={styles.verticalMarginHalfem}>{linkToRfo(rfo.id, rfo.title)}</div>
        <div className={styles.verticalMarginHalfem}>
          {rfo.materials &&
            rfo.materials.map((material, idx) => {
              return (
                material.classification && (
                  <div key={idx}>
                    <span>{t(material.classification)}</span>
                    <span>&nbsp;|&nbsp;</span>
                    <span>
                      {material.continuity === 'onetime' && t('Kertaerä')}
                      {material.continuity === 'continuous' && t('Jatkuva tuotanto')}
                    </span>
                  </div>
                )
              );
            })}
        </div>
        {isTsvAllowed(rfo) && !isClosed(rfo) && rfo.allowedToMakeTsvRequest && (
          <div>
            <Link className={'buttonStyle'} to={`${ILMOITUKSET}/${rfo.id}/teetsv`}>
              {t('Pyydä kunnan toissijaista jätehuoltopalvelua')}
            </Link>
          </div>
        )}
      </td>
      <td className={styles.myPageRfoListCalendar}><Calender id={`RfoListTableView created ${rfo.id}`} date={formatDateNumber(new Date(rfo.created))} />{formatDate(new Date(rfo.created))}</td>
      <td className={styles.myPageRfoListCalendar}>
        <Calender
          id={`RfoListTableView expires ${rfo.id}`}
          warning={enableExpireWarning && rfoExpiresSoon}
          warningToolTipText={t('Sulkeutuu pian')}
          date={formatDateNumber(rfoExpiresDate)}            
        />
        {formatDate(rfoExpiresDate)}
      </td>
    </tr>
  );
};

const RfoListTableView = ({ enableExpireWarning, rfos, t, noResultsText }) => {
  return (
    <>
      {rfos.length > 0 && (
        <div className={styles.borderTop}>
          <table className={cx(styles.listTable, styles.smallVerticalCellPaddings)}>
            <thead>
              <tr>
                <th>{t('Ilmoitus')}</th>
                <th>{t('Ilmoitettu')}</th>
                <th>{t('Vanhenee')}</th>
              </tr>
            </thead>
            <tbody>
              {rfos.map((rfo) => (
                <RfoRow enableExpireWarning={enableExpireWarning} rfo={rfo} t={t} key={rfo.id} />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {rfos.length === 0 && noResultsText && (
        <div>{noResultsText}</div>
      )}
    </>
  );
};

export default withNamespaces()(RfoListTableView);
