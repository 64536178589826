import React from 'react';
import cx from 'classnames';
import { Col, Row } from '../Layout/Layout';
import formStyles from '../Layout/Form.module.css';
import styles from './Questionnaire.module.css';
import { getRfoCloseDate, getServiceState } from './questionnaire-utils';
import { getLocation } from './questionnaire-utils';

const isValidForm = (viewForm) => {
  if (!viewForm.result)
    return true;

  return (viewForm.result.result && viewForm.result.result !== 'other') || 
    (viewForm.result.result && viewForm.result.result === 'other' && viewForm.result.otherReason);
}

const send = (viewForm, sendForm) => {
  const payload = {
    id: viewForm.id,
    type: viewForm.type,
    result: {
      result: viewForm.result.result,
      contactCount: viewForm.result.contactCount,
      otherReason: viewForm.result.otherReason,
      feedback: viewForm.result.feedback
    },
    rfo: viewForm.rfo
  }

  sendForm(payload);
}

const acceptDecimalNumberInput = (e) => {
  if (e.currentTarget)
  {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9,]/g, '').replace(/(,.*),/g, '$1');
  }
}

export const ReceivingServices = ({ t, viewForm, handleChange, sendForm }) => {
    return viewForm.statusName === 'unanswered' 
           ? editForm(t, viewForm, handleChange, sendForm) 
           : readForm(t, viewForm);
  };

const getRfoInfo = (t, viewForm) => {  
  const [material] = viewForm.rfo.Materials;

  return (
    <div className={styles.detailWrapper}>
    <div className={styles.detailContainerHalf}>
      <p>
        <strong>{t('Sulkeutunut')}:</strong> {getRfoCloseDate(viewForm)}
      </p>
      <p>
        <strong>{t('Palvelu')}:</strong> {viewForm.rfo.Service.ServiceIds && viewForm.rfo.Service.ServiceIds.map((serviceId) => t(serviceId)).join(', ')}
      </p>
      <p>
        <strong>{t('Sijainti')}:</strong> {getLocation(material, viewForm.rfo.Regions)}
      </p>
    </div>
  </div>
  );
}

const readForm = (t, viewForm) => {
  const answers = viewForm.result.receivingServicesQuestionnaire;

  return (
    <Row className={cx(formStyles.formRow)}>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        {getRfoInfo(t, viewForm)}
      </Col>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <p>
          <strong>{t('Syy sulkeutumiselle')}:</strong> {getServiceState(t, answers.result)}
        </p>
        {answers.result === 'other' &&(
          <p>
            <strong>{t('Muu syy')}:</strong> {answers.otherReason}
          </p>
        )}
        <p>
          <strong>{t('Kuinka monta yhteydenottoa sait yhteensä Materiaalitorissa tai sen ulkopuolelta ilmoituksen perusteella?')}: </strong> {answers.contactCount}
        </p>
        <p>
          <strong>{t('Voit antaa lisätietoja Materiaalitorista etsimästäsi palvelusta tai antaa vapaata palautetta Materiaalitorista')}: </strong>{answers.feedback}
        </p>        
      </Col>
    </Row>
  )
}

const editForm = (t, viewForm, handleChange, sendForm) => {
  const showOtherReasonTextBox = viewForm.result && viewForm.result.result === 'other';  

  return (
    <Row className={cx(formStyles.formRow)}>
      <Col span={12} className={cx(formStyles.formInputContainer)}>

        {getRfoInfo(t, viewForm)}

        <h4>{t('Syy sulkeutumiselle')}:</h4>
        <div className={styles.typeWrapper__firstLevel}>
          <label className={'qa-serviceFromMateriaalitori'}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'gotServiceFromMateriaalitori'}
              className={cx('radioButton')}
            />
            <span className={'radioButtonLabel'}>{t('Sain palvelun Materiaalitorin ilmoituksen perusteella')}</span>
          </label>

          <label>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'noContacts'}
              className={cx('radioButton', 'qa-noContacts')}
            />
            <span className={'radioButtonLabel'}>{t('En saanut Materiaalitorista yhteydenottoja')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'noNeedForService'}
              className={cx('radioButton')}
            />
            <span className={'radioButtonLabel'}>{t('Palvelun tarve poistui muusta syystä')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'rfoExpired'}
              className={cx('radioButton', 'qa-expired')}
            />
            <span className={'radioButtonLabel'}>{t('Ilmoitus sulkeutui määräajan umpeuduttua')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'republishExpiredRfo'}
              className={cx('radioButton', 'qa-republishReason')}
            />
            <span className={'radioButtonLabel'}>{t('Ilmoitus sulkeutui määräajan umpeuduttua ja julkaisen sen uudelleen')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'other'}
              className={cx('radioButton', 'qa-otherReason')}
            />
            <span className={'radioButtonLabel'}>{t('Muu syy')}</span>
          </label>         
        </div>

        {showOtherReasonTextBox && (
          <div className={styles.marginTop1em}>
            <label htmlFor="otherReason">
              <span className={formStyles.defaultLabelSpan}>
                {t('Muu syy')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              </span>              
              <textarea
                name="otherReason"
                id="otherReason"
                type="text"
                rows={6}            
                onChange={handleChange}
                maxLength={1000}
              />
            </label>
          </div>
        )}

        <div className={styles.marginTop1em}>
          <Col span={12} className={cx(formStyles.formInputContainer)}>
            <label htmlFor="ContactCount">
              <span className={formStyles.defaultLabelSpan}>
                {t('Kuinka monta yhteydenottoa sait yhteensä Materiaalitorissa tai sen ulkopuolelta ilmoituksen perusteella?')}{' '}
              </span>

              <input
                id="contactCount"
                name="contactCount"
                type="text"
                onChange={ e => { acceptDecimalNumberInput(e); handleChange(e) }}
                maxLength={30}
              />
            </label>
          </Col>
        </div>

      </Col>
      
      <Col span={12} className={[cx(formStyles.formInputContainer), styles.marginTop1em]}>
        <div>
          <label htmlFor="feedback">
            <h4>{t('Voit antaa lisätietoja Materiaalitorista etsimästäsi palvelusta tai antaa vapaata palautetta Materiaalitorista')}:
            </h4>
            <textarea
              name="feedback"
              id="feedback"
              type="text"
              rows={6}            
              onChange={handleChange}
              maxLength={1000}
            />
          </label>
        </div>
      </Col>
      
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <button
          className={cx(styles.submitButton, 'qa-submit-questionnaire')}
          type="button"
          onClick={() => send(viewForm, sendForm)}
          disabled={!isValidForm(viewForm)}>
          {t('Lähetä')}
        </button>        
      </Col>
    </Row>
)};
