import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Search from './Search';

//Wrapper for class component to use useLocation and useHistory
function SearchWithParams() {

  const location = useLocation();
  const history = useHistory();

  return (
      <Search location={location} history={history} />
  );
}
    
export default SearchWithParams;