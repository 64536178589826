import React from 'react';
import cx from 'classnames';
import { Col, Row } from '../Layout/Layout';
import formStyles from '../Layout/Form.module.css';
import styles from './Questionnaire.module.css';
import { getPaymentState, getRfoCloseDate, getServiceState, QuantityUnitField } from './questionnaire-utils';
import { getLocation } from './questionnaire-utils';

const serviceFromMateriaalitori = (viewForm) => {
  return viewForm.result.result === 'gotServiceFromMateriaalitori';
}

const isOtherReason = (viewForm) => {
  return viewForm.result.result === 'other';
}

const isValidForm = (viewForm) => {  
  const serviceFromMato = serviceFromMateriaalitori(viewForm);

  if ((serviceFromMato && viewForm.result.receiverCount && viewForm.result.receivedAmount && viewForm.result.quantityUnit))
  {
    return true;
  }
  if (isOtherReason(viewForm) && viewForm.result.otherReason)
  {
    return true;
  }
  return viewForm.result.result && !serviceFromMato  && !isOtherReason(viewForm);
}

const send = (viewForm, sendForm) => {
  const serviceFromMato = serviceFromMateriaalitori(viewForm);

  const payload = {
    id: viewForm.id,
    type: viewForm.type,
    result: {
      result: viewForm.result.result,
      paymentType: serviceFromMato ? viewForm.result.paymentType : undefined,
      receiverCount: serviceFromMato ? viewForm.result.receiverCount : undefined,
      receivedAmount: serviceFromMato ? viewForm.result.receivedAmount : undefined,
      quantityUnit: serviceFromMato ? viewForm.result.quantityUnit : undefined,
      feedback: viewForm.result.feedback,
      otherReason: isOtherReason(viewForm) ? viewForm.result.otherReason : undefined,
    },
    rfo: viewForm.rfo
  }

  sendForm(payload);  
}

const materialQuantity = (quantity) => (
  <span>
    {quantity.Amount} {quantity.UnitOfMeasure}
  </span>
);

const acceptDecimalNumberInput = (e) => {
  if (e.currentTarget)
  {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9,]/g, '').replace(/(,.*),/g, '$1');
  }
}

export const OfferingMaterial = ({ t, viewForm, handleChange, sendForm }) => {
  return viewForm.statusName === 'unanswered' ? editForm(t, viewForm, handleChange, sendForm) : readForm(t, viewForm);
}

const getRfoInfo = (t, viewForm) => {

  const [material] = viewForm.rfo.Materials;

  return (
    <div className={styles.detailWrapper}>
      <div className={styles.detailContainerHalf}>
        <p>
          <strong>{t('Sulkeutunut')}:</strong> {getRfoCloseDate(viewForm)}
        </p>
        <p>
          <strong>{t('Materiaali')}:</strong> {t(material.Classification)}
          {material.SubClassification && <span>, {t(material.SubClassification)}</span>}
        </p>
        <p>
          <strong>{t('Määrä')}:</strong> {materialQuantity(material.Quantity)}{' '}
          <span>
            {material.Continuity === 'onetime' ? t('Kertaerä') : t('Jatkuva tuotanto')}
          </span>
        </p>
      </div>
      <div className={styles.detailContainerHalf}>
        <p>
          <strong>{t('Toimiala')}:</strong> {t(material.Industry)}
        </p>
        <p>
          <strong>{t('Sijainti')}:</strong> {getLocation(material, viewForm.rfo.Regions)}
        </p>
      </div>
    </div>
  )
}

const readForm = (t, viewForm) => {
  const answers = viewForm.result.offeringMaterialQuestionnaire;

  return (
    <Row className={cx(formStyles.formRow)}>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        {getRfoInfo(t, viewForm)}
      </Col>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
      <p>
          <strong>{t('Syy sulkeutumiselle')}:</strong> {getServiceState(t, answers.result)}
        </p>
        {answers.result === 'other' &&(
          <p>
            <strong>{t('Muu syy')}:</strong> {answers.otherReason}
          </p>
        )}
        {answers.result === 'gotServiceFromMateriaalitori' && (
          <div>
            <p>
              <strong>{t('Maksu')}:</strong> {getPaymentState(t, answers.paymentType)}
            </p>
            <p>
              <strong>{t('Kuinka monelle vastaanottajalle luovutit materiaalia, lukumäärä')}:</strong> {answers.receiverCount}
            </p>
            <p>
              <strong>{t('Vastaanotettu materiaalin kokonaismäärä')}:</strong> {answers.receivedAmount}{' '}{answers.quantityUnit}
            </p>                        
          </div>          
        )}
        <p>
          <strong>{t('Lisätietoa luovuttamastasi materiaalista ja sen määrästä tai vapaata palautetta Materiaalitori-palvelusta')}: </strong>{answers.feedback}
        </p>
      </Col>
    </Row>
  )
}

const editForm = (t, viewForm, handleChange, sendForm) => {
  const showPaymentOptions = viewForm.result && viewForm.result.result === 'gotServiceFromMateriaalitori';
  const showOtherReasonTextBox = viewForm.result && viewForm.result.result === 'other';

  return (
    <Row className={cx(formStyles.formRow)}>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        {getRfoInfo(t, viewForm)}
      </Col>
      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <h4>{t('Syy sulkeutumiselle')}:</h4>
        <div className={styles.typeWrapper__firstLevel}>
          <label className={'qa-serviceFromMateriaalitori'}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'gotServiceFromMateriaalitori'}
              className={cx('radioButton')}
            />
            <span className={'radioButtonLabel'}>{t('Sain palvelun Materiaalitorin ilmoituksen perusteella')}</span>

            {showPaymentOptions && (
            <div className={styles.typeWrapper__secondLevel}>
              <div className={styles.marginTop1em}>

              <Col span={12}className={cx(formStyles.formInputContainer)}>
              <label>{t('Jos luovutit materiaalin')} <span className={'requiredIndicator'}>{t('(pakollinen)')}</span></label>
              <label className={cx(styles.typeWrapper__secondLevel)}>
                <input
                  onChange={handleChange}
                  type="radio"
                  name="paymentType"
                  value={'gotPaidForMaterial'}
                  className={cx('radioButton')}
                  checked={viewForm.result && viewForm.result.paymentType === 'gotPaidForMaterial'}
                />
                <span className={'radioButtonLabel'}>{t('Sain maksun materiaalista')}</span>
              </label>

              <label className={cx(styles.typeWrapper__secondLevel, 'qa-paidForReceivingMaterial')}>
                <input
                  onChange={handleChange}
                  type="radio"
                  name="paymentType"
                  value={'paidForReceivingMaterial'}
                  className={cx('radioButton')}
                  checked={viewForm.result && viewForm.result.paymentType === 'paidForReceivingMaterial'}
                />
                <span className={'radioButtonLabel'}>{t('Maksoin materiaalin vastaanottamisesta')}</span>
              </label>
              
              <label className={cx(styles.typeWrapper__secondLevel)}>
                <input
                  onChange={handleChange}
                  type="radio"
                  name="paymentType"
                  value={'gaveMaterialWithoutPayment'}
                  className={cx('radioButton')}
                  checked={viewForm.result && viewForm.result.paymentType === 'gaveMaterialWithoutPayment'}
                />
                <span className={'radioButtonLabel'}>{t('Luovutin materiaalin maksutta')}</span>
              </label>
              </Col>
              </div>
              
              <div className={styles.marginTop1em}>
              <Col span={12} className={cx(formStyles.formInputContainer)}>

              <label htmlFor="ReceiverCount">
                <span className={formStyles.defaultLabelSpan}>
                  {t('Kuinka monelle vastaanottajalle luovutit materiaalia, lukumäärä')}{' '}
                  <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                </span>

                <input
                  id="receiverCount"
                  name="receiverCount"
                  type="text"
                  onChange={ e => { acceptDecimalNumberInput(e); handleChange(e) }}
                  maxLength={30}
                />
              </label>
              </Col>

              <Col span={8} className={cx(formStyles.formInputContainer)}>
                <label htmlFor="ReceivedAmount">
                  <span className={formStyles.defaultLabelSpan}>
                    {t('Vastaanotettu materiaalin kokonaismäärä')}{' '}
                    <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                  </span>
              
                  <input
                    name="receivedAmount"
                    id="receivedAmount"
                    type="text"
                    onChange={ e => { acceptDecimalNumberInput(e); handleChange(e) }}
                    maxLength={30}
                  />
                </label>
              </Col>
              <Col span={4} className={cx(formStyles.formInputContainer)}>
                <QuantityUnitField t={t} material={viewForm.result} handleMaterialChange={handleChange} />
              </Col>
            </div>
            </div>
            )}
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'serviceOutsideMateriaalitori'}
              className={cx('radioButton')}
            />
            <span className={'radioButtonLabel'}>{t('Sain palvelun Materiaalitorin ulkopuolelta')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'rfoExpired'}
              className={cx('radioButton', 'qa-expired')}
            />
            <span className={'radioButtonLabel'}>{t('Ilmoitus sulkeutui määräajan umpeuduttua')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'republishExpiredRfo'}
              className={cx('radioButton', 'qa-republishReason')}
            />
            <span className={'radioButtonLabel'}>{t('Ilmoitus sulkeutui määräajan umpeuduttua ja julkaisen sen uudelleen')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
          <input
            onChange={handleChange}
            type="radio"
            name="result"
            value={'didNotGetService'}
            className={cx('radioButton')}
          />
          <span className={'radioButtonLabel'}>{t('En saanut palvelua')}</span>
        </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'noNeedForService'}
              className={cx('radioButton', 'qa-noNeedForService')}
            />
            <span className={'radioButtonLabel'}>{t('Palvelun tarve poistui muusta syystä')}</span>
          </label>

          <label className={cx(styles.typeWrapper__firstLevel)}>
            <input
              onChange={handleChange}
              type="radio"
              name="result"
              value={'other'}
              className={cx('radioButton', 'qa-otherReason')}
            />
            <span className={'radioButtonLabel'}>{t('Muu syy')}</span>
          </label>                    
        </div>

        {showOtherReasonTextBox && (
          <div className={styles.marginTop1em}>
            <label htmlFor="otherReason">
              <span className={formStyles.defaultLabelSpan}>
                {t('Muu syy')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              </span>              
              <textarea
                name="otherReason"
                id="otherReason"
                type="text"
                rows={6}            
                onChange={handleChange}
                maxLength={1000}
              />
            </label>
          </div>
        )}
      </Col>

      <Col span={12} className={[cx(formStyles.formInputContainer), styles.marginTop1em]}>
        <div>
          <label htmlFor="feedback">
          <h4>{t('Lisätietoa tai palautetta Materiaalitori-palvelusta')}:</h4>
            <textarea
              name="feedback"
              id="feedback"
              type="text"
              rows={6}            
              onChange={handleChange}
              maxLength={1000}
            />
          </label>
        </div>
      </Col>

      <Col span={12} className={cx(formStyles.formInputContainer)}>
        <button
          className={cx(styles.submitButton, "qa-submit-questionnaire")}
          type="button"
          onClick={() => send(viewForm, sendForm)}
          disabled={!isValidForm(viewForm)}>
          {t('Lähetä')}
        </button>        
      </Col>
    </Row>
  )
};
