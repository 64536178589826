export const SEARCH_TYPES = {
  OFFERING_MATERIALS: 'offeringMaterials',
  OFFERING_SERVICES: 'offeringServices',
  RECEIVING_MATERIALS: 'receivingMaterials',
  RECEIVING_SERVICES: 'receivingServices',
  ALL: 'all'
};

export const MATERIAL_TYPES = {
  WASTE: 'waste',
  MATERIAL: 'material',
  ALL: 'all'
};

export const CONTINUITY_TYPES = {
  ONETIME: 'onetime',
  CONTINUOUS: 'continuous',
  ALL: 'all'
};
