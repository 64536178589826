export const ILMOITUKSET = '/ilmoitukset';
export const LISAA_ILMOITUS = '/ilmoitukset/lisaa';
export const LISAA_ILMOITUS_JATE = '/ilmoitukset/lisaa/jate';
export const MUOKKAA_ILMOITUS = '/ilmoitukset/muokkaa';
export const LISAA_ILMOITUS_HILMA = '/ilmoitukset/lisaa/hilma';

export const TARJOUKSET = '/tarjoukset';

export const OMA_SIVU = '/omasivu';
export const OMAT_TIEDOT = '/omasivu/omattiedot';
export const OMAT_VIESTIT = '/omasivu/viestit';
export const OMAT_ILMOITUKSET = '/omasivu/ilmoitukset';

export const TSV_PYYNNOT = '/tsv/pyynnot';
export const TSV_LUONNOS = '/tsv/sopimusluonnokset/{0}/teeluonnos';
export const TSV_SOPIMUKSET = '/tsv/sopimukset';
export const TSV_SOPIMUSARKISTO = '/tsv/sopimusarkisto';
export const TSV_VIRANOMAISET = '/tsv/viranomaiset';
export const TSV_SOPIMUSLUONNOKSET = '/tsv/sopimusluonnokset';

export const PIENERAT = '/pienerat';
export const KOKOOMATIEDOT_ILMOITUKSET = '/kokoomatiedot/ilmoitukset';
export const KOKOOMATIEDOT_TSV = '/kokoomatiedot/tsv';
export const KOKOOMATIEDOT_PIENERAT = '/kokoomatiedot/pienerat';
export const KOKOOMATIEDOT_KYSELYT = '/kokoomatiedot/kyselyt';

export const OHJEET_VALTUUDET = '/ohjeet/valtuudet';

export const HILMA_HAKU = '/hilma-tsv';

export const KYSELY = '/kysely';
