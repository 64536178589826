export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const flatServices = services => {
  const flatServices = [];
  
  services.forEach(service => {
    flatServices.push(service);
  });

  return flatServices;
}

export const flattenServices = services => {
  const flatServices = [];
  let subServices = [];
  services.forEach(service => {
    flatServices.push(service);
    if (service.subServices && service.subServices.length > 0)
    {
      if (!subServices.some(subServiceArray => {
        return subServiceArray.length === service.subServices.length && subServiceArray.every(value => service.subServices.some(service => service.id === value.id))
        })
      )
      {
        subServices.push(service.subServices);
      }
    }
  });
  
  return [flatServices, ...subServices];
};

