import React, { Component } from 'react';
import qs from 'qs';
import { withNamespaces } from 'react-i18next';
import { getRaw } from '../../services/ApiService';
import * as API_ENDPOINTS from '../../services/endpoints';
import Loader from '../Loader/Loader';
import { compose } from 'ramda';
import withCancelToken from '../CancelToken/withCancelToken';
import simpleListViewStyles from './SimpleListView.module.css';

class FetchQuestionnaires extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      questionnaires: [],
      continuationToken: undefined,
    };
  }

  componentDidMount() {
    this.fetchQuestionnaires(this.props.questionnaireParams);
  }

  fetchQuestionnaires = (questionnaireParams, continueSearch = false) => {
    this.setState({
      loading: true,
    });

    let fetchApiUrl = this.props.openQuestionnaires
      ? API_ENDPOINTS.FETCH_OPEN_QUESTIONNAIRES
      : API_ENDPOINTS.FETCH_QUESTIONNAIRES;

    if (continueSearch) {
      questionnaireParams = Object.assign(questionnaireParams, {
        continuationToken: this.state.continuationToken,
      });
    }

    const config = {
      params: questionnaireParams,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
      cancelToken: this.props.cancelTokenSource.token,
    };

    getRaw(fetchApiUrl, config)
      .then((result) => this.addQuestionnaireToState(result))
      .catch((thrown) => {
        if (!this.props.isCancel(thrown)) {
          this.setState({ loading: false });
          console.log(thrown);
        }
      });
  };

  addQuestionnaireToState = (response) => {    
    this.setState({
      loading: false,
      questionnaires: this.state.questionnaires.concat(response.data.payload),
      continuationToken: response.headers['tietoalusta-continuation-token']
    });
  };

  render() {
    const { t, renderQuestionnaires, useContinuationButton, customNoResultsText } = this.props;
    const { questionnaires, loading, continuationToken } = this.state;

    return (
      <>
        <Loader loading={loading}>
          {renderQuestionnaires(questionnaires)}
          {useContinuationButton && continuationToken && (
            <div className={simpleListViewStyles.buttonWrap}>
              <button
                className={'buttonStyle'}
                onClick={this.fetchMoreRfos(this.props.questionnairesParams)}
                disabled={loading}
              >
                {t('Lisää vastauksia')}
              </button>
            </div>
          )}
        </Loader>
        {!loading && questionnaires && questionnaires.length === 0 && (
          <div>{customNoResultsText ? customNoResultsText : t('Ei vastauksia.')}</div>
        )}
      </>
    );
  }
}

export default compose(withCancelToken, withNamespaces())(FetchQuestionnaires);