export const HANDLE_RFO_FORM_CHANGE = 'rfo/HANDLE_RFO_FORM_CHANGE';
export const HANDLE_MATERIAL_FORM_CHANGE = 'rfo/HANDLE_MATERIAL_FORM_CHANGE';
export const CLEAR_RFO_FORM = 'rfo/CLEAR_RFO_FORM';
export const ADD_MATERIAL = 'rfo/ADD_MATERIAL';
export const DELETE_MATERIAL = 'rfo/DELETE_MATERIAL';
export const RFO_VIEW = 'rfo/RFO_VIEW';
export const CLEAR_RFO_VIEW = 'rfo/CLEAR_RFO_VIEW';
export const ADD_FILES = 'rfo/ADD_FILES';
export const DELETE_FILE = 'rfo/DELETE_FILE';
export const DELETE_FILES = 'rfo/DELETE_FILES';
export const ADD_REGION = 'rfo/ADD_REGION';
export const TOGGLE_REGION = 'rfo/TOGGLE_REGION';
export const DELETE_REGION = 'rfo/DELETE_REGION';
export const ADD_SUBSERVICE = 'rfo/ADD_SUBSERVICE';
export const DELETE_SUBSERVICE = 'rfo/DELETE_SUBSERVICE';
export const DELETE_ALL_SUBSERVICES = 'rfo/DELETE_ALL_SUBSERVICES';
export const DELETE_SUBCLASSIFICATION = 'rfo/DELETE_SUBCLASSIFICATION';
export const LOADING_RFOS = 'rfo/LOADING_RFOS';
export const LOADING_RFO = 'rfo/LOADING_RFO';
export const LOADING_MAP_RFOS = 'rfo/LOADING_MAP_RFOS';
export const RFO_NOT_FOUND = 'rfo/RFO_NOT_FOUND';
export const UPDATE_RFO_LIST = 'rfo/UPDATE_RFO_LIST';
export const APPEND_RFO_LIST = 'rfo/APPEND_RFO_LIST';
export const UPDATE_MAP_RFO_LIST = 'rfo/UPDATE_MAP_RFO_LIST';
export const RFO_EDIT_VIEW = 'rfo/ADD_RFO_TO_EDIT_VIEW';
export const RFO_FORM_HAS_CHANGES = 'rfo/RFO_FORM_HAS_CHANGES';
