import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import { getFromCookies } from './utils/cookie-utils';

const resources = {
  'fi-FI': {
    translation: {
      'event-link-RequestForOfferCreated': 'Uusi ilmoitus luotu',
      'event-link-RequestForOfferWithHilmaCreated': 'Kunnan toissijainen jätehuoltopalvelu (TSV) pyyntöluonnos tallennettu',
      'event-link-RequestForOfferWithHilmaUpdated': 'Kunnan toissijainen jätehuoltopalvelu (TSV) pyyntöluonnos muokattu',
      'event-link-OfferReceived': 'Tarjous vastaanotettu',
      'event-link-OfferCreated': 'Tarjous luotu',
      'event-link-answer-OfferReceived': 'Vastaus saapunut ilmoitukseesi',
      'event-link-answer-OfferCreated': 'Vastaus jätetty ilmoitukseen',
      'event-link-OfferAcceptedByCustomer': 'Asiakas on hyväksynyt tarjouksen',
      'event-link-OfferAccepted': 'Tarjous hyväksytty',
      'event-link-TsvRequestReceived':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) pyyntö vastaanotettu',
      'event-link-TsvRequestSent': 'Kunnan toissijainen jätehuoltopalvelu (TSV) pyyntö lähetetty',
      'event-link-TsvAccepted': 'Kunnan toissijainen jätehuoltopalvelu (TSV) pyyntö hyväksytty',
      'event-link-TsvAcceptedByFacility':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) pyyntö hyväksytty',
      'event-link-UserAuthorizedToCompany': 'Uusi käyttäjä liittynyt organisaatioon',
      'event-link-UserAuthorizationDenied': 'Käyttäjän liittymispyyntö hylätty',
      'event-link-CompanyDetailsUpdated': 'Organisaation tiedot päivitetty',
      'event-link-CompanyRegistrationAdded': 'Lupa / rekisterimerkintä lisätty',
      'event-link-CompanyRegistrationRemoved': 'Lupa / rekisterimerkintä poistettu',
      'event-link-RequestForOfferStatusChanged': 'Ilmoituksen tila muuttunut',
      'event-link-RequestForOfferUpdated': 'Ilmoituksen tila päivitetty',
      'event-link-OfferRejectedByCustomer': 'Tarjous hylätty',
      'event-link-OfferRejected': 'Tarjous hylätty',
      'event-link-WasteBatchReported': 'Pienerä raporttia muokattu',
      'event-link-WasteBatchReportRowDeleted': 'Pienerä raportista poistettu rivi',
      'event-link-TsvContractDraftMade':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimusluonnos tehty',
      'event-link-TsvContractDraftMadeByFacility':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimusluonnos vastaanotettu',
      'event-link-TsvRequestCanceled':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -pyyntö peruttu',
      'event-link-TsvRequestCanceledByClient':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -pyyntö peruttu',
      'event-link-TsvRequestRejected':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -pyyntö hylätty',
      'event-link-TsvRequestRejectedByFacility':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -pyyntö hylätty',
      'event-link-TsvContractAccepted':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimus hyväksytty',
      'event-link-TsvContractAcceptedByClient':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimus hyväksytty',
      'event-link-TsvContractRejected':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimus hylätty',
      'event-link-TsvContractRejectedByClient':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimus hylätty',
      'event-link-TsvContractTerminated':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimus purettu',
      'event-link-TsvContractTerminatedByFacility':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimus purettu',
      'event-link-TsvContractImportedByFacility':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimus tuotu',
      'event-link-TsvContractImported':
        'Kunnan toissijainen jätehuoltopalvelu (TSV) -sopimus tuotu',

      'event-description-UserAuthorizationDenied': '',
      'event-description-RequestForOfferCreated': '',
      'event-description-RequestForOfferWithHilmaCreated': '',
      'event-description-RequestForOfferWithHilmaUpdated': '',
      'event-description-OfferReceived': '',
      'event-description-OfferCreated': '',
      'event-description-OfferAcceptedByCustomer': '',
      'event-description-OfferAccepted': '',
      'event-description-TsvRequestReceived': '',
      'event-description-TsvRequestSent': '',
      'event-description-TsvAccepted': '',
      'event-description-TsvAcceptedByFacility': '',
      'event-description-UserAuthorizedToCompany': '',
      'event-description-CompanyDetailsUpdated': '',
      'event-description-CompanyRegistrationAdded': '',
      'event-description-CompanyRegistrationRemoved': '',
      'event-description-RequestForOfferStatusChanged': '',
      'event-description-RequestForOfferUpdated': '',
      'event-description-OfferRejectedByCustomer': '',
      'event-description-OfferRejected': '',
      'event-description-WasteBatchReported': '',
      'event-description-WasteBatchReportRowDeleted': '',
      'event-description-TsvContractDraftMade': '',
      'event-description-TsvContractDraftMadeByFacility': '',
      'event-description-TsvRequestCanceled': '',
      'event-description-TsvRequestCanceledByClient': '',
      'event-description-TsvRequestRejected': '',
      'event-description-TsvRequestRejectedByFacility': '',
      'event-description-TsvContractAccepted': '',
      'event-description-TsvContractAcceptedByClient': '',
      'event-description-TsvContractRejected': '',
      'event-description-TsvContractRejectedByClient': '',
      'event-description-TsvContractTerminated': '',
      'event-description-TsvContractTerminatedByFacility': '',
      'event-description-TsvContractImported': '',
      'event-description-TsvContractImportedByFacility': '',
      'saved-search-freq-0': 'ei s-posti ilmoitusta',
      'saved-search-freq-100': 'ilmoitus heti s-postiin',
      'saved-search-freq-200': 's-postikooste päivittäin',
      'saved-search-freq-300': 's-postikooste viikottain',
      'offer-state-offerMade': 'avoin',
      'offer-state-offerRejected': 'hylätty',
      'offer-state-offerAccepted': 'hyväksytty',
      'offer-reject-reason-0': 'Muu syy',
      'offer-reject-reason-10': 'Tarjous ei ole jätelain etusijajärjestyksen mukainen',
      'offer-reject-reason-20': 'Tarjous ei vastaa pyydettyä',
      'offer-reject-reason-30': 'Tarjoaja on laiminlyönyt lakisääteiset velvoitteet',
      'offer-reject-reason-40': 'Tarjotun palvelun ajankohta',
      'offer-reject-reason-50': 'Tarjotun palvelun laatu',
      'offer-reject-reason-60': 'Pitkät kuljetusmatkat',
      'offer-reject-reason-70': 'Tarjotun palvelun hinta',
      'tsv-state-tsv-requested': 'Odottaa käsittelyä',
      'tsv-state-tsv-processing': 'Käsittelyssä',
      'tsv-state-tsv-canceled': 'Peruttu',
      'tsv-state-tsv-rejected': 'Hylätty',
      'tsv-state-tsv-contract-rejected': 'Sopimus hylätty',
      'tsv-state-tsv-contract-sketch': 'Luonnos',
      'tsv-state-tsv-contract-sketch-ready': 'Sopimus odottaa kunnan jätelaitoksen allekirjoitusta',
      'tsv-state-tsv-contract-draft': 'Sopimus hyväksyttävänä',
      'tsv-state-tsv-contract': 'Sopimus tehty',
      'tsv-state-tsv-contract-terminated': 'Sopimus purettu',

      'tsv-state-search-tsv-canceled': 'Pyyntö peruttu pyytäjän toimesta',
      'tsv-state-search-tsv-rejected': 'Pyyntö hylätty jätelaitoksen toimesta',
      'tsv-state-search-tsv-contract-sketch': 'Luonnos',
      'tsv-state-search-tsv-contract-sketch-ready': 'Sopimus odottaa kunnan jätelaitoksen allekirjoitusta',
      'tsv-state-search-tsv-contract-draft': 'Sopimus hyväksyttävänä',
      'tsv-state-search-tsv-contract': 'Sopimus tehty',
      'tsv-state-search-tsv-contract-terminated': 'Sopimus purettu',
      'tsv-state-search-tsv-requested': 'Odottaa käsittelyä',
      'tsv-state-search-tsv-processing': 'Käsittelyssä',
      'tsv-state-imported-contract': 'Sopimustiedot tuotu',

      'tsv-reject-reason-waste-quantity-or-quality':
        'Jäte on määrältään tai laadultaan teknisesti sopimaton olemassa olevaan jätehuoltojärjestelmään',
      'tsv-reject-reason-capacity-insufficient': 'Jätehuoltokapasiteetti ei ole riittävä',
      'tsv-reject-reason-other': 'Muu syy',

      'no-offers-received': 'Hankintamenettelyssä ei saatu ollenkaan tarjouksia',
      'received-offer-unsuitable': 'Hankintamenettelyssä saadut tarjoukset eivät vastanneet asetettuja vaatimuksia',
      'other-reason': 'Muu syy',

      kuljetus: 'Kuljetus',

      kasittely: 'Käsittely',
      esikasittely: 'Esikäsittely',
      uudelleenkayton_valmistelu: 'Uudelleenkäytön valmistelu',
      kierratys: 'Kierrätys',
      hyodyntaminen_maantaytossa_tai_maisemoinnissa:
        'Hyödyntäminen maantäytössä tai maisemoinnissa',
      hyodyntaminen_maa_tai_infrarakentamisessa: 'Hyödyntäminen maa- tai infrarakentamisessa',
      hyodyntaminen_maanviljelyksessa_tai_viherrakentamisessa:
        'Hyödyntäminen maanviljelyksessä tai viherrakentamisessa',
      muu_hyodyntaminen_materiaalina: 'Muu hyödyntäminen materiaalina',
      polttoaineen_valmistaminen: 'Polttoaineen valmistaminen',
      polttaminen_jatteenpolttolaitoksessa_tai_rinnakkaispolttolaitoksessa:
        'Polttaminen jätteenpolttolaitoksessa tai rinnakkaispolttolaitoksessa',
      polttaminen_vaarallisen_jatteen_polttolaitoksessa:
        'Polttaminen vaarallisen jätteen polttolaitoksessa',
      sijoittaminen_pysyvan_tai_tavanomaisen_jatteen_kaatopaikalle:
        'Sijoittaminen pysyvän tai tavanomaisen jätteen kaatopaikalle',
      sijoittaminen_vaarallisen_jatteen_kaatopaikalle:
        'Sijoittaminen vaarallisen jätteen kaatopaikalle',
      sijoittaminen_maankaatopaikalle: 'Sijoittaminen maankaatopaikalle',

      kuljetus_kasittely: 'Kuljetus ja käsittely',
      kiinteiston_kokonaispalvelu: 'Kiinteistön kokonaispalvelu',
      varastointi: 'Varastointi',
      asiantuntijapalvelu: 'Asiantuntijapalvelut ja osaaminen',
      analyysipalvelu: 'Analyysipalvelu',
      muu_palvelu: 'Muu palvelu',

      biotalous_ja_biomateriaalit: 'Biotalous ja biomateriaalit',
      energia_uusiutuva_energia: 'Energia (uusiutuva energia, energiatehokkuus)',
      materiaali_ja_resurssitehokkuus: 'Materiaali- ja resurssitehokkuus',              
      jatehuolto: 'Jätehuolto',
      jatevesien_kasittely: 'Jätevesien käsittely',
      kiertotalous: 'Kiertotalous',
      liiketoiminnan_kehittaminen_liiketoimintamallit: 'Liiketoiminnan kehittäminen, liiketoimintamallit',
      mallinnus_tiedonhallinta: 'Mallinnus, tiedonhallinta',
      monitorointi_vaikutusten_arviointi: 'Monitorointi, vaikutusten arviointi',
      materiaalit: 'Materiaalit',
      pakkaukset: 'Pakkaukset',
      rakentamisen_kiertotalous: 'Rakentamisen kiertotalous',
      rakennusjatteet_ja_purkumateriaalit: 'Rakennusjätteet ja purkumateriaalit',
      maa_ja_infrarakentaminen: 'Maa- ja infrarakentaminen',
      lainsaadanto: 'Lainsäädäntö',
      rahoitus: 'Rahoitus',
      kiertotalous_hankinnoissa: 'Kiertotalous hankinnoissa',
      suunnittelu: 'Suunnittelu',
      muut: 'Muut',

      muovi: 'Muovi',
      metalli: 'Metalli',
      lasi: 'Lasi',
      puu: 'Puu',
      paperi: 'Paperi',
      kartonki: 'Kartonki',
      kipsi: 'Kipsi',
      betoni: 'Betoni',
      tiilet_ja_keramiikka: 'Tiilet ja keramiikka',
      keramiikka: 'Keramiikka',
      eristeet: 'Eristeet',
      asfaltti: 'Asfaltti',
      maa_ainekset: 'Maa-ainekset',
      tekstiili: 'Tekstiili',
      liete: 'Liete',
      tuhka_ja_kuona: 'Tuhka ja kuona',
      orgaaninen_jate: 'Orgaaninen jäte',
      sekalainen_jate: 'Sekalainen jäte',
      orgaaninen_sivuvirta: 'Orgaaninen sivuvirta',
      teollisuuden_ja_prosessien_jatteet: 'Teollisuuden ja prosessien jätteet',
      teollisuuden_ja_prosessien_sivuvirta: 'Teollisuuden ja prosessien sivuvirta',
      muu_sivuvirta: 'Muu sivuvirta',
      endosulfaani: 'Endosulfaani',
      heksaklooributadieeni: 'Heksaklooributadieeni',
      polyklooratut_naftaleenit: 'Polyklooratut naftaleenit',
      alkaanit: 'Alkaanit C 10–C 13, kloori- (lyhytketjuiset klooratut parafiinit – SCCP)',
      tetrabromidifenyylieetteri: 'Tetrabromidifenyylieetteri C₁₂H₆Br₄O',
      pentabromidifenyylieetteri: 'Pentabromidifenyylieetteri C₁₂H₅Br₅O',
      heksabromidifenyylieetteri: 'Heksabromidifenyylieetteri C₁₂H₄Br₆O',
      heptabromidifenyylieetteri: 'Heptabromidifenyylieetteri C₁₂H₃Br₇O',
      dekabromidifenyylieetteri: 'Dekabromidifenyylieetteri C₁₂Br₁₀O',
      perfluorioktaanisulfonihappo_ja_sen_johdannaiset: 'Perfluorioktaanisulfonihappo ja sen johdannaiset (PFOS) C₈F₁₇SO₂X',
      polyklooratut_dibentso_para_dioksiinit_ja_dibentsofuraanit: 'Polyklooratut dibentso-para-dioksiinit ja dibentsofuraanit (PCDD/PCDF)',
      ddt: 'DDT (1,1,1-trikloori-2,2-bis(4-kloorifenyyli)etaani)',
      klordaani: 'Klordaani',
      heksakloorisykloheksaanit_mukaan_lukien_lindaani: 'Heksakloorisykloheksaanit, mukaan lukien lindaani',
      dieldriini: 'Dieldriini',
      endriini: 'Endriini',
      heptakloori: 'Heptakloori',
      heksaklooribentseeni: 'Heksaklooribentseeni',
      klordekoni: 'Klordekoni',
      aldriini: 'Aldriini',
      pentaklooribentseeni: 'Pentaklooribentseeni',
      polyklooratut_bifenyylit: 'Polyklooratut bifenyylit (PCB)',
      mireksi: 'Mireksi',
      toksafeeni: 'Toksafeeni',
      heksabromibifenyyli: 'Heksabromibifenyyli',
      heksabromisyklododekaani: 'Heksabromisyklododekaani',
      kumi: 'Kumi',
      tuhka: 'Tuhka',
      kuona: 'Kuona',
      asbesti: 'Asbesti',
      asfaltti_ja_bitumi: 'Asfaltti ja bitumi',
      lanta: 'Lanta',
      elainperainen_orgaaninen_materiaali_ei_lanta:
        'Eläinperäinen orgaaninen materiaali (pois lukien lanta)',
      kasviperainen_orgaaninen_materiaali: 'Kasviperäinen orgaaninen materiaali',
      polyt_ja_sakat: 'Pölyt ja sakat',
      epakurantit_tuotteet: 'Epäkurantit tuotteet',
      katalyytit: 'Katalyytit',
      muu_teollisuusjate: 'Muu teollisuusjäte',
      oljy_emulsio_ja_muut_oljyiset_jatteet: 'Öljy, emulsio ja muut öljyiset jätteet',
      akut_ja_paristot: 'Akut ja paristot',
      renkaat: 'Renkaat',
      sahko_ja_elektroniikkaromu: 'Sähkö- ja elektroniikkaromu',
      sekalainen_rakennus_ja_purkujate:
        'Sekalainen rakennus- ja purkujäte (mukaan lukien energiajäte)',
      sekalainen_yhdyskunta_jate: 'Sekalainen yhdyskuntajäte (mukaan lukien energiajäte)',
      jatepolttoaine: 'Jätepolttoaine (SRF/RDF)',
      biojate: 'Biojäte',
      maalit_ja_lakat: 'Maalit ja lakat',
      laakkeet: 'Lääkkeet',
      kemikaalit: 'Kemikaalit (pois lukien lääkkeet)',
      mekaanisen_kasittelyn_rejektit: 'Mekaanisen käsittelyn rejektit',
      liete_pesu_ja_jatevedet: 'Liete sekä pesu- ja jätevedet',
      kayttokelvottomat_suurikokoiset_esineet: 'Käyttökelvottomat suurikokoiset esineet',
      muu_jate: 'Muu jäte',
      elintarvikesivuvirta: 'Elintarvikesivuvirta',
      kayttokelpoiset_huonekalut: 'Käyttökelpoiset huonekalut',
      rakennusosat: 'Rakennusosat',
      rakentaminen_ja_purkaminen: 'Rakentaminen ja purkaminen',
      jate_vesi_ja_jatevesihuolto: 'Jäte-, vesi ja jätevesihuolto',
      kaivannaisteollisuus: 'Kaivannaisteollisuus',
      maa_metsa_ja_kalatalous: 'Maa-, metsä- ja kalatalous',
      puu_paperi_ja_kartonkiteollisuus: 'Puu-, paperi- ja kartonkiteollisuus',
      nahka_turkis_ja_tekstiiliteollisuus: 'Nahka-, turkis- ja tekstiiliteollisuus',
      oljynjalostus_ja_muu_polttoaineen_valmistus: 'Öljynjalostus ja muu polttoaineen valmistus',
      kemianteollisuus: 'Kemianteollisuus',
      metalliteollisuus: 'Metalliteollisuus',
      energiateollisuus_ja_muut_termiset_prosessit: 'Energiateollisuus ja muut termiset prosessit',
      muu_teollisuus: 'Muu teollisuus',
      tukku_ja_vahittaiskauppa: 'Tukku- ja vähittäiskauppa',
      ravitsemus_ja_majoituspalvelut: 'Ravitsemus- ja majoituspalvelut',
      terveydenhuolto: 'Terveydenhuolto',
      kotitaloudet_toimistot_koulut_paivakodit_ja_vastaavat:
        'Kotitaloudet, toimistot, koulut, päiväkodit ja vastaavat',
      muu_toimiala: 'Muu toimiala',
      elintarviketeollisuus: 'Elintarviketeollisuus',
      petrokemianteollisuus: 'Petrokemianteollisuus',
      valokuvateollisuus: 'Valokuvateollisuus',
      energiantuotanto: 'Energiantuotanto',
      etusivu_faq_kehote:
        'Katso nämä ja monet muut keskeiset kysymykset ja vastaukset <2>Kysymyksiä ja vastauksia</2> -sivulta.',
      etusivu_ohjeet_kehote: 'Katso <1>Materiaalitorin ohjeet.</1>',
      etusivu_materiaalit_linkki: 'Materiaalitorilla kierrätettävistä materiaaleista ja palveluista on lisää tietoa <1>täällä</1>.',
      etusivu_pilot_banner:
        'Pilottivaihe jatkuu ja palvelua kehitetään edelleen. Seuraava päivitys tulee elokuussa, jolloin Materiaalitorissa on tarvittaessa mahdollista edetä pyytämään kunnan toissijaista jätehuoltopalvelua (TSV) ja tekemään siitä sopimuksia. Palautetta on tullut runsaasti ja ne on huomioitu kehitystyössä. Otamme edelleen mielellämme vastaan palautetta tällä <1>palautelomakkeella</1>. Puhelintuki (09) 6122 5080 on kesälomalla 8.-26.7. ja tuona aikana myös asiakastukeen tulleisiin sähköposteihin saatetaan vastata normaalia pidemmällä vasteajalla.',

      ilmoitussivun_ilmoitus_tyypit:
        'Valitse oikea ilmoitustyyppi.\n\nValitse ”<4>Etsin materiaalille vastaanottajaa</4>”, mikäli ilmoitat tarjolla olevasta jätteestä tai muusta materiaalista, kuten tuotannon sivuvirrasta. Valitse seuraavaksi, onko ilmoittamasi materiaali jätettä vai muuta materiaalia. Jos kyseessä on jäte, voit määritellä ilmoituksessa lisäksi, millaista palvelua etsit jätteelle. \n\n  Valitse ”<9>Etsin hyödynnettäviä materiaaleja</9>”, mikäli ilmoitat halustasi vastaanottaa materiaalia. Materiaali voi olla jätettä tai sivuvirtaa. \n\n Mikäli haluat pikemminkin tarjota jätehuoltopalvelua, valitse ”<14>Tarjoan jätehuolto- tai asiantuntijapalvelua</14>”. Silloin voit ilmoittaa tarjoamastasi materiaalien käsittely- tai kuljetuspalvelusta, analysointipalvelusta tai materiaaleihin liittyvistä muista asiantuntijapalveluista.\n\n”<18>Etsin asiantuntijapalvelua tai osaamista</18>” -ilmoituksella voit etsiä asiantuntijoita esimerkiksi tutkimus- tai kehityshankkeiden toteutukseen tai vaikka asiantuntijapuheenvuoron pitäjäksi. Ilmoituksessa valitaan valikosta ne osaamisalueet, joita haetaan ja kuvataan vapaana tekstinä ja se, mitä haetaan. Tarvittaessa haettavaa asiaa tarkennetaan liitteiden avulla. \n\nHuom! Jätteen vastaanottaminen edellyttää jätelain 29 §:n mukaista hyväksyntää.',
      ilmoitussivun_tsv:
        'Mikäli et ilmoituksesta huolimatta löydä jätteellesi vastaanottajaa, sinun on mahdollista pyytää kunnan jätelaitokselta jätehuoltopalvelua (kunnan toissijainen jätehuoltopalvelu eli ns. TSV-palvelu). Jos valitset tämän, täytettäväksesi tuleva ilmoituspohja täyttää jätelainsäädännössä ilmoitukselle asetetut vaatimukset (mm. ilmoituksen 14 vuorokauden voimassaoloaika).\n\nLisätietoa <1>kunnan toissijaisesta jätehuoltopalvelusta</1>.',

      tsv_pyynnon_katselusivun_painikkeiden_ohje:
        'Kunnan jätelaitoksen on mahdollista kieltäytyä palvelun tarjoamisesta, mikäli jäte ei määrältään tai laadultaan sovellu teknisesti kunnan jätehuoltojärjestelmään tai kunnalla ei ole tarvittavaa kapasiteettia jätteen vastaanottamiseksi. Tällaisessa tapauksessa pyyntö tulee hylätä ja lisätä perustelut hylkäämiselle. Pyynnön hylkäämiseen tyytymätön on tarvittaessa ohjattava viemään asiaan kunnan jätehuoltoviranomaisen ratkaistavaksi.\n\nJätelain 33 §:n mukaan kunnan on tehtävä jätteen haltijan kanssa kunnan toissijaisesta jätehuoltopalvelusta sopimus silloin, kun palvelun arvo on vähintään 2000 euroa vuodessa. Sopimusosapuolia ovat kunnan jätelaitos ja palvelua pyytävä jätteen haltija taikka tälle jätelain mukaisesti jätehuoltopalvelua tarjoava yritys. Sopimuksen kesto voi olla enintään kolme vuotta kerrallaan ja osapuolet voivat irtisanoa sopimuksessa määritellyn irtisanomisajan kuluttua.\n\nSopimus voidaan tehdä suoraan Materialitorissa hyödyntämällä valmista sopimuspohjaa valitsemalla ”Tee sopimus Materiaalitorissa”. Tällöin sopimuspohja aukeaa täytettäväksi. Lopuksi sopimusluonnos ja tieto pyynnön hyväksymisestä lähtee pyytäjälle. Sopimus tulee voimaan, kun pyynnön tekijä hyväksyy sopimuksen.\n\nVaihtoehtoisesti sopimuksen voi tehdä Materiaalitorin ulkopuolella. Sopimuksessa on oltava tiedot sopimusosapuolista, jätteestä ja sen sijainnista sekä palvelusta ja sen hinnasta. <10>Nämä tiedot on tuotava Materiaalitoriin viimeistään 14 vuorokauden kuluttua sopimuksen tekemisestä.</10> Tiedot tuodaan valitsemalla ”Tuo tiedot tehdystä sopimuksesta”.',
      jateilmoituksen_luontisivun_jate:
        'Jätteen lajia tai materiaalityppiä mahdollisimman hyvin kuvaava termi. Valmiiden ilmoitusten hakua voi rajata tämän jätteen luokittelun perusteella.\n\nVoit valita vain yhden jätteen yhteen ilmoitukseen. Mikäli teet ilmoituksen sekalaisesta jätteestä, määrittele jätteen tarkempi koostumus vapaamuotoisessa ”Jätteen kuvaus” –kohdassa.\n\nHuom!\n\nTuottajavastuun alaiset jätteet (renkaat, ajoneuvot, sähkö- ja elektroniikkalaitteet, paristot ja akut, paperi, pakkaukset) tulee toimittaa pääsääntöisesti tuottajan hyväksymään vastaanottoon.\n\nAsumisessa sekä kunnan hallinto- ja palvelutoiminnassa syntyvät yhdyskuntajätteet kuuluvat kunnan vastuulle, jolloin kunta järjestää kyseisten jätteiden jätehuollon.\n\nLisätietoja jätehuollon vastuista\n<17>https://www.ymparisto.fi/fi-FI/Kulutus_ja_tuotanto/Jatteet_ja_jatehuolto/Jatehuollon_vastuut_ja_jarjestaminen</17>',
      jateilmoituksen_luontisivun_jate_hilma:
      'Jätteen lajia tai materiaalityppiä mahdollisimman hyvin kuvaava termi.\n\nVoit valita vain yhden jätteen yhteen ilmoitukseen. Jos tarvitset palvelua usealle eri jätteelle, voit tehdä useita pyyntöjä samasta hankintailmoituksesta. Mikäli teet ilmoituksen sekalaisesta jätteestä, määrittele jätteen tarkempi koostumus vapaamuotoisessa ”Jätteen kuvaus” –kohdassa.\n\nHuom!\n\nTuottajavastuun alaiset jätteet (renkaat, ajoneuvot, sähkö- ja elektroniikkalaitteet, paristot ja akut, paperi, pakkaukset) tulee toimittaa pääsääntöisesti tuottajan hyväksymään vastaanottoon.\n\nAsumisessa sekä kunnan hallinto- ja palvelutoiminnassa syntyvät yhdyskuntajätteet kuuluvat kunnan vastuulle, jolloin kunta järjestää kyseisten jätteiden jätehuollon.\n\nLisätietoja jätehuollon vastuista\n<17>https://www.ymparisto.fi/fi-FI/Kulutus_ja_tuotanto/Jatteet_ja_jatehuolto/Jatehuollon_vastuut_ja_jarjestaminen</17>',
      m2: 'm²',
      m3: 'm³',
      'Hilma-ilmoitus on vielä voimassa': 'Hankintailmoitus on voimassa Hilmassa. Pyynnön voi tehdä vasta, kun tarjousten lähettämisen määräaika on päättynyt.',
      'Hilma-ilmoitus kuuluu toiselle organisaatiolle': 'Hankintailmoituksen on tehnyt toinen organisaatio. Et voi tehdä pyyntöä tämän ilmoituksen pohjalta.',

      'offering-label': 'Tarjotaan',
      'receiving-label': 'Etsitään',
      'waste-label': 'jätettä',
      'nonwaste-label': 'sivuvirtaa',
      'material-label': 'materiaalia',
      'wasteservices-label': 'jätehuolto- tai asiantuntijapalvelua',
      'professionalservices-label': 'asiantuntijapalvelua tai osaamista',

      'offeringWaste-title': 'Tarjotaan jätettä',
      'offeringMaterial-title': 'Tarjotaan sivuvirtaa',
      'receivingMaterial-title': 'Etsitään materiaalia',
      'offeringServices-title': 'Tarjotaan jätehuolto- tai asiantuntijapalvelua',
      'receivingServices-title': 'Etsitään asiantuntijapalvelua tai osaamista',
      'includeClosedAndExpired-title': 'Etsi myös sulkeutuneista ilmoituksista',
      'User-role-name': 'Yrityksen tai organisaation edustaja',
      'SystemAdmin-role-name': 'SystemAdmin',
      'Admin-role-name': 'Admin',
      'PublicOfficer-role-name': 'Viranomainen',
      'RegionalCoordinator-role-name': 'Aluekoordinaattori',
      'MunicipalWasteManagement-role-name': 'Kunnallisen jätehuollon edustaja',
      'company-registration-type-1': 'Muu lupa',
      'company-registration-type-0': 'Muu lupa',
      'company-registration-type-100': 'Jätehuoltorekisterimerkintä',
      'company-registration-type-200': 'Ympäristölupa',
      'company-registration-type-300': 'Rekisterimerkintä ympäristönsuojelun tietojärjestelmässä',

      onetime: 'Kertaerä',
      continuous: 'Jatkuvasti syntyvä materiaali',
      'Tiedot jätelaitoksen TSV-palveluista': 'Tiedot jätelaitoksen TSV-palveluista',
      'Tiedot jätelaitokset TSV-palveluista- lisätiedot':
        'Lisää halutessasi tiedot jätelaitoksen tarjoamasta kunnan toissijaisesta jätehuoltopalvelusta (TSV-palvelu). Tiedot näkyvät TSV-pyynnön esittämisvaiheessa eli vain jätteen haltijalle tai muulle toimijalle, joka on tehnyt tarjouspyynnön, odottanut siihen tarjouksia, arvioinut mahdolliset tarjoukset ja siirtynyt muun palvelutarjonnan puutteen vuoksi pyytämään TSV-palvelua. Tiedot voivat olla esimerkiksi tietoja vastaanotettavista jätelajeista, käsittelytavoista, hinnoista, palvelun sijainnista, tarkemmista yhteystiedoista tai linkki jätelaitoksen verkkosivulle, josta tiedot löytyvät. Muista pitää tiedot ajan tasalla.',
      hilmaUrl:'https://www.hankintailmoitukset.fi',
      'tuhkalannoitteena_maa-_ja_puutarhataloudessa': 'Tuhkalannoitteena maa- ja puutarhataloudessa',
      'tuhkalannoitteena_maisemoinnissa_ja_viherrakentamisessa': 'Tuhkalannoitteena maisemoinnissa ja viherrakentamisessa',
      'tuhkalannoitteena_metsataloudessa': 'Tuhkalannoitteena metsätaloudessa',
      'tuhka_maarakentamisessa_vaylalla_peitettyna_rakenteena': 'Tuhka maarakentamisessa väylällä peitettynä rakenteena',
      'tuhka_maarakentamisessa_vaylalla_paallystettyna_rakenteena': 'Tuhka maarakentamisessa väylällä päällystettynä rakenteena',
      'tuhka_maarakentamisessa_kenttana_peitettyna_rakenteena': 'Tuhka maarakentamisessa kenttänä peitettynä rakenteena',
      'tuhka_maarakentamisessa_kenttana_paallystettyna_rakenteena': 'Tuhka maarakentamisessa kenttänä päällystettynä rakenteena',
      'tuhka_maarakentamisessa_vallina_peitettyna_rakenteena': 'Tuhka maarakentamisessa vallina peitettynä rakenteena',
      'tuhka_maarakentamisessa_teollisuus-_ja_varastorakennuksen_pohjarakenteena': 'Tuhka maarakentamisessa teollisuus- ja varastorakennuksen pohjarakenteena',
      'tuhka_maarakentamisessa_tuhkamursketiessa': 'Tuhka maarakentamisessa tuhkamursketiessä'
    },
  },
  'sv-FI': {
    translation: {
      Etusivulle: 'Till ingångssidan',
      'Jätteen haltija, etsi jätehuoltopalvelua Materiaalitorilta!':
        'Avfallsinnehavare, leta efter avfallshanteringstjänster på Materialtorget!',
      'Vuoden 2021 alusta Materiaalitorin käyttövelvoite koskee myös hankintayksiköitä.':
        'Från ingången av 2021 är även upphandlande enheter skyldiga att använda Materialtorget.',
      'Kunnilla on velvollisuus järjestää asumisessa syntyvän jätteen sekä kunnan hallinto- ja palvelutoiminnassa syntyvän yhdyskuntajätteen jätehuolto. Yritykset ja muut organisaatiot käyttävät markkinaehtoisia jätehuoltopalveluita.':
        'Kommunerna är skyldiga att ordna avfallshantering av avfall från bosättning samt kommunalt avfall från kommunens förvaltnings- och serviceverksamhet. Företag och andra organisationer använder avfallshanteringstjänster på marknadsvillkor.',
      'Yksityisen palvelutarjonnan puute on edellytys kunnan toissijaiselle jätehuoltopalvelun käytölle. Sen selvittämiseksi jätteen haltijan on tehtävä ilmoitus tarvitsemastaan jätehuoltopalvelusta Materiaalitoriin. Toissijaista palvelua voi pyytää kunnan jätelaitokselta Materiaalitorin kautta, jos kohtuullista markkinaehtoista palvelua ei löydy 14 vuorokauden kuluessa ilmoituksen jättämisestä.':
      'Bristande utbud av privata tjänster är en förutsättning för kommunal avfallshantering i andra hand. För att kunna utreda marknadsläget ska avfallsinnehavare sätta in en annons på Materialtorget med information om de avfallshanteringstjänster de behöver. Avfallsinnehavare kan be om avfallshantering i andra hand av en kommunal avfallsanläggning, om det inte går att få tag i en skälig tjänst på marknadsvillkor inom 14 dygn från det att annonsen publicerades.',
      etusivu_summer_banner:
        '<0>Logga in</0> på Materialtorget för att sätta in <4>en annons om den avfallshanteringstjänst du behöver</4>.',
      'Materiaalitorin käyttövelvoitetta ei ole, kun hankittavan kunnan toissijaisen jätehuoltopalvelun tarve on alle 2000 euroa vuodessa, tai kun jätehuoltopalvelua tarvitaan nopeasti ennalta arvaamattoman kiireen vuoksi.':
        'Skyldigheten att använda Materialtorget gäller inte sådan kommunal avfallshantering i andra hand som underskrider värdet av 2 000 euro per år eller situationer där avfallshantering behövs snabbt på grund av oförutsebar brådska.',
      etusivu_hilma_banner:
        'Upphandlande enheter kan göra begäran av avfallshantering i andra hand med uppgifterna från en publicerad annons på Materialtorget (upphandling under 60 000 €) eller med uppgifterna från en enligt upphandlingslagen i Hilma annonseringskanal (<1>https://www.hankintailmoitukset.fi/sv/</1>) publicerad och avslutad annons utan att publicera en skild annons på Materialtorget.',
      'Suomi.fi-valtuudet':
        'Suomi.fi fullmakter',
      'Käyttäjien valtuuttaminen ja rekisteröityminen':
        'Införa tjänsten för befullmäktigande i tjänsten Materiaalitori.fi',
      etusivu_valtuudet_info_banner:
        'I juni 2022 införs en tjänst för befullmäktigande i tjänsten Materiaalitori.fi. Då sker inloggningen på Materialtorget alltid via Suomi.fi-identifikation med nätbankskoder, mobilcertifikat eller certifikatkort och inloggarens rättigheter att uträtta ärenden kontrolleras. Separata användarnamn och lösenord används inte längre.',
      etusivu_valtuudet_requirements_banner: 
        'Beakta att du efter den 9 juni inte kan logga in i tjänsten, om du inte har organisationens fullmakt. Den som har firmateckningsrätt för organisationen kan använda Materialtorget utan separat fullmakt. ',
        etusivu_valtuudet_suggestion_banner:
        'Fullmakt att använda Materialtorget ges av en person med firmateckningsrätt i företaget/organisationen. Fullmakten kan ges redan i förväg från och med den 18 maj 2022.',
      etusivu_valtuudet_ohje_banner: 
        'Anvisning för att ge fullmakt.',
      hilmaUrl:
        'https://www.hankintailmoitukset.fi/sv/',
      Poista: 'Ta bort',
      'Lisää lupa tai rekisterimerkintä': 'Lägg till tillstånd eller registeranteckning',
      Tyyppi: 'Typ',
      '(pakollinen)': '(obligatorisk)',
      '(pakollinen, mikäli aluetta ei annettu)': '(obligatorisk, om områden är inte fyllas i)',
      '(pakollinen, mikäli kuntaa ei annettu)': '(obligatorisk, om kommun är inte fyllas i)',
      'Valitse luvan/rekisteriotteen tyyppi': 'Välj typ av tillstånd/registerutdrag',
      'company-registration-type-100': 'Anteckning i avfallshanteringsregistret',
      'company-registration-type-200': 'Miljötillstånd',
      'company-registration-type-300': 'Registeranteckning i miljöskyddets informationssystem',
      'company-registration-type-1': 'Annat tillstånd',
      Tunniste: 'ID',
      'Luvan myöntäjä': 'Tillståndet har beviljats av',
      Dokumentti: 'Dokument',
      Peruuta: 'Ångra',
      Tallenna: 'Spara',
      '+ Lisää uusi lupa / rekisterimerkintä': '+ Lägg till nytt tillstånd / registeranteckning',
      'Tiedot tallennettu.': 'Uppgifterna sparades.',
      'Tietojen tallennus epäonnistui.': 'Det gick inte att spara uppgifterna.',
      Nimi: 'Namn',
      'Y-tunnus': 'FO-nummer',
      Sähköposti: 'E-post',
      Osoite: 'Adress',
      Postinumero: 'Postnummer',
      Kunta: 'Kommun',
      Kyllä: 'Ja',
      Ei: 'Nej',
      Muokkaa: 'Redigera',
      Käyttäjätiedot: 'Användaruppgifter',
      Etunimi: 'Förnamn',
      Sukunimi: 'Efternamn',
      Organisaatio: 'Organisation',
      Puhelin: 'Telefon',
      Rooli: 'Roll',
      Jätelaitokset: 'Avfallsanläggningar',
      'Vaihda salasana': 'Byt lösenord',
      'Organisaation tiedot': 'Organisationens uppgifter',
      'Jätteen vastaanottoon liittyvät luvat ja rekisteritiedot':
        'Tillstånd och registeruppgifter om mottagning av avfall',
      'Myöntävä viranomainen': 'Beviljande myndighet',
      Rekisteriote: 'Registerutdrag',
      Voimaantulopäivä: 'Datum för ikraftträdande',
      Vanhenemispäivä: 'Datum för upphörande',
      'Organisaation käyttäjät': 'Organisationens användare',
      'Organisaatiossa on seuraavat käyttäjät:': 'Organisationen har följande användare:',
      'Vahvistusta odottavat käyttäjät': 'Användare som väntar på bekräftelse',
      Auktorisoi: 'Auktorisera',
      Hylkää: 'Avslå',
      'Lupa tai rekisterimerkintä': 'Tillstånd eller registeranteckning',
      Myöntäjä: 'Beviljat av',
      'Haluatko varmasti poistaa luvan / rekisterimerkinnän tunnisteella':
        'Är du säker på att du vill ta bort tillståndet / registeranteckninen med koden',
      Käyttäjä: 'Användare',
      'Omat tiedot': 'Mina uppgifter',
      'Jotain meni vikaan': 'Något gick fel',
      'Materiaalit ja palvelut': 'Material och tjänster',
      'Tietoa palvelusta': 'Information om tjänsten',
      Ohjeet: 'Anvisningar',
      'Usein kysytyt kysymykset': 'Vanliga frågor',
      Käyttöehdot: 'Användningsvillkor',
      Tietosuoja: 'Dataskydd',
      Evästeet: 'Cookies',
      'Palvelun tuottaja': 'Tjänsteproducent',
      Aleksanterinkatu: 'Alexandersgatan',
      'Palvelun ylläpitäjä': 'Tjänsteadministratör',
      'Kiertotalouden markkinapaikka yrityksille': 'Marknadsplats för cirkulär ekonomi för företag',
      Materiaalitori: 'Materialtorget',
      'Materiaalitori on kohtaamispaikka yritysten ja organisaatioiden jätteiden, sivuvirtojen ja ylijäämämateriaalien tuottajille ja hyödyntäjille.':
        'Materialtorget är en mötesplats för företag och organisationer som producerar eller återvinner avfall, biflöden och överskottsmaterial.',
      'Kysymykset ja vastaukset': 'Frågor och svar',
      Saavutettavuusseloste: 'Tillgänglighetsbeskrivning',
      'Materiaalitorissa nyt': 'På Materialtorget nu',
      'Rekisteröitynyttä käyttäjää': 'Registrerade användare',
      Ilmoitusta: 'Annonser',
      'Yritystä / organisaatiota': 'Företag / organisationer',
      'Materiaalit kiertoon!': 'Återvinn material!',
      'Ilmoita materiaaleista ja palveluista maksuttomasti!':
        'Annonsera avgiftsfritt om material och tjänster!',
      'Uusimmat ilmoitukset Materiaalitorissa': 'De senaste annonserna på Materialtorget',
      'Etsityt materiaalit': 'Sökt material',
      'Tarjotut materiaalit': 'Erbjudet material',
      'Tarjotut palvelut': 'Erbjudna tjänster',
      'Etsityt palvelut': 'Sökta tjänster',
      '+ Lisää ilmoitus': '+ Lägg till annons',
      'Hae & selaa ilmoituksia': 'Sök & bläddra bland annonser',
      'Vauhtia kiertotalouteen': 'Fart på den cirkulära ekonomin',
      'Läpinäkyvyyttä kunnan toissijaiseen jätehuoltopalveluun (TSV)':
        'Transparens i kommunal avfallshantering i andra hand (TSV)',
      'Pilottikäyttö alkoi': 'Pilottest började',
      'Anna palautetta': 'Ge feedback',
      'Materiaalitorilla jätteet ja sivuvirrat kiertämään':
        'Materialtorget sätter avfall och biflöden i cirkulation',
      'Kysymyksiä ja vastauksia - FAQ': 'Frågor och svar – FAQ',
      'Miksi palveluun pitää tunnistautua vahvasti?': 'Varför kräver tjänsten stark autentisering?',
      'Voiko palvelua käyttää ilman y-tunnusta?': 'Kan tjänsten användas utan FO-nummer?',
      'Mistä tietää onko hallussa oleva materiaali jätettä?':
        'Hur vet jag om materialet som jag innehar är avfall?',
      'Mikä on TSV?': 'Vad är avfallshantering i andra hand, TSV?',
      'Ohjeet - näin käytät Materiaalitoria': 'Anvisningar – så här använder du Materialtorget',
      'Rekisteröidy ja aloita Materiaalitorin käyttö heti':
        'Registrera dig och börja använda Materialtorget på en gång',
      'Rekisteröidy Materiaalitoriin.': 'Registrera dig i Materialtorget.',
      'Jos kuvan latauksessa on ongelmaa, niin voit ladata kuvan itsellesi klikkaamalla kuvan kohdalla hiiren oikeaa ja valitsemalla "Tallenna kuva nimellä...", tai avaamalla kuvan ja klikkaamalla "Lataa kuva" -painiketta Alt-näppäin pohjassa.':
        'Om du har problem med att ladda upp bild, du kan ladda den upp med att öppna bilden ock klicka "Ladda upp bild" med Alt knapp.',
      '(alt-klikkaa)': '(alt-klicka)',
      'Lataa kuva': 'Ladda upp bild',
      'Sulje kuva': 'Stäng bild',
      Lisätietoja: 'Mer information',
      'InfoBox.HideInfoBoxRequest': 'InfoBox.HideInfoBoxRequest',
      Katuosoite: 'Gatuadress',
      'Hae osoite kartalle': 'Sök adressen till kartan',
      'Hakusi antoi useita sijainteja ja nyt näytetään osoite:':
        'Din sökning gav flera platser och nu visas adressen:',
      'Kartalle valittu osoite:': 'Adress som valts till kartan:',
      'Määrän yksikkö': 'Mängdenhet',
      'Valitse yksikkö': 'Välj enhet',
      Jäte: 'Avfall',
      'Valitse jäte': 'Välj avfall',
      'Toimiala, jossa jäte syntyy': 'Bransch där avfallet uppkommer',
      'Valitse toimiala': 'Välj bransch',
      'Euroopan jäteluettelon koodi eli jätenimike':
        'Kod i europeiska avfallsförteckningen dvs. avfallsbeteckning',
      'Jätteen vaarallisuus': 'Avfallets farlighet',
      'Valitse tyyppi': 'Välj typ',
      'Vaarallinen jäte': 'Farligt avfall',
      'Vaaraton jäte': 'Icke-farligt avfall',
      'Ei tiedossa': 'Okänt',
      'Jätteen kuvaus': 'Beskrivning av avfallet',
      'Jätteen määrä ja toistuvuus': 'Avfallsmängd och upprepning',
      'Kertaerä vai jatkuvasti syntyvä jäte':
        'Engångsparti eller avfall som uppkommer kontinuerligt',
      Kertaerä: 'Engångsparti',
      'Jatkuvasti syntyvä jäte': 'Avfall som uppkommer kontinuerligt',
      'Arvio jätteen kokonaismäärästä vuodessa': 'Uppskattning om den totala avfallsmängden per år',
      'Arvio jätteen määrästä': 'Uppskattning om avfallsmängd',
      'Lisätietoja määrästä': 'Ytterligare information om mängden',
      Materiaali: 'Material',
      'Valitse': 'Välj',
      'Valitse...': 'Välj...',
      'Valitse materiaali': 'Välj material',
      'Toimiala, jossa materiaali syntyy': 'Branschen där materialet uppkommer',
      'Materiaalin kuvaus': 'Beskrivning av materialet',
      'Materiaalin määrä ja toistuvuus': 'Materialmängd och upprepning',
      'Kertaerä vai jatkuvasti syntyvä materiaali':
        'Engångsparti eller material som uppkommer kontinuerligt',
      'Jatkuvasti syntyvä materiaali': 'Material som uppkommer kontinuerligt',
      'Arvio materiaalin kokonaismäärästä vuodessa':
        'Uppskattning om den totala materialmängden per år',
      'Arvio materiaalin määrästä': 'Uppskattning om materialmängden',
      'Valitse haettava materiaali': 'Välj material att söka',
      'Materiaalin ja tarpeen kuvaus': 'Beskrivning av material och behov',
      Määrä: 'Mängd',
      Kuvaus: 'Beskrivning',
      'Jätettä?': 'Avfall?',
      'TSV?': 'Avfallshantering i andra hand,TSV?',
      Hinta: 'Pris',
      sopimus: 'avtal',
      'Päättyy: ': 'Upphör: ',
      'Kaikki sopimukset': 'Alla avtal',
      Tapahtumat: 'Händelser',
      'Alla näet viimeisimmät sinua tai organisaatiotasi koskevat tapahtumat':
        'Nedan ser du de händelser som gäller dig eller din organisation',
      Tapahtuma: 'Händelse',
      'Vastuullisen organisaatio': 'Ansvarig organisation',
      Vastuullinen: 'Ansvarig',
      Aika: 'Tid',
      'Lisää tapahtumia': 'Lägg till händelser',
      'Omat / organisaation tiedot': 'Egna / organisationens uppgifter',
      'Omat sivut -etusivu': 'Mina sidor-ingångssidan',
      'Organisaation tapahtumat': 'Organisationens händelser',
      'Oma sivu': 'Min sida',
      'Organisaation ilmoitukset': 'Organisationens annonser',
      'Kaikki ilmoitukset': 'Alla annonser',
      'Omat / Organisaation tiedot': 'Egna / Organisationens uppgifter',
      'Omat hakuvahdit': 'Mina bevakningar',
      'Sopimukset kunnan toissijaisesta jätehuoltopalvelusta':
        'Avtal om kommunal avfallshantering i andra hand',
      'Pyynnöt kunnan toissijaisesta jätehuoltopalvelusta':
        'Begäranden om kommunal avfallshantering i andra hand',
      'Kaikki pyynnöt': 'Alla begäranden',
      'Tiedot muusta kunnan toissijaisesta jätehuoltopalvelusta':
        'Uppgifter om annan kommunal avfallshantering i andra hand',
      'Ilmoita ja katsele tietoja': 'Annonsera och läs uppgifter',
      'Tiedot kunnan toissijaisesta jätehuoltopalvelusta':
        'Uppgifter om kommunal avfallshantering i andra hand',
      'Tiedot jätelaitosten sopimuksista ja hylätyistä pyynnöistä':
        'Information om avfallsanläggningarnas avtal och avvisade begäran',
      'Sopimukset/TSV-pyynnöt':
        'Avtal/Begäranden',
      'Katsele tietoja': 'Läs uppgifter',
      Näytä: 'Visa',
      aktiiviset: 'aktiva',
      sulkeutuneet: 'stängda',
      Ilmoitukset: 'Annonser',
      'Organisaationne kaikki ilmoitukset': 'Alla annonser från er organisation',
      Aktiiviset: 'Aktiva',
      'Tarjous hyväksytty': 'Offerten godkänd',
      Sulkeutuneet: 'Stängda',
      Suljettu: 'Stängd',
      'Jatkuva tuotanto': 'Kontinuerlig produktion',
      'Pyydä kunnan toissijaista jätehuoltopalvelua':
        'Begär kommunal avfallshantering i andra hand',
      Ilmoitus: 'Annons',
      Ilmoitettu: 'Annonserad',
      Vanhenee: 'Går ut',
      Vanhentunut: 'Gått ut',
      'Avaa valikko': 'Öppna meny',
      'Sulje valikko': 'Stäng meny',
      'Sulkeutuu pian': 'Stänger snart',
      'Ei pääsyoikeutta, kirjaudu sisään': 'Behörighet saknas, logga in',
      'Sivua ei löydy': 'Sidan hittas inte',
      'Tee tarjous': 'Lämna offert',
      'Tarjous on sitova.': 'Offerten är bindande.',
      'Vastaa ilmoitukseen': 'Svara på annons',
      'Viesti': 'Meddelande',
      Liitteet: 'Bilagor',
      Lähetä: 'Skicka',
      'Lähetys onnistui.': 'Meddelandet har sänts.',
      'Lähetys epäonnistui.': 'Sändningen misslyckades.',
      'Et voi tehdä tarjousta omaan ilmoitukseesi.':
        'Du kan inte lämna en offert om din egen annons.',
      'Et voi vastata omaan ilmoitukseesi.': 'Du kan svara på din egen annons.',
      'offer-reject-reason-0': 'Annan orsak',
      'Hyväksy tarjous': 'Godkänn offert',
      'Hylkää tarjous': 'Avslå offert',
      'Viesti tarjoajalle': 'Meddelade till offertgivaren',
      'Tarjouksen hylkäämisen syy(t)': 'Orsak(er) till avslag på offert',
      'offer-reject-reason-10':
        'Offerten stämmer inte överens med prioriteringsordningen i avfallslagen',
      'offer-reject-reason-20': 'Offerten motsvarar inte det som begärts',
      'offer-reject-reason-30': 'Offertgivaren har försummat lagstadgade skyldigheter',
      'offer-reject-reason-40': 'Tidpunkt för den erbjudna tjänsten',
      'offer-reject-reason-50': 'Kvaliteten på den erbjudna tjänsten',
      'offer-reject-reason-60': 'Långa transportsträckor',
      'offer-reject-reason-70': 'Priset på den erbjudna tjänsten',
      'Tarjouksen arviointi': 'Bedömning av offert',
      'Tarjous ilmoitukseen': 'Offert om annons',
      'Vastaus ilmoitukseen': 'Svar på annons',
      'Tarjous ilmoitukseesi': 'Offert om din annons',
      'Vastaus ilmoitukseesi': 'Svar på din annons',
      Tarjouksesi: 'Din offert',
      Vastauksesi: 'Ditt svar',
      Puh: 'Tfn',
      'Palvelun kuvaus': 'Beskrivning av tjänsten',
      'Tarjottava palvelu': 'Tjänst som erbjuds',
      'Tarkenteet tarjottavalle palvelulle': 'Specifikationer om tjänst som erbjuds',
      'Palvelun ajankohta ja kesto': 'Tjänstens tidpunkt och varaktighet',
      'Palvelun hinta': 'Tjänstens pris',
      'Hintaa koskeva lisätieto': 'Tilläggsuppgift om priset',
      'Palvelun ehdot': 'Tjänstens villkor',
      'Sijainti, jossa osaamista tai palvelua tarvitaan': 'Läge där kompetensen eller tjänsten behövs',
      'Kohteen nimi': 'Objektets namn',
      'Tarjouksen voimassaoloaika': 'Offertens giltighetstid',
      Voimassaoloaika: 'Giltighetstid',
      'Tarjoajan yhteystiedot': 'Offertgivarens kontaktuppgifter',
      'Lähettäjän yhteystiedot': 'Avsändarens kontaktuppgifter',
      'Rooli organisaatiossa': 'Roll inom organisationen',
      Puhelinnumero: 'Telefonnummer',
      Sähköpostiosoite: 'E-postadress',
      'Yrityksen tiedot': 'Uppgifter om företaget',
      'Ei liitteitä': 'Inga bilagor',
      'Hylkäyksen syy(t)': 'Orsak(er) till avslag',
      'Tarjouksen tila': 'Offertens status',
      Ilmoituksesi: 'Din annons',
      Takaisin: 'Tillbaka',
      'Lähettäjän jätteen vastaanottoon liittyvät luvat ja rekisteritiedot':
        'Avsändarens tillstånd och registeruppgifter om mottagning av avfall',
      'Tarjous hyväksytty.': 'Offert godkänd.',
      'Tarjouksen hyväksyminen epäonnistui.': 'Det gick inte att godkänna offerten.',
      'Tarjous hylätty.': 'Offerten avslagen.',
      'Tarjouksen hylkääminen epäonnistui.': 'Det gick inte att avslå offerten.',
      'Ladataan tarjousta': 'Laddar offert',
      'Tarjousta ei ole olemassa': 'Offerten finns inte',
      'Tarjoajan tiedot': 'Offertgivarens uppgifter',
      'Ilmoituksen sulkemisen jälkeen ilmoitusta ei voi enää muokata tai avata uudelleen, eikä sille voi pyytää kunnan toissijaista jätehuoltopalvelua. Haluatko varmasti sulkea ilmoituksen pysyvästi?':
        'Efter att annonsen har stängts kan den inte längre redigeras eller öppnas på nytt, och man kan då inte heller be om en kommunalavfallshanteringstjänst i andra hand. Är du säker på att du vill stänga annonsen permanent?',
      'Sulje ilmoitus': 'Stäng annons',
      'Muutokset menetetään, jos poistut sivulta.': 'Ändringarna sparas inte om du lämnar sidan.',
      'Lomakkeen täyttö on kesken. Haluatko varmasti poistua lomakesivulta ja hukata täyttämäsi tiedot?':
        'Ändringarna sparas inte om du lämnar sidan.', // TODO: exact translation missing
      'Ilmoitus luotu.': 'Annonsen skapades.',
      'Ilmoituksen luonti epäonnistui.': 'Det gick inte att skapa annonsen.',
      'Muutokset tallennettu.': 'Ändringarna sparades.',
      'Tallennus epäonnistui.': 'Det gick inte att spara.',
      'Ilmoituksen otsikko': 'Annonsens rubrik',
      'Liitteet ja kuvat': 'Bilagor och bilder',
      'Haettava palvelu ja sen kesto': 'Sökt tjänst och dess varaktighet',
      'Jätteen sijainti': 'Avfallets läge',
      'Ilmoituksen voimassaoloaika': 'Annonsens giltighetstid',
      'Yhteyshenkilön tiedot': 'Kontaktpersonens uppgifter',
      'Materiaalin sijainti': 'Materialets läge',
      'Kunnat tai maakunnat, joiden alueelta etsit materiaalia. Voit antaa tämän lisäksi myös osoitteen, jossa materiaalia tarvitaan.':
      'Kommuner eller landskap där du söker material. Du kan även ange adressen där materialet behövs.',
      'Valitse alueet': 'Välj områden',
      'Alueet, joilla palvelua tai osaamista tarvitaan': 'Områden där tjänsten eller kompetensen behövs',
      'Valitse koko Suomi': 'Välj hela Finland',
      'Muokkaa ilmoitusta': 'Redigera annons',
      'Kopioi uuden pohjaksi': 'Kopia som mall för en ny annons',
      huomaa_ilmoituksen_muokkaaminen:
        '<0>Obs!</0> Om annonsen redigeras kan giltighetstiden förlängas. En redigerad annons ska publiceras på Materialtorget och hållas oförändrad under minst 14 dygn för att kommunal avfallshantering i andra hand ska kunna begäras när det råder bristande utbud av andra tjänster.',
      'Lisää ilmoitus': 'Lägg till annons',
      'Et voi muokata toisen yrityksen ilmoitusta.':
        'Du kan inte redigera ett annat företags annonser.',
      'Et voi muokata suljettua ilmoitusta.': 'Du kan inte redigera en stängd annons.',
      'Lähetä ilmoitus': 'Skicka annons',
      'Julkaise ilmoitus': 'Publicera annonsen',
      'Tallenna ilmoitus': 'Spara annons',
      'Julkaise uudelleen': 'Publicera på nytt',
      'Takaisin ilmoitukseen': 'Tillbaka till annons',
      'Esikatsele ilmoitusta': 'Förhandsgranska annons',
      'Lisää ilmoituksia': 'Fler annonser',
      Ilmoitustyyppi: 'Annonstyp',
      'Etsin materiaalille vastaanottajaa': 'Jag söker en mottagare för material',
      'Onko materiaali jätettä?': 'Är materialet avfall? ',
      'Etsin hyödynnettäviä materiaaleja': 'Jag söker material att utnyttja',
      'Tarjoan jätehuolto- tai asiantuntijapalvelua':
        'Jag erbjuder en avfallshanterings- eller sakkunnigtjänst',
      'Etsin asiantuntijapalvelua tai osaamista':
        'Jag söker en experttjänst eller kompetens',
      'Näytä tarkemmat sijaintitiedot vain kirjautuneille käyttäjille.':
        'Visa närmare lägesinformation endast för användare som är inloggade på Materialtorget.',
      Palvelu: 'Tjänst',
      '(pakollinen, mikäli pyydetään TSV-palvelua)':
        '(obligatorisk, om avfallshantering i andra hand begärs)',
      'Ensisijaisesti haettu palvelu.': 'Tjänst som söks i första hand.',
      'Haettavan palvelun kuvaus': 'Beskrivning av tjänsten som söks',
      'Palvelun toivottu ajankohta': 'Önskad tidpunkt för tjänsten',
      'Hae ilmoituksia - Materiaalitori': 'Sök annonser – Materialtorget',
      'Hae, suodata ja selaa ilmoituksia tarpeidesi mukaan.':
        'Sök, filtrera och bläddra bland annonser efter dina behov.',
      'Useita ilmoituksia': 'Flera annonser',
      'Katso ilmoitus': 'Se annons',
      'Useita sijainteja': 'Flera platser',
      'Kirjaudu nähdäksesi ilmoittajan tiedot': 'Logga in för att se annonsörens uppgifter',
      'Hakutulokset': 'Sökresultat',
      'Ei hakutuloksia.': 'Inga sökresultat.',
      'Ei ilmoituksia.': 'Inga annonser.',
      'Ei aktiivisia ilmoituksia.': 'Inga aktiva annonser.',
      'Ei tapahtumia.': 'Inga händelser.',
      'Ei sopimuksia.': 'Inga avtal.',
      'Ei tietoja.': 'Inga uppgifter.',
      'Ei pyyntöjä.': 'Inga förfrågningar.',
      'Voit muuttaa tai rajata hakutuloksia hakukoneen kriteereillä.':
        'Du kan ändra eller begränsa sökresultaten med kriterier i sökmotorn.',
      'Tähän ilmoitukseen tehdyt tarjoukset': 'Offerter lämnade till denna annons',
      'Tähän ilmoitukseen tekemäsi tarjoukset': 'Offerter som du lämnat till denna annons',
      'Tähän ilmoitukseen jättämäsi vastaukset': 'Svar som du lämnat till denna annons',
      'Ilmoitukseesi saadut tarjoukset': 'Offerter som du fått om din annons',
      'Ilmoitukseesi saadut vastaukset': 'Svar som du fått på din annons',
      'Ei tarjouksia': 'Inga offerter',
      'Ei vastauksia': 'Inga svar',
      Lähettäjä: 'Avsändare',
      Päivämäärä: 'Datum',
      Tila: 'Status',
      Pyyntö: 'Begäran',
      Pyyntöpäivä: 'Datum för begäran',
      'Päivämäärän esittämisessä tapahtui virhe': 'Ett fel inträffade vid datumvisning',
      Ilmoittaja: 'Annonsör',
      'Kirjaudu sisään nähdäksesi ilmoittajan tiedot': 'Logga in för att se annonsörens uppgifter',
      'Tarjotaan jätettä': 'Avfall erbjudes',
      'Näytä koko ilmoitus': 'Visa hela annonsen',
      'Pienennä ilmoitus': 'Minimera annons',
      Luokittelu: 'Klassificering',
      'Pysyvä jäte': 'Inert avfall',
      'POP-jäte': 'POP avfall',
      TSV: 'Avfallshantering i andra hand',
      'Jäte menee TSV-prosessiin mikäli vastaanottajaa ei löydy':
        'Avfallet går till processen för avfallshantering i andra hand om en mottagare inte hittas',
      'Jäte ei mene TSV-prosessiin':
        'Avfallet går inte till processen för avfallshantering i andra hand',
      'Haettava palvelu': 'Tjänst som söks',
      'Ilmoitus voimassa': 'Annonsen giltig',
      'Ilmoitus luotu': 'Annonsen skapades',
      'Etsitään materiaalia': 'Material sökes',
      'Tarjotaan materiaalia': 'Material erbjudes',
      'Tarjotaan palveluja': 'Tjänst erbjudes',
      'Etsitään palveluja': 'Tjänst sökes',
      'Tarkennukset määrään': 'Precisering av mängd',
      Voimassa: 'I kraft',
      'Vastaanotetaan materiaalia': 'Material mottages',
      Sijainti: 'Plats',
      'Tarjotaan jätehuolto- tai asiantuntijapalvelua':
        'Avfallshanterings- eller sakkunnigtjänst erbjudes',
      'Tarjottu palvelu': 'Erbjuden tjänst',
      'Ladataan ilmoitusta': 'Laddar annonsen',
      'Ilmoitusta ei ole olemassa': 'Annonsen finns inte',
      'Hakuvahti tallennettu.': 'Bevakningen sparades.',
      'Hakuvahdin tallennus epäonnistui.': 'Det gick inte att spara bevakningen.',
      'Tallenna hakuvahti': 'Spara bevakning',
      'Nimeä haku': 'Namnge sökning',
      'nimi näkyy vahdin otsikkona hakuvahtien listauksissa': 'namnet visas som rubrik för vakten i listan över annonsvakter',
      'Hakuvahdit näkyvät valikossa hakukoneen alareunassa sekä listana Oma sivu -osion etusivulla.': 'Annonsvakterna syns i menyn i sökmotorns nedre kant och som en lista på första sidan i avsnittet Min sida.',
      Hakukriteerit: 'Sökkriterier',
      Hakusana: 'Sökord',
      'Ilmoituksen tyyppi': 'Typ av annons',
      'Materiaali tyyppi': 'Typ av material',
      Palvelut: 'Tjänster',
      Sijainnit: 'Platser',
      'Haluan viestin uusista hakuosumista sähköpostiini':
        'Jag vill få ett meddelande om nya sökträffar till min e-post',
      'Ei koskaan (tallennettu haku)': 'Aldrig (sparad sökning)',
      Heti: 'Genast',
      'Kerran päivässä': 'En gång per dag',
      'Kerran viikossa': 'En gång per vecka',
      'Lisää hakutuloksia': 'Fler sökresultat',
      'Hakuvahti poistettu.': 'Bevakningen togs bort.',
      'Hakuvahdin poisto epäonnistui.': 'Det gick inte att ta bort bevakningen.',
      Hakuvahdit: 'Bevakningar',
      'Valitse tallentamasi hakuvahti...': 'Välj bevakning som du sparat...',
      poista: 'ta bort',
      'Poistetaanko hakuvahti: ': 'Vill du ta bort bevakningen: ',
      'Sähköposti-ilmoitusten aikaväli': 'Tidsintervall för e-postaviseringar',
      'Ei tallennettuja hakuvahteja': 'Inga sparade bevakningar',
      'Hae ilmoituksia joissa...': 'Sök annonser där...',
      'offering-label': 'Erbjudes',
      'receiving-label': 'Sökes',
      'waste-label': 'avfall',
      'nonwaste-label': 'biflöde',
      'material-label': 'material',
      'wasteservices-label': 'avfallshanterings- och sakkunnigtjänst',
      'professionalservices-label': 'experttjänst eller kompetens',
      'offeringWaste-title': 'Avfall erbjudes',
      'Tarjotaan sivuvirtaa': 'biflöde erbjudes',
      'receivingMaterial-title': 'Material sökes',
      'offeringServices-title': 'Avfallshanterings- och sakkunnigtjänst erbjudes',
      'receivingServices-title': 'Experttjänst eller kompetens sökes',
      'includeClosedAndExpired-title': 'sök också från stängt annonser',
      'Poista hakuehto': 'Ta bort sökvillkor',
      'Valitse palvelu': 'Välj tjänst',
      'Tallenna hakuvahdiksi': 'Spara som bevakning',
      'Tilastoja ilmoituksista ja materiaaleista': 'Statistik om annonser och material',
      'Hae ilmoitustyypin perusteella': 'Sök baserat på typ av annons',
      'Vain jätteet': 'Endast avfall',
      'Vain muut materiaalit ja sivuvirrat': 'Endast övrigt material och biflöden',
      Kaikki: 'Alla',
      Jatkuva: 'Kontinuerlig',
      Ajankohta: 'Tidpunkt',
      'Valitse ajankohta': 'Välj tidpunkt',
      'Voimassa olevat': 'Gällande',
      'Hae tiedot': 'Sök uppgifter',
      Toistuvuus: 'Upprepning',
      'Ilmoituksia, kpl': 'Annonser, st.',
      'Palvelun tarkennus': 'Precisering av tjänsten',
      Katso: 'Se',
      'Tilastoja kunnan toissijaisesta jätehuoltopalvelusta tehdyistä sopimuksista':
        'Statistik över avtal om kommunal avfallshantering i andra hand',
      'Tarkastele tietoja kunnan toissijaisesta jätehuoltopalvelusta.':
        'Granska uppgifter om kommunal avfallshantering i andra hand.',
      'Tilastoja muusta kunnan toissijaisesta jätehuoltopalvelusta':
        'Statistik över annan kommunal avfallshantering i andra hand',
      'Hae tietoja valitsemillasi rajauksilla': 'Sök uppgifter med valda begränsningar',
      Jätelaitos: 'Avfallsanläggning',
      'Valitse jätelaitos': 'Välj avfallsanläggning',
      'Sopimuksen alkamisajankohta': 'Avtalets begynnelsetid',
      'Voimassa olevat sopimukset': 'Gällande avtal',
      Sopimustiedot: 'Avtalsuppgifter',
      'Palvelun hinta (€/tonnia jätettä)': 'Tjänstens pris (€/ton avfall)',
      'Sopimusten määrä': 'Antal avtal',
      'Sopimuksien määrä': 'Antal avtal',
      Raportointivuosi: 'Rapporteringsår',
      'Tietoja palvelusta': 'Uppgifter om tjänsten',
      'Määrä tonneissa': 'Mängd i ton',
      'Palvelun kokonaisarvo ja palvelua käyttäneiden asiakkaiden määrä':
        'Tjänstens totalvärde och antal kunder som använt tjänsten',
      'Palvelun kokonaisarvo (€)': 'Tjänstens totalvärde (€)',
      'Asiakkaiden määrä': 'Antal kunder',
      'Lähetä pyyntö': 'Skicka begäran',
      'Ei pääsyoikeutta': 'Behörighet saknas',
      'Järjestä jätelaitokset:': 'Ordna avfallsanläggningar:',
      'Valitse jätelaitos (pakollinen)': 'Välj avfallsanläggning (obligatorisk)',
      km: 'km',
      'Ilmoitukseen tulleet tarjoukset': 'Inkomna offerter till annons',
      'Tarjousten hylkäämisten perusteet': 'Grunder för avslag på offerter',
      'Lisätietoa (vapaaehtoinen)': 'Mer information (frivillig)',
      Yhteyshenkilö: 'Kontaktperson',
      'Pyydä kunnan toissijaista jätehuoltopalvelua (TSV-palvelu)':
        'Begär kommunal avfallshantering i andra hand (TSV-tjänst)',
      'TSV-pyyntö vastaanotettu!': 'Begäran om kommunal avfallshantering i andra hand mottagen!',
      'Pyyntö lähetetty.': 'Begäran skickades.',
      'Pyynnön lähetys epäonnistui.': 'Det gick inte att skicka begäran.',
      'Lähin ensin': 'Närmaste först',
      'Jätelaitoksen nimi A-Ö': 'Avfallsanläggningens namn A–Ö',
      'Kunnan nimi A-Ö': 'Kommunens namn A–Ö',
      'Ei oikeuksia nähdä tätä sivua.': 'Behörighet saknas för att visa denna sida.',
      'Ilmoitus, jota pyyntö koskee': 'Annons som begäran gäller',
      'Puh.': 'Tfn',
      Hylkäysperusteet: 'Grunder för avslag',
      'Peru pyyntö': 'Ångra begäran',
      'Tunnistaudu vahvasti käsitelläksesi pyyntöä':
        'Identifiera dig starkt för att handlägga begäran',
      'Tee sopimus Materiaalitorissa': 'Ingå avtal på Materialtorget',
      'Tuo tiedot tehdystä sopimuksesta': 'Hämta uppgifter om avtal som ingåtts',
      'Hylkää TSV-pyyntö': 'Avslå begäran om avfallshantering i andra hand',
      tuosopimus: 'hämta avtal',
      'Sopimustiedot tallennettu.': 'Avtalsuppgifterna sparades.',
      'Tapahtui virhe sopimuksen tallennuksessa.': 'Ett fel inträffade när avtalet sparades.',
      'Tunnistaudu vahvasti tehdäksesi sopimusluonnoksen':
        'Identifiera dig starkt för att göra ett utkast till avtal',
      'Sopimus kunnan toissijaisesta jätehuoltopalvelusta':
        'Avtal om kommunal avfallshantering i andra hand',
      '1. Sopimusosapuolet': '1. Avtalsparter',
      Palveluntarjoaja: 'Tjänsteleverantör',
      'Jätelaitoksen yhteystiedot': 'Avfallsanläggningens kontaktuppgifter',
      'Pyynnön esittäjä': 'Begäran framförs av',
      'Yrityksen nimi': 'Företagets namn',
      '2. Sopimuksen viitetiedot': '2. Avtalets referensuppgifter',
      Sopimusnumero: 'Avtalsnummer',
      'Sopimuksen nimi': 'Avtalets namn',
      Viitenumero: 'Referensnummer',
      '3. Sopimuksen kohde': '3. Avtalsobjekt',
      '4. Jätteen tiedot': '4. Uppgifter om avfallet',
      '5. Palvelukuvaus, ajankohta ja hinta': '5. Tjänstebeskrivning, tidpunkt och pris',
      'Valitse tarjottava palvelu': 'Välj tjänst som erbjuds',
      '€/tonni': '€/ton',
      'Ajankohta, jolloin palvelua annetaan': 'Tidpunkt då tjänsten tillhandahålls',
      'Lisätietoja palvelusta': 'Ytterligare information om tjänsten',
      '6. Sopimuksen voimassaolo': '6. Avtalets giltighet',
      'Sopimuksen voimassaoloaika': 'Avtalets giltighetstid',
      '7. Sopimusehdot': '7. Avtalsvillkor',
      Irtisanomisehto: 'Uppsägningsvillkor',
      'Muut sopimusehdot': 'Övriga avtalsvillkor',
      '8. Liitteet': '8. Bilagor',
      '9. Allekirjoitukset': '9. Underskrifter',
      'Allekirjoita ja lähetä hyväksyttäväksi': 'Underteckna och sänd för godkännande',
      'Tallenna luonnos': 'Spara förslag',
      'Valmis allekirjoitettavaksi' : 'Redo att skriva under',
      'Tallenna tiedot': 'Spara uppgifterna',
      'Sopimus hylätty.': 'Avtalet avslogs.',
      'Sopimuksen hylkääminen epäonnistui.': 'Det gick inte att avslå avtalet.',
      'Sopimus hyväksytty.': 'Avtalet godkändes.',
      'Sopimuksen hyväksyminen epäonnistui.': 'Det gick inte att godkänna avtalet.',
      'Tarkista sopimustiedot ja allekirjoita sopimus vahvasti tunnistautuneena.':
        'Kontrollera avtalsuppgifterna och underteckna avtalet när du identifierat dig starkt.',
      'Palvelun hinta €/tonni ': 'Tjänstens pris €/ton ',
      ' €/tonnia jätettä': ' €/ton avfall',
      'Ajankohta - palvelu tuotetaan välillä': 'Tidpunkt – tjänsten produceras mellan',
      'Palvelu alkaa: ': 'Tjänsten börjar: ',
      'Ei tiedossa.': 'Okänt.',
      'Palvelu päättyy: ': 'Tjänsten slutar: ',
      'Lisätietoja palvelusta:': 'Mer information om tjänsten:',
      'Sopimus on voimassa ': 'Avtalet gäller till ',
      ' asti': 'Till och med',
      'Irtisanomisehto:': 'Uppsägningsvillkor:',
      'Muut ehdot:': 'Övriga villkor:',
      'Hylkää sopimus': 'Avslå avtal',
      'Tunnistaudu vahvasti hyväksyäksesi sopimuksen':
        'Identifiera dig starkt för att godkänna avtalet',
      'Hyväksy ja allekirjoita sopimus': 'Godkänn och underteckna avtalet',
      'Jäteluokka: ': 'Avfallsklass: ',
      'Määrä: ': 'Mängd: ',
      'TSV-sopimukset': 'Avtal om avfallshantering i andra hand',
      Sopimus: 'Avtal',
      'Pyynnön lähettäjä': 'Avsändare för begäran',
      Päättymisaika: 'Sluttid',
      'Lisää sopimuksia': 'Flera avtal',
      'TSV-pyyntö': 'Begäran om kommunal avfallshantering i andra hand',
      'Saapuneet TSV-pyynnöt': 'Inkomna begäranden om kommunal avfallshantering i andra hand',
      Yritys: 'Företag',
      Saapunut: 'Inkommit',
      'Lisää pyyntöjä': 'Fler begäranden',
      'Ei TSV-pyyntöjä': 'Inga begäranden om kommunal avfallshantering i andra hand',
      'TSV-pyynnöt': 'Begäranden om kommunal avfallshantering i andra hand',
      'Hae jätteen mukaan': 'Sök enligt avfall',
      'Hae jätelaitoksen mukaan': 'Sök enligt avfallsanläggning',
      'Hae tilan mukaan': 'Sök enligt status',
      'Hae nimellä, y-tunnuksella ja muulla sisällöllä...':
        'Sök med namn, FO-nummer och annat innehåll...',
      Hae: 'Sök',
      'Pyyntö kunnan toissijaisesta jätehuoltopalvelusta (TSV-palvelu)':
        'Begäran om kommunal avfallshantering i andra hand (TSV-tjänst)',
      'Pyyntö jätetty': 'Begäran inlämnad',
      'Jätelaitos, jolle pyyntö osoitettu': 'Avfallsanläggning som begäran är riktad till',
      Lisätietoa: 'Mer information',
      'Pyynnön tila': 'Status för begäran',
      'Pyyntö peruttu.': 'Begäran ångrades.',
      'Pyynnön peruminen epäonnistui.': 'Det gick inte att ångra begäran.',
      'Pyyntö hylätty.': 'Begäran avslogs.',
      'Pyynnön hylkääminen epäonnistui.': 'Det gick inte att avslå begäran.',
      'Kirjaudu ulos': 'Logga ut',
      Kirjaudu: 'Logga in',
      Rekisteröidy: 'Registrera dig',
      'Poista rivi': 'Ta bort rad',
      'Haluatko varmasti poistaa rivin?': 'Är du säker på att du vill ta bort raden?',
      'Tallennus onnistui.': 'Lagringen lyckades.',
      Virhe: 'Fel',
      'tulee olla nollaa suurempi kokonaisluku': 'ska vara ett heltal större än noll',
      Kokonaisarvo: 'Totalvärde',
      'tulee olla nollaa suurempi luku': 'ska vara ett tal större än noll',
      'tulee olla valittu': 'ska vara valt',
      'Valitse vuosi, jota koskevia tietoja haluat ilmoittaa tai katsella':
        'Välj det år som du vill anmäla eller granska uppgifter om',
      'Lataa tiedot': 'Ladda uppgifter',
      'Asiakkaiden kokonaismäärä:': 'Totalt antal kunder:',
      'Kokonaisarvo (€):': 'Totalvärde (€):',
      'Annettu palvelu': 'Tjänst som tillhandahållits',
      'Lisää rivi': 'Lägg till rad',
      'content-type': 'content-type',
      Tervetuloa: 'Välkommen',
      'event-link-RequestForOfferCreated': 'Ny annonsen skapad',
      'event-link-RequestForOfferWithHilmaCreated': 'Utkastet till begäran om kommunal avfallshantering i andra hand har sparats',
      'event-link-RequestForOfferWithHilmaUpdated': 'Utkastet till begäran om kommunal avfallshantering i andra hand har redigerats',
      'event-link-OfferReceived': 'Offerten har mottagits',
      'event-link-OfferCreated': 'Offerten skapades',
      'event-link-answer-OfferReceived': 'Ett svar på din annons har inkommit',
      'event-link-answer-OfferCreated': 'Ett svar har lämnats på annonsen',
      'event-link-OfferAcceptedByCustomer': 'Kunden har godkänt offerten',
      'event-link-OfferAccepted': 'Offerten godkänd',
      'event-link-TsvRequestReceived': 'Begäran om kommunal avfallshantering i andra hand mottogs',
      'event-link-TsvRequestSent': 'Begäran om kommunal avfallshantering i andra hand sändes',
      'event-link-TsvAccepted': 'Begäran om kommunal avfallshantering i andra hand godkändes',
      'event-link-TsvAcceptedByFacility':
        'Begäran om kommunal avfallshantering i andra hand godkändes',
      'event-link-UserAuthorizedToCompany': 'En ny användare har anslutit sig till organisationen',
      'event-link-UserAuthorizationDenied': 'Användarens begäran om anslutning avslagen',
      'event-link-CompanyDetailsUpdated': 'Organisationens uppgifter uppdaterades',
      'event-link-CompanyRegistrationAdded': 'Tillstånd / registeranteckning lades till',
      'event-link-CompanyRegistrationRemoved': 'Tillstånd / registeranteckning togs bort',
      'event-link-RequestForOfferStatusChanged': 'Annonsens status förändrades',
      'event-link-RequestForOfferUpdated': 'Annonsens status uppdaterades',
      'event-link-OfferRejectedByCustomer': 'Offerten avslogs',
      'event-link-OfferRejected': 'Offerten avslogs',
      'event-link-WasteBatchReported': 'Rapport om litet parti redigerades',
      'event-link-WasteBatchReportRowDeleted': 'Rad borttagen om rapport om litet parti',
      'event-link-TsvContractDraftMade':
        'Utkast till avtal om kommunal avfallshantering i andra hand uppgjordes',
      'event-link-TsvContractDraftMadeByFacility':
        'Utkast till avtal om kommunal avfallshantering i andra hand mottogs',
      'event-link-TsvRequestCanceled': 'Begäran om kommunal avfallshantering i andra hand ångrades',
      'event-link-TsvRequestCanceledByClient':
        'Begäran om kommunal avfallshantering i andra hand ångrades',
      'event-link-TsvRequestRejected': 'Begäran om kommunal avfallshantering i andra hand avslogs',
      'event-link-TsvRequestRejectedByFacility':
        'Begäran om kommunal avfallshantering i andra hand avslogs',
      'event-link-TsvContractAccepted': 'Avtal om kommunal avfallshantering i andra hand godkändes',
      'event-link-TsvContractAcceptedByClient':
        'Avtal om kommunal avfallshantering i andra hand godkändes',
      'event-link-TsvContractRejected': 'Avtal om kommunal avfallshantering i andra hand avslogs',
      'event-link-TsvContractRejectedByClient':
        'Avtal om kommunal avfallshantering i andra hand avslogs',
      'event-link-TsvContractTerminated': 'Avtal om kommunal avfallshantering i andra hand hävdes',
      'event-link-TsvContractTerminatedByFacility':
        'Avtal om kommunal avfallshantering i andra hand hävdes',
      'event-link-TsvContractImportedByFacility':
        'Avtal om kommunal avfallshantering i andra hand hämtades',
      'event-link-TsvContractImported': 'Avtal om kommunal avfallshantering i andra hand hämtades',
      'event-description-UserAuthorizationDenied': '',
      'event-description-RequestForOfferCreated': '',
      'event-description-RequestForOfferWithHilmaCreated': '',
      'event-description-RequestForOfferWithHilmaUpdated': '',
      'event-description-OfferReceived': '',
      'event-description-OfferCreated': '',
      'event-description-OfferAcceptedByCustomer': '',
      'event-description-OfferAccepted': '',
      'event-description-TsvRequestReceived': '',
      'event-description-TsvRequestSent': '',
      'event-description-TsvAccepted': '',
      'event-description-TsvAcceptedByFacility': '',
      'event-description-UserAuthorizedToCompany': '',
      'event-description-CompanyDetailsUpdated': '',
      'event-description-CompanyRegistrationAdded': '',
      'event-description-CompanyRegistrationRemoved': '',
      'event-description-RequestForOfferStatusChanged': '',
      'event-description-RequestForOfferUpdated': '',
      'event-description-OfferRejectedByCustomer': '',
      'event-description-OfferRejected': '',
      'event-description-WasteBatchReported': '',
      'event-description-WasteBatchReportRowDeleted': '',
      'event-description-TsvContractDraftMade': '',
      'event-description-TsvContractDraftMadeByFacility': '',
      'event-description-TsvRequestCanceled': '',
      'event-description-TsvRequestCanceledByClient': '',
      'event-description-TsvRequestRejected': '',
      'event-description-TsvRequestRejectedByFacility': '',
      'event-description-TsvContractAccepted': '',
      'event-description-TsvContractAcceptedByClient': '',
      'event-description-TsvContractRejected': '',
      'event-description-TsvContractRejectedByClient': '',
      'event-description-TsvContractTerminated': '',
      'event-description-TsvContractTerminatedByFacility': '',
      'event-description-TsvContractImported': '',
      'event-description-TsvContractImportedByFacility': '',
      'saved-search-freq-0': 'ingen e-postavisering',
      'saved-search-freq-100': 'avisering genast till e-post',
      'saved-search-freq-200': 'e-postsammandrag dagligen',
      'saved-search-freq-300': 'e-postsammandrag veckovis',
      'offer-state-offerMade': 'öppen',
      'offer-state-offerRejected': 'avslagen',
      'offer-state-offerAccepted': 'godkänd',
      'tsv-state-tsv-requested': 'Väntar på handläggning',
      'tsv-state-tsv-processing': 'Under handläggning',
      'tsv-state-tsv-canceled': 'Ångrad',
      'tsv-state-tsv-rejected': 'Avslagen',
      'tsv-state-tsv-contract-sketch': 'Förslag',
      'tsv-state-tsv-contract-sketch-ready': 'Väntar på den kommunala avfallsanläggningens underskrift',
      'tsv-state-tsv-contract-rejected': 'Avtalet avslogs',
      'tsv-state-tsv-contract-draft': 'Avtalet godkänns',
      'tsv-state-tsv-contract': 'Avtalet ingicks',
      'tsv-state-tsv-contract-terminated': 'Avtalet hävdes',
      'tsv-state-search-tsv-canceled': 'Begäran har återkallats av den som gjorde begäran',
      'tsv-state-search-tsv-rejected': 'Begäran avslogs av avfallsanläggningen',
      'tsv-state-search-tsv-contract-draft': 'Avtalet godkänns',
      'tsv-state-search-tsv-contract': 'Avtalet ingicks',
      'tsv-state-search-tsv-contract-terminated': 'Avtalet hävdes',
      'tsv-state-search-tsv-requested': 'Väntar på handläggning',
      'tsv-state-search-tsv-processing': 'Under handläggning',
      'tsv-state-imported-contract': 'Avtalsuppgifter hämtades',
      'tsv-reject-reason-waste-quantity-or-quality':
        'Avfallets mängd eller beskaffenhet är tekniskt olämplig för det befintliga avfallshanteringssystemet',
      'tsv-reject-reason-capacity-insufficient': 'Avfallshanteringskapaciteten är otillräcklig',
      'tsv-reject-reason-other': 'Annan orsak',
      kuljetus: 'Transport',
      kasittely: 'Behandling',
      esikasittely: 'Förbehandling',
      uudelleenkayton_valmistelu: 'Förberedelse för återanvändning',
      kierratys: 'Återvinning',
      hyodyntaminen_maantaytossa_tai_maisemoinnissa:
        'Utnyttjande vid markfyllning eller landskapsanpassning',
      hyodyntaminen_maa_tai_infrarakentamisessa:
        'Utnyttjande vid schaktning eller byggande av infrastruktur',
      hyodyntaminen_maanviljelyksessa_tai_viherrakentamisessa:
        'Utnyttjande vid jordbruk eller grönområdesbyggande',
      muu_hyodyntaminen_materiaalina: 'Annat utnyttjande som material',
      polttoaineen_valmistaminen: 'Bränsletillverkning',
      polttaminen_jatteenpolttolaitoksessa_tai_rinnakkaispolttolaitoksessa:
        'Förbränning i avfallsförbränningsanläggning eller samförbränningsanläggning',
      polttaminen_vaarallisen_jatteen_polttolaitoksessa:
        'Förbränning i förbränningsanläggning för farligt avfall',
      sijoittaminen_pysyvan_tai_tavanomaisen_jatteen_kaatopaikalle:
        'Deponering på avstjälpningsplats för inert eller vanligt avfall',
      sijoittaminen_vaarallisen_jatteen_kaatopaikalle:
        'Deponering på avstjälpningsplats för farligt avfall',
      sijoittaminen_maankaatopaikalle: 'Deponering på avstjälpningsplats för marksubstanser',
      kuljetus_kasittely: 'Transport och behandling',
      kiinteiston_kokonaispalvelu: 'Totaltjänst för fastighet',
      varastointi: 'Lagring',
      asiantuntijapalvelu: 'Sakkunnigtjänster och kunnanden',
      analyysipalvelu: 'Analystjänst',
      muu_palvelu: 'Annan tjänst',

      biotalous_ja_biomateriaalit: 'Bioekonomi och biomaterial',
      energia_uusiutuva_energia: 'Energi (förnybar energi, energieffektivitet)',
      materiaali_ja_resurssitehokkuus: 'Material- och resurseffektivitet',              
      jatehuolto: 'Avfallshantering',
      jatevesien_kasittely: 'Behandling av avloppsvatten ',
      kiertotalous: 'Cirkulär ekonomi',
      liiketoiminnan_kehittaminen_liiketoimintamallit: 'Affärsutveckling, affärsmodeller',
      mallinnus_tiedonhallinta: 'Modellering, datahantering',
      monitorointi_vaikutusten_arviointi: 'Övervakning, konsekvensanalys',
      materiaalit: 'Material',
      pakkaukset: 'Förpackningar ',
      rakentamisen_kiertotalous: 'Cirkulära ekonomi inom byggandet',
      rakennusjatteet_ja_purkumateriaalit: 'Byggavfall och rivningsmaterial',
      maa_ja_infrarakentaminen: 'Byggande av mark och infrastruktur',
      lainsaadanto: 'Lagstiftning',
      rahoitus: 'Finansiering',
      kiertotalous_hankinnoissa: 'Cirkulär ekonomi i upphandlingar ',
      suunnittelu: 'Planering och design',
      muut: 'Övrigt',
      endosulfaani: 'Endosulfan',
      heksaklooributadieeni: 'Hexaklorbutadien',
      polyklooratut_naftaleenit: 'Polyklorerade naftalene',
      alkaanit: 'Alkaner, C10–C13, klorerade (klorparaffiner med kort kolkedja) (SCCP)',
      tetrabromidifenyylieetteri: 'Tetrabromdifenyleter C₁₂H₆Br₄O',
      pentabromidifenyylieetteri: 'Pentabromdifenyleter C₁₂H₅Br₅O',
      heksabromidifenyylieetteri: 'Hexabromdifenyleter C₁₂H₄Br₆O',
      heptabromidifenyylieetteri: 'Heptabromdifenyleter C₁₂H₃Br₇O',
      dekabromidifenyylieetteri: 'Dekabromdifenyleter C₁₂Br₁₀O',
      perfluorioktaanisulfonihappo_ja_sen_johdannaiset: 'Perfluoroktansulfon-syra och dess derivat (PFOS) C₈F₁₇SO₂X',
      polyklooratut_dibentso_para_dioksiinit_ja_dibentsofuraanit: 'Polyklorerade dibenso-p-dioxiner och dibensofuraner (PCDD/PCDF)',
      ddt: 'DDT (1,1,1-triklor-2,2-bis(4-klorfenyl)etan)',
      klordaani: 'Klordan',
      heksakloorisykloheksaanit_mukaan_lukien_lindaani: 'Hexaklorcyklohexan, inbegripet lindan',
      dieldriini: 'Dieldrin',
      endriini: 'Endrin',
      heptakloori: 'Heptaklor',
      heksaklooribentseeni: 'Hexaklorbensen',
      klordekoni: 'Klordekon',
      aldriini: 'Aldrin',
      pentaklooribentseeni: 'Pentaklorbensen',
      polyklooratut_bifenyylit: 'Polyklorerade bifenyler (PCB)',
      mireksi: 'Mirex',
      toksafeeni: 'Toxafen',
      heksabromibifenyyli: 'Hexabrombifenyl',
      heksabromisyklododekaani: 'Hexabromcyklododekan',
      'tuhkalannoitteena_maa-_ja_puutarhataloudessa': 'Gödselmedel av aska inom jordbruk och trädgårdsodling',
      'tuhkalannoitteena_maisemoinnissa_ja_viherrakentamisessa': 'Gödselmedel av aska inom landskapsarkitektur och anläggande av grönområden',
      'tuhkalannoitteena_metsataloudessa': 'Gödselmedel av aska inom skogsbruket',
      'tuhka_maarakentamisessa_vaylalla_peitettyna_rakenteena': 'Användning i markbyggnad av vägar som en täckt struktur',
      'tuhka_maarakentamisessa_vaylalla_paallystettyna_rakenteena': 'Användning i markbyggnad av vägar som en ytbehandlad struktur',
      'tuhka_maarakentamisessa_kenttana_peitettyna_rakenteena': 'Aska som en täckt struktur i markbyggnad för områden',
      'tuhka_maarakentamisessa_kenttana_paallystettyna_rakenteena': 'Aska som en ytbehandlad struktur i markbyggnad för områden',
      'tuhka_maarakentamisessa_vallina_peitettyna_rakenteena': 'Aska som en täckt struktur i markbyggnad för vallar',
      'tuhka_maarakentamisessa_teollisuus-_ja_varastorakennuksen_pohjarakenteena': 'Aska som en ytbehandlad struktur i markbyggnad för vallar',
      'tuhka_maarakentamisessa_tuhkamursketiessa': 'Aska i form av krossaska i markbyggnad av väg',
      muovi: 'Plast',
      metalli: 'Metall',
      lasi: 'Glas',
      puu: 'Trä',
      paperi: 'Papper',
      kartonki: 'Kartong',
      kipsi: 'Gips',
      betoni: 'Betong',
      tiilet_ja_keramiikka: 'Tegel och keramik',
      keramiikka: 'Keramik',
      eristeet: 'Isoleringsmaterial',
      asfaltti: 'Asfalt',
      maa_ainekset: 'Marksubstanser',
      tekstiili: 'Textil',
      liete: 'Slam',
      tuhka_ja_kuona: 'Aska och slagg',
      orgaaninen_jate: 'Organiskt avfall',
      sekalainen_jate: 'Blandat avfall',
      orgaaninen_sivuvirta: 'Organiskt biflöde',
      teollisuuden_ja_prosessien_jatteet: 'Industri- och processavfall',
      teollisuuden_ja_prosessien_sivuvirta: 'Industri- och processbiflöde',
      muu_sivuvirta: 'Annat biflöde',
      kumi: 'Gummi',
      tuhka: 'Aska',
      kuona: 'Slagg',
      asbesti: 'Asbest',
      asfaltti_ja_bitumi: 'Asfalt och bitumen',
      lanta: 'Gödsel',
      elainperainen_orgaaninen_materiaali_ei_lanta:
        'Animaliskt organiskt material (exklusive gödsel)',
      kasviperainen_orgaaninen_materiaali: 'Vegetabiliskt organiskt material',
      polyt_ja_sakat: 'Damm och fällning',
      epakurantit_tuotteet: 'Okuranta produkter',
      katalyytit: 'Katalysatorer',
      muu_teollisuusjate: 'Annat industriavfall',
      oljy_emulsio_ja_muut_oljyiset_jatteet: 'Olja, emulsion och annat oljeavfall',
      akut_ja_paristot: 'Ackumulatorer och batterier',
      renkaat: 'Däck',
      sahko_ja_elektroniikkaromu: 'El- och elektronikskrot',
      sekalainen_rakennus_ja_purkujate:
        'Blandat byggnads- och rivningsavfall (inklusive energiavfall)',
      sekalainen_yhdyskunta_jate: 'Blandat kommunalt avfall (inklusive energiavfall)',
      jatepolttoaine: 'Avfallsbränsle (SRF/RDF)',
      biojate: 'Bioavfall',
      maalit_ja_lakat: 'Färg och lack',
      laakkeet: 'Läkemedel',
      kemikaalit: 'Kemikalier (exklusive läkemedel)',
      mekaanisen_kasittelyn_rejektit: 'Rejekt från mekanisk behandling',
      liete_pesu_ja_jatevedet: 'Slam samt tvätt- och avloppsvatten',
      kayttokelvottomat_suurikokoiset_esineet: 'Obrukbara skrymmande föremål',
      muu_jate: 'Övrigt avfall',
      elintarvikesivuvirta: 'Livsmedelsbiflöde',
      kayttokelpoiset_huonekalut: 'Brukbara möbler',
      rakennusosat: 'Byggnadsdelar',
      rakentaminen_ja_purkaminen: 'Byggande och rivning',
      jate_vesi_ja_jatevesihuolto: 'Avfallshantering, vatten- och avloppsvattenförsörjning',
      kaivannaisteollisuus: 'Mineralutvinningsindustri',
      maa_metsa_ja_kalatalous: 'Jord- och skogsbruk och fiskerinäring',
      puu_paperi_ja_kartonkiteollisuus: 'Trä-, pappers- och kartongindustri',
      nahka_turkis_ja_tekstiiliteollisuus: 'Läder-, päls- och textilindustri',
      oljynjalostus_ja_muu_polttoaineen_valmistus: 'Oljeraffinering och övrig bränsletillverkning',
      kemianteollisuus: 'Kemisk industri',
      metalliteollisuus: 'Metallindustri',
      energiateollisuus_ja_muut_termiset_prosessit: 'Energiindustri och andra termiska processer',
      muu_teollisuus: 'Annan industri',
      tukku_ja_vahittaiskauppa: 'Parti- och detaljhandel',
      ravitsemus_ja_majoituspalvelut: 'Restaurang- och hotelltjänster',
      terveydenhuolto: 'Hälso- och sjukvård',
      kotitaloudet_toimistot_koulut_paivakodit_ja_vastaavat:
        'Privathushåll, kontor, skolor, daghem och motsvarande',
      muu_toimiala: 'Annan bransch',
      elintarviketeollisuus: 'Livsmedelsindustri',
      petrokemianteollisuus: 'Petrokemisk industri',
      valokuvateollisuus: 'Fotografiindustri',
      energiantuotanto: 'Energiproduktion',
      etusivu_faq_kehote:
        'Läs dessa och många andra centrala frågor och svar på sidan <2>Frågor och svar</2>.',
      etusivu_ohjeet_kehote: 'Läs <1>Materialtorgets anvisningar.</1>',
      etusivu_materiaalit_linkki: 'Mer information om material som återvinns på Materialtorget och anknutna tjänster finns <1>här</1>.',
      etusivu_pilot_banner:
        'Pilotfasen fortsätter och tjänsten vidareutvecklas. Nästa uppdatering kommer i augusti, då det blir möjligt att vid behov gå vidare för att begära kommunal avfallshantering i andra hand och ingå avtal om det. Vi har fått mycket feedback och beaktat det under utvecklingsarbetet. Vi tar fortfarande gärna emot feedback med denna <1>feedbackblankett</1>. Telefonstödet på nummer (09) 6122 5080 är på semester 8–26.7 och under denna tid kan svaret dröja längre även på e-postmeddelanden som kommit till kundstödet.',
      // ' ”<18>Etsin asiantuntijapalvelua tai osaamista</18>” -ilmoituksella voit etsiä asiantuntijoita esimerkiksi tutkimus- tai kehityshankkeiden toteutukseen tai vaikka asiantuntijapuheenvuoron pitäjäksi. Ilmoituksessa valitaan valikosta ne osaamisalueet, joita haetaan ja kuvataan vapaana tekstinä ja se, mitä haetaan. Tarvittaessa haettavaa asiaa tarkennetaan liitteiden avulla. \n\nHuom! Jätteen vastaanottaminen edellyttää jätelain 29 §:n mukaista hyväksyntää.',
      // ' ”Med annonsen <18>Jag söker en experttjänst eller kompetens</18>” kan du söka experter till exempel för att genomföra forsknings- eller utvecklingsprojekt eller till exempel för att hålla ett expertanförande. I annonsen väljs i menyn de kompetensområden, som söks och som beskrivs som fri text, och det som söks. Vid behov preciseras ärendet med hjälp av bilagor.\n\nObs! Mottagning av avfall förutsätter ett sådant godkännande som avses i 29 § i avfallslagen.',
      ilmoitussivun_ilmoitus_tyypit:
        'Välj rätt typ av annons.\n\nVälj först ”<4>Jag söker en mottagare för material</4>”, om du annonserar tillgängligt avfall eller annat material, som ett produktionsbiflöde. Välj sedan om materialet som du annonserar ut är avfall eller något annat material. Om det är frågan om avfall, kan du också fastställa i annonsen vilket slags tjänst du söker för avfallet. \n\n  Välj ”<9>Jag söker material att utnyttja</9>”, om du anmäler din villighet att ta emot material. Materialet kan vara avfall eller ett biflöde. \n\n Om du snarare vill erbjuda en avfallshanteringstjänst, välj ”<14>Jag erbjuder en avfallshanterings- eller sakkunnigtjänst</14>”. Då kan du annonsera om en behandlings- eller transporttjänst, analystjänst eller andra sakkunnigtjänster i anslutning till material som du tillhandahåller.\n\n”Med annonsen <18>Jag söker en experttjänst eller kompetens</18>” kan du söka experter till exempel för att genomföra forsknings- eller utvecklingsprojekt eller till exempel för att hålla ett expertanförande. I annonsen väljs i menyn de kompetensområden, som söks och som beskrivs som fri text, och det som söks. Vid behov preciseras ärendet med hjälp av bilagor.\n\nObs! Mottagning av avfall förutsätter ett sådant godkännande som avses i 29 § i avfallslagen.',
      ilmoitussivun_ilmoitus_tyyppi_tarjoanmateriaalia:
        'Du erbjuder <1>avfall eller annat material</1>, såsom biflöde från produktionen. Välj sedan om materialet som du annonserar ut är avfall eller något annat material. Om det är frågan om avfall, kan du också fastställa i annonsen vilket slags tjänst du söker för avfallet.',
      ilmoitussivun_ilmoitus_tyyppi_vastaanotanmateriaalia: 'Du vill <1>ta emot material</1>. Materialet kan vara avfall eller biflöde.',
      ilmoitussivun_ilmoitus_tyyppi_tarjoanpalveluita: 'Om vill <1>Om du vill erbjuda en avfallshanterings- eller experttjänst</1>, välj följande. Då kan du annonsera om en behandlings- eller transporttjänst, analystjänst eller andra sakkunnigtjänster i anslutning till material som du tillhandahåller.',
      ilmoitussivun_ilmoitus_tyyppi_etsinpalveluita: 'Om du söker <1>experttjänster eller kompetens</1>, välj följande. Du söka experter till exempel för att genomföra forsknings- eller utvecklingsprojekt eller till exempel för att hålla ett expertanförande. I menyn väljs önskade kompetensområden för annonsen och de kan preciseras med text och bilagor',
        // ' ... \n\n  Välj ”<9>Jag söker material att utnyttja</9>”, om du anmäler din villighet att ta emot material. Materialet kan vara avfall eller ett biflöde. \n\n Om du snarare vill erbjuda en avfallshanteringstjänst, välj ”<14>Jag erbjuder en avfallshanterings- eller sakkunnigtjänst</14>”. Då kan du annonsera om en behandlings- eller transporttjänst, analystjänst eller andra sakkunnigtjänster i anslutning till material som du tillhandahåller.\n\n”Med annonsen <18>Jag söker en experttjänst eller kompetens</18>” kan du söka experter till exempel för att genomföra forsknings- eller utvecklingsprojekt eller till exempel för att hålla ett expertanförande. I annonsen väljs i menyn de kompetensområden, som söks och som beskrivs som fri text, och det som söks. Vid behov preciseras ärendet med hjälp av bilagor.\n\nObs! Mottagning av avfall förutsätter ett sådant godkännande som avses i 29 § i avfallslagen.',        
      
      ilmoitussivun_tsv:
        'Om du trots annonsen inte hittar en mottagare för ditt material, kan du begära om en avfallshanteringstjänst av en kommunal avfallsanläggning (kommunal avfallshantering i andra hand). Om du väljer detta, uppfyller den annonsmall som du ska fylla i de krav som ställs på annonsen i avfallslagstiftningen (bl.a. en giltighetstid på 14 dygn för annonsen).\n\nMer information om <1>kommunal avfallshantering i andra hand</1>.',
      tsv_pyynnon_katselusivun_painikkeiden_ohje:
        'En kommunal avfallsanläggning kan vägra tillhandahålla tjänsten, ifall avfallet till sin mängd och beskaffenhet inte tekniskt lämpar sig för det kommunala avfallshanteringssystemet eller om kommunen saknar nödvändig kapacitet för att ta emot avfallet. I sådana fall ska begäran avslås och motiveringar till avslaget ska läggs till. Den som är missnöjd med att begäran avslagits ska vid behov hänvisas att föra ärendet till den kommunala avfallshanteringsmyndigheten för avgörande.\n\nEnligt 33 §:n i avfallslagen ska kommunen ingå ett avtal med avfallsinnehavaren om kommunal avfallshantering i andra hand när värdet på avfallshanteringen är minst 2 000 euro per år. Avtalsparterna är den kommunala avfallsanläggningen och avfallsinnehavaren som begär hanteringen eller ett företag som tillhandahåller avfallshantering till denne i enlighet med avfallslagen. Avtalet får gälla i högst tre år i sänder och parterna kan säga upp det efter den uppsägningstid som anges i avtalet.\n\nAvtalet kan göras direkt på Materialtorget genom att utnyttja den färdig avtalsmall genom att välja ”Ingå avtal på Materialtorget”. I detta fall öppnas avtalsmallen och den kan ifyllas. Till slut skickas utkastet till avtal och uppgift om att begäran godkänts till den som gjort begäran. Avtalet träder i kraft när den som gjort begäran godkänner avtalet.\n\nAlternativt kan avtalet ingås utanför Materialtorget. Avtalet ska innehålla uppgifter om avtalsparterna, avfallet och var det är beläget samt tjänsten och dess pris. <10>Dessa uppgifter ska föras in till Materialtorget senast 14 dagar efter att avtalet gjorts.</10> Uppgifterna hämtas genom att välja ”Hämta uppgifter om avtal som ingåtts”.',
      'Pyyntö kunnan toissijaisesta jätehuoltopalvelusta osoitetaan ensisijaisesti lähimmälle jätehuoltoa hoitavalle kunnan jätelaitokselle. Pyyntö voidaan osoittaa myös kauempana sijaitsevalle jätelaitokselle, mutta tästä aiheutuva kuljetusmatka kustannuksineen on ollut syytä huomioida myös ilmoitukseen mahdollisesti tulleita tarjouksia arvioitaessa. Mikäli valittu jätelaitos ei pysty tarjoamaan pyydettyä palvelua, pyyntö on mahdollista osoittaa toiselle jätelaitokselle. Kunnan jätelaitoksen on mahdollista kieltäytyä palvelun tarjoamisesta, mikäli jäte ei määrältään tai laadultaan sovellu teknisesti kunnan jätehuoltojärjestelmään tai kunnalla ei ole tarvittavaa kapasiteettia jätteen vastaanottamiseksi.':
        'Begäran om kommunal avfallshantering i andra hand riktas i första hand till avfallsanläggningen i den närmaste kommunen med avfallshantering. Begäran kan även riktas till en avfallsanläggning belägen på större avstånd, men den transport med kostnader som förorsakas av detta har man haft anledning att beakta även vid bedömningen av eventuella mottagna anbud för annonsen. Om den valda avfallsanläggningen inte kan erbjuda den begärda tjänsten kan begäran riktas till en annan avfallsanläggning. En kommunal avfallsanläggning kan vägra tillhandahålla tjänsten, ifall avfallet till sin mängd och beskaffenhet inte tekniskt lämpar sig för det kommunala avfallshanteringssystemet eller om kommunen saknar nödvändig kapacitet för att ta emot avfallet.',
      'Pyyntö kunnan toissijaisesta jätehuoltopalvelusta osoitetaan ensisijaisesti lähimmälle jätehuoltoa hoitavalle kunnan jätelaitokselle. Mikäli valittu jätelaitos ei pysty tarjoamaan pyydettyä palvelua, pyyntö on mahdollista osoittaa toiselle jätelaitokselle. Kunnan jätelaitoksen on mahdollista kieltäytyä palvelun tarjoamisesta, mikäli jäte ei määrältään tai laadultaan sovellu teknisesti kunnan jätehuoltojärjestelmään tai kunnalla ei ole tarvittavaa kapasiteettia jätteen vastaanottamiseksi.':
      'Begäran om kommunal avfallshantering i andra hand riktas i första hand till avfallsanläggningen i den närmaste kommunen med avfallshantering. Om den valda avfallsanläggningen inte kan erbjuda den begärda tjänsten kan begäran riktas till en annan avfallsanläggning. En kommunal avfallsanläggning kan vägra tillhandahålla tjänsten, ifall avfallet till sin mängd och beskaffenhet inte tekniskt lämpar sig för det kommunala avfallshanteringssystemet eller om kommunen saknar nödvändig kapacitet för att ta emot avfallet.',      
      jateilmoituksen_luontisivun_jate:
        'Term som beskriver typen av avfall eller materialtypen så väl som möjligt. Avgränsa sökningen utifrån denna klassificering av avfall.\n\nDu kan endast välja en typ av avfall till en annons. Om du sätter in en annons om blandat avfall, ange närmare vad avfallet består av under punkten ”Beskrivning av avfallet”.\n\nObs!\n\nAvfall som omfattas av producentansvar (däck, fordon, elektrisk och elektronisk utrustning, batterier och ackumulatorer, papper, förpackningar) bör i regel föras till en mottagningsplats som godkänts av producenten.\n\nKommunen ansvarar för kommunalt avfall från boende och från kommunens förvaltnings- och serviceverksamhet, vilket innebär att kommunen ordnar avfallshanteringen för detta avfall.\n\nMer information om ansvaret för avfallshanteringen\n<17>https://www.ymparisto.fi/sv-FI/Konsumtion_och_produktion/Avfall_och_avfallshantering/Avfallshantering__vem_ansvarar_for_och_ordnar_den</17>',
      m2: 'm²',
      m3: 'm³',
      kg: 'kg',
      m: 'm',
      t: 't',
      kpl: 'st.',
      'offeringMaterial-title': 'Biflöde erbjudes',
      'User-role-name': 'Representant för företag eller organisation',
      'SystemAdmin-role-name': 'SystemAdmin',
      'Admin-role-name': 'Admin',
      'PublicOfficer-role-name': 'Myndighet',
      'RegionalCoordinator-role-name': 'Områdeskoordinator',
      'MunicipalWasteManagement-role-name': 'Representant för kommunal avfallshantering',
      'company-registration-type-0': 'Annat tillstånd',
      onetime: 'Engångsparti',
      continuous: 'Material som uppkommer kontinuerligt',
      Tilastot: 'Statistik',
      Yhteystiedot: 'Kontaktuppgifter',
      'Admin-toiminnot': 'Admin-funktioner',
      'Kunta tai kaupunki': 'Kommun eller stad',
      'Maakunta, kunta tai kaupunki': 'Landskap, kommun eller stad',
      'Lisää ilmoitus Materiaalitoriin valitsemalla tilanteeseesi sopiva ilmoitustyyppi.':
        'Lägg till en annons på Materialtorget genom att välja en annonstyp som lämpar sig för din situation.',
      'Haluan luoda ilmoituksen niin, että voin ilmoituksen voimassaolon jälkeen tarvittaessa pyytää kunnan toissijaista jätehuoltopalvelua (ns. TSV-palvelu).':
        'Jag vill skapa annonsen så att jag efter annonsens giltighetstid vid behov kan begära kommunal avfallshantering i andra hand (s.k. TSV-tjänst).',
      'Näytä organisaation tiedot vain Materiaalitorin kirjautuneille käyttäjille.':
        'Visa organisationens uppgifter endast för användare som är inloggade på Materialtorget.',
      Tiedosto: 'Fil',
      'Lataa tiedosto': 'Ladda fil',
      'Ilmoittajan jätteen vastaanottoon liittyvät luvat tai rekisterimerkinnät':
        'Annonsörens tillstånd eller registeranteckningar om mottagning av avfall',
      'Oma sivu -osiossa näet kootusti kaikki sinuun ja organisaatioosi liittyvät tiedot, ilmoitukset ja hakuvahdit.':
        'På Min sida ser du en sammanställning över uppgifter, annonser och bevakningar som gäller dig och din organisation.',
      'Oma sivu -osiossa näet kootusti kaikki sinuun ja organisaatioosi liittyvät tiedot, mahdolliset ilmoitukset, hakuvahdit, tulleet TSV-pyynnöt ja tiedot TSV-sopimuksista. Lisäksi voit tuoda ja katsella tietoja muusta kuin sopimuksiin liittyvästä TSV-palvelusta.':
        'På Min sida ser du en sammanställning över uppgifter som gäller dig och din organisation, eventuella annonser, bevakningar, inkomna begäranden om kommunal avfallshantering i andra hand och avtal om kommunal avfallshantering i andra hand. Dessutom kan du hämta och granska uppgifter om annan än avtalsrelaterad kommunal avfallshantering i andra hand.',
      'Oma sivu -osiossa näet kootusti kaikki sinuun ja organisaatioosi liittyvät tiedot, seuraamiesi tai valvomiesi jätelaitosten TSV-sopimustiedot, niihin liittyvät palvelupyynnöt ja ilmoitukset sekä jätelaitosten hylkäämät pyynnöt TSV-palvelusta. "Tilastot"-kohdasta löydät kootusti muusta TSV-palvelusta toimitetut tiedot.':
        'På Min sida ser du en sammanställning över alla uppgifter om dig och din organisation, uppgifter om avtal om kommunal avfallshantering i andra hand för avfallsanläggningar som du bevakar, tillhörande begäran om tjänster och annonser samt begäran om kommunal avfallshantering i andra hand som avfallsanläggningen avslagit. Under ”Statistik” hittar du en sammanställning över uppgifter som lämnats om annan kommunal avfallshantering i andra hand.',
      'Tällä sivulla näet tilastotietoa Materiaalitorin ilmoituksista, materiaaleista ja kunnan toissijaisesta jätehuoltopalvelusta. Hakukoneilla voit etsiä tietoa monilla eri kriteereillä.':
        'På denna sida ser du statistik över annonser, material och kommunal avfallshantering i andra hand på Materialtorget. Med sökfunktionerna kan du söka information med många olika kriterier.',
      'Tarkastele tietoja Materiaalitoriin tehdyistä ilmoituksista ja ilmoitettujen materiaalien määristä.':
        'Granska uppgifter om annonser som gjorts på Materialtorget och de annonserade materialmängderna.',
      'Tarkastele tietoja Materiaalitorin käyttövelvoitteen ulkopuolelle jäävästä kunnan toissijaisesta palvelusta. Tiedot täydentyvät vuosittain.':
        'Granska uppgifter om kommunens tjänst i andra hand som faller utanför skyldigheten att använda Materialtorget. Uppgifterna kompletteras årligen.',
      'Määritä, onko materiaalisi jätettä vai muuta materiaalia:': 'Definiera om ditt material är avfall eller något annat material',
      'Jäte on mistä tahansa toiminnasta syntyvää sen tuottajalle käyttökelvotonta materiaalia. Jätelain (646/2011) mukaan jätettä on aine tai esine, jonka haltija on poistanut tai aikoo poistaa käytöstä taikka on velvollinen poistamaan käytöstä. Esimerkkiluettelo jätteistä löytyy jäteasetuksen (179/2012) liitteestä 4 ”Jäteluettelo: Yleisimmät jätteet sekä vaaralliset jätteet”.':
        'Avfall är material som uppkommer i vilken verksamhet som helst och som är obrukbart för producenten. Enligt avfallslagen (646/2011) är avfall ett ämne eller föremål som innehavaren har kasserat eller avser eller är skyldig att kassera. En exempelförteckning över avfall finns i bilaga 4 till förordningen om avfall (179/2012) ”Avfallsförteckning: Det vanligaste avfallet samt farligt avfall”.',
      'Lähtökohtaisesti toiminnanharjoittaja vastaa materiaalin luokittelusta jätteeksi. Ympäristöluvanvaraisessa toiminnassa syntyvät jätteet kirjataan yleensä ympäristölupaan. Apua esineen ja aineen jäteluonteen arvioinnissa voi tarvittaessa pyytää toiminnanharjoittajan sijaintipaikan mukaiselta jätelain valvontaviranomaiselta (kunnan ympäristönsuojeluviranomainen tai elinkeino-, liikenne- ja ympäristökeskus). \n\n On tärkeää tunnistaa, onko ilmoittamasi materiaali jätettä. Materiaalin luokittelusta jätteeksi seuraa jätelaista tulevia velvoitteita. Materiaalitorissakin jätteestä tulee antaa enemmän tietoja kuin muusta materiaalista. Jätelaissa määritellään myös, milloin aine tai esine ei ole jäte vaan sivutuote.  \n\n Kunnan toissijaista jätehuoltopalvelua voi muun palvelutarjonnan puutteen vuoksi pyytää vain jätteelle.': 
        'I regel svarar verksamhetsutövaren för klassificeringen av material som avfall. Avfall som uppkommer i verksamhet som kräver miljötillstånd antecknas i allmänhet i miljötillståndet. Vid behov kan man be om hjälp med att bedöma avfallskaraktären hos ett föremål eller ämne av en tillsynsmyndighet enligt avfallslagen (kommunens miljöskyddsmyndighet och närings-, trafik- och miljöcentralen). \n\n Det är viktigt att identifiera huruvida det material som du annonserar är avfall. När material klassificeras som avfall, följer skyldigheter med stöd av avfallslagen. Mer uppgifter ges om avfall än om annat material också på Materialtorget. I avfallslagen definieras också när ett ämne inte är avfall utan en biprodukt.  \n\n Kommunal avfallshantering i andra hand kan på grund av bristande utbud av andra tjänster begäras endast för avfall.',
      'Jäteasetuksen (179/2012) liitteen 4 jäteluettelon mukainen kuusinumeroinen jätekoodi. Kutsutaan myös EWC-koodiksi (European Waste Catalog) tai LoW-koodiksi (List of Waste).\n\nJätenimike on suositeltavaa antaa, mikäli se on tiedossa.':
        'En sexsiffrig avfallskod i enlighet med bilaga 4 till förordningen om avfall (179/2012). Kallas också EWC-kod (European Waste Catalog) eller LoW-kod (List of Waste).\n\nAnge gärna avfallskategori, om den är känd.',
      'Vaarallisella jätteellä tarkoitetaan jätettä, jolla on palo- tai räjähdysvaarallinen, tartuntavaarallinen, muu terveydelle vaarallinen, ympäristölle vaarallinen tai muu vastaava ominaisuus (vaaraominaisuus).\n\nVaarattomalla jätteellä tarkoitetaan jätettä, jolla ei ole vaaraominaisuuksia. Vaaratonta jätettä on kutsuttu myös tavanomaiseksi jätteeksi.\n\nJätedirektiivin liitteessä III, joka on annettu komission asetuksella (EU) N:o 1357/2014, on lueteltu ominaisuudet, jotka tekevät jätteistä vaarallisia, sekä kyseisten ominaisuuksien arvioinnissa käytettävät kriteerit. Jäte on vaarallista, jos sillä on yksikin komission asetuksessa määritelty vaaraominaisuus. Vaaralliset jätteet on jäteasetuksen liitteen 4 jäteluettelossa merkitty tähdellä (*). Lisätietoja luokittelemisesta vaaraominaisuuksien perusteella saa Suomen ympäristökeskuksen julkaisemasta ”Jätteen luokittelu vaaralliseksi jätteeksi” –oppaasta.':
        'Med farligt avfall avses avfall som är brandfarligt eller explosivt, smittfarligt, annars hälsofarligt eller miljöfarligt eller har någon motsvarande egenskap (farlig egenskap).\n\nMed icke-farligt avfall avses avfall som inte har några farliga egenskaper. Icke-farligt avfall har också kallats vanligt avfall.\n\nI bilaga III till avfallsdirektivet, som utfärdats genom kommissionens förordning (EU) nr 1357/2014, listas de egenskaper som gör att avfall klassificeras som farligt avfall samt de kriterier som ska användas vid bedömningen av dessa egenskaper. Avfallet är farligt redan om det besitter en enda av de farliga egenskaper som anges i kommissionens förordning. Farligt avfall har märkts ut med asterisk (*) i avfallsförteckningen i bilaga 4 till statsrådets förordning om avfall. Mer information om klassificering av avfall utifrån farliga egenskaper finns i Finlands miljöcentrals handledning om klassificering av avfall som farligt avfall.',
      'Pysyvä jäte on jätelaji, joka ei aiheuta ympäristön saastumista tai haittaa terveydelle ja jossa ei pitkänkään ajan kuluessa tapahdu olennaisia fysikaalisia, kemiallisia tai biologisia muutoksia.': 
        'En typ av avfall som inte orsakar miljöföroreningar eller skadar hälsan och som inte genomgår relevanta fysiska, kemiska eller biologiska förändringar ens under lång tid.',
      'Pysyvät orgaaniset yhdisteet (POP) ovat myrkyllisiä, hitaasti hajoavia kemiallisia yhdisteitä, jotka kertyvät eliöihin ravintoketjussa ja kulkeutuvat kauas päästöpaikastaan ilman, veden tai muuttavien eläinlajien välityksellä':
        'Långlivade organiska föreningar (POP) är giftiga, långsamt nedbrytabara kemiska föreningar som ackumuleras i näringskedjans organismer och transporteras långa vägar från sin utsläppsplats via luft, vatten eller migrerande djurarter.',
      'Ilmoita yksittäinen jäte-erä tai jatkuvasti syntyvä jäte, mikäli jätettä syntyy toistuvasti pidemmällä aikavälillä ja tavoitteenasi on pidempiaikainen sopimus palvelusta.':
        'Anmäl ett enskilt avfallsparti eller avfall som uppkommer kontinuerligt, om avfall uppkommer upprepade gånger under en längre tid och ditt mål är att ingå ett längre avtal om tjänsten.',
      'Yksittäisten jäte-erien määrä, koko ja toistuvuus tai muita tarkennettavia tietoja jätteen määrästä tai palveluntarpeen toistuvuudesta, jotka voivat olla jätteen vastaanottajan löytämisen kannalta oleellisia.':
        'Mängden enskilda avfallspartier, deras storlek, hur ofta de upprepas eller andra preciserande uppgifter om avfallsmängd eller hur ofta tjänstebehovet upprepas, vilka kan vara väsentliga med tanke på att hitta en mottagare för avfallet.',
      'Yksittäisten materiaalierien määrä, koko, toistuvuus ja saatavuus tai muita tarkennettavia tietoja materiaalin määrästä tai toistuvuudesta, jotka voivat olla hyödyntämisen kannalta oleellisia.':
        'Mängd, storlek, tillgång och upprepad förekomst eller andra detaljerade uppgifter om materialets mängd eller förekomst som är väsentliga med tanke på användningen.',
      'Lisätiedot jätteestä liitteiden, kuten kuvien ja muiden liitetiedostojen muodossa. Sallitut tiedostomuodot ovat jpg, png, gif, pdf, doc(x), xls(x) ja ppt(x).':
        'Mer information om avfallet i form av bilagor, som bilder och andra bifogade filer. Tillåtna filformat är jpg, png, gif, pdf, doc(x), xls(x) och ppt(x).',
      'Jätteen saa luovuttaa vain vastaanottajalle, jolla on jätelain mukainen hyväksyntä vastaanottaa jätettä. Jätteen haltijan tulee varmistaa ennen jätehuoltopalvelua koskevan sopimuksen tekemistä, että kyseisellä palveluntarjoajalla on oikeus vastaanottaa jäte. \n\n Palvelua haettaessa on syytä huomioida jätelain mukainen velvollisuus noudattaa etusijajärjestystä. \n\n Etusijajärjestyksen mukaan ensisijaisesti on vähennettävä syntyvän jätteen määrää ja haitallisuutta. Jos jätettä kuitenkin syntyy, jätteen haltijan on ensisijaisesti valmisteltava jäte uudelleenkäyttöä varten tai toissijaisesti kierrätettävä se. Jos kierrätys ei ole mahdollista, jätteen haltijan on hyödynnettävä jäte muulla tavoin, mukaan lukien hyödyntäminen energiana. Jos hyödyntäminen ei ole mahdollista, jäte on loppukäsiteltävä.':
        'Avfallet får endast överlämnas till en mottagare som har ett godkännande enligt avfallslagen att motta avfall. Avfallsinnehavaren ska innan ett avtal om avfallshanteringstjänst ingås försäkra sig om att den aktuella tjänsteleverantören har rätt att ta emot avfallet. \n\n När en tjänst söks finns det skäl att observera skyldigheten att iaktta prioriteringsordningen i avfallslagen. \n\n Enligt prioriteringsordningen ska i första hand mängden av och skadligheten hos det avfall som verksamheten ger upphov till minskas. Om avfall emellertid uppkommer, ska avfallsinnehavaren i första hand förbereda avfallet för återanvändning och i andra hand materialåtervinna det. Är materialåtervinning inte möjlig, ska avfallsinnehavaren återvinna avfallet på något annat sätt, exempelvis som energi. Om avfallet inte kan återvinnas ska det bortskaffas.',
      'Jätteen saa luovuttaa vain vastaanottajalle, jolla on jätelain mukainen hyväksyntä vastaanottaa jätettä. Jätteen haltijan tulee varmistaa ennen jätehuoltopalvelua koskevan sopimuksen tekemistä, että kyseisellä palveluntarjoajalla on tarvittava viranomaislupa ja -hyväksyntä vastaanottaa jäte.':
        'Avfallet får endast överlämnas till en mottagare som har ett godkännande enligt avfallslagen att motta avfall. Avfallsinnehavaren ska innan ett avtal om avfallshanteringstjänst ingås försäkra sig om att den aktuella tjänsteleverantören har rätt att ta emot avfallet.',
      'Vaatimukset ja toiveet haettavalle palvelulle tai muuta kuvausta palvelusta.':
        'Krav och önskemål på den sökta tjänsten eller en annan beskrivning av tjänsten.',
      'Haettavan palvelun alku- ja päättymisaika, sopimuskauden pituus tai muu palvelunajankohtaan liittyvä tarkennus.':
        'Start- och sluttid för den sökta tjänsten, avtalsperiodens längd eller någon annan precisering gällande tidpunkten för tjänsten.',
      'Jätteen sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa erityisesti jätteen kuljetuspalvelua haettaessa. Voit halutessasi valita, että tarkka katuosoitetieto näkyy vain Materiaalitoriin kirjautuneille käyttäjille. Sijainti näytetään joka tapauksessa vähintään kunnan tarkkuudella myös Materiaalitoriin kirjautumattomille käyttäjille.':
        'Avfallets plats, minst den kommun där avfallet finns. Det rekommenderas att ange gatuadressen, i synnerhet när en tjänst för avfallstransport söks. Om du vill kan du välja att en exakt uppgift om gatuadress visas endast för inloggade användare på Materialtorget. Platsen visas i alla fall med kommunnoggrannhet också för användare som inte är inloggade på Materialtorget.',
      'Kirjoita esimerkiksi laitosalueen tai työmaan nimi tai muu vapaa tunniste.':
        'Skriv till exempel namnet på anläggningsområdet eller byggplatsen eller någon annan fritt formulerad identifiering.',
      'Ilmoita päivämäärä, mihin saakka ilmoitus on voimassa. Ilmoitus on nähtävissä Materiaalitorissa ja siihen voi vastata voimassaolon aikana. Voimassaoloaika tulee olla vähintään 14 vuorokautta ja enintään 12 kuukautta, jonka jälkeen ilmoitus poistuu Materiaalitorin ilmoituksista, mutta arkistoituu ilmoittajan omille sivuille, josta se on mahdollista tarvittaessa julkaista uudelleen. Mikäli ilmoitukseen ei ole voimassaoloaikana tullut hyväksyttäviä vastauksia, on mahdollista tehdä pyyntö kunnan toissijaisesta jätehuoltopalvelusta Materiaalitorin kautta. Materiaalitori ilmoittaa, kun pyynnön tekeminen on mahdollista.':
        'Ange datum för hur länge annonsen är i kraft. Annonsen publiceras på Materialtorget och kan besvaras så länge den är i kraft. Giltighetstiden bör vara minst 14 dygn och högst 12 månader. Efter detta syns annonsen inte längre på Materialtorget men arkiveras på annonsörens egna sidor. Där kan annonsören vid behov ta fram den och publicera den på nytt. Om det under den tid som annonsen gäller inte har kommit in några godtagbara svar, är det möjligt att lämna in en begäran om kommunal avfallshantering i andra hand via Materialtorget. Materialtorget meddelar när det är möjligt att lämna in en begäran.',
      'Ilmoita päivämäärä, mihin saakka ilmoitus on voimassa. Ilmoitus on nähtävissä Materiaalitorissa ja siihen voi vastata voimassaolon aikana. Voimassaoloaika voi olla enintään 12 kuukautta, jonka jälkeen ilmoitus poistuu Materiaalitorin ilmoituksista, mutta arkistoituu ilmoittajan omille sivuille, josta se on mahdollista tarvittaessa julkaista uudelleen.':
        'Ange datumet som annonsen är giltig till. Annonsen visas på Materialtorget och den kan besvaras under giltighetstiden. Giltighetstiden kan vara högst 12 månader, varefter annonsen tas bort från annonserna på Materialtorget, men arkiveras på annonsörens sidor, där det är möjligt att publicera den på nytt.',
      'Valitse palvelu, jota ensisijaisesti haet jätteelle':
        'Välj den tjänst som du i första hand söker för avfallet',
      'Tarkenna hakemaasi palvelua': 'Precisera tjänsten som du söker för avfallet',
      'Tarkenna tarjoamaasi palvelua': 'Precisera tjänsten som erbjuds',
      'Materiaalia mahdollisimman hyvin kuvaava termi. Valmiiden ilmoitusten hakua voi rajata tämän luokittelun perusteella. Voit valita vain yhden materiaalin yhteen ilmoitukseen.':
        'En term som beskriver materialet så väl som möjligt. Sökningen av färdiga annonser kan begränsas på basis av denna klassificering. Du kan välja endast ett material till en annons.',
      'Materiaalin koostumus tai muu hyödyntämisen kannalta olennainen tieto materiaalin ominaisuuksista.':
        'Materialets beskaffenhet eller någon annan väsentlig uppgift om materialets egenskaper med tanke på utnyttjandet.',
      'Materiaalin sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa. Voit halutessasi valita, että tarkka katuosoitetieto näkyy vain Materiaalitoriin kirjautuneille käyttäjille. Sijainti näytetään joka tapauksessa vähintään kunnan tarkkuudella myös Materiaalitoriin kirjautumattomille käyttäjille':
        'Materialets plats, minst den kommun där avfallet finns. Det rekommenderas att ange gatuadressen. Om du vill kan du välja att en exakt uppgift om gatuadress visas endast för inloggade användare på Materialtorget. Platsen visas i alla fall med kommunnoggrannhet också för användare som inte är inloggade på Materialtorget',
      'Ilmoita päivämäärä, mihin saakkailmoitus on voimassa. Ilmoitus on nähtävissä Materiaalitorissa ja siihen voi vastata voimassaolon aikana. Voimassaoloaika voi olla enintään 6 kuukautta, jonka jälkeen ilmoitus poistuu Materiaalitorin ilmoituksista, mutta arkistoituu ilmoittajan omille sivuille, josta se on mahdollista tarvittaessa julkaista uudelleen.':
        'Ange datumet som annons är giltig till. Annonsen visas på Materialtorget och den kan besvaras under giltighetstiden. Giltighetstiden kan vara högst 6 månader, varefter annonsen tas bort från annonserna på Materialtorget, men arkiveras på annonsörens sidor, där det är möjligt att publicera den på nytt.',
      'Etsittävää materiaalia mahdollisimman hyvin kuvaava termi. Valmiiden ilmoitusten hakua voi rajata tämän luokittelun perusteella. Voit valita vain yhdenmateriaalin yhteen ilmoitukseen.':
        'En term som beskriver det sökta materialet så väl som möjligt. Sökningen av färdiga annonser kan begränsas på basis av denna klassificering. Du kan välja endast ett material till en annons.',
      'Hyödyntämisen kannalta olennaiset edellytykset materiaaliin ja sen ominaisuuksiin liittyen. Voit lisäksi kuvata, miten aiot materiaalia hyödyntää. Voit myös, kertoa miten suurta määrää haet, ja onko kyse jatkuvasta vai kertatarpeesta.':
        'Väsentliga förutsättningar gällande materialet och dess egenskaper med tanke på utnyttjande. Du kan också beskriva hur du avser utnyttja materialet. Du kan också ange vilken mängd du söker och om det är frågan om ett kontinuerligt eller engångsbehov.',
      'Valitse tarjottavaa palvelua mahdollisimman hyvin kuvaava palvelu. Palveluun sisältyvää jätteen käsittelyä voit tarvittaessa tarkentaa. Eri käsittelymenetelmiä voi valita useita. Valmiiden ilmoitusten hakua voi rajata tämän palveluluokittelun perusteella. ”Kiinteistön kokonaispalvelu” tarkoittaa palvelua, jossa huolehditaan kiinteistöllä syntyvien eri jätteiden jätehuollon järjestämisestä kokonaisuutena.':
        'Välj en tjänst som beskriver den erbjudna tjänsten så väl som möjligt. Vid behov kan du precisera den avfallsbehandling som ingår i tjänsten. Du kan välja flera olika behandlingsmetoder. Sökningen av färdiga annonser kan begränsas med denna tjänsteklassificering. ”Totaltjänst för fastighet” avser en tjänst där man ordnar avfallshanteringen för olika avfall som uppkommer på fastigheten som en helhet.',
      'Valitse tarjottavaa palvelua mahdollisimman hyvin kuvaava palvelu. Palveluun sisältyvää jätteen käsittelyä voit tarvittaessa tarkentaa. Eri käsittelymenetelmiä voi valita useita.\n\n”Kiinteistön kokonaispalvelu” tarkoittaa palvelua, jossa huolehditaan kiinteistöllä syntyvien eri jätteiden jätehuollon järjestämisestä kokonaisuutena.':
        'Välj en tjänst som beskriver den erbjudna tjänsten så väl som möjligt. Vid behov kan du precisera den avfallsbehandling som ingår i tjänsten. Du kan välja flera olika behandlingsmetoder.\n\n”Totaltjänst för fastighet” avser en tjänst där man ordnar avfallshanteringen för olika avfall som uppkommer på fastigheten som en helhet.',
      'Mahdollisimman selkeä kuvaus tarjoamastasi palvelusta. Yksilöi myös tarvittaessa, mille jätteille tai materiaaleille palvelu soveltuu.':
        'En så tydlig beskrivning som möjligt av den tjänst som du tillhandahåller. Specificera även vid behov för vilket avfall eller vilka material tjänsten lämpar sig.',
      'Lisätiedot palvelusta liitteiden, kuten kuvien ja muiden liitetiedostojen muodossa. Sallitut tiedostomuodot ovat jpg, png, gif, pdf, doc(x), xls(x) ja ppt(x).':
        'Mer information om tjänsten i form av bilagor, som bilder och andra bifogade filer. Tillåtna filformat är jpg, png, gif, pdf, doc(x), xls(x) och ppt(x).',
      'Lisätiedot liitteiden, kuten kuvien ja muiden liitetiedostojen muodossa. Sallitut tiedostomuodot ovat jpg, png, gif, pdf, doc(x), xls(x) ja ppt(x).':
        'Mer information i form av bilagor, som bilder och andra bifogade filer. Tillåtna filformat är jpg, png, gif, pdf, doc(x), xls(x) och ppt(x).',
      'Palvelun sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa erityisesti mm. käsittelypalveluja ja varastointia tarjotessa. Voit halutessasi valita, että tarkka katuosoitetieto näkyy vain Materiaalitoriin kirjautuneille käyttäjille. Sijainti näytetään joka tapauksessa vähintään kunnan tarkkuudella myös Materiaalitoriin kirjautumattomille käyttäjille. Mikäli sijainteja on useita tai tarjoat esimerkiksi kuljetuspalvelua tietyllä alueella, voit valita sijainteja alueet-valikosta.':
        'Tjänstens plats, minst den kommun där avfallet finns. Det rekommenderas att du anger gatuadressen, i synnerhet när det gäller bl.a. behandlingstjänster och lagring. Om du vill kan du välja att en exakt uppgift om gatuadress visas endast för inloggade användare på Materialtorget. Platsen visas i alla fall med kommunnoggrannhet också för användare som inte är inloggade på Materialtorget. Om det finns flera platser eller om du tillhandahåller till exempel en transporttjänst inom ett bestämt område, kan du välja platserna i områdesmenyn.',
      'Voit ilmoittaa useita sijainteja lisäämällä kuntia tai maakuntia, joiden alueella tarjoat palvelua. Aloita kirjoittamalla kenttään kunnan tai maakunnan nimeä ja saat valmiita ehdotuksia, joista voit valita.':
        'Du kan anmäla flera platser genom att lägga till kommuner eller landskap där du tillhandahåller tjänsten. Börja genom att skriva in kommunens eller landskapets namn i fältet, då du får färdiga förslag som du kan välja.',
      'Voit ilmoittaa useita sijainteja lisäämällä kuntia tai maakuntia. Aloita kirjoittamalla kenttään kunnan tai maakunnan nimeä ja saat valmiita ehdotuksia, joista voit valita.':
        'Du kan anmäla flera platser genom att lägga till kommuner eller landskap. Börja genom att skriva in kommunens eller landskapets namn i fältet, då du får färdiga förslag som du kan välja.',
      'Ilmoita päivämäärä, mihin saakkailmoitus on voimassa. Ilmoitus on nähtävissä Materiaalitorissa ja siihen voi vastata voimassaolon aikana.Voimassaoloaika voi olla enintään 6 kuukautta, jonka jälkeen ilmoitus poistuu Materiaalitorin ilmoituksista, mutta arkistoituu ilmoittajan omille sivuille, josta se on mahdollista tarvittaessa julkaista uudelleen.':
        'Ange datumet som annons är giltig till. Annonsen visas på Materialtorget och den kan besvaras under giltighetstiden. Giltighetstiden kan vara högst 6 månader, varefter annonsen tas bort från annonserna på Materialtorget, men arkiveras på annonsörens sidor, där det är möjligt att publicera den på nytt.',
      'Kyseessä on jätelain (646/2011) 33 §:n mukainen kunnan toissijainen jätehuoltopalvelu, jota jätteen haltijan on mahdollista pyytää muun palvelutarjonnan puuttuessa.\n\nJätelain mukaan jätteen haltija on velvollinen järjestämään jätteen jätehuollon, mikäli jätehuolto ei kuulu kunnan vastuulle tai jäte ole ns. tuottajavastuun alainen. Käytännössä tämä tarkoittaa sitä, että yritysten ja monien julkisten organisaatioiden on järjestettävä useimpien jätteidensä jätehuolto itse markkinoilla olevia jätehuoltopalveluja hyödyntäen.\n\nMikäli markkinoilta ei kuitenkaan löydy tarvittavaa palvelua, on sitä mahdollista pyytää kunnalta, sillä kunta on jätelain mukaan velvollinen järjestämään jätehuollon toissijaisesti myös muulle jätteelle, kuin sille josta se on ensisijaisesti vastuussa. Ensisijaisesti kunta on velvollinen järjestämään pääsääntöisesti asumisessa syntyvän jätteen sekä kunnan hallinto- ja palvelutoiminnassa syntyvän yhdyskuntajätteen jätehuollon. Kunnalla on lisäksi vaarallisen jätteen vastaanottoon ja käsittelyyn liittyviä vastuita.':
        'Det är frågan om kommunal avfallshantering i andra hand enligt 33 § i avfallslagen (646/2011), som avfallsinnehavaren kan begära om annat utbud av tjänster saknas.\n\nEnligt avfallslagen är avfallsinnehavaren skyldig att ordna avfallshanteringen för avfallet, om avfallshanteringen inte ankommer på kommunen eller inte omfattas av det s.k. producentansvaret. I praktiken innebär detta att företag och många offentliga organisationer själva måste ordna avfallshantering för sitt avfall genom att utnyttja avfallshanteringstjänster som finns tillgängliga på marknaden.\n\nOm en tjänst som behövs emellertid inte finns tillgänglig på marknaden, är det möjligt att begära den av kommunen, eftersom kommunen enligt avfallslagen är skyldig att ordna avfallshantering i andra hand även för annat avfall än sådant som den i första hand ansvarar för. I första hand är kommunen skyldig att i regel ordna avfallshantering för avfall som uppkommer vid boende samt för kommunalt avfall från kommunens förvaltnings- och serviceverksamhet. Kommunen har även ansvar gällande mottagning och behandling av farligt avfall.',
      'Kunnan toissijaisen jätehuoltopalvelun edellytyksenä on, että jätteen haltija pyytää sitä yksityisen palveluntarjonnan puutteen vuoksi ja että jäte laadultaan ja määrältään soveltuu kuljetettavaksi tai käsiteltäväksi kunnan jätehuoltojärjestelmässä. Pyynnön voi jätteen haltijan puolesta esittää jätteen kuljettaja tai muu toimija osana tarjoamaansa jätehuoltopalvelua, kun pyyntö koskee jätteen käsittelyä. Pyynnön voi tehdä kuuden (6) kuukauden kuluessa ilmoituksen voimassaolon päättymisestä.':
        'En förutsättning för kommunal avfallshantering i andra hand är emellertid att avfallsinnehavaren begär det på grund av bristande privat utbud av tjänster och att avfallet till sin beskaffenhet och mängd lämpar sig för transport eller behandling i kommunens avfallshanteringssystem. Transportören av avfallet eller någon annan aktör kan framföra begäran för innehavarens räkning som en del av avfallshanteringstjänsten som den erbjuder, när begäran gäller behandling av avfall. Begäran kan göras inom sex (6) månader från det att annonsens giltighetstid gått ut.',
      'Materiaalitori edistää jätteiden, sivuvirtojen ja ylijäämämateriaalien sekä uudelleen käytettävien osien hyötykäyttöä tarjoamalla kohtaamispaikan, jossa materiaalien tarjoajat ja tarvitsijat voivat löytää toisensa. Materiaalitorilla voi myös etsiä ja tarjota näihin materiaaleihin liittyviä palveluja, kuten jätehuolto- ja asiantuntijapalveluja. Materiaalitorilla voi tarjota ja hakea myös kiertotalouden asiantuntijapalveluita ja osaamista.':
        'Materialtorget främjar återvinning av avfall, biflöden och överskottsmaterial samt återanvändbara delar genom att erbjuda en mötesplats där leverantörer och användare av material kan hitta varandra. På Materialtorget kan man även söka och erbjuda tjänster i anknytning till dessa material, såsom avfallshanterings- och experttjänster. På Materialtorget kan man också erbjuda och söka kompetens och experttjänster inom cirkulär ekonomi.',
      'Materiaalien kierrätyksellä ja uudelleenkäytöllä voidaan vähentää luonnonvarojen kulutusta ja vähentää ympäristökuormitusta. Kiertotaloutta edistää erityisesti se, että hyödynnettävissä olevat materiaalit ovat nähtävissä ennakoidusti ja alueellisesti. Tämä mahdollistaa kustannustehokkaan toiminnan ja uusien hyödyntämistapojen ja liiketoiminnan kehittämisen.':
        'Genom återvinning och återanvändning av material kan miljöbelastningen och förbrukningen av naturresurser minskas. Cirkulär ekonomi främjas särskilt av att användbara material kan ses regionalt och på ett förutsägbart sätt. Detta möjliggör en kostnadseffektiv verksamhet samt utveckling av affärsverksamheten och nya användningssätt.',
      'Materiaalitori on tarkoitettu yritysten ja organisaatioiden jätteiden ja sivuvirtojen ammattimaiseen vaihdantaan ja näihin liittyviin palveluihin. Palvelun omistaa ympäristöministeriö ja sitä ylläpitää Motiva Oy.':
        'Materialtorget är avsett för företags och organisationers yrkesmässiga utbyte av avfall och biflöden och tjänster i anknytning till detta. Tjänsten ägs av miljöministeriet och upprätthålls av Motiva Oy.',
      'Ilmoita ja etsi hyödynnettäviä materiaaleja':
        'Annonsera och sök användbara material',
      'Materiaalitori-palvelu tarjoaa avoimen vaihdanta-alustan yritysten ylijäämämateriaaleille, jätteille ja sivuvirroille. Yritysten kannattaa tuoda Materiaalitorille näkyviin vielä hyödyntämättömät hukat ja ylijäämät, sillä niille voidaan löytää käyttöä. Avoimuus on tärkeää, ideoita uusille käyttömahdollisuuksille ja uuteen liiketoimintaan syntyy, kun tiedetään mitä materiaaleja on tarjolla.':
        'Materialtorget tillhandahåller en öppen bytesplattform för företags överskottsmaterial, avfall och biflöden. På Materialtorget kan företag synliggöra svinn och överskott som inte ännu utnyttjats, eftersom det kan finnas användning för dessa.',
      'Materiaalitorilla tarjotaan ja haetaan erilaisia yrityksissä syntyviä jätteitä ja sivuvirtoja sekä ylijäämämateriaaleja, kuten puuta, kartonkia, lasia, betonia, tuhkaa, elintarvikevirtoja, tekstiilejä ja biojätettä. Toistaiseksi eniten on ollut teollisuuden sivuvirtoja ja rakennus- ja purkumateriaaleja ja -osia sekä betonia ja erilaisia maa-aineksia.':
        'På materialtorget erbjuds och söks olika typer av avfall och biflöden som uppkommer i företag samt överskottsmaterial, såsom trä, kartong, glas, betong, aska, livsmedelsflöden, textilier och bioavfall. Hittills har det funnits flest biflöden från industrin samt byggnads- och rivningsmaterial och -delar samt betong och olika typer av jordmaterial.',
      'Tuhkan vastuullista käyttöä edistää syksyllä 2021 käyttöön otettu tuhkan kelpoisuusluokittelu, joka helpottaa tuhkan kierrättämistä. Tuhkantuottaja saa lisäarvoa ja säästää jätekustannuksissa, arvokas tuhka saadaan hyötykäyttöön ja neitseellisiä luonnonvaroja käytetään vähemmän.':
        'En klassificering av användbarheten hos aska infördes 2021 för att främja en ansvarsfull användning. Klassificeringen gör det lättare att återvinna aska. Askproducenten får mervärde och sparar på avfallskostnaderna, värdefull aska kan utnyttjas och då används jungfruliga naturresurser i mindre omfattning.',
      'Materiaalitori tuo läpinäkyvyyttä kunnan toissijaisen jätehuoltopalvelun käyttöön ja sen edellytyksenä olevan muun palvelutarjonnan puutteen osoittamiseen. 1.1.2020 voimaan tuleva jätelain uudistus velvoittaa Materiaalitorin käyttöön jätteen haltijat, jotka tarvitsevat kunnan toissijaista jätehuoltopalvelua vuodessa yli 2000 euron arvosta. Julkisia jätteen haltijoita eli hankintayksiköitä velvollisuus koskee 1.1.2021 alkaen.':
        'Materialtorget bidrar med ökad transparens i användningen av den kommunala avfallshanteringstjänst som tillhandahålls i andra hand och konstaterandet av bristande utbud av andra tjänster som det grundar sig på. Ändringen av avfallslagen som träder i kraft 1.1.2020 ålägger avfallsinnehavare att använda Materialtorget om de behöver en kommunalt ordnad avfallshanteringstjänst i andra hand till ett värde av minst 2 000 euro per år. Denna skyldighet börjar gälla offentliga avfallsinnehavare, dvs. upphandlande enheter, från och med 1.1.2021.',
      'Jätehuollon kilpailutus ja kunnan toissijaisen jätehuoltopalvelun pyytäminen':
        'Konkurrensutsättning av avfallshantering och begäran om kommunal avfallshantering i andra hand',
      'Jätehuoltoon ja kiertotalouteen liittyvät palvelut':
        'Tjänster i anknytning till avfallshantering och cirkulär ekonomi',
      'Materiaalitorissa tarjotaan materiaalien kuljetukseen, käsittelyyn ja varastointiin liittyvien palveluiden lisäksi myös asiantuntija- ja analyysipalveluita. Asiantuntijapalveluita voi tarjota ja etsiä palveluilmoituksilla. Asiantuntijapalveluiden hakutoimintoja hyödynnetään Kiertotalous-Suomessa ja teollisten symbioosien palveluissa.':
        'På Materialtorget erbjuds förutom transport-, behandlings- och lagringstjänster även expert- och analystjänster. Experttjänster kan erbjudas och sökas genom tjänsteannonser. Sökfunktionerna för experttjänsterna utnyttjas av tjänsterna Cirkulär Ekonomi Finland och Teollisten symbioosien palvelu.',
      'Materiaalitorilla voidaan kilpailuttaa jätehuoltopalvelut ja pyytää kunnan toissijaista palvelua, jos markkinaehtoista palvelua ei ole saatavilla. Materiaalitori tuo läpinäkyvyyttä kunnan toissijaisen jätehuoltopalvelun käyttöön ja sen edellytyksenä olevan muun palvelutarjonnan puutteen osoittamiseen. Jätelaki velvoittaa Materiaalitorin käyttöön jätteen haltijat, jotka tarvitsevat kunnan toissijaista jätehuoltopalvelua vuodessa yli 2000 euron arvosta.':
        'På Materialtorget kan man konkurrensutsätta avfallshanteringstjänster och begära kommunal avfallshantering i andra hand om ingen marknadsmässig tjänst är tillgänglig. Materialtorget medför transparens i användningen av kommunal avfallshantering i andra hand och påvisandet av ett bristande utbud av tjänster som detta förutsätter. Avfallslagen ålägger avfallsinnehavare att använda Materialtorget om de behöver en kommunalt ordnad avfallshanteringstjänst i andra hand till ett värde av minst 2 000 euro.',
      'Katso esite asiasta':
        'Se broschyr om ämnet',
      'Lue lisää ajankohtaisista asioista':
        'Läs mer om aktuella saker',
      'Myös hankintayksiköt ovat velvoitettuja kilpailuttamaan jätehuoltopalvelunsa. Jos kilpailutus tehdään Hilma-palvelussa, eikä markkinaehtoista palvelua ole saatavilla, hankintayksikkö voi Hilma-palvelun tiedoilla pyytää kunnan toissijaista jätehuoltopalvelua Materiaalitorilla. Materiaalitori hakee ilmoitustiedot Hilma-palvelusta, eikä erillistä ilmoitusta Materiaalitorille tarvitse tehdä.':
        'Även de upphandlande enheterna är skyldiga att konkurrensutsätta sina avfallshanteringstjänster. Om konkurrensutsättningen görs i Hilma-tjänsten och det inte finns någon marknadsbestämd tjänst att tillgå, kan den upphandlande enheten med stöd av uppgifterna i Hilma-tjänsten begära kommunal avfallshantering i andra hand via Materialtorget. Materialtorget hämtar annonsuppgifterna från Hilma-tjänsten och ingen separat annons behöver göras i Materialtorget.',
      'Materiaalitori avattiin 8.4.2019 ja se on tarkoitettu kaikille yrityksille ja julkisille organisaatioille. Pilottikäytön ajan käyttäjiltä kerätään tehostetusti palautetta ja palvelua kehitetään edelleen. Joitakin uusia toiminnallisuuksia lisätään palveluun matkan varrella. Palveluun tehtyjen ilmoitusten tulee olla aitoja, jolloin ne mahdollistavat oikean vaihdannan kehittymisen.':
        'Materialtorget öppnade 8.4.2019 och det är avsett för alla företag och offentliga organisationer. Under pilotanvändningen pågår en effektiviserad insamling av feedback från användarna och tjänsten utvecklas vidare. Vissa nya funktioner läggs till i tjänsten efter hand. Annonserna som läggs till i tjänsten ska vara äkta för att de ska möjliggöra en riktig utveckling av utbytet.',
      'Kirjautuminen ja rekisteröityminen, organisaation tietojen tarkistaminen, ilmoitusten selailu ja tekeminen ja niihin vastaaminen, aluekoordinaattorin ohjeet...':
        'Logga in och registrera dig, granska organisationens uppgifter, bläddra bland och besvara annonser, anvisningar för områdeskoordinatorer...',
      'Ilmoitusten lisääminen ja niihin vastaaminen edellyttää palveluun rekisteröitymistä. Rekisteröityminen on avoin kaikille ja se tapahtuu Suomi.fi-tunnistautumisen kautta. Tunnistautumiseen tarvitset verkkopankkitunnukset tai mobiilivarmenteen.':
        'Registrering i tjänsten krävs för att lägga till och svara på annonser. Registreringen är öppen för alla och den görs via Suomi.fi-identifiering. För identifiering behöver du nätbankkoder eller mobilcertifikat.',
      'Lisää otsikoksi kuvaava nimi etsittävälle palvelulle tai osaamiselle.':
        'Lägg till ett beskrivande namn som rubrik för den tjänst eller kompetens som söks.',
      'Etsittävä palvelu tai osaaminen':
        'Tjänst eller kompetens som söks',
      'Ilmoituksia voi suodattaa palvelun tai osaamisen kuvauksen perusteella.':
        'Annonserna kan filtreras på basis av tjänsten eller kompetensbeskrivningen.',
      'Kuvaile mahdollisimman selkeästi etsimääsi palvelua tai osaamista: tehtävänkuva ja kesto, asiantuntijalta vaadittavat taidot ja kokemus, minkälaista henkilöä haette, kielitaitovaatimukset, määräaikainen/jatkuva/projektiluonteinen tehtävä, mitä organisaatio tarjoaa haettavalle henkilölle tai organisaatiolle.':
        'Beskriv så tydligt som möjligt den tjänst eller kompetens du söker: uppgiftsbeskrivning och varaktighet, de färdigheter och erfarenheter som krävs av experten, hurdan person du söker, språkkrav, tidsbunden/fortlöpande/projektliknande uppgift, vad organisationen erbjuder personen eller organisationen.',
      'Valitse palvelu tai osaaminen, voit valita useita':
        'Välj tjänst eller kompetens, du kan välja flera',
      'Palvelun tai osaamisen kuvaus':
        'Beskrivning av tjänsten eller kompetensen',
      'Sallitut tiedostomuodot ovat jpg, png, gif, pdf, doc(x), xls(x) ja ppt(x).':
        'Tillåtna filformat är jpg, png, gif, pdf, doc(x), xls(x) och ppt(x)',
      'Rajaa tarvittaessa alueet, joilla palvelua tai osaamista tarvitaan. Ilman asetettuja rajauksia ilmoitus koskee koko Suomea.':
        'Avgränsa vid behov de områden i vilka tjänsten eller kompetensen söks. Utan fastställda avgränsningar gäller annonsen hela Finland.',
      'Ilmoita, mihin päivämäärään asti ilmoitus on voimassa. Voimassaoloaika on enintään 12 kuukautta. Voimassaolon  jälkeen ilmoituksen voi uusia Materiaalitorissa ilmoittajan ”omien sivujen” kautta.':
        'Ange till vilket datum annonsen gäller. Giltighetstiden är högst 12 månader. Efter att annonsens slutat gälla kan den förnyas på Materialtorget via annonsörens ”egna sidor”.',
      'Organisaation esittely':
        'Presentation av organisationen',
      'Muuta huomioitavaa': 'Annat att beakta',
      tiistaisin: 'tisdagar',
      torstaisin: 'torsdagar',
      Ympäristöministeriö: 'Miljöministeriet',
      'Ympäristöministeriön logo': 'Logo av Miljöministeriet',
      'Motivan logo': 'Logo av Motiva',
      VALTIONEUVOSTO: 'STATSRÅDET',
      Vaihde: 'Växel',
      'valtioneuvoston vaihde': 'statsrådets växel',
      Kirjaamo: 'Registratorskontor',
      Faksi: 'Fax',
      'Tämä on pyyntö kunnan toissijaisesta jätehuoltopalvelusta. Palvelun edellytyksenä oleva muun palvelutarjonnan puute on todennettu tekemällä tarjouspyyntö tarvittavasta jätehuoltopalvelusta Materiaalitoriin. Tarjouspyyntöön ei ole 14 vuorokauden vähimmäismääräajassa tullut lainkaan tarjouksia tai saadut tarjoukset on arvioitu laadultaan tai hinnaltaan kohtuuttomiksi.':
        'Detta är en begäran om kommunal avfallshantering i andra hand. Bristande annat tjänsteutbud som är en förutsättning för tjänsten har konstaterats genom att göra en offertbegäran på Materialtorget om den avfallshanteringstjänst som behövs. Inga offerter har inkommit på offertbegäran inom minimitidsfristen på 14 dygn eller de inkomna offerterna har till sin kvalitet eller pris bedömts vara oskäliga.',
      'Tarkemmat tiedot pyynnön tekijästä, jätteestä ja sen sijainnista, haettavasta palvelusta sekä ilmoituksen julkaisu- ja päättymisajankohdat löytyvät pyynnön ohessa olevasta ilmoituksesta.':
        'Närmare information om den som gjort begäran, avfallet och var det är beläget, tjänsten som söks samt publicerings- och sluttidpunkter för annonsen finns i annonsen som bifogas till begäran.',
      'Täytä sopimustiedot, allekirjoita ja lähetä sopimus asiakkaalle hyväksyttäväksi.':
        'Fyll i avtalsuppgifterna, underteckna och skicka avtalet till kunden för godkännande.',
      'Jätelain (646/2011) 33 §:n mukaan kunnan jätelaitoksen on tehtävä sopimus toissijaisesta jätehuoltopalvelusta, kun kyseessä on muusta kuin ennalta arvaamattomasta kiireestä johtuva palvelu, jonka arvo on vähintään 2000 euroa vuodessa. \n\n  Sopimus voidaan tehdä hyödyntämällä tätä sopimuslomaketta. Mikäli sopimus tehdään Materiaalitorin ulkopuolella, on olennaiset sopimustiedot tuotava Materiaalitoriin viimeistään 14 vuorokauden kuluttua sopimuksen tekemisestä. Täyttämällä tämän lomakkeen pyydetyt tiedot asianmukaisesti, erillistä tietojen toimittamista ei tarvitse tehdä.  \n\n Täytetty sopimuslomake allekirjoitetaan ja  lähetetään palvelupyynnön esittäjälle hyväksyttäväksi ja allekirjoitettavaksi. Sopimuksen voi allekirjoittaa vahvasti tunnistautuneena.':
        'Enligt 33 § i avfallslagen (646/2011) skall kommunens avfallsanläggning ingå ett avtal om avfallshanteringstjänst som tillhandahålls i andra hand, då det är frågan om annat än oförutsedd och brådskande situation, trots att värdet på tjänsten i andra hand som behövs skulle vara minst 2 000 euro. \n\n Avtalet kan göras på Materialtorget med hjälp av denna färdiga avtalsmall. Om avtalet ingås utanför Materialtorget, skall väsentliga uppgifter om avtalet lämnas in till Materialtorget senast 14 dygn från det att avtalet ingåtts. Genom att omsorgsfullt fylla i uppgifterna på denna blankett behöver inte uppgifterna lämnas in skilt.  \n\n Det ifyllda kontraktet undertecknas och skickas till begäraren av tjänsten för att godkännas och undertecknas. Avtalet kan undertecknas genom stark autentisering.',
      'Jätelain (646/2011) 33 §:n mukaan kunnan jätelaitoksen on tehtävä sopimus toissijaisesta jätehuoltopalvelusta, kun kyseessä on muusta kuin ennalta arvaamattomasta kiireestä johtuva palvelu, jonka arvo on vähintään 2000 euroa vuodessa. \n\n Mikäli sopimus tehdään Materiaalitorin ulkopuolella, on olennaiset sopimustiedot tuotava Materiaalitoriin viimeistään 14 vuorokauden kuluttua sopimuksen tekemisestä. Täytä vähintään oheiset pakolliseksi merkityt kohdat.':
        'Enligt 33 § i avfallslagen (646/2011) ska en kommunal avfallsanläggning ingå ett avtal om avfallshantering i andra hand, när det är frågan om en tjänst som föranleds av någon annan orsak än oförutsebar brådska och värde på avfallshanteringen är minst 2 000 euro per år. \n\n Om avtalet ingås utanför Materialtorget, ska de väsentliga avtalsuppgifterna föras in till Materialtorget senast inom 14 dygn från det att avtalet ingåtts. Fyll åtminstone i punkterna som är märkta som obligatoriska nedan.',
      'Tietojen tuominen kunnan toissijaisesta jätehuoltopalvelusta tehdystä sopimuksesta':
        'Hämtning av information från avtalet om kommunal avfallshantering i andra hand',
      'Voit antaa sopimukselle nimen ja/tai numeron/tunnisteen. Lisäksi voit antaa viitenumeron, joka voi olla esimerkiksi asiakasnumero tai –tunniste.':
        'Du kan ge avtalet ett namn och/eller ett nummer/en beteckning. Dessutom kan du ange ett referensnummer, som kan vara till exempel ett kundnummer eller en kundbeteckning.',
      'Tässä sopimuksessa sovitaan jätelain (646/2011) 33 §:n mukaisesta kunnan toissijaisestajätehuoltopalvelusta, sen hinnasta ja kestosta sekä vastaanotettavasta jätteestä ja sen määrästä.Kunnan on tehtävä toissijaisesta jätehuoltopalvelusta sopimus, kun kyseessä on muusta kuin ennalta arvaamattomasta kiireestä johtuva palvelu, jonka arvo on vähintään 2000 euroa vuodessa. Kunnan toissijaisen jätehuoltopalvelun edellytyksenä oleva muun palvelutarjonnan puute on todennettu etsimällä tarvittavaa jätehuoltopalveluaMateriaalitorissa.':
        'Detta avtal gäller kommunal avfallshantering i andra hand i enlighet med 33 § i avfallslagen (646/2011), dess pris och varaktighet samt avfallet som mottas och dess mängd. Kommunen ska ingå ett avtal om kommunal avfallshantering i andra hand när det är frågan om en tjänst som föranleds av någon annan orsak än oförutsebar brådska och värde på avfallshanteringen är minst 2 000 euro per år. Bristande annat tjänsteutbud som är en förutsättning för kommunal avfallshantering i andra hand har konstaterats genom att på Materialtorget söka den avfallshanteringstjänst som behövs.',
      'Määritä palvelun yksikköhinta (euroa per tonnia kyseistä jätettä). ':
        'Definiera tjänstens jämförpris (euro per ton av det aktuella avfallet). ',
      'Määritä päivämäärä, johon asti sopimus on voimassa. Sopimuksen enimmäiskesto on kolme (3) vuotta. Sopimus tulee voimaan, kun molemmat osapuolet ovat allekirjoittaneet sopimuksen.':
        'Fastställ datumet som avtalet gäller till. Avtalets maximilängd är tre (3) år. Avtalet träder i kraft när det har undertecknats av båda parterna.',
      'Sopimus vaatii vielä allekirjoituksen henkilöltä jolla on allekirjoitusvaltuutus, ennen sopimuksen lähettämistä pyynnön tekijälle.':
        'Innan kontraket kan undertecknas och skickas till avtaslparten kräver kontraktet ännu en underskrift av en person med undertecknandefullmakt.',
      'Sopimuksen on määräaikaisuudestaan huolimatta oltava molempien osapuolten irtisanottavissa sopimuksessa määritellyn kohtuullisen irtisanomisajan kuluttua. Määritä tähän irtisanomisehto irtisanomisaikoineen.':
        'Trots att avtalet gäller för viss tid ska det kunna sägas upp av båda parterna inom rimlig uppsägningstid på det sätt som definieras i avtalet. Ange här uppsägningsvillkoret med uppsägningstider.',
      'Mahdolliset lisätiedot ja sopimusehdotliitteiden muodossa. Sallitut tiedostomuodot ovat jpg, png, gif, pdf, doc(x), xls(x) ja ppt(x).':
        'Eventuell tilläggsinformation och avtalsvillkor i form av bilagor. Tillåtna filformat är jpg, png, gif, pdf, doc(x), xls(x) och ppt(x).',
      'Täytä kaikki pakolliset kentät! (Palvelu, hinta, irtisanomisehdot ja sopimuksen voimassaoloaika.)':
        'Fyll i alla obligatoriska fält! (Tjänst, pris, uppsägningsvillkor och avtalets giltighetstid.)',
      'Sopimus tulee voimaan, kun molemmat sopimusosapuolet ovat allekirjoittaneet sopimuksen. Sähköinen allekirjoittaminen tapahtuu vahvan tunnistautumisen avulla.':
        'Avtalet träder i kraft när det har undertecknats av båda avtalsparterna. Elektronisk underteckning sker via stark autentisering.',
      'Näytä alkuperäinen pyyntö': 'Visa ursprunglig begäran',
      'Alkuperäinen ilmoitus tarjouksineen' : 'Ursprunglig annons med offerten',
      'Pienennä alkuperäinen pyyntö': 'Minimera ursprunglig begäran',
      'Jätelain (646/2011) 33 §:n mukaan kunnan jätelaitoksen on tehtävä sopimus toissijaisesta jätehuoltopalvelusta, kun kyseessä on muusta kuin ennalta arvaamattomasta kiireestä johtuva palvelu, jonka arvo on vähintään 2000 euroa vuodessa. Sopimus voidaan tehdä hyödyntämällä tätä sopimuslomaketta. Jätettä ja  osapuolia koskevat tiedot ovat tulleet sopimukseen suoraan Materiaalitorissa tehdystä ilmoituksesta ja palvelupyynnöstä. Kunnan jätelaitos on täydentänyt sopimuslomaketta palvelua koskevilta osilta ja allekirjoittanut sopimuksen. Kunnan toissijaista jätehuoltopalvelua pyytänyt voi hyväksyä ja allekirjoittaa sopimuksen vahvasti tunnistautuneena. Allekirjoitettu sopimus tallentuu omiin tietoihin.':
        'Enligt 33 § i avfallslagen (646/2011) ska en kommunal avfallsanläggning ingå ett avtal om avfallshantering i andra hand, när det är frågan om en tjänst som föranleds av någon annan orsak än oförutsebar brådska och värdet på avfallshanteringen är minst 2 000 euro per år. Avtalet kan ingås genom att utnyttja denna avtalsblankett. Uppgifterna om avfallet och parterna har hämtats till avtalet direkt från annonsen och tjänstebegäran som gjorts på Materialtorget. Den kommunala avfallsanläggningen har kompletterat avtalsblanketten i fråga om de delar som gäller tjänsten och undertecknat avtalet. Den som begärt kommunal avfallshantering i andra hand kan godkänna och underteckna avtalet genom stark identifiering. Det undertecknade avtalet sparas under Mina uppgifter.',
      'Otamme vastaan pyyntöjä kunnan toissijaisesta jätehuoltopalvelusta Materiaalitorin kautta':
        'Begäran om kommunal avfallshantering i andra hand lämnas in via Materialtorget',
      'Tiedot jätelaitoksen TSV-palveluista':
        'Uppgifter om avfallsanläggningens tjänster för avfallshantering i andra hand',
      'Tiedot jätelaitokset TSV-palveluista- lisätiedot':
        'Ange vid behov uppgifter om den avfallshantering som kommunen ordnar i andra hand och som tillhandahålls av avfallsanläggningen. Uppgifterna syns när någon ber om kommunal avfallshantering i andra hand. Uppgifterna visas alltså endast för avfallsinnehavare eller andra aktörer som har begärt offerter, inväntat och bedömt eventuella offerter och på grund av bristande utbud av andra tjänster bett om kommunal avfallshantering i andra hand. Uppgifterna kan vara t.ex. uppgifter om avfallsslag som tas emot, behandlingssätt, priser, tjänstens läge, närmare kontaktuppgifter eller en länk till avfallsanläggningens webbplats, där behövliga uppgifter finns. Vänligen se till att informationen är uppdaterad.',
      'Linkki lisätietoihin': 'Tillägga länk',
      'Jos tämä sijainti ei vastaa hakuasi: tarkenna osoitetietoa lisäämällä esimerkiksi kadun numero ja varmista että kadun nimi on kirjoitetty täsmälleen oikein. Laita katuosoitteeseen kadun nimen lisäksi vain katunumero - älä laita talon/rapun kirjaintunnuksia ym.':
        'Om denna plats inte motsvarar din sökning: precisera adressinformation genom att lägga till exempelvis gatans nummer och kontrollera att namnet på gatan inte är felstavat. Fyll endast i gatunumret utöver gatans namn i gatuadressen - fyll inte i husets/trappans bokstavskod eller liknande.',
      'Vakuutan, että organisaatiolla on tarvittavat viranomaisluvat ja hyväksynnät vastaanottaa kyseistä jätettä':
        'Jag försäkrar att organisationen har nödvändiga myndighetstillstånd och godkännanden för att ta emot avfallet i fråga',
      'Määrittele tarjousta koskevat ehdot. Kukin vastaa itse omista tarjouksista sekä niiden ehdoista ja niiden pohjalta mahdollisesti tehtävistä sopimuksista. Mikäli tarjous hyväksytään, tarjoaja sitoutuu tarjoamaan palvelun tarjouksensa yhteydessä ilmoittamiensa ehtojen mukaisesti.':
        'Definiera villkoren för anbudet. Var och en svarar själv för sina egna anbud samt deras villkor och eventuella avtal som ingås utgående från dessa. Om anbudet godkänns förbinder sig tjänsteleverantören sig till att erbjuda tjänsten i enlighet med villkoren som uppgivits i samband med anbudet.',
      'Palvelun sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa erityisesti mm. käsittelypalveluja ja varastointia tarjotessa. Mikäli tarjoat kuljetuspalvelua, laita sijainti, josta jäte noudetaan.':
        'Tjänstens plats, åtminstone den kommun där avfallet finns. Det rekommenderas att du anger gatuadressen, i synnerhet när det gäller bl.a. behandlingstjänster och lagring. Om du erbjuder en transporttjänst ska du fylla i platsen där avfallet hämtas.',
      'Valitse kunnan jätelaitos, jolle pyyntö osoitetaan. Pyyntö tulee ensisijaisesti kohdistaa lähimmälle jätelaitokselle.':
        'Välj den kommunala avfallsanläggningen som begäran är riktad till. Begäran ska i första hand riktas till den närmaste avfallsanläggningen.',
      'Tarjouksia arvioidessa huomioi ainakin seuraavat asiat:\n\n 1) Jätteen saa luovuttaa vain vastaanottajalle, jolla on jätelain mukainen hyväksyntä vastaanottaa jätettä. Jätteen haltijan tulee varmistaa ennen jätehuoltopalvelua koskevan sopimuksen tekemistä, että kyseisellä palveluntarjoajalla on tarvittavat viranomaisluvat ja -hyväksynnät vastaanottaa jäte.\n\n 2) Noudata jätelain etusijajärjestystä. Jätteen haltijan on ensisijaisesti valmisteltava jäte uudelleenkäyttöä varten tai toissijaisesti kierrätettävä se. Jos kierrätys ei ole mahdollista, jätteen haltijan on hyödynnettävä jäte muulla tavoin, mukaan lukien hyödyntäminen energiana. Jos hyödyntäminen ei ole mahdollista, jäte on loppukäsiteltävä.':
        'Vid bedömningen av anbud ska åtminstone följande saker beaktas:\n\n 1) Avfallet får endast överlämnas till en mottagare som har ett godkännande enligt avfallslagen att motta avfall. Avfallsinnehavaren ska innan ett avtal om avfallshanteringstjänst ingås försäkra sig om att den aktuella tjänsteleverantören har rätt att ta emot avfallet.\n\n 2) Observera skyldigheten att iaktta prioriteringsordningen i avfallslagen. Enligt prioriteringsordningen ska i första hand mängden av och skadligheten hos det avfall som verksamheten ger upphov till minskas. Om avfall emellertid uppkommer, ska avfallsinnehavaren i första hand förbereda avfallet för återanvändning och i andra hand materialåtervinna det. Är materialåtervinning inte möjlig, ska avfallsinnehavaren återvinna avfallet på något annat sätt, exempelvis som energi. Om avfallet inte kan återvinnas ska det bortskaffas.',
      'Kunnan on toimitettava vuosittain maaliskuun loppuun mennessä Materiaalitoriin olennaiset tiedot muun kuin jätelain 33 §:n 2 momentissa tarkoitetun kunnan toissijaisen jätehuoltopalvelun kokonaisarvosta ja palvelua käyttävien jätteen haltijoiden kokonaismäärästä sekä olennaiset tiedot annetusta palvelusta jätelajeittain ja käsittelymenetelmittäin eriteltyinä. \n\n Tällä tarkoitetaan tietoja Materiaalitorin käyttövelvoitteen ulkopuolelle jäävästä kunnan toissijaisesta jätehuoltopalvelusta eli 2000 euron kynnysarvon alle jäävästä kunnan toissijaisesta jätehuoltopalvelusta sekä ennakoimattomasta kiireestä johtuvasta ja siirtymäaikoina Materiaalitorin käyttövelvoitteen ulkopuolelle rajatusta kunnan toissijaisesta jätehuoltopalvelusta.':
        'Kommunen ska innan slutet av mars varje år lämna in till Materialtorget väsentliga uppgifter om det totala värdet för kommunens avfallshantering i andra hand och det totala antalet avfallsinnehavare samt väsentliga uppgifter om tjänsten som erbjuds enligt avfallstyp och hanteringsmetod. Detta gäller inte sådan kommunal avfallshantering in andra hand som avses i avfallslagen 33 § 2 mom. \n\nMed detta avses uppgifter om kommunens avfallshantering i andra hand som inte omfattas av skyldigheten att använda Materialtorget, dvs. vars värde underskrider 2 000 euro eller beror på oförutsägbar brådska eller övergångsperioder.',
      'Ilmoita ja katsele tietoja Materiaalitorin käyttövelvoitteen ulkopuolelle jäävästä kunnan toissijaisesta jätehuoltopalvelusta.':
        'Uppge och granska uppgifter om kommunens avfallshantering i andra hand som inte omfattas av skyldigheten att använda Materialtorget.',
      'Lisää tiedot muusta kunnan toissijaisesta jätehuoltopalvelusta jätelajeittain ja käsittelymenetelmittäin eriteltynä sekä tiedot kyseisen palvelun kokonaisarvosta ja asiakasmäärästä vuosittain. Tallenna tiedot lopuksi, jolloin ne tallentuvat samaan näkymään kyseisille paikoilleen. Rivien poistaminen poistaa tallennetut tiedot kokonaan.':
        'Lägg till uppgifter om kommunens avfallshantering i andra hand enligt avfallstyp och hanteringsmetod samt uppgifter om det årliga totala värdet och antalet kunder för tjänsten. Avsluta med att spara uppgifterna, varvid de sparas så som de visas i samma vy. Radering av rader raderar även de sparade uppgifterna.',
      'Tämä on vapaamuotoinen yhteydenotto ilmoituksen tekijään. Voitte halutessanne jatkaa yhteydenpitoa palvelun ulkopuolella.':
        'Detta är ett frivilligt meddelande till annonsens upphovsman. Om ni önskar kan ni fortsätta hålla kontakt utanför tjänsten.',
      'Ajankohtaista': 'Aktuellt',
      'Hae tietoja hankintailmoituksesta Hilmasta': 'Sök uppgifter om upphandlingsannonsen i Hilma',
      'Ilmoitusnumero': 'Annonsnummer',
      'Hae Hilmasta hankintailmoituksen tietoja kirjoittamalla tähän ilmoituksen numero. Numero löytyy hankintailmoituksen yläreunasta ja alkaa vuosiluvulla, jolloin ilmoitus on luotu. Kirjoita numero huolellisesti, vuosiluvun jälkeen tulee väliviiva.' : 'Sök upphandlingsannonsens uppgifter i Hilma genom att skriva in annonsnummer här. Numret finns i övre kanten av upphandlingsannonsen och börjar med det årtal då annonsen skapats. Skriv in numret omsorgsfullt; årtalet följs av ett bindestreck.',
      'Hae tietoja': 'Sök uppgifter',
      'Haulla löytyneet ilmoitukset': 'Annonser som hittades genom sökningen',
      'Hankinnan otsikkotiedot': 'Upphandlingens rubrikuppgifter',
      'Hilma-ilmoitusnumero': 'Hilma-annonsens nummer',
      'Hankinnan tyyppi': 'Upphandlingens typ',
      'Ilmoittajan Y-tunnus': 'Annonslämnarens FO-nummer',
      'Hilma-ilmoitus on vielä voimassa': 'Upphandlingsannonsen i Hilma gäller fortfarande. Begäran kan göras först efter att tidsfristen för att lämna anbud har gått ut.',
      'Hilma-ilmoitus kuuluu toiselle organisaatiolle': 'Upphandlingsannonsen har gjorts av en annan organisation. Du kan inte göra en begäran utifrån den här annonsen.', 
      'Pyydä kunnan toissijaista jätehuoltopalvelua Materiaalitoriin tai julkisten hankintojen ilmoituskanavaan Hilmaan tehdyn ilmoituksen pohjalta. Huomaathan, että ilmoituksen voimassaoloajan on oltava päättynyt. Pyyntö ohjataan valitsemasi kunnan tai kunnan jätelaitoksen käsiteltäväksi.': 
        'Begär om kommunal avfallshantering i andra hand utifrån en annons som lämnats på Materialtorget eller i Hilma, annonseringskanalen för offentliga upphandlingar. Observera att annonsen måste ha upphört att gälla. Begäran styrs för handläggning till den kommun eller kommunala avfallsanläggning du valt.',
      'Materiaalitorissa jo olevasta ilmoituksesta': 'Utifrån en annons som redan finns på Materialtorget',
      'Hilma-hankintajärjestelmän ilmoituksesta': 'Utifrån en annons i upphandlingssystemet Hilma',
      'Jos ilmoituksesi on ollut julkaistuna Materiaalitorissa, aloita pyynnön tekeminen tästä.': 'Om din annons tidigare har publicerats på Materialtorget kan du göra en begäran här.',
      'Jos olet tehnyt hankintailmoituksen Hilmaan, aloita pyynnön tekeminen tästä.': 'Om du har gjort en upphandlingsannons i Hilma kan du göra en begäran här.',
      'Pyydä palvelua': 'Begär om en tjänst',
      'Hae tietoja Hilmasta': 'Sök uppgifter i Hilma',
      'Täydennä tiedot': 'Komplettera uppgifterna',
      'Ilmoitus luotu Hilmassa': 'En annons har skapats i Hilma',
      'Ilmoitus vanhentunut Hilmassa': 'Annonsen i Hilma har upphört att gälla',
      'Palvelutarpeen perustelu': 'Motivering av servicebehovet',
      'Pyydettävä palvelu ja sen kesto': 'Den tjänst som begärs och tjänstens varaktighet',
      'Esikatsele tiedot': 'Förhandsgranska uppgifterna',
      'Esikatsele ja tallenna tiedot': 'Förhandsgranska och spara uppgifterna',
      'Muokkaa tietoja':'Redigera uppgifter',
      'Viimeistele ja lähetä pyyntö': 'Skicka begäran',
      'Pyydettävän palvelun kuvaus': 'Beskrivning av den begärda tjänsten',
      'Valitse palvelu, jota ensisijaisesti pyydät jätteelle': 'Välj den tjänst som i första hand ska begäras för avfallet',
      'no-offers-received': 'Inga anbud lämnades i upphandlingen',
      'received-offer-unsuitable': 'De anbud som lämnades i upphandlingen motsvarade inte de uppställda kraven',
      'other-reason': 'Någon annan orsak',
      'Tarkasta puuttuvat tai virheelliset pakolliset tiedot': 'Kontrollera de obligatoriska uppgifter som saknas eller som är felaktiga',
      'Jätteen alkuperä, koostumus, olomuoto (kiinteä, neste, liete, kaasu)': 'Avfallets ursprung, konsistens, form (fast, vätska, slam, gas)',
      'Tiedot mahdollisista epäpuhtauksista ja tarkennukset vaaraominaisuuksista tai muita jätteen vastaanottajan kannalta oleellisia tietoja jätteestä.' : 
        'Uppgifter om eventuella orenheter och precisering av farliga egenskaper eller andra väsentliga uppgifter om avfallet som mottagaren bör veta.',
      'Tiedot mahdollisista epäpuhtauksista ja tarkennukset vaaraominaisuuksista tai muita jätteen vastaanottajan löytämisen kannalta oleellisia tietoja jätteestä.':
        'Uppgifter om eventuella orenheter och preciseringar om farliga egenskaper eller andra uppgifter som är väsentliga med tanke på att hitta en mottagare för avfallet.',
      'Yksittäisten jäte-erien määrä, koko ja toistuvuus tai muita tarkennettavia tietoja jätteen määrästä tai palveluntarpeen toistuvuudesta, jotka voivat olla jätteen vastaanottajan kannalta oleellisia.' : 
        'Mängd, storlek och upprepad förekomst i fråga om enskilda avfallspartier eller andra preciserande uppgifter om avfallsmängden eller servicebehovets regelbundenhet som kan vara väsentliga för mottagaren av avfallet.',
      'Palvelua pyydettäessä on syytä huomioida jätelain mukainen velvollisuus noudattaa etusijajärjestystä. \n\n Etusijajärjestyksen mukaan ensisijaisesti on vähennettävä syntyvän jätteen määrää ja haitallisuutta. Jos jätettä kuitenkin syntyy, jätteen haltijan on ensisijaisesti valmisteltava jäte uudelleenkäyttöä varten tai toissijaisesti kierrätettävä se. Jos kierrätys ei ole mahdollista, jätteen haltijan on hyödynnettävä jäte muulla tavoin, mukaan lukien hyödyntäminen energiana. Jos hyödyntäminen ei ole mahdollista, jäte on loppukäsiteltävä.' : 
        'Vid begäran om en tjänst bör man beakta skyldigheten att iaktta prioritetsordningen enligt avfallslagen. \n\n Enligt prioriteringsordningen ska i första hand mängden av och skadligheten hos det avfall som verksamheten ger upphov till minskas. Om avfall emellertid uppkommer, ska avfallsinnehavaren i första hand förbereda avfallet för återanvändning och i andra hand materialåtervinna det. Är materialåtervinning inte möjlig, ska avfallsinnehavaren återvinna avfallet på något annat sätt, exempelvis som energi. Om avfallet inte kan återvinnas ska det bortskaffas.',
      'Ensisijaisesti pyydetty palvelu.' : 'Den tjänst som begärts i första hand.',
      'Tarkenna pyytämääsi palvelua' : 'Precisera den begärda tjänsten',
      'Vaatimukset ja toiveet pyydettävälle palvelulle tai muuta kuvausta palvelusta.' : 'Krav och önskemål på den begärda tjänsten eller annan beskrivning av tjänsten.',
      'Pyydettävän palvelun alku- ja päättymisaika, sopimuskauden pituus tai muu palvelunajankohtaan liittyvä tarkennus.' : 'Tid för inledande och avslutande av den begärda tjänsten, avtalsperiodens varaktighet eller annan precisering av tidpunkten för tjänsten.',
      'Jätteen sijainti vähintään sijaintikunnan tarkkuudella. Katuosoitteen antaminen on suositeltavaa erityisesti jätteen kuljetuspalvelua pyydettäessä.' : 'Avfallets läge åtminstone enligt lokaliseringsort (kommun). Det rekommenderas att gatuadressen uppges i synnerhet vid begäran om transport av avfall.',
      jateilmoituksen_luontisivun_jate_hilma: 'Term som beskriver avfallets eller materialets typ så bra som möjligt.\n\nDu kan välja endast ett avfall per annos. Om du behöver tjänster för flera olika avfall kan du göra flera begäran om samma upphandlingsannons. Om du sätter in en annons om blandat avfall, ange närmare vad avfallet består av under punkten ”Beskrivning av avfallet”.\n\nObs!\n\nAvfall som omfattas av producentansvar (däck, fordon, elektrisk och elektronisk utrustning, batterier och ackumulatorer, papper, förpackningar) bör i regel föras till en mottagningsplats som godkänts av producenten.\n\nKommunen ansvarar för kommunalt avfall från boende och från kommunens förvaltnings- och serviceverksamhet, vilket innebär att kommunen ordnar avfallshanteringen för detta avfall.\n\nMer information om ansvaret för avfallshanteringen\n<17>https://www.ymparisto.fi/sv-FI/Konsumtion_och_produktion/Avfall_och_avfallshantering/Avfallshantering__vem_ansvarar_for_och_ordnar_den</17>',
      'Tarkempi perustelu' : 'Närmare motivering',
      'Muuta mahdollista lisätietoa' : 'Andra eventuella tilläggsuppgifter',
      'Hankintailmoitus, jota pyyntö koskee' : 'Den upphandlingsannons som begäran gäller',
      'Näytä kaikki tiedot' : 'Visa alla uppgifter',
      'Pienennä' : 'Minska',
      'Hankintayksikön nimi' : 'Den upphandlande enhetens namn',
      'Tiedot tallennettu onnistuneesti.' : 'Uppgifterna sparades korrekt.',
      'Ominaisuus on väliaikaisesti poissa käytöstä': 'Funktionen är tillfälligt inaktiverad',
      'Tarkenna palvelua (pakollinen)': 'Precisera tjänsten (obligatorisk)',
      'Tarkenna valintaa': 'Precisera val',
      'Kunta tai alueet': 'Kommun eller områden',
      'Puhelinneuvonta (ti ja to)': 'Telefonrådgivning (ti och to)',
      'Soittoaikojen ulkopuolella voit jättää soittopyynnön vastaajaan ja olemme sinuun yhteydessä mahdollisimman pian. Jätä yhteystietosi, kerro mitä asiasi koskee ja milloin tavoittaa parhaiten.': 'Utanför telefontiderna kan du lämna en begäran om återuppringning i telefonsvararen så kontaktar vi dig så fort som möjligt. Lämna dina kontaktuppgifter, berätta vad ditt ärende gäller samt när du bäst kan nås.',
      'Puhelinneuvonta ei palvele heinäkuussa, yhteydenotot sähköpostilla.':'Telefonrådgivningen är stängd i juli. Ta kontakt per epost.',
      'Sähköposti (ma-pe)': 'Epost (må-fre)',
      'Sähköpostitse palvelemme kaikkina arkipäivinä ja pyrimme vastaamaan viesteihin saman päivän aikana.': 'Vi betjänar alla vardagar via epost. Vi strävar efter att svara på epostmeddelanden under samma dag.',
      'Avoimet kyselyt' : 'Öppna förfrågningar',
      'Sinulla on avoimia kyselyitä. Päästäksesi lisäämään uusia ilmoituksia, vastaa ensin avoimiin kyselyihin.': 'Du har öppna förfrågningar. För att kunna lägga ut nya annonser ska du först svara på öppna förfrågningar.',
      'Vastaa kyselyyn' : 'Besvara',
      'Kysely sulkeutuneesta ilmoituksesta': 'Förfrågan om stängd annons',
      'Vastaus kyselyyn': 'Svarar',
      'Sain palvelun': 'Jag fick tjänsten',
      'Sain palvelun Materiaalitorin ilmoituksen perusteella': 'Jag fick tjänsten via annonsen på Materialtorget',
      'Syy sulkemiselle': 'Stängdes orsak',
      'Syy sulkeutumiselle': 'Orsak till stänging av annonsen',
      'En saanut Materiaalitorista yhteydenottoja': 'Jag fick inga kontakter via Materialtorget',
      'Lopetin palveluntarjonnan': 'Jag tillhandahåller inte tjänsten längre',
      'Muu syy': 'Annan orsak',
      'Voit antaa lisätietoja Materiaalitorilla tarjoamastasi palvelusta tai antaa vapaata palautetta Materiaalitorista': 'Du kan ge mer information om den tjänst du erbjuder på Materialtorget eller ge fritt formulerad respons om Materialtorget',
      'Voit antaa lisätietoja Materiaalitorista etsimästäsi palvelusta tai antaa vapaata palautetta Materiaalitorista': 'Du kan ge mer information om den tjänst du söker på Materialtorget eller ge fritt formulerad respons om Materialtorget',
      'Löysin materiaalille vastaanottajan': 'Jag hittade en mottagare för materialet',
      'Materiaalitorin ilmoituksen perusteella': 'Via annonsen på Materialtorget',
      'Jos luovutit materiaalin': 'Om du överlämnade materialet',
      'Sain maksun materiaalista': 'Jag fick betalt för materialet',
      'Maksoin materiaalin vastaanottamisesta': 'Jag betalade för mottagningen av materialet',
      'Luovutin materiaalin maksutta': 'Jag överlät materialet utan kostnad',
      'Kuinka monelle vastaanottajalle luovutit materiaalia, lukumäärä': 'Till hur många mottagare överlämnade du materialet, antal',
      'Vastaanotettu materiaalin kokonaismäärä': 'Den totala mängden mottaget material',
      'Sain palvelun Materiaalitorin ulkopuolelta': 'Jag fick tjänsten utanför Materiatorget',
      'Palvelun tarve poistui muusta syystä': 'Behovet av tjänsten försvann av annan orsak',
      'Ilmoitus sulkeutui määräajan umpeuduttua': 'Annonsen stängdes eftersom giltighetstiden löpte ut',
      'Ilmoitus sulkeutui määräajan umpeuduttua ja julkaisen sen uudelleen': 'Annonsen stängdes eftersom giltighetstiden löpte ut och jag publicerar annonsen pånytt',
      'Lisätietoa luovuttamastasi materiaalista ja sen määrästä tai vapaata palautetta Materiaalitori-palvelusta': 'Mer information om det material du överlåtit och mängden eller fritt formulerad respons om tjänsten Materialtorget',
      'Löysin materiaalin': 'Jag hittade materialet',
      'Jos sait materiaalia': 'Om du fick materialet',
      'Maksan materiaalista': 'Jag betalar för materialet',
      'Sain maksun vastaanottamisesta': 'Jag fick betalt för att ta emot materialet',
      'Sain maksutta': 'Jag fick det gratis',
      'Kuinka monelta toimijalta sait materiaalia': 'Från hur många aktörer fick du material',
      'Kuinka monta yhteydenottoa sait yhteensä Materiaalitorissa tai sen ulkopuolelta ilmoituksen perusteella?': 'Hur många kontakter fick du sammanlagt på Materialtorget eller utanför det på basis av annonsen?',
      'Saamasi materiaalin kokonaismäärä': 'Den totala mängden material du fått',
      'Ilmoituksen tarve poistui muusta syystä': 'Behovet av annonsen försvann av annan orsak',
      'Lisätietoa saamastasi materiaalista tai vapaata palautetta Materiaalitori-palvelusta': 'Mer information om det material du fått eller fritt formulerad respons om tjänsten Materialtorget',
      'Lisätietoa saamastasi palvelusta tai vapaata palautetta Materiaalitori-palvelusta': 'Mer information om den tjänsten du fått eller fritt formulerad respons om tjänsten Materialtorget',
      'Lisätietoa tai palautetta Materiaalitori-palvelusta': 'Tillägssinformation eller feedback om Materialtorget-tjänsten',
      'Saatu palvelu': 'Erhållen tjänst',
      'Tarkenna saamaasi palvelua': 'Specificera tjänsten du fått',
      'Arvio palveluun toimitettavan jätteen määrästä': 'Uppskattning av mängden avfall som levereras till tjänsten',
      'Jos kyse oli kertaerästä, ilmoita koko määrä valituissa yksiköissä, jos jatkuvasti syntyvästä materiaalista, arvioi koko sopimuskautena toimitettava materiaali.': 'Om det är fråga om ett engångsparti, ange hela mängden och välj enhet. Om det är fråga om material som uppstår hela tiden, uppskatta hur mycket material som ska levereras under hela avtalsperioden.',
      'En saanut palvelua': 'Jag fick ingen tjänst',
      'Kiitos vastauksistasi!': 'Tack för dina svar!',
      'Virhe tallennuksessa.': 'Fel vid lagringen.',
      'Palautekyselyt': 'Respons',
      'Ei vastauksia kyselyihin.': 'Inga svaren',
      'Lisää': 'Fler',
      'Vie tiedot': 'Exportera',
      'Toimiala': 'Bransch',
      'Sulkeutunut': 'Stängt',
      'Vastattu': 'Svarade',
      'Maksu': 'Betalning',
      'Materiaalin kokonaismäärä': 'Total materialmängden',
      'Huomioithan että puhelinnumeron tai sähköpostin vaihtaminen astuu voimaan seuraavan Materiaalitorin sisäänkirjautumisen yhteydessä.' : 'Observera, att byte av telefonnumer eller epost träder i kraft följande gång du loggar in på Materialtorget.',
      'Yhteystietosi välitetään viestin vastaanottajalle viestin mukana.': 'Dina kontaktuppgifter levereras till meddelandets mottagare i samband med meddelandet.',
      'Näytä ilmoitukset kartalla': 'Visa annonserna på kartan',
      'Poista hakukriteerit': 'Ta bort sökkriterier',
      'Ohje': 'Anvisning',
      'TSV-palvelu': 'TSV-tjänst',
      'Haluan TSV-palvelun.': 'Jag vill ha TSV-tjänsten.',
      'En tarvitse TSV-palvelua.': 'Jag behöver ingen TSV-tjänst.',
      'Voin ilmoituksen voimassaolon jälkeen tarvittaessa pyytää kunnan toissijaista jätehuoltopalvelua eli ns. TSV-palvelua.': 'Efter att anmälan är i kraft kan jag vid behov begära kommunal avfallshantering i andra hand, dvs. så kallad TSV-tjänst.',
      'Saan jätteen tai materiaalin käsittelyn muuta kautta.': 'Jag erhåller behandling av avfall eller material på annat sätt.',
      'Kirjoita materiaalin tai palvelun vapaamuotoinen ja kuvaava nimi. Tee otsikosta selkeä ja kuvaava sillä se on hakulistauksissa, ilmoituksen sivulla yms. kaikista näkyvin elementti.': 'Skriv ett fritt formulerat, beskrivande namn på materialet eller tjänsten. Gör rubriken tydlig och beskrivande, eftersom det är det synligaste elementet i sökresultat, annonssidan osv.',
      'Vältä otsikossa ”tarjotaan” –tyyppisiä termejä, koska se käy lukijalle hakuehdoista ja sivun tiedoista ilmi muutenkin.': 'Undvik termer av typen ”erbjudes” i rubriken, eftersom det framgår för läsaren även i övrigt i sökvillkoren och sidans uppgifter.',
      'Otsikon muotoilussa on hyvä ottaa huomioon, että ilmoituksia voidaan hakea myös otsikossa olevien sanojen perusteella.': 'Vid rubrikformuleringen är det bra att observera att annonser också kan sökas baserat på orden i rubriken.',
      'Voit lisätä ilmoitukseen lisätietoja kuvina ja muina liitetiedostoina': 'Du kan lägga till mer information i annonsen i form av bilder och andra bilagor',
      'Liitetiedostotyyppi tulee olla: JPG / PNG / GIF DOC(x) / XLS(x) /PPT(x)': 'Filformatet för bilagan ska vara: JPG / PNG / GIF DOC(x) / XLS(x) /PPT(x)',
      'Julkaistu': 'Publicerad',
      'Materiaalitori vauhdittaa kiertotaloutta': 'Materialtorget sätter fart på den cirkulära ekonomin',
      'Palvelun tarkenteet': 'Tjänst specifikationer',
      'Valitse ilmoitustyyppi': 'Välj typ av annons',
      'Aloita valitsemalla ilmoituksen tyyppi.': 'Börja med att välja typ av annons',
      'Näytä lisää': 'Visa mer',
      'Vähemmän': 'Mindre',
      'Määrä numeroina': 'Antal i siffror',
      validityInfo: 'Obs: Giltighetstid <1><3>avser annonsens giltighet på Materialtorget</3></1>, inte avtalets giltighetstid',
      'Kirjaudu sisään lisätäksesi ilmoituksen': 'Logga in för att lägga till annonsen',
      'Rekisteröidy Materiaalitoriin ja aloita käyttö': 'Registrera dig på Materialtorget och börja använda det',
      'Materiaalitorille rekisteröityäksesi tarvitset edustamasi organisaation valtuutuksen.': 'För att registrera dig på Materialtorget behöver du en fullmakt av den organisation du företräder.',
      'Rekisteröidyttyäsi Materiaalitoriin pääset laatimaan ilmoituksia, vastaamaan ilmoituksiin ja asettamaan hakuvahteja. Ilman rekisteröitymistä voit selata ilmoituksia.': 'När du har registrerat dig på Materialtorget kan du skapa annonser, besvara annonser och ställa in sökningsbevakare. Du kan bläddra i annonserna även utan registrering.',
      'Hae ilmoituksia': 'Sök annonser',
      'Uusimmat ensin': 'Senaste först',
      'Vanhimmat ensin': 'Äldsta först',
      'Ensin vanhenevat': 'De som förfaller först',
      'Pisimpään voimassa olevat': 'Längst giltiga',
      'Materiaalin tarkenne': 'Materialets specifikation',
      'Palvelun tarkenne': 'Tjänstens specifikation',
      'Onko jäte vaarallista?': 'Är avfallet farligt?',
      'Onko jäte pysyvää?': 'Är avfallet inert?',
      'Onko jäte POP-jätettä?': 'Är avfallet POP-avfall?',
      'POP-jätteen sisältämät pysyvät orgaaniset yhdisteet': 'De långlivade organiska föreningarna som finns i POP-avfall',
      'Pysyvät orgaaniset yhdisteet (POP) ovat myrkyllisiä, hitaasti hajoavia kemiallisia yhdisteitä, jotka kertyvät eliöihin ravintoketjussa ja kulkeutuvat kauas päästöpaikastaan ilman, veden tai muuttavien eläinlajien välityksellä.': 'Långlivade organiska föreningar (POP) är giftiga, långsamt nedbrytabara kemiska föreningar som ackumuleras i näringskedjans organismer och transporteras långa vägar från sin utsläppsplats via luft, vatten eller migrerande djurarter.',
      'Materiaalitorin infosivut': 'Materialtorgets informationssidor',
      'Lisää tietoa Materiaalitorin käytöstä löydät täältä.': 'Mer information om användningen av Materialtorget hittar du här.',
      'Materiaalitorin infosivuille.': 'Till Materialtorgets informationssidor.',
      'Materiaalitori osana Kiertotalous-Suomea': 'Materialtorget är en del av Cirkulär Ekonomi Finland',
      'Kiertotalous-Suomi on osaamisen ja tiedon solmukohta, joka yhdistää ratkaisuja etsivät toimijat ja niiden tarjoajat. Verkosto tukee yrityksiä, kuntia, oppilaitoksia, tutkimuslaitoksia ja kiertotalouden parissa toimivia oman kiertotalouspolun löytämisessä.':
        'Cirkulär Ekonomi Finland är ett nav för kunskap och information som förenar de aktörer som söker lösningar med leverantörer som tillhandahåller dem. Nätverket stöder företag, kommuner, läroanstalter, forskningsanstalter och aktörer inom cirkulär ekonomi i att hitta sin egen väg för cirkulär ekonomi.',
      'Tutustu Kiertotalous-Suomeen': 'Bekanta dig med Cirkulär Ekonomi Finland',
      'Infosivut': 'Informationssidor',
      'Materiaalitorin info-sivut kertovat lisää käyttömahdollisuuksista.': 'På Materialtorgets info-sidor hittar du fler användningsmöjligheter.'
    },
  },
};

const getLanguage = () => {
  const cookieLang = getFromCookies('language');
  if (cookieLang === 'fi-FI' || cookieLang === 'sv-FI') {
    return cookieLang;
  }
  return 'fi-FI';
};

i18n.use(reactI18nextModule).init({
  resources,
  lng: getLanguage(),
  keySeparator: false,
  nsSeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: false,
    withRef: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
    usePureComponent: true,
  },
});

export default i18n;
