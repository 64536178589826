import cx from 'classnames';
import { find, isEmpty, path, propEq } from 'ramda';
import React from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ILMOITUKSET, LISAA_ILMOITUS, MUOKKAA_ILMOITUS } from '../../routes';
import { filterImageAttachments, nonImageAttachmentList } from '../../utils/attachment-utils';
import { formatDate, formatDateNumber } from '../../utils/date-utils';
import { splitTextToParagraphsOnLinebreak } from '../../utils/text-utils';
import Accordion from '../Accordion/Accordion';
import OnlyPublicComponent from '../Auth/OnlyPublicComponent';
import PrivateComponent from '../Auth/PrivateComponent';
import Registrations from '../Company/Registrations';
import ImageList from '../ImageList/ImageList';
import InfoBanner from '../InfoBanner/InfoBanner';
import InfoBox from '../InfoBox/InfoBox';
import formStyles from '../Layout/Form.module.css';
import Map from '../Map/Map.js';
import { getEurefCoordinates } from '../RegionSelect/region-utils';
import CloseRfoButton from './CloseRfoButton';
import { LocationFromMaterialFragment, RfoTitleFragment } from './FormFragments';
import styles from './RequestForOffer.module.css';
import { isClosed, isExpired, isTsv, isWaste, isTSVActive, isHidden, isExpiringSoon, isTsvAllowed } from './rfo-utils';
import RfoOffers from './RfoOffrers';
import RfoTsvRequests from './RfoTsvRequests';
import * as RFO_TYPES from './types';
import ChromeErrorInfobox from './../Attachment/ChromeErrorInfobox';
import { Calender } from '../Icon/icons';

const View = ({ rfo, isInPreviewMode, viewOnly, t, regionsAndMunicipalities, ewcs, popWastes, i18n }) => {
  const id = path(['id'])(rfo);
  const { language } = i18n;

  const displayExpiresDate = (rfoData) => {
    return displayDate(`View expires ${rfoData.id}`, rfoData.expires, isExpiringSoon(rfoData));
  };
  const displayCreatedDate = (rfoData) => {
    return displayDate(`View created ${rfoData.id}`, rfoData.created, false);
  };

  const displayDate = (id, dateStr, warning) => {
    if (isNaN(Date.parse(dateStr))) {
      return <span>{t('Päivämäärän esittämisessä tapahtui virhe')}</span>;
    }
    const date = new Date(dateStr);
    return (
      <span className={styles.rfoViewCalendar}>
        <Calender          
          id={id}
          warning={warning}
          warningToolTipText={t('Sulkeutuu pian')}
          date={formatDateNumber(date)}            
        />
        <span>{formatDate(date)}</span>
      </span>
    );
  };

  const materialQuantity = (quantity) => (
    <span>
      {quantity.amount} {quantity.unitOfMeasure}
    </span>
  );

  const displayEwcCode = (ewcId, ewcList, lang = 'fi-FI') => {
    const ewc = find(propEq('id', ewcId))(ewcList);
    if (ewc) {
      const localeDisplayValue = lang === 'sv-FI' ? ewc.sv : ewc.fi;
      return `${ewcId} ${localeDisplayValue}`;
    }
    return '-';
  };

  const tsvButton = (rfo, isTsv, isWaste, disabled = false) => {
    if (isTsv && isWaste) {
      return (
        <>
        {isTsvAllowed(rfo) && rfo.allowedToMakeTsvRequest && (
        <PrivateComponent belongsToBusiness={rfo.company.businessId}>
          {disabled ? (
            <span className={cx('disabledLink', styles.uppercase)}>
              {t('Pyydä kunnan toissijaista jätehuoltopalvelua')}
            </span>
          ) : (
            <Link
              className={cx('buttonStyle', 'qa-openTsvCreateForm')}
              to={`${ILMOITUKSET}/${rfo.id}/teetsv`}
            >
              {t('Pyydä kunnan toissijaista jätehuoltopalvelua')}
            </Link>
          )}
        </PrivateComponent>
        )}
        </>
      );
    }
    return null;
  };

  const tsvSection = (rfo) => {
    const tsvButtonDisabled = rfo.tsvStatus !== 'tsv-allowed';
    if (isTsv(rfo)) {
      return (
        <>
          {tsvButton(rfo, isTsv(rfo), isWaste(rfo), tsvButtonDisabled)}
          {isTsvAllowed(rfo) && !rfo.hilmaNotice && rfo.allowedToMakeTsvRequest && (
            <InfoBox
            infoText={
              t(
                'Kyseessä on jätelain (646/2011) 33 §:n mukainen kunnan toissijainen jätehuoltopalvelu, jota jätteen haltijan on mahdollista pyytää muun palvelutarjonnan puuttuessa.\n\nJätelain mukaan jätteen haltija on velvollinen järjestämään jätteen jätehuollon, mikäli jätehuolto ei kuulu kunnan vastuulle tai jäte ole ns. tuottajavastuun alainen. Käytännössä tämä tarkoittaa sitä, että yritysten ja monien julkisten organisaatioiden on järjestettävä useimpien jätteidensä jätehuolto itse markkinoilla olevia jätehuoltopalveluja hyödyntäen.\n\nMikäli markkinoilta ei kuitenkaan löydy tarvittavaa palvelua, on sitä mahdollista pyytää kunnalta, sillä kunta on jätelain mukaan velvollinen järjestämään jätehuollon toissijaisesti myös muulle jätteelle, kuin sille josta se on ensisijaisesti vastuussa. Ensisijaisesti kunta on velvollinen järjestämään pääsääntöisesti asumisessa syntyvän jätteen sekä kunnan hallinto- ja palvelutoiminnassa syntyvän yhdyskuntajätteen jätehuollon. Kunnalla on lisäksi vaarallisen jätteen vastaanottoon ja käsittelyyn liittyviä vastuita.'
                ) +
                '\n\n' +
                t(
                  'Kunnan toissijaisen jätehuoltopalvelun edellytyksenä on, että jätteen haltija pyytää sitä yksityisen palveluntarjonnan puutteen vuoksi ja että jäte laadultaan ja määrältään soveltuu kuljetettavaksi tai käsiteltäväksi kunnan jätehuoltojärjestelmässä. Pyynnön voi jätteen haltijan puolesta esittää jätteen kuljettaja tai muu toimija osana tarjoamaansa jätehuoltopalvelua, kun pyyntö koskee jätteen käsittelyä. Pyynnön voi tehdä kuuden (6) kuukauden kuluessa ilmoituksen voimassaolon päättymisestä.'
                  )
                }
                />
          )}
        </>
      );
    } else {
      return '';
    }
  };

  const offerButton = (rfo) => {
    const isWasteRfo = isWaste(rfo);

    return (
    <>
      {rfo.allowedToMakeOffer && (
        <PrivateComponent doesNotBelongToBusiness={rfo.company.businessId}>
          <Link
            className={cx('buttonStyle', 'qa-createOfferBtn')}
            to={`${ILMOITUKSET}/${rfo.id}/teetarjous`}
          >
            {isWasteRfo ? t('Tee tarjous') : t('Vastaa ilmoitukseen')}
          </Link>
        </PrivateComponent>
      )}
    </>);
  };

  const editRfoButton = (rfo) => {

    const [material] = rfo.materials;
    const useTsv = path(['useTsv'])(material); 
    const businessId = path(['company', 'businessId'])(rfo);       
    
    return (
      <>
        { rfo.allowedToEdit && (
          <PrivateComponent belongsToBusiness={businessId}>
            <Link className={cx('buttonStyle', 'qa-editRfoBtn')} to={`${MUOKKAA_ILMOITUS}/${rfo.id}`}>
              {rfo.hilmaNotice ? t('Muokkaa tietoja') : t('Muokkaa ilmoitusta')}
            </Link>
            {useTsv && !rfo.hilmaNotice && editRfoWarning()}
          </PrivateComponent>
          )}
      </>
    );
  };

  const editRfoWarning = () => {
    const content = (
      <Trans i18nKey="huomaa_ilmoituksen_muokkaaminen">
        <strong>Huomaa!</strong> Ilmoituksen muokkaaminen voi pidentää ilmoituksen voimassaoloaikaa.
        Muokatun ilmoituksen on oltava julkaistuna ja muokkaamattomana Materiaalitorissa vähintään
        14 vuorokautta, jotta kunnan toissijaista jätehuoltopalvelua on mahdollista pyytää muun
        palvelutarjonnan puuttuessa.
      </Trans>
    );
    return <InfoBanner content={content} small={true} />;
  };

  const copyRfoButton = (rfo) => {
    const businessId = path(['company', 'businessId'])(rfo);

    return (
      <>
        {rfo.allowedToEdit && (
          <PrivateComponent belongsToBusiness={businessId}>
            <Link className={cx('buttonStyle', 'qa-copyRfoBtn')} to={`${LISAA_ILMOITUS}/${rfo.id}`}>
              {t('Kopioi uuden pohjaksi')}
            </Link>
          </PrivateComponent>
        )}
      </>
    );
  };

  const ilmottajaFragment = (rfoData, t, isHilmaNotice) => (
    <>
      <h2>{isHilmaNotice ? t('Pyynnön esittäjä') : t('Ilmoittaja')}</h2>
      <OnlyPublicComponent>
        {!path(['contactIsPublic'], rfoData) && (
          <p>{t('Kirjaudu sisään nähdäksesi ilmoittajan tiedot')}</p>
        )}
      </OnlyPublicComponent>
      <div className={styles.detailWrapper}>
        <div className={styles.detailContainerHalf}>
          <p>
            <span>{path(['company', 'name'], rfoData) && rfoData.company.name}</span>
            <br />
            <span>
              {path(['company', 'businessId'])(rfoData) ? t('Y-tunnus') + ':' : ''}{' '}
              {path(['company', 'businessId'])(rfoData)}
            </span>
          </p>
        </div>

        <div className={styles.detailContainerHalf}>
          <p>
            <span>{path(['contact', 'name'], rfoData) && path(['contact', 'name'], rfoData)}</span>
            <br />
            <span>
              {path(['contact', 'title'], rfoData) && path(['contact', 'title'], rfoData)}
            </span>
            <br />
            <span>
              {path(['contact', 'phone'], rfoData) && path(['contact', 'phone'], rfoData)}
            </span>
            <br />
            <span>
              {path(['contact', 'email'], rfoData) && path(['contact', 'email'], rfoData)}
            </span>
          </p>
        </div>
      </div>
    </>
  );

  const getCoordinates = ({ location }) => {
    let coordinates = { id: id };
    if (location.coordinates) {
      const eurefCoordinates = getEurefCoordinates(location.coordinates);
      if (eurefCoordinates.lat) {
        coordinates.lat = eurefCoordinates.lat;
        coordinates.lon = eurefCoordinates.lon;
      } else if (
        !eurefCoordinates.lat &&
        location.cityId &&
        Array.isArray(regionsAndMunicipalities)
      ) {
        const municipality = regionsAndMunicipalities.filter((f) => f.id === location.cityId);
        if (municipality.length > 0) {
          const municipalityEurefCoordinates = getEurefCoordinates(municipality[0].coordinates);
          coordinates.lat = municipalityEurefCoordinates.lat;
          coordinates.lon = municipalityEurefCoordinates.lon;
        }
      }
    }
    return coordinates;
  };

  const getRegionCoordinates = (regions) => {
    const regionsWithCoords = regions.map((r) =>
      regionsAndMunicipalities.find((f) => f.id === r.id || f.regionId === r.id)
    );
    const coordinates = regionsWithCoords.map((r) => getEurefCoordinates(r.coordinates));
    return coordinates;
  };

  const renderRfo = (rfoData, isInPreviewMode) => {
    const hasAttachments = rfoData.attachments && rfoData.attachments.length > 0;
    const nonImageAttachments = hasAttachments
      ? nonImageAttachmentList(rfoData.attachments)
      : undefined;
    const imageAttachments = hasAttachments ? filterImageAttachments(rfoData.attachments) : [];
    const hasImageAttachments = !isEmpty(imageAttachments);
    const hilmaNoticeId = path(['hilmaNotice', 'id'])(rfo) || rfoData.hilmaId;
    const hilmaNoticeCreated = path(['hilmaNotice', 'created'])(rfo) || rfoData.hilmaCreated;

    switch (rfoData.rfoType) {
      case RFO_TYPES.RFO_OFFERING_WASTE: {
        const [material] = rfoData.materials;
        const { service } = material;

        const coordinateMarker = getCoordinates(material);

        const markerCoordinates =
          rfoData.regions && regionsAndMunicipalities && regionsAndMunicipalities.length
            ? getRegionCoordinates(rfoData.regions.filter((r) => r.id[0] === 'M'))
            : [];
            markerCoordinates.push(coordinateMarker);
        const regionFeatures = rfoData.regions
          ? rfoData.regions.filter((r) => r.id[0] === 'R')
          : [];

        return (
          <div className={viewOnly ? 'embeddedLiftup' : undefined}>
            <RfoTitleFragment
              t={t}
              rfo={rfoData}
              classnameForSidemark={styles.smallerGreenSidemark}
            />
            {!hilmaNoticeId && (
              <div className={styles.companyTitle}>
                {path(['company', 'name'], rfoData) && rfoData.company.name}
              </div>
            )}
            <Accordion
              hideByDefault={true}
              showText={hilmaNoticeId ? t('Näytä kaikki tiedot') : t('Näytä koko ilmoitus')}
              hideText={hilmaNoticeId ? t('Pienennä') : t('Pienennä ilmoitus')}
              viewOnly={viewOnly}
            >
              <div className={styles.mainDescription}>
                {splitTextToParagraphsOnLinebreak(material.description)}
              </div>
              <div className={styles.detailWrapper}>
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Luokittelu')}</h2>

                  <p>
                    {t('Jäte')}: {t(material.classification)}
                    {material.subClassification && <span>, {t(material.subClassification)}</span>}
                  </p>
                  <p className={styles.labelParagraph}>
                    <strong>{t('Euroopan jäteluettelon koodi eli jätenimike')}</strong>
                  </p>
                  <p>{displayEwcCode(material.ewcCode, ewcs, language)}</p>
                  <p className={styles.labelParagraph}>
                    <strong>{t('Jätteen vaarallisuus')}</strong>
                  </p>
                  <p>
                    {material.type === 'dangerous' ? t('Vaarallinen jäte') : t('Vaaraton jäte')}
                  </p>
                  <p className={styles.labelParagraph}>
                    <strong>{t('Pysyvä jäte')}</strong>
                  </p>
                  <p>
                    {material.permanentWasteType === 'permanentWaste' && ('Kyllä')}
                    {material.permanentWasteType === 'nonPermanentWaste' && ('Ei')}
                    {material.permanentWasteType === 'unknown' && ('Ei tiedossa')}
                  </p>
                  <p className={styles.labelParagraph}>
                    <strong>{t('POP-jäte')}</strong>
                  </p>
                  <p>
                    {material.popWasteType === 'popWaste' && ('Kyllä')}
                    {material.popWasteType === 'nonPopWaste' && ('Ei')}
                    {material.popWasteType === 'unknown' && ('Ei tiedossa')}
                  </p>
                  {material.popWasteType === 'popWaste' && (
                    <>
                      <p className={styles.labelParagraph}>
                        <strong>{t('POP-jätteen sisältämät pysyvät orgaaniset yhdisteet')}</strong>
                      </p>
                      <p>
                      {material.popWastes.map((popId) => t(popId)).join(', ')}
                      </p>
                    </>
                  )}
                  <p>{material.permanent && t('Pysyvä jäte')}</p>
                  <p className={styles.labelParagraph}>
                    <strong>{t('Toimiala, jossa jäte syntyy')}</strong>
                  </p>
                  <p>{t(material.industry)}</p>
                </div>
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Määrä')}</h2>
                  <p>
                    {materialQuantity(material.quantity)}{' '}
                    <span>
                      {material.continuity === 'onetime' ? t('Kertaerä') : t('Jatkuva tuotanto')}
                    </span>
                  </p>
                  {material.amountDescription && (
                    <>
                      <p className={styles.labelParagraph}>
                        <strong>{t('Lisätietoja määrästä')}</strong>
                      </p>
                      {splitTextToParagraphsOnLinebreak(material.amountDescription)}
                    </>
                  )}
                </div>

                {/* <div className={styles.detailContainerHalf}>
                <h2>{t('TSV')}</h2>
                <p>
                  {material.useTsv
                    ? t('Jäte menee TSV-prosessiin mikäli vastaanottajaa ei löydy')
                    : t('Jäte ei mene TSV-prosessiin')}
                </p>
              </div> */}
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Haettava palvelu')}</h2>
                  <p className={styles.labelParagraph}>
                    <strong>{t('Palvelu')}</strong>
                  </p>
                  <p>
                    {service.serviceIds &&
                      service.serviceIds.map((serviceId) => t(serviceId)).join(', ')}
                  </p>
                  <p className={styles.labelParagraph}>
                    <strong>{t('Haettavan palvelun kuvaus')}</strong>
                  </p>
                  {splitTextToParagraphsOnLinebreak(service.requirements)}
                  <p className={styles.labelParagraph}>
                    <strong>{t('Palvelun toivottu ajankohta')}</strong>
                  </p>
                  {splitTextToParagraphsOnLinebreak(service.duration)}
                </div>
                 {!hilmaNoticeId && (    
                <div className={styles.detailContainerHalf}>
                  <h3>{t('Ilmoitus voimassa')}</h3>
                  <p>{displayExpiresDate(rfoData)}{t(' asti')}</p>
                  {rfoData.created && (
                    <>
                      <h3>{t('Julkaistu')}</h3>
                      <p>{displayCreatedDate(rfoData)}</p>
                    </>
                  )}
                </div>
                )}   
                {hilmaNoticeId && (
                  <div className={styles.detailContainerHalf}>
                    <h3 name="hilmaId">{t('Hilma-ilmoitusnumero')}</h3>
                    <p>{hilmaNoticeId}</p>
                    <h3>{t('Ilmoitus luotu Hilmassa')}</h3>
                    <p>{displayDate(rfoData.Id, hilmaNoticeCreated, false)}</p>
                    <h3>{t('Ilmoitus vanhentunut Hilmassa')}</h3>
                      <p>{displayExpiresDate(rfoData)}</p>
                    {path(['hilmaNotice', 'cpvCodes'])(rfoData) && (
                      <>
                      <h3>{t('Hankinnan tyyppi')}</h3>
                      <p>{path(['hilmaNotice', 'cpvCodes'])(rfoData)}</p>
                      </>
                    )}
                  </div>
                )}  
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Liitteet')}</h2>
                  {!hasAttachments && t('Ei liitteitä')}
                  {nonImageAttachments}
                </div>
              </div>
              {hasImageAttachments && (
                <>
                  <ImageList images={imageAttachments} willNotLoad={isInPreviewMode} />
                  <ChromeErrorInfobox />
                </>
              )}

              <div className={'divider'} />
              <div>
                <h2>{t('Jätteen sijainti')}</h2>
                <p>
                  {rfoData.regions &&
                    rfoData.regions
                      .map((region) => {
                        return region.nameFi;
                      })
                      .join(', ')}
                </p>
                <p>
                  <LocationFromMaterialFragment material={material} />
                </p>
                {(markerCoordinates.length > 0 || regionFeatures.length > 0) && (
                  <div
                    className={formStyles.formRow}
                    style={{
                      width: '100%',
                      height: regionFeatures.length ? '40em' : '20em',
                      maxHeight: '70vh',
                    }}
                  >
                    <Map
                      regionFeatures={regionFeatures}
                      mapState={{
                        markers: markerCoordinates,
                        searchTerm: '',
                      }}
                      onSearchResult={() => void 0}
                      onMarkerDraw={() => void 0}
                    />
                  </div>
                )}
              </div>
              <div className={'divider'} />
              {ilmottajaFragment(rfoData, t, hilmaNoticeId)}
            </Accordion>
          </div>
        );
      }

      case RFO_TYPES.RFO_OFFERING_MATERIAL: {
        const [material] = rfoData.materials;
        const coordinateMarker = getCoordinates(material);

        const markerCoordinates =
          rfoData.regions && regionsAndMunicipalities && regionsAndMunicipalities.length
            ? getRegionCoordinates(rfoData.regions.filter((r) => r.id[0] === 'M'))
            : [];
            markerCoordinates.push(coordinateMarker);
        const regionFeatures = rfoData.regions
          ? rfoData.regions.filter((r) => r.id[0] === 'R')
          : [];

        return (
          <div className={viewOnly ? 'embeddedLiftup' : undefined}>
            <RfoTitleFragment
              t={t}
              rfo={rfoData}
              classnameForSideMark={styles.smallerGreenSidemark}
            />
            <div className={styles.companyTitle}>
              {path(['company', 'name'], rfoData) && rfoData.company.name}
            </div>

            <Accordion
              hideByDefault={true}
              showText={t('Näytä koko ilmoitus')}
              hideText={t('Pienennä ilmoitus')}
              viewOnly={viewOnly}
            >
              <div className={styles.mainDescription}>
                {splitTextToParagraphsOnLinebreak(material.description)}
              </div>

              <div className={styles.detailWrapper}>
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Luokittelu')}</h2>
                  <p>
                    {t('Materiaali')}: {t(material.classification)}
                    {material.subClassification && <span>, {t(material.subClassification)}</span>}
                  </p>
                </div>

                <div className={styles.detailContainerHalf}>
                  <h2>{t('Määrä')}</h2>
                  <p>
                    {materialQuantity(material.quantity)}{' '}
                    <span>
                      {material.continuity === 'onetime' ? t('Kertaerä') : t('Jatkuva tuotanto')}
                    </span>
                  </p>
                  {material.amountDescription && (
                    <>
                      <p className={styles.labelParagraph}>
                        <strong>{t('Tarkennukset määrään')}</strong>
                      </p>
                      {splitTextToParagraphsOnLinebreak(material.amountDescription)}
                    </>
                  )}
                </div>

                <div className={styles.detailContainerHalf}>
                  <h2>{t('Voimassa')}</h2>
                  <p>{displayExpiresDate(rfoData)}{t(' asti')}</p>
                </div>

                <div className={styles.detailContainerHalf}>
                  <h2>{t('Liitteet')}</h2>
                  {!hasAttachments && t('Ei liitteitä')}
                  {nonImageAttachments}
                </div>
              </div>

              {hasImageAttachments && (
                <>
                  <ImageList images={imageAttachments} willNotLoad={isInPreviewMode} />
                  <ChromeErrorInfobox />
                </>
              )}

              <div className={'divider'} />

              <div>
                <h2>{t('Materiaalin sijainti')}</h2>
                <p>
                  {rfoData.regions &&
                    rfoData.regions
                      .map((region) => {
                        return region.nameFi;
                      })
                      .join(', ')}
                </p>
                <p>
                  <LocationFromMaterialFragment material={material} />
                </p>
                {(markerCoordinates.length > 0 || regionFeatures.length > 0) && (
                  <div
                    className={formStyles.formRow}
                    style={{
                      width: '100%',
                      height: regionFeatures.length ? '40em' : '20em',
                      maxHeight: '70vh',
                    }}
                  >
                    <Map
                      regionFeatures={regionFeatures}
                      mapState={{
                        markers: markerCoordinates,
                        searchTerm: '',
                      }}
                      onSearchResult={() => void 0}
                      onMarkerDraw={() => void 0}
                    />
                  </div>
                )}
              </div>

              <div className={'divider'} />

              {ilmottajaFragment(rfoData, t)}
            </Accordion>
          </div>
        );
      }

      case RFO_TYPES.RFO_RECEIVING_MATERIAL: {       
        
        const [material] = rfoData.materialsWanted;
        const markerCoordinates =
          rfoData.regions && regionsAndMunicipalities && regionsAndMunicipalities.length
            ? getRegionCoordinates(rfoData.regions.filter((r) => r.id[0] === 'M'))
            : [];

        const regionFeatures = rfoData.regions
          ? rfoData.regions.filter((r) => r.id[0] === 'R')
          : [];

        return (
          <div className={viewOnly ? 'embeddedLiftup' : undefined}>
            <RfoTitleFragment
              t={t}
              rfo={rfoData}
              classnameForSideMark={styles.smallerGreenSidemark}
            />
            <div className={styles.companyTitle}>
              {path(['company', 'name'], rfoData) && rfoData.company.name}
            </div>

            <Accordion
              showText={t('Näytä koko ilmoitus')}
              hideText={t('Pienennä ilmoitus')}
              viewOnly={viewOnly}
              hideByDefault="true"
            >
              <div className={styles.mainDescription}>
                {splitTextToParagraphsOnLinebreak(material.description)}
              </div>

              {/* Tiedot */}
              <div className={styles.detailWrapper}>
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Luokittelu')}</h2>

                  <p>
                    {t('Materiaali')}: {t(material.classification)}
                    {material.subClassification && <span>, {t(material.subClassification)}</span>}
                  </p>
                </div>
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Voimassa')}</h2>
                  <p>{displayExpiresDate(rfoData)}{t(' asti')}</p>
                </div>

                <div className={styles.detailContainerHalf}>
                  <h2>{t('Sijainti')}</h2>
                  <p>
                    {rfoData.regions &&
                      rfoData.regions
                        .map((region) => {
                          return region.nameFi;
                        })
                        .join(', ')}
                  </p>
                  {material.location && (
                    <p>
                      <LocationFromMaterialFragment material={material} />
                    </p>
                  )}
                </div>

                <div className={cx(styles.detailContainerHalf, styles.marginBottom1em)}>
                  <h2>{t('Liitteet')}</h2>
                  {!hasAttachments && t('Ei liitteitä')}
                  {nonImageAttachments}
                </div>

                {(markerCoordinates.length > 0 || regionFeatures.length > 0) && (
                  <div
                    className={formStyles.formRow}
                    style={{
                      width: '100%',
                      height: regionFeatures.length ? '40em' : '20em',
                      maxHeight: '70vh',
                    }}
                  >
                    <Map
                      regionFeatures={regionFeatures}
                      mapState={{
                        markers: markerCoordinates,
                        searchTerm: '',
                      }}
                      onSearchResult={() => void 0}
                      onMarkerDraw={() => void 0}
                    />
                  </div>
                )}
              </div>

              {hasImageAttachments && (
                <>
                  <ImageList images={imageAttachments} willNotLoad={isInPreviewMode} />
                  <ChromeErrorInfobox />
                </>
              )}

              <div className={'divider'} />

              {ilmottajaFragment(rfoData, t)}

              {!isInPreviewMode && rfo && rfo.company && (
                <PrivateComponent>
                  <Registrations
                    businessId={rfo.company.businessId}
                    title={t(
                      'Ilmoittajan jätteen vastaanottoon liittyvät luvat tai rekisterimerkinnät'
                    )}
                  />
                </PrivateComponent>
              )}
            </Accordion>
          </div>
        );
      }

      case RFO_TYPES.RFO_OFFERING_SERVICES: {
        const [material] = rfoData.materials;
        const markerCoordinates =
          rfoData.regions && regionsAndMunicipalities && regionsAndMunicipalities.length
            ? getRegionCoordinates(rfoData.regions.filter((r) => r.id[0] === 'M'))
            : [];

        const regionFeatures = rfoData.regions
          ? rfoData.regions.filter((r) => r.id[0] === 'R')
          : [];

        return (
          <div className={viewOnly ? 'embeddedLiftup' : undefined}>
            <RfoTitleFragment
              t={t}
              rfo={rfoData}
              classnameForSideMark={styles.smallerGreenSidemark}
            />
            <div className={styles.companyTitle}>
              {path(['company', 'name'], rfoData) && rfoData.company.name}
            </div>

            <Accordion
              showText={t('Näytä koko ilmoitus')}
              hideText={t('Pienennä ilmoitus')}
              viewOnly={viewOnly}
            >
              <div className={styles.mainDescription}>
                {rfoData.service &&
                  rfoData.service.serviceDescription &&
                  splitTextToParagraphsOnLinebreak(rfoData.service.serviceDescription)}
              </div>

              <div className={styles.detailWrapper}>
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Tarjottu palvelu')}</h2>
                  {path(['service', 'serviceIds'], rfoData) &&
                    path(['service', 'serviceIds'], rfoData).map((serviceId, idx) => {
                      return <p key={idx}>{t(serviceId)}</p>;
                    })}
                </div>

                <div className={styles.detailContainerHalf}>
                  <h2>{t('Voimassa')}</h2>
                  <p>{displayExpiresDate(rfoData)}{t(' asti')}</p>
                </div>

                <div className={styles.detailContainerHalf}>
                  <h2>{t('Liitteet')}</h2>
                  {!hasAttachments && t('Ei liitteitä')}
                  {nonImageAttachments}
                </div>
              </div>

              {hasImageAttachments && (
                <>
                  <ImageList images={imageAttachments} willNotLoad={isInPreviewMode} />
                  <ChromeErrorInfobox />
                </>
              )}

              <div className={styles.divider} />

              <div>
                <h2>{t('Sijainti')}</h2>
                <p>
                  {rfoData.regions &&
                    rfoData.regions
                      .map((region) => {
                        return region.nameFi;
                      })
                      .join(', ')}
                </p>
                <p>
                  <LocationFromMaterialFragment material={material} />
                </p>
                {(markerCoordinates.length > 0 || regionFeatures.length > 0) && (
                  <div
                    className={formStyles.formRow}
                    style={{
                      width: '100%',
                      height: regionFeatures.length ? '40em' : '20em',
                      maxHeight: '70vh',
                    }}
                  >
                    <Map
                      regionFeatures={regionFeatures}
                      mapState={{
                        markers: markerCoordinates,
                        searchTerm: '',
                      }}
                      onSearchResult={() => void 0}
                      onMarkerDraw={() => void 0}
                    />
                  </div>
                )}
              </div>

              <div className={styles.divider} />

              {ilmottajaFragment(rfoData, t)}

              {!isInPreviewMode && rfo && rfo.company && (
                <PrivateComponent>
                  <Registrations
                    businessId={rfo.company.businessId}
                    title={t(
                      'Ilmoittajan jätteen vastaanottoon liittyvät luvat tai rekisterimerkinnät'
                    )}
                  />
                </PrivateComponent>
              )}
            </Accordion>
          </div>
        );
      }

      case RFO_TYPES.RFO_RECEIVING_SERVICES: {
        const [material] = rfoData.materials;
        const markerCoordinates =
          rfoData.regions && regionsAndMunicipalities && regionsAndMunicipalities.length
            ? getRegionCoordinates(rfoData.regions.filter((r) => r.id[0] === 'M'))
            : [];

        const regionFeatures = rfoData.regions
          ? rfoData.regions.filter((r) => r.id[0] === 'R')
          : [];

        return (
          <div className={viewOnly ? 'embeddedLiftup' : undefined}>
            <RfoTitleFragment
              t={t}
              rfo={rfoData}
              classnameForSideMark={styles.smallerGreenSidemark}
            />
            <div className={styles.companyTitle}>
              {path(['company', 'name'], rfoData) && rfoData.company.name}
            </div>

            <Accordion
              showText={t('Näytä koko ilmoitus')}
              hideText={t('Pienennä ilmoitus')}
              viewOnly={viewOnly}
            >
              <div className={styles.mainDescription}>
                {rfoData.service &&
                  rfoData.service.serviceDescription &&
                  splitTextToParagraphsOnLinebreak(rfoData.service.serviceDescription)}
              </div>

              <div className={styles.detailWrapper}>
                <div className={styles.detailContainerHalf}>
                  <h2>{t('Etsittävä palvelu tai osaaminen')}</h2>
                  {path(['service', 'serviceIds'], rfoData) &&
                    path(['service', 'serviceIds'], rfoData).map((serviceId, idx) => {
                      return <p key={idx}>{t(serviceId)}</p>;
                    })}
                </div>

                <div className={styles.detailContainerHalf}>
                  <h2>{t('Voimassa')}</h2>
                  <p>{displayExpiresDate(rfoData)}{t(' asti')}</p>
                </div>

                <div className={styles.detailContainerHalf}>
                  <h2>{t('Liitteet')}</h2>
                  {!hasAttachments && t('Ei liitteitä')}
                  {nonImageAttachments}
                </div>
              </div>

              {hasImageAttachments && (
                <>
                  <ImageList images={imageAttachments} willNotLoad={isInPreviewMode} />
                  <ChromeErrorInfobox />
                </>
              )}

              <div className={styles.divider} />

              <div>
                <h2>{t('Sijainti')}</h2>
                <p>
                  {rfoData.regions &&
                    rfoData.regions
                      .map((region) => {
                        return region.nameFi;
                      })
                      .join(', ')}
                </p>
                <p>
                  <LocationFromMaterialFragment material={material} />
                </p>
                {(markerCoordinates.length > 0 || regionFeatures.length > 0) && (
                  <div
                    className={formStyles.formRow}
                    style={{
                      width: '100%',
                      height: regionFeatures.length ? '40em' : '20em',
                      maxHeight: '70vh',
                    }}
                  >
                    <Map
                      regionFeatures={regionFeatures}
                      mapState={{
                        markers: markerCoordinates,
                        searchTerm: '',
                      }}
                      onSearchResult={() => void 0}
                      onMarkerDraw={() => void 0}
                    />
                  </div>
                )}
              </div>

              <div className={styles.divider} />

              {ilmottajaFragment(rfoData, t)}

              {((rfoData.companyIntroduction && rfoData.companyIntroduction.length > 0) || (rfoData.otherNotices && rfoData.otherNotices.length > 0)) && (
                <div>
                  <h2>{t('Lisätietoja')}</h2>
                  {(rfoData.companyIntroduction && rfoData.companyIntroduction.length > 0) && (
                    <>
                      <p className={styles.labelParagraph}>
                        <strong>{t('Organisaation esittely')}</strong>
                      </p>
                      {splitTextToParagraphsOnLinebreak(rfoData.companyIntroduction)}
                    </>
                  )}
                  {(rfoData.otherNotices && rfoData.otherNotices.length > 0) && (
                    <>
                      <p className={styles.labelParagraph}>
                        <strong>{t('Muuta huomioitavaa')}</strong>
                      </p>
                      {splitTextToParagraphsOnLinebreak(rfoData.otherNotices)}
                    </>
                  )}
                </div>
              )}


              {!isInPreviewMode && rfo && rfo.company && (
                <PrivateComponent>
                  <Registrations
                    businessId={rfo.company.businessId}
                    title={t(
                      'Ilmoittajan jätteen vastaanottoon liittyvät luvat tai rekisterimerkinnät'
                    )}
                  />
                </PrivateComponent>
              )}
            </Accordion>
          </div>
        );
      }


      default:
        return null;
    }
  };

  const actionButtons = (t, rfo) => {
    const notExpired = !isExpired(rfo);
    const tsvActive = isTSVActive(rfo);
    const offerAccepted = isHidden(rfo);    
    const isHilmaNotice = path(['hilmaNotice', 'id'])(rfo);

    return (
      <div className={styles.buttonWrap}>
        {notExpired && !offerAccepted && !tsvActive && offerButton(rfo)}
        {!tsvActive && !offerAccepted && editRfoButton(rfo)}
        {!isHilmaNotice && copyRfoButton(rfo)}
        {!isHilmaNotice && rfo.allowedToEdit && (
        <CloseRfoButton
          t={t}
          rfoId={path(['id'])(rfo)}
          rfoBusinessId={path(['company', 'businessId'])(rfo)}
        />
        )}
        {rfo.allowedToEdit && (
          <PrivateComponent belongsToBusiness={rfo.company.businessId}>
            {tsvSection(rfo)}
          </PrivateComponent>
        )}
      </div>
    );
  };

  return (
    <>
      {rfo && renderRfo(rfo, isInPreviewMode)}
      {!viewOnly && !isInPreviewMode && !isClosed(rfo) && actionButtons(t, rfo)}
      {!viewOnly && !isInPreviewMode && rfo && (
        <PrivateComponent>
          {!rfo.hilmaNotice && (
            <>
            <RfoOffers rfo={rfo} />
            <RfoTsvRequests rfo={rfo} />
          </>
          )}
        </PrivateComponent>
      )}
    </>
  );
};

export default withNamespaces()(View);
