import React, { Component } from 'react';
import Steps, {Step} from 'rc-steps';
import { withNamespaces } from 'react-i18next';
import '../../styles/steps.css';

class HilmaTsvSteps extends Component {
    render() {
        const { t } = this.props;
    
        return (
          <Steps labelPlacement="vertical" current={this.props.currentStep} style={{marginTop:"20px"}} >
              <Step title={t('Hae tietoja Hilmasta')} />
              <Step title={t('Täydennä tiedot')} />
              <Step title={t('Tallenna tiedot')} />
              <Step title={t('Lähetä pyyntö')} />
          </Steps>
        );
    }
}

export default withNamespaces()(HilmaTsvSteps);