import React from 'react';
import CookieDeclaration from '../../src/components/Cookies/CookieDeclaration'

const Evasteet = ({ lang }) => {
  switch (lang) {
    case 'sv-FI':
      return (
        <>
          <h1>Cookies</h1>
          <CookieDeclaration language="sv"/>
        </>
      );

    default: {
      return (
        <>
          <h1>Evästeet</h1>
          <CookieDeclaration language="fi"/>
        </>
      );
    }
  }
};

export default Evasteet;