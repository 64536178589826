import cx from 'classnames';
import React from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import { regExpForNumberWithUnlimitedDecimals } from '../../utils/text-utils';
import InfoBox from '../InfoBox/InfoBox';
import formStyles from '../Layout/Form.module.css';
import styles from '../Home/Home.module.css';
import { Col, Row } from '../Layout/Layout';
import Select from '../Select/Select';
import IndustryOptionList from './IndustryOptionList';
import MaterialOptionList from './MaterialOptionList';
import UnitOptionList from './UnitOptionList';
import ReactSelect, { components } from 'react-select';

const QuanityUnitField = React.memo(({ t, material, handleMaterialChange, isWaste = false }) => (
  <>
    <label>
      <span className={cx(formStyles.defaultLabelSpan, formStyles.doubleLineHeight)}>{t('Määrän yksikkö')}</span>{' '}
      <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
      <select
        name="quantityUnit"
        value={material.quantityUnit || ''}
        onChange={handleMaterialChange}
        className={(!material.quantityUnit ? formStyles.placeholder : '' )}
      >
        <option value="" disabled hidden>
          {t('Valitse...')}
        </option>
        <UnitOptionList t={t} />
      </select>
    </label>
  </>
));

const searchEwcFn = (t) => (searchString) => (value) => {
  const ss = searchString.toLowerCase();
  return (
    value.id.toLowerCase().indexOf(ss) > -1 ||
    value.id.split(' ').join('').toLowerCase().indexOf(ss) > -1 ||
    value.fi.toLowerCase().indexOf(ss) > -1 ||
    value.sv.toLowerCase().indexOf(ss) > -1
  );
};

const searchSubClassificationsFn= (t) => (searchString) => (value) => {
  return (
    value.id.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
    t(value.id).toLowerCase().indexOf(searchString.toLowerCase()) > -1
  );
};

//input's pattern match validates contents only on submit
//thus prevent entering characters and more than one comma with this
const acceptDecimalNumberInput = (e) => {
  if (e.currentTarget)
  {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9,]/g, '').replace(/(,.*),/g, '$1');
  }
};

const SingleWasteEdit = React.memo(({ t, material, ewcs, popWastes, handleMaterialChange, i18n, rfo, currentSubClassifications }) => {
  const displayFn = (lang) => (value) => {
    const localeDisplayValue = lang === 'sv-FI' ? value.sv : value.fi;
    return `[${value.id}] ${localeDisplayValue}`;
  };

  const popWasteOptions = popWastes.map(pop => { return { value: pop.id, label: t(pop.id) } });
  const selectedPopWastes = popWasteOptions.filter(x => material.popWastes && material.popWastes.indexOf(x.value) > -1);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={require("../Icon/icons/angle-down.svg")} alt="" />
      </components.DropdownIndicator>
    );
  };

  const dropStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '2px solid #dedede', //mtMediumGray
      boxShadow: 'none',
      flex: 1,
      cursor: 'pointer',
      '&:hover': {
        border: '2px solid #dedede', //mtMediumGray
        boxshadow: 'none'
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,      
      padding: '0.65rem',
      fontWeight: 400
    }),
    input: (provided, state) => ({
      ...provided,
    }),
    menuList: (provided, state) => ({
      padding: '1rem',
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 400,
      backgroundColor: 'white',
      textAlign: 'left',
      cursor: 'pointer',
      padding: '0.25rem 1rem',
      fontFamily: 'Lato'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontWeight: 400,
      backgroundColor: 'white'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontStyle: 'italic',
      color: '#5c5c5c' //mtDarkerGrey
    }),
    multiValueLabel: (provided, state) => ({
      color: '#fff',
      backgroundColor: '#007ec6', //mtDeepBlue
      fontSize: '0.75rem',
      padding: '0 0.25rem',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#fff',
      backgroundColor: '#007ec6', //mtDeepBlue
      '&:hover': {
        color: '#fff',
        backgroundColor: '#94c3f2', //mtBlue
      }
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      height: '36px',
      width: '36px',
      marginRight: '8px',
    })
  };

  return (
    <>
      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Jäte')} <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              <InfoBox
                infoText={rfo.hilmaId 
                  ? <Trans i18nKey="jateilmoituksen_luontisivun_jate_hilma">
                    Jätteen lajia tai materiaalityppiä mahdollisimman hyvin kuvaava termi.
                    <br />
                    <br />
                    Voit valita vain yhden jätteen yhteen ilmoitukseen. Jos tarvitset palvelua usealle eri jätteelle, voit tehdä useita pyyntöjä samasta hankintailmoituksesta. 
                    Mikäli teet ilmoituksen sekalaisesta jätteestä, määrittele jätteen tarkempi koostumus vapaamuotoisessa
                    ”Jätteen kuvaus” –kohdassa.
                    <br />
                    <br />
                    Huom!
                    <br />
                    <br />
                    Tuottajavastuun alaiset jätteet (renkaat, ajoneuvot, sähkö- ja
                    elektroniikkalaitteet, paristot ja akut, paperi, pakkaukset) tulee toimittaa
                    pääsääntöisesti tuottajan hyväksymään vastaanottoon.
                    <br />
                    <br />
                    Asumisessa sekä kunnan hallinto- ja palvelutoiminnassa syntyvät
                    yhdyskuntajätteet kuuluvat kunnan vastuulle, jolloin kunta järjestää kyseisten
                    jätteiden jätehuollon.
                    <br />
                    <br />
                    Lisätietoja jätehuollon vastuista
                    <br />
                    <a href="https://www.ymparisto.fi/fi-FI/Kulutus_ja_tuotanto/Jatteet_ja_jatehuolto/Jatehuollon_vastuut_ja_jarjestaminen">
                      https://www.ymparisto.fi/fi-FI/Kulutus_ja_tuotanto/Jatteet_ja_jatehuolto/Jatehuollon_vastuut_ja_jarjestaminen
                    </a>
                    .
                  </Trans>
                  : <Trans i18nKey="jateilmoituksen_luontisivun_jate">
                    Jätteen lajia tai materiaalityppiä mahdollisimman hyvin kuvaava termi. Valmiiden
                    ilmoitusten hakua voi rajata tämän jätteen luokittelun perusteella.
                    <br />
                    <br />
                    Voit valita vain yhden jätteen yhteen ilmoitukseen. Mikäli teet ilmoituksen
                    sekalaisesta jätteestä, määrittele jätteen tarkempi koostumus vapaamuotoisessa
                    ”Jätteen kuvaus” –kohdassa.
                    <br />
                    <br />
                    Huom!
                    <br />
                    <br />
                    Tuottajavastuun alaiset jätteet (renkaat, ajoneuvot, sähkö- ja
                    elektroniikkalaitteet, paristot ja akut, paperi, pakkaukset) tulee toimittaa
                    pääsääntöisesti tuottajan hyväksymään vastaanottoon.
                    <br />
                    <br />
                    Asumisessa sekä kunnan hallinto- ja palvelutoiminnassa syntyvät
                    yhdyskuntajätteet kuuluvat kunnan vastuulle, jolloin kunta järjestää kyseisten
                    jätteiden jätehuollon.
                    <br />
                    <br />
                    Lisätietoja jätehuollon vastuista
                    <br />
                    <a href="https://www.ymparisto.fi/fi-FI/Kulutus_ja_tuotanto/Jatteet_ja_jatehuolto/Jatehuollon_vastuut_ja_jarjestaminen">
                      https://www.ymparisto.fi/fi-FI/Kulutus_ja_tuotanto/Jatteet_ja_jatehuolto/Jatehuollon_vastuut_ja_jarjestaminen
                    </a>
                    .
                  </Trans>
                }
              />{' '}
            </span>
            <select
              name="classification"
              value={material.classification || ''}
              onChange={handleMaterialChange}
              className={(!material.classification ? formStyles.placeholder : '' )}
            >
              <option value="" disabled hidden>
                {t('Valitse...')}
              </option>
              <MaterialOptionList includeWaste={true} />
            </select>
            </label>
          {currentSubClassifications && currentSubClassifications.length > 0 && (
            <>
              <label>
                <span className={formStyles.defaultLabelSpan}>{t('Tarkenna valintaa')}</span>
                <Select
                  values={currentSubClassifications}
                  onAdd={(val) =>
                    handleMaterialChange({ target: { value: val.id, name: 'subClassification' } })
                  }
                  single={true}
                  value={currentSubClassifications.filter(
                    (f) => f.id === material.subClassification
                  )}
                  displayFn={(value) => t(value.id)}
                  keyFn={(val) => val.id}
                  searchFn={searchSubClassificationsFn(t)}
                  showAllOnFocus={true}
                  onRemove={() => {
                    handleMaterialChange({ target: { value: '', name: 'subClassification' } });
                  }}
                />
              </label>
            </>
          )}                     
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>{t('Toimiala, jossa jäte syntyy')}</span>{' '}
            <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
            <select name="industry" value={material.industry || ''} onChange={handleMaterialChange} className={(!material.industry ? formStyles.placeholder : '' )}>
              <option value="" disabled hidden>
                {t('Valitse...')}
              </option>
              <IndustryOptionList />
            </select>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Euroopan jäteluettelon koodi eli jätenimike')}{' '}
              <InfoBox
                infoText={t(
                  'Jäteasetuksen (179/2012) liitteen 4 jäteluettelon mukainen kuusinumeroinen jätekoodi. Kutsutaan myös EWC-koodiksi (European Waste Catalog) tai LoW-koodiksi (List of Waste).\n\nJätenimike on suositeltavaa antaa, mikäli se on tiedossa.'
                )}
              />
            </span>
          </label>
          <Select
            values={ewcs}
            onAdd={(val) =>
              handleMaterialChange({ target: { value: val.id, name: 'ewcCode', type: 'custom' } })
            }
            single={true}
            value={ewcs.filter((f) => f.id === material.ewcCode)}
            displayFn={displayFn(i18n.language)}
            searchFn={searchEwcFn()}
            keyFn={(val) => val.id}
            showAllOnFocus={true}
            noSelectFn={(ewc) => ewc.id.length < 6}
            onRemove={() => {
              handleMaterialChange({ target: { value: '', name: 'ewcCode', type: 'custom' } });
            }}
          />
        </Col>
      </Row>
      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Onko jäte vaarallista?')}{' '}
              <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              <InfoBox
                infoText={t(
                  'Vaarallisella jätteellä tarkoitetaan jätettä, jolla on palo- tai räjähdysvaarallinen, tartuntavaarallinen, muu terveydelle vaarallinen, ympäristölle vaarallinen tai muu vastaava ominaisuus (vaaraominaisuus).\n\nVaarattomalla jätteellä tarkoitetaan jätettä, jolla ei ole vaaraominaisuuksia. Vaaratonta jätettä on kutsuttu myös tavanomaiseksi jätteeksi.\n\nJätedirektiivin liitteessä III, joka on annettu komission asetuksella (EU) N:o 1357/2014, on lueteltu ominaisuudet, jotka tekevät jätteistä vaarallisia, sekä kyseisten ominaisuuksien arvioinnissa käytettävät kriteerit. Jäte on vaarallista, jos sillä on yksikin komission asetuksessa määritelty vaaraominaisuus. Vaaralliset jätteet on jäteasetuksen liitteen 4 jäteluettelossa merkitty tähdellä (*). Lisätietoja luokittelemisesta vaaraominaisuuksien perusteella saa Suomen ympäristökeskuksen julkaisemasta ”Jätteen luokittelu vaaralliseksi jätteeksi” –oppaasta.'
                )}
              />
            </span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label  className={'qa-dangerousWaste'}>
            <input
              checked={material.type === 'dangerous'}
              className='radioButton'
              type="radio"
              name="type"
              value="dangerous"
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>{t('Kyllä')}</span>
          </label>
        </Col>
      </Row>
      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label>
            <input
              type="radio"
              className='radioButton'
              name="type"
              value="nondangerous"
              checked={material.type === 'nondangerous'}
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>
              {t('Ei')}
            </span>
          </label>
        </Col>
      </Row>
      <Row className={cx(formStyles.formRow, styles.marginBottom2em)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label>
            <input
              type="radio"
              className='radioButton'
              name="type"
              value="unknown"
              checked={material.type === 'unknown'}
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>
              {t('Ei tiedossa')}
            </span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Onko jäte pysyvää?')}{' '}
              <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              <InfoBox
                infoText={t(
                  'Pysyvä jäte on jätelaji, joka ei aiheuta ympäristön saastumista tai haittaa terveydelle ja jossa ei pitkänkään ajan kuluessa tapahdu olennaisia fysikaalisia, kemiallisia tai biologisia muutoksia.'
                )}
              />
            </span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-permanentWaste'}>
            <input
              checked={material.permanentWasteType === 'permanentWaste'}
              className='radioButton'
              type="radio"
              name="permanentWasteType"
              value="permanentWaste"
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>{t('Kyllä')}</span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-nonPermanentWaste'}>
            <input
              checked={material.permanentWasteType === 'nonPermanentWaste'}
              className='radioButton'
              type="radio"
              name="permanentWasteType"
              value="nonPermanentWaste"
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>{t('Ei')}</span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow, styles.marginBottom2em)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-unknownPermanentWaste'}>
            <input
              checked={material.permanentWasteType === 'unknown'}
              className='radioButton'
              type="radio"
              name="permanentWasteType"
              value="unknown"
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>{t('Ei tiedossa')}</span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Onko jäte POP-jätettä?')}{' '}
              <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              <InfoBox
                infoText={t(
                  'Pysyvät orgaaniset yhdisteet (POP) ovat myrkyllisiä, hitaasti hajoavia kemiallisia yhdisteitä, jotka kertyvät eliöihin ravintoketjussa ja kulkeutuvat kauas päästöpaikastaan ilman, veden tai muuttavien eläinlajien välityksellä'
                )}
              />
            </span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-popWaste'}>
            <input
              checked={material.popWasteType === 'popWaste'}
              className='radioButton'
              type="radio"
              name="popWasteType"
              value="popWaste"
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>{t('Kyllä')}</span>
          </label>
        </Col>
      </Row>
      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-nonPopWaste'}>
            <input
              type="radio"
              className='radioButton'
              name="popWasteType"
              value="nonPopWaste"
              checked={material.popWasteType === 'nonPopWaste'}
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>
              {t('Ei')}
            </span>
          </label>
        </Col>
      </Row>
      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-unknownPopWaste'}>
            <input
              type="radio"
              className='radioButton'
              name="popWasteType"
              value="unknown"
              checked={material.popWasteType === 'unknown'}
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>
              {t('Ei tiedossa')}
            </span>
          </label>
        </Col>
      </Row>

      {material.popWasteType === 'popWaste' && (
        <Row className={cx(formStyles.formRow)}>
          <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label id='popSelect'>
            <span className={formStyles.defaultLabelSpan}>
              {t('POP-jätteen sisältämät pysyvät orgaaniset yhdisteet')}{' '}
              </span>
              <ReactSelect                
                isMulti={true}
                options={popWasteOptions}
                closeMenuOnSelect={false}
                components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                value={selectedPopWastes}
                placeholder={t('Valitse...')}
                onChange={(data) => {
                  handleMaterialChange({target: { value: data.length === 0 ? [] : data.map(item => item.value), name: 'popWastes', type: 'multiselect'}})
                }}
                styles={dropStyles}
                isSearchable={false}
              />            
          </label>
          </Col>
        </Row>
      )}

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainer, styles.marginTop2em)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Jätteen kuvaus')} <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
              <ul>
                <li>{t('Jätteen alkuperä, koostumus, olomuoto (kiinteä, neste, liete, kaasu)')}</li>
              {rfo.hilmaId ? (
                <li>{t('Tiedot mahdollisista epäpuhtauksista ja tarkennukset vaaraominaisuuksista tai muita jätteen vastaanottajan kannalta oleellisia tietoja jätteestä.')}</li>
              ) : (
                <li>{t('Tiedot mahdollisista epäpuhtauksista ja tarkennukset vaaraominaisuuksista tai muita jätteen vastaanottajan löytämisen kannalta oleellisia tietoja jätteestä.')}</li>
              )}
              </ul>
            </span>
            <textarea
              name="description"
              type="text"
              rows={6}
              value={material.description || ''}
              onChange={handleMaterialChange}
              maxLength={10000}
            />
          </label>
        </Col>
      </Row>

      <div className='divider'/>
      <h2>{t('Jätteen määrä ja toistuvuus')}</h2>
      <p>
        <strong>
          {t('Kertaerä vai jatkuvasti syntyvä jäte')}{' '}
          <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
          <InfoBox
            infoText={t(
              'Ilmoita yksittäinen jäte-erä tai jatkuvasti syntyvä jäte, mikäli jätettä syntyy toistuvasti pidemmällä aikavälillä ja tavoitteenasi on pidempiaikainen sopimus palvelusta.'
            )}
          />
        </strong>
      </p>
      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-onetime'}>
            <input
              checked={material.continuity === 'onetime'}
              className='radioButton'
              type="radio"
              name="continuity"
              value="onetime"
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>{t('Kertaerä')}</span>
          </label>
        </Col>
      </Row>
      <Row className={cx(formStyles.formRow, styles.marginBottom2em)}>
        <Col span={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-continuous'}>
            <input
              type="radio"
              className='radioButton'
              name="continuity"
              value="continuous"
              checked={material.continuity === 'continuous'}
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>
              {t('Jatkuvasti syntyvä jäte')}
            </span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={4} sm={6} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            {material.continuity === 'continuous' ? (
              <span className={cx(formStyles.defaultLabelSpan, formStyles.doubleLineHeight)}>
                {t('Arvio jätteen kokonaismäärästä vuodessa')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              </span>
            ) : (
              <span className={cx(formStyles.defaultLabelSpan, formStyles.doubleLineHeight)}>
                {t('Arvio jätteen määrästä')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              </span>
            )}
            <input
              name="quantityAmount"
              type="text"
              pattern={regExpForNumberWithUnlimitedDecimals}
              placeholder={t('Määrä numeroina')}
              value={material.quantityAmount || ''}
              onChange={ e => { acceptDecimalNumberInput(e); handleMaterialChange(e) }}
              maxLength={100}
            />
          </label>
        </Col>
        <Col span={4} sm={6} xs={12} className={cx(formStyles.formInputContainer, formStyles.doubleLineHeight)}>
          <QuanityUnitField
            t={t}
            material={material}
            handleMaterialChange={handleMaterialChange}
            isWaste={true}
          />
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Lisätietoja määrästä')}
              <InfoBox
                infoText={rfo.hilmaId 
                  ? t('Yksittäisten jäte-erien määrä, koko ja toistuvuus tai muita tarkennettavia tietoja jätteen määrästä tai palveluntarpeen toistuvuudesta, jotka voivat olla jätteen vastaanottajan kannalta oleellisia.')
                  : t('Yksittäisten jäte-erien määrä, koko ja toistuvuus tai muita tarkennettavia tietoja jätteen määrästä tai palveluntarpeen toistuvuudesta, jotka voivat olla jätteen vastaanottajan löytämisen kannalta oleellisia.'
                )}
              />
            </span>

            <textarea
              name="amountDescription"
              type="text"
              rows={6}
              value={material.amountDescription || ''}
              onChange={handleMaterialChange}
              maxLength={1000}
            />
          </label>
        </Col>
      </Row>
    </>
  );
});

const SingleMaterialEdit = React.memo(({ t, material, handleMaterialChange, currentSubClassifications }) => {
  return (
    <>
      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Materiaali')} <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
              <InfoBox
                infoText={t(
                  'Materiaalia mahdollisimman hyvin kuvaava termi. Valmiiden ilmoitusten hakua voi rajata tämän luokittelun perusteella. Voit valita vain yhden materiaalin yhteen ilmoitukseen.'
                )}
              />
            </span>
            <select
              name="classification"
              value={material.classification || ''}
              onChange={handleMaterialChange}
              className={(!material.classification ? formStyles.placeholder : '' )}
            >
              <option value="" disabled hidden>
                {t('Valitse...')}
              </option>
              <MaterialOptionList includeMaterials={true} />
            </select>
          </label>
          {currentSubClassifications && currentSubClassifications.length > 0 && (
            <>
              <label>
                <span className={formStyles.defaultLabelSpan}>{t('Tarkenna valintaa')}</span>
                <Select
                  values={currentSubClassifications}
                  onAdd={(val) =>
                    handleMaterialChange({ target: { value: val.id, name: 'subClassification' } })
                  }
                  single={true}
                  value={currentSubClassifications.filter(
                    (f) => f.id === material.subClassification
                  )}
                  displayFn={(value) => t(value.id)}
                  keyFn={(val) => val.id}
                  searchFn={searchSubClassificationsFn(t)}
                  showAllOnFocus={true}
                  onRemove={() => {
                    handleMaterialChange({ target: { value: '', name: 'subClassification' } });
                  }}
                />
              </label>
            </>
          )}
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Toimiala, jossa materiaali syntyy')}{' '}
              <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
            </span>
            <select name="industry" value={material.industry || ''} onChange={handleMaterialChange} className={(!material.industry ? formStyles.placeholder : '' )}>
              <option value="" disabled hidden>
                {t('Valitse...')}
              </option>
              <IndustryOptionList />
            </select>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Materiaalin kuvaus')}{' '}
              <InfoBox
                infoText={t(
                  'Materiaalin koostumus tai muu hyödyntämisen kannalta olennainen tieto materiaalin ominaisuuksista.'
                )}
              />
            </span>

            <textarea
              name="description"
              type="text"
              rows={6}
              value={material.description || ''}
              onChange={handleMaterialChange}
              maxLength={10000}
            />
          </label>
        </Col>
      </Row>

      <div className='divider'/>
      <h2>{t('Materiaalin määrä ja toistuvuus')}</h2>
      <p>
        <strong>{t('Kertaerä vai jatkuvasti syntyvä materiaali')}</strong>{' '}
        <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
      </p>
      <Row className={cx(formStyles.formRow)}>
        <Col span={12} sm={12} xs={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-onetime'}>
            <input
              checked={material.continuity === 'onetime'}
              type="radio"
              className='radioButton'
              name="continuity"
              value="onetime"
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>{t('Kertaerä')}</span>
          </label>
        </Col>
      </Row>
      <Row className={cx(formStyles.formRow, styles.marginBottom2em)}>
        <Col span={12} sm={12} xs={12} className={cx(formStyles.formInputContainerNoBottomPadding)}>
          <label className={'qa-continuous'}>
            <input
              type="radio"
              className='radioButton'
              name="continuity"
              value="continuous"
              checked={material.continuity === 'continuous'}
              onChange={handleMaterialChange}
            />
            <span className='radioButtonLabel'>
              {t('Jatkuvasti syntyvä materiaali')}
            </span>
          </label>
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={4} sm={6} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            {material.continuity === 'continuous' ? (
              <span className={cx(formStyles.defaultLabelSpan, formStyles.doubleLineHeight)}>
                {t('Arvio materiaalin kokonaismäärästä vuodessa')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              </span>
            ) : (
              <span className={cx(formStyles.defaultLabelSpan, formStyles.doubleLineHeight)}>
                {t('Arvio materiaalin määrästä')}{' '}
                <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
              </span>
            )}

            <input
              name="quantityAmount"
              type="text"
              pattern={regExpForNumberWithUnlimitedDecimals}
              placeholder={t('Määrä numeroina')}
              value={material.quantityAmount || ''}
              onChange={ e => { acceptDecimalNumberInput(e); handleMaterialChange(e) }}
              maxLength={100}
            />
          </label>
        </Col>
        <Col span={4} sm={6} xs={12} className={cx(formStyles.formInputContainer)}>
          <QuanityUnitField t={t} material={material} handleMaterialChange={handleMaterialChange} />
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Lisätietoja määrästä')}
              <InfoBox
                infoText={t(
                  'Yksittäisten materiaalierien määrä, koko, toistuvuus ja saatavuus tai muita tarkennettavia tietoja materiaalin määrästä tai toistuvuudesta, jotka voivat olla hyödyntämisen kannalta oleellisia.'
                )}
              />
            </span>

            <textarea
              name="amountDescription"
              type="text"
              rows={6}
              value={material.amountDescription || ''}
              onChange={handleMaterialChange}
              maxLength={1000}
            />
          </label>
        </Col>
      </Row>
    </>
  );
});

const SingleReceiveMaterialEdit = React.memo(({ t, material, handleMaterialChange, currentSubClassifications }) => {
  return (
    <>
      <Row className={cx(formStyles.formRow)}>
        <Col span={6} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Materiaali')} <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
              <InfoBox
                infoText={t(
                  'Etsittävää materiaalia mahdollisimman hyvin kuvaava termi. Valmiiden ilmoitusten hakua voi rajata tämän luokittelun perusteella. Voit valita vain yhdenmateriaalin yhteen ilmoitukseen.'
                )}
              />
            </span>
            <select
              name="classification"
              value={material.classification || ''}
              onChange={handleMaterialChange}
              className={(!material.classification ? formStyles.placeholder : '' )}
            >
              <option value="" disabled hidden>
                {t('Valitse haettava materiaali')}
              </option>
              <MaterialOptionList includeMaterials={true} includeWaste={true} />
            </select>
          </label>
          {currentSubClassifications && currentSubClassifications.length > 0 && (
            <>
              <label>
                <span className={formStyles.defaultLabelSpan}>{t('Tarkenna valintaa')}</span>
                <Select
                  values={currentSubClassifications}
                  onAdd={(val) =>
                    handleMaterialChange({ target: { value: val.id, name: 'subClassification' } })
                  }
                  single={true}
                  value={currentSubClassifications.filter(
                    (f) => f.id === material.subClassification
                  )}
                  displayFn={(value) => t(value.id)}
                  keyFn={(val) => val.id}
                  searchFn={searchSubClassificationsFn(t)}
                  showAllOnFocus={true}
                  onRemove={() => {
                    handleMaterialChange({ target: { value: '', name: 'subClassification' } });
                  }}
                />
              </label>
            </>
          )}
        </Col>
      </Row>

      <Row className={cx(formStyles.formRow)}>
        <Col span={12} sm={12} xs={12} className={cx(formStyles.formInputContainer)}>
          <label>
            <span className={formStyles.defaultLabelSpan}>
              {t('Materiaalin ja tarpeen kuvaus')}{' '}
              <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>{' '}
              <InfoBox
                infoText={t(
                  'Hyödyntämisen kannalta olennaiset edellytykset materiaaliin ja sen ominaisuuksiin liittyen. Voit lisäksi kuvata, miten aiot materiaalia hyödyntää. Voit myös, kertoa miten suurta määrää haet, ja onko kyse jatkuvasta vai kertatarpeesta.'
                )}
              />
            </span>
            <textarea
              name="description"
              type="text"
              rows={6}
              value={material.description || ''}
              onChange={handleMaterialChange}
              maxLength={10000}
            />
          </label>
        </Col>
      </Row>
    </>
  );
});

const SingleWasteEditWithTranslation = withNamespaces()(SingleWasteEdit);
const SingleMaterialEditWithTranslation = withNamespaces()(SingleMaterialEdit);
const SingleReceiveMaterialEditWithTranslation = withNamespaces()(SingleReceiveMaterialEdit);

export {
  SingleMaterialEditWithTranslation as SingleMaterialEdit,
  SingleWasteEditWithTranslation as SingleWasteEdit,
  SingleReceiveMaterialEditWithTranslation as SingleReceiveMaterialEdit,
};
