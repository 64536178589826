import React, { Component } from 'react';
import Parser from 'rss-parser';
import styles from './RssFeed.module.css';
import i18n from '../../i18n';
import { formatDateTime } from '../../utils/date-utils';

class RssFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 'fi',
      items: [],
    };
  }

  readFeed(language) {
    let parser = new Parser();
    parser.parseURL('/api/rss/ajankohtaista/' + language, (err, feed) => {
      if (err) throw err;
      this.setState({ items: feed.items });
    });
  }

  componentDidMount() {
    this.setState({ language: i18n.language.substring(0, 2) });
    this.readFeed(this.state.language);
  }

  render() {
    return this.state.items && this.state.items.length > 0 ? (
      <div>
        {this.state.items.map((item) => {
          return (
            <div key={item.guid} className={styles.newsItem}>
              <span className={styles.timestamp}>{formatDateTime(new Date(item.isoDate))}</span>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <span className={styles.newsTitle}>{item.title}</span>
              </a>
            </div>
          );
        })}
      </div>
    ) : (
      <div></div>
    );
  }
}
export default RssFeed;
