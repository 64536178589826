import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import cx from 'classnames';
import { Container, Row, Col } from '../Layout/Layout';
import { withNamespaces } from 'react-i18next';
import styles from './SelectRequestType.module.css';
import MTLogo from './MT-logo-vihrea.png';
import HilmaLogo from './Hilma-logo-transparent.png';
import {HILMA_HAKU, OMAT_ILMOITUKSET } from '../../routes';
import InfoBox from '../InfoBox/InfoBox';

const SelectRequestType = ({ t }) => {
  return (
    <>
      <Header />
      <Navigation />
      <Container className={cx('flex-grow-1', 'customContentPage')}>
        <Row options={{ center: true }}>
          <Col span={9} sm={10} xs={12}>
            <h1 className={styles.centerText}>{t('Pyydä kunnan toissijaista jätehuoltopalvelua')}</h1>
            <p className={'subHeading'}>
              {t(
                'Pyydä kunnan toissijaista jätehuoltopalvelua Materiaalitoriin tai julkisten hankintojen ilmoituskanavaan Hilmaan tehdyn ilmoituksen pohjalta. Huomaathan, että ilmoituksen voimassaoloajan on oltava päättynyt. Pyyntö ohjataan valitsemasi kunnan tai kunnan jätelaitoksen käsiteltäväksi.'
              )}
            <InfoBox
              infoText={
              t(
                'Kyseessä on jätelain (646/2011) 33 §:n mukainen kunnan toissijainen jätehuoltopalvelu, jota jätteen haltijan on mahdollista pyytää muun palvelutarjonnan puuttuessa.\n\nJätelain mukaan jätteen haltija on velvollinen järjestämään jätteen jätehuollon, mikäli jätehuolto ei kuulu kunnan vastuulle tai jäte ole ns. tuottajavastuun alainen. Käytännössä tämä tarkoittaa sitä, että yritysten ja monien julkisten organisaatioiden on järjestettävä useimpien jätteidensä jätehuolto itse markkinoilla olevia jätehuoltopalveluja hyödyntäen.\n\nMikäli markkinoilta ei kuitenkaan löydy tarvittavaa palvelua, on sitä mahdollista pyytää kunnalta, sillä kunta on jätelain mukaan velvollinen järjestämään jätehuollon toissijaisesti myös muulle jätteelle, kuin sille josta se on ensisijaisesti vastuussa. Ensisijaisesti kunta on velvollinen järjestämään pääsääntöisesti asumisessa syntyvän jätteen sekä kunnan hallinto- ja palvelutoiminnassa syntyvän yhdyskuntajätteen jätehuollon. Kunnalla on lisäksi vaarallisen jätteen vastaanottoon ja käsittelyyn liittyviä vastuita.'
                ) +
                '\n\n' +
                t(
                  'Kunnan toissijaisen jätehuoltopalvelun edellytyksenä on, että jätteen haltija pyytää sitä yksityisen palveluntarjonnan puutteen vuoksi ja että jäte laadultaan ja määrältään soveltuu kuljetettavaksi tai käsiteltäväksi kunnan jätehuoltojärjestelmässä. Pyynnön voi jätteen haltijan puolesta esittää jätteen kuljettaja tai muu toimija osana tarjoamaansa jätehuoltopalvelua, kun pyyntö koskee jätteen käsittelyä. Pyynnön voi tehdä kuuden (6) kuukauden kuluessa ilmoituksen voimassaolon päättymisestä.'
                  )
              }
            />
            </p>
            <div className={'divider'} />
          </Col>
        </Row>
        <Row options={{ center: true }}>
          <Col span={3} sm={5} xs={12} className={styles.columnContainer}>
          <div className={styles.logoImageContainer}>
            <img
              className={styles.logoImageContainer__image}
              src={MTLogo}
              alt={t('Materiaalitorin logo')}
            />
          </div>
            <h2 className={styles.centerText}>{t('Materiaalitorissa jo olevasta ilmoituksesta')}</h2>
            <p className={styles.centerTextTall}>
              {t(
                'Jos ilmoituksesi on ollut julkaistuna Materiaalitorissa, aloita pyynnön tekeminen tästä.'
              )}
            </p>
            <p className={styles.centerText}>
              <Link className={'buttonStyle'} to={`${OMAT_ILMOITUKSET}?showClosed=true`}>
                {t('Pyydä palvelua')}
              </Link>
            </p>
          </Col>

          <Col span={3} sm={5} xs={12} className={styles.columnContainer}>
          <div className={styles.logoImageContainer}>
            <img
              className={styles.logoImageContainer__image}
              src={HilmaLogo}
              alt={t('Hilman logo')}
            />
          </div>
            <h2 className={styles.centerText}>
              {t('Hilma-hankintajärjestelmän ilmoituksesta')}
            </h2>
            <p className={styles.centerTextTall}>
              {t(
                  'Jos olet tehnyt hankintailmoituksen Hilmaan, aloita pyynnön tekeminen tästä.'
              )}
            </p>
            <p className={styles.centerText}>
              <Link className={'buttonStyle'} to={HILMA_HAKU}>
                {t('Pyydä palvelua')}
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default withNamespaces()(SelectRequestType);