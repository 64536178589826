import React from 'react';

const OpenPaper = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
    <path
      stroke={color || '#000'}
      fill={'none'}
      d="M35,57.35l-20-8v6.77l20,8Zm0-20.77-20-8v6.77l20,8ZM88.8,1.86a5,5,0,0,0-4.66-.5L46,16.62,7.86,1.36A5,5,0,0,0,1,6V70a5,5,0,0,0,3.14,4.64l39.92,16s.82.26,1,.29a4.88,4.88,0,0,0,1.94,0c.15,0,1-.3,1-.3l39.91-16A5,5,0,0,0,91,70V6A5,5,0,0,0,88.8,1.86ZM41,80.77,9,68v-56l32,12.8ZM83,68,51,80.77v-56l32-12.8ZM77,49.35l-20,8v6.77l20-8Zm0-20.77-20,8v6.77l20-8Z"
    />
  </svg>
);

export default OpenPaper;
