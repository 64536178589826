import cx from 'classnames';
import { clone, compose } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postJson } from '../../services/ApiService';
import Loader from './../Loader/Loader';
import editStyles from './CompanyDetailsEdit.module.css';
import { addToastNotification, ToastTypes } from '../../services/ToastService';
import { Row, Col } from '../Layout/Layout';
import info from '../InfoBox/InfoBox.module.css';
import {
  givenName,
  familyName,
  phone,
  email,
  isAuthorizedToMonitorFacilites,
  publicOfficerFacilities,
} from '../../state/ducks/user/selectors';
import styles from './CompanyFullView.module.css';
import { UPDATE_USER_DETAILS } from '../../services/endpoints';

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isEditMode: false,
      user: clone(props.user),
    };
  }

  modifyFormState = (name, val) => {
    let userDraft = clone(this.state.user);
    userDraft.user.profile[name] = val;
    this.setState({ user: userDraft });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.modifyFormState(target.name, value);
  };

  abandonChanges = () => {
    this.setState({ user: clone(this.props.user) });
  };

  hideForm = () => {
    this.setState({ isEditMode: false });
  };

  hideFormAndAbandonChanges = () => {
    this.abandonChanges();
    this.hideForm();
  };

  handleSubmit = () => {
    this.setState({ loading: true });

    const payload = {
      email: this.state.user.user.profile.email,
      phone: this.state.user.user.profile.phone_number
    };

    postJson(UPDATE_USER_DETAILS, payload)
      .then((res) => {
        this.hideForm();
        addToastNotification(this.props.t('Tiedot tallennettu.'), ToastTypes.SUCCESS);
      })
      .catch((err) => {
        addToastNotification(this.props.t('Tietojen tallennus epäonnistui.'), ToastTypes.WARNING);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  disableSaveButton = () => {
    let disable =
      this.state.loading ||
      !this.state.user.user.profile.email ||
      !this.state.user.user.profile.phone_number;

    return disable;
  };

  render() {
    const { t, user, userDetails, company } = this.props;

    return this.state.isEditMode ? (      
      <Col span={6} sm={10} xs={12}>
        <form className={styles.form}>
          <div className={styles.detailRow}>
            <span className={styles.details__label}>{t('Etunimi')}</span>
            <span className={styles.details__value}>{givenName(this.state.user)|| ''}</span>
          </div>

          <div className={styles.detailRow}>
            <span className={styles.details__label}>{t('Sukunimi')}</span>
            <span className={styles.details__value}>{familyName(this.state.user) || ''}</span>
          </div>

          <div className={styles.detailRow}>
            <span className={styles.details__label}>{t('Organisaatio')}</span>
            <span className={styles.details__value}>{company.name || ''}</span>
          </div>

          <div className={styles.detailRow}>
            <span className={styles.details__label}>{t('Puhelin')}</span>
            <input
              className={styles.form__input}
              type="text"
              name="phone_number"
              value={phone(this.state.user) || ''}
              onChange={this.handleChange}
              maxLength={100}
            />
          </div>

          <div className={styles.detailRow}>
            <span className={styles.details__label}>{t('Sähköposti')}</span>
            <input
              className={styles.form__input}
              type="text"
              name="email"
              value={email(this.state.user) || ''}
              onChange={this.handleChange}
              maxLength={100}
            />
          </div>

          <div className={styles.detailRow}>
            <span className={styles.details__label}>{t('Rooli')}</span>
            <span className={styles.details__value}>{t(userDetails.role + '-role-name')}</span>
          </div>

          <span className={info.infoWrapper}>
            <span className={cx({ [info.infoContentShow]: true })}>
              {t(
                'Huomioithan että puhelinnumeron tai sähköpostin vaihtaminen astuu voimaan seuraavan Materiaalitorin sisäänkirjautumisen yhteydessä.'
              )}
            </span>
          </span>

          <div className={editStyles.buttonWrap}>
            <button
              type="button"
              onClick={this.hideFormAndAbandonChanges}
              className={cx(editStyles.marginRight1rem, 'cancel')}
            >
              {t('Peruuta')}
            </button>
            <button
              type="button"
              onClick={this.handleSubmit}
              disabled={this.disableSaveButton()}
              className={cx(editStyles.marginRight1rem, 'qa-saveUserDetailsButton')}
            >
              {t('Tallenna')}
            </button> 
            <Loader loading={this.state.loading} />
          </div>
        </form>
      </Col>
    ) : (
      <>
        <Row>
          <Col span={12} xs={12}>
            <h2 className={styles['heading']}>{t('Käyttäjätiedot')}</h2>
          </Col>
        </Row>

        <Row>
          <Col span={6} sm={10} xs={12} className={styles['details']}>
            <div className={styles.detailRow}>
              <span className={styles.details__label}>{t('Etunimi')}</span>
              <span className={styles.details__value}>{givenName(user)}</span>
            </div>
            <div className={styles.detailRow}>
              <span className={styles.details__label}>{t('Sukunimi')}</span>
              <span className={styles.details__value}>{familyName(user)}</span>
            </div>
            <div className={styles.detailRow}>
              <span className={styles.details__label}>{t('Organisaatio')}</span>
              <span className={styles.details__value}>{company.name}</span>
            </div>
            <div className={styles.detailRow}>
              <span className={styles.details__label}>{t('Puhelin')}</span>
              <span className={styles.details__value}>{phone(user)}</span>
            </div>
            <div className={styles.detailRow}>
              <span className={styles.details__label}>{t('Sähköposti')}</span>
              <span className={styles.details__value}>{email(user)}</span>
            </div>

            <div className={styles.detailRow}>
              <span className={styles.details__label}>{t('Rooli')}</span>
              <span className={styles.details__value}>{t(userDetails.role + '-role-name')}</span>
            </div>
            
            <div className={editStyles.buttonWrap}>          
              <button
                type="button"
                onClick={() => {this.setState({ isEditMode: true })}}
                className={cx(editStyles.marginRight1rem, 'qa-editUserDetailsButton')}>
                {t('Muokkaa')}
              </button>
            </div>

            {isAuthorizedToMonitorFacilites(user) && (
              <div className={styles.detailRow}>
                <span className={styles.details__label}>{t('Jätelaitokset')}</span>
                <div className={styles.details__value}>
                  {publicOfficerFacilities(user).map(facility => (
                    <div key={facility.businessId}>
                      {facility.name + ' (' + facility.businessId + ')'}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isTouchDevice: state.generalState.isTouchDevice,
  };
};

export default compose(connect(mapStateToProps))(UserEdit);