import React from 'react';
import { withNamespaces } from 'react-i18next';
import styles from './RfoSummary.module.css';
import cx from 'classnames';
import { formatDate } from '../../utils/date-utils';
import { KYSELY } from '../../routes';
import { Link } from 'react-router-dom';
import { RFO_OFFERING_MATERIAL, RFO_OFFERING_SERVICES, RFO_OFFERING_WASTE, RFO_RECEIVING_MATERIAL, RFO_RECEIVING_SERVICES } from '../RequestForOffer/types';

const linkToQuestionnaire = (id, title) => {
  return <Link to={`${KYSELY}/${id}`}>{title}</Link>;
};

const getRfoType = (t, rfoType) => {
  switch (rfoType)
  {
    case RFO_OFFERING_MATERIAL: return t('offeringMaterial-title')
    case RFO_OFFERING_WASTE: return t('offeringWaste-title')
    case RFO_RECEIVING_MATERIAL: return t('receivingMaterial-title')
    case RFO_OFFERING_SERVICES: return t('offeringServices-title')
    case RFO_RECEIVING_SERVICES: return t('receivingServices-title')
    default: return ''
  }
}

const resultView = (t, searchType, data) => {  
  return (
    <table className={cx(styles.resultTable)}>
      <thead>
        <tr>
          <th>{t('Otsikko')}</th>
          <th>{t('Tyyppi')}</th>
          <th>{t('Luotu')}</th>
          <th>{t('Vastattu')}</th>
        </tr>
      </thead>
      <tbody>
        {data.sort((a, b) => { return new Date(b.statusDate) - new Date(a.statusDate); })
             .map(resultRow => (
          <tr key={resultRow.id}>
            <td>{linkToQuestionnaire(resultRow.id, t(resultRow.rfo.Title))}</td>
            <td>{getRfoType(t, resultRow.rfo.RfoType)}</td>              
            <td>{formatDate(new Date(resultRow.created))}</td>
            <td>{formatDate(new Date(resultRow.statusDate))}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
};

const QuestionnaireSummaryResultView = ({ t, searchType, data }) => {
  return <div>{resultView(t, searchType, data)}</div>;
};

export default withNamespaces()(QuestionnaireSummaryResultView);
