import React from 'react';

const Materiaalitori = ({ color = '#fff', size }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 648.8 107.9"
    height={size}
  >
    <g>
      <polygon
        fill={color}
        points="251.2,73.7 247.8,49.1 236.5,73.8 233,73.8 221.6,49.1 218.3,73.7 206.4,73.7 212.9,30.9 224.6,30.9 
			234.7,54.2 244.9,30.9 256.6,30.9 263.1,73.7 	"
      />
      <path
        fill={color}
        d="M290.7,57.2c0-4.3-3.1-7.5-7.4-7.5c-4.3,0-7.4,3.2-7.4,7.5c0,4.3,3.1,7.5,7.4,7.5
			C287.6,64.7,290.7,61.5,290.7,57.2 M301.5,40.7v33h-8.8l-1-2.3c-2.7,2.2-6.1,3.5-10,3.5c-10.1,0-17.3-7.4-17.3-17.6
			c0-10.2,7.2-17.5,17.3-17.5c3.9,0,7.4,1.3,10.1,3.5l1.2-2.6H301.5z"
      />
      <path
        fill={color}
        d="M329.5,63.8v10h-7.8c-7.3,0-11.7-4.5-11.7-11.8V49.5h-6v-2.7L318.6,31h2.3v9.7h8.3v8.8h-8V60
			c0,2.4,1.4,3.8,3.8,3.8H329.5z"
      />
      <path
        fill={color}
        d="M342.4,53.6h13.4c-1-3.3-3.4-5.1-6.8-5.1C345.6,48.5,343.3,50.3,342.4,53.6 M366.7,59.9h-24.5
			c0.7,3.8,3.1,5.7,6.7,5.7c2.7,0,4.9-1.2,6-3.1h11.3c-2.3,7.5-9,12.2-17.3,12.2c-10.3,0-17.9-7.5-17.9-17.6
			c0-10.1,7.6-17.6,17.9-17.6c10.6,0,18,7.6,18,17.3C367,57.9,366.9,58.9,366.7,59.9"
      />
      <path
        fill={color}
        d="M392.1,40.5v10.4h-4.4c-4,0-5.7,1.8-5.7,6v16.8h-11.4v-33h7.6l1.8,3.7c2.3-2.7,5.2-3.8,9.1-3.8H392.1z"
      />
      <path fill={color} d="M395,26.9h11.2v9.7H395V26.9z M394.9,40.7h11.4v33h-11.4V40.7z" />
      <path
        fill={color}
        d="M436.2,57.2c0-4.3-3.1-7.5-7.4-7.5c-4.3,0-7.4,3.2-7.4,7.5c0,4.3,3.1,7.5,7.4,7.5
			C433.1,64.7,436.2,61.5,436.2,57.2 M447.1,40.7v33h-8.8l-1-2.3c-2.7,2.2-6.1,3.5-10,3.5c-10.1,0-17.3-7.4-17.3-17.6
			c0-10.2,7.2-17.5,17.3-17.5c3.9,0,7.4,1.3,10.1,3.5l1.2-2.6H447.1z"
      />
      <path
        fill={color}
        d="M477,57.2c0-4.3-3.1-7.5-7.4-7.5c-4.3,0-7.4,3.2-7.4,7.5c0,4.3,3.1,7.5,7.4,7.5C473.9,64.7,477,61.5,477,57.2
			 M487.9,40.7v33h-8.8l-1-2.3c-2.7,2.2-6.1,3.5-10,3.5c-10.1,0-17.3-7.4-17.3-17.6c0-10.2,7.2-17.5,17.3-17.5
			c3.9,0,7.4,1.3,10.1,3.5l1.2-2.6H487.9z"
      />
      <rect x="493.5" y="28.8" fill={color} width="11.4" height="45" />
      <path fill={color} d="M510.6,26.9h11.2v9.7h-11.2V26.9z M510.5,40.7h11.4v33h-11.4V40.7z" />
      <path
        fill={color}
        d="M549.8,63.8v10H542c-7.3,0-11.7-4.5-11.7-11.8V49.5h-6v-2.7L539,31h2.3v9.7h8.3v8.8h-8V60
			c0,2.4,1.4,3.8,3.8,3.8H549.8z"
      />
      <path
        fill={color}
        d="M577,57.2c0-4.3-3-7.4-7.1-7.4c-4.2,0-7.1,3-7.1,7.4c0,4.4,3,7.4,7.1,7.4C574,64.6,577,61.6,577,57.2
			 M551.4,57.2c0-10.2,7.7-17.6,18.5-17.6c10.7,0,18.5,7.4,18.5,17.6c0,10.2-7.8,17.6-18.5,17.6C559.1,74.8,551.4,67.4,551.4,57.2"
      />
      <path
        fill={color}
        d="M613.6,40.5v10.4h-4.4c-4,0-5.7,1.8-5.7,6v16.8h-11.4v-33h7.6l1.8,3.7c2.3-2.7,5.2-3.8,9.1-3.8H613.6z"
      />
      <path fill={color} d="M616.5,26.9h11.2v9.7h-11.2V26.9z M616.4,40.7h11.4v33h-11.4V40.7z" />
      <path
        fill={color}
        d="M59.4,46.7l5.1-3.4L53.7,23.1c-1-1.9-2.3-3.5-3.8-4.6h21.4c1.9,0,3.3,0.8,4.1,2.4l6.3,11.7l5.1-3.3l-7.5,17.4
			H59.4z"
      />
      <path
        fill={color}
        d="M94.9,80c1.5-1.2,2.8-2.7,3.8-4.6l10.8-20.2l-5.1-3.4H124l7.8,17.4l-5-3.2l-6.3,11.7c-0.9,1.6-2.3,2.4-4.1,2.4
			H94.9z"
      />
      <path
        fill={color}
        d="M86.6,78c-3.4,0-6.5-1.9-8.2-5.1L70.5,58L88,47.1l10.4,19.4l-3.1,5.6c-0.9,1.8-2.8,3.7-4,4.5
			C89.9,77.5,88.3,78,86.6,78"
      />
      <path
        fill={color}
        d="M116.8,42.8l9.3-17.3c1.7-3.1,4.8-5.1,8.2-5.1c1.6,0,3.2,0.5,4.6,1.3c1.2,0.7,3.1,2.7,4,4.4l2.4,6.8
			l-11.1,20.7L116.8,42.8z"
      />
      <path
        fill={color}
        d="M162.5,85l5.5-2.6l-21.3-59.3c-1.1-2-2.3-3.6-3.8-4.7h21.4c1.9,0,3.3,0.8,4.2,2.4l18.3,53.6l5.5-2.5L182.3,88
			L162.5,85z"
      />
      <path
        fill={color}
        d="M36.8,86.5L54,33.1l-2.7-5.9c-0.8-1.8-2.5-3.9-3.7-4.7c-1.3-1-2.9-1.5-4.5-1.7c-3.4-0.2-6.6,1.5-8.4,4.4
			L16.2,86.5H36.8z"
      />
    </g>
  </svg>
);

export default Materiaalitori;
