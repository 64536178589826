import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { isChrome } from './chrome-utils';

class ChromeErrorInfoText extends Component {
  render() {
    const { t } = this.props;

    return isChrome() ? <>{' ' + t('(alt-klikkaa)')}</> : <></>;
  }
}

export default withNamespaces()(ChromeErrorInfoText);
