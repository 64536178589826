import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import {
HILMA_TSV_SERVICE_REASON_NO_OFFERS,
HILMA_TSV_SERVICE_REASON_OFFER_UNSUITABLE,
HILMA_TSV_SERVICE_REASON_OTHER
} from './types';

const SelectReasonForServiceNeed = (t, form, handleChange, showTextAreaForOption2, showTextAreaForOption3) => (
        <div>
            <input
              type="radio"
              name="reasonForServiceOption"
              value={HILMA_TSV_SERVICE_REASON_NO_OFFERS}
              checked={form.reasonForServiceOption === HILMA_TSV_SERVICE_REASON_NO_OFFERS}
              id="reasonForServiceOption-1"
              className={'radioButton'}
              onChange={handleChange}
            />
            <label htmlFor="reasonForServiceOption-1" className={'radioButtonLabel'}>
              {t('no-offers-received')}
            </label>
            <input
              type="radio"
              name="reasonForServiceOption"
              value={HILMA_TSV_SERVICE_REASON_OFFER_UNSUITABLE}
              checked={form.reasonForServiceOption === HILMA_TSV_SERVICE_REASON_OFFER_UNSUITABLE}
              id="reasonForServiceOption-2"
              className={'radioButton'}
              onChange={handleChange}
            />
            <label htmlFor="reasonForServiceOption-2" className={'radioButtonLabel'}>
              {t('received-offer-unsuitable')}
            </label>
            {showTextAreaForOption2 && (
              <label>
                <h4>{`${t('Tarkempi perustelu')}`}{' '}
                  <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                </h4>
                <textarea
                    name="reasonForServiceDetails"
                    type="text"
                    rows={3}
                    value={form.reasonForServiceDetails || ''}
                    onChange={handleChange}
                  />
              </label>
            )}
            <input
              type="radio"
              name="reasonForServiceOption"
              value={HILMA_TSV_SERVICE_REASON_OTHER}
              checked={form.reasonForServiceOption === HILMA_TSV_SERVICE_REASON_OTHER}
              id="reasonForServiceOption-3"
              className={'radioButton'}
              onChange={handleChange}
            />
             <label htmlFor="reasonForServiceOption-3" className={'radioButtonLabel'}>
              {t('other-reason')}
            </label>
            {showTextAreaForOption3 && (
              <label>
                <h4>{`${t('Tarkempi perustelu')}`}{' '}
                  <span className={'requiredIndicator'}>{t('(pakollinen)')}</span>
                </h4>
                <textarea
                  name="reasonForServiceDetails"
                  type="text"
                  rows={3}
                  value={form.reasonForServiceDetails || ''}
                  onChange={handleChange}
                />
              </label>
            )}
          </div>
      );
    
class HilmaServiceNeedSelection extends Component {

    showTextAreaForOption2 = (form) => {
        return form.reasonForServiceOption === HILMA_TSV_SERVICE_REASON_OFFER_UNSUITABLE;
    };
    
    showTextAreaForOption3 = (form) => {
        return form.reasonForServiceOption === HILMA_TSV_SERVICE_REASON_OTHER;
    }
      
    render() {
        const {
            t,
            form,
            handleChange
          } = this.props;
  
      return (
          <>
        <div>
        {SelectReasonForServiceNeed(
                t,
                form,
                handleChange,
                this.showTextAreaForOption2(form),
                this.showTextAreaForOption3(form),
            )}
        </div>
      </>
      );
    }
}
  
export default withNamespaces()(HilmaServiceNeedSelection);