import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, path } from 'ramda';
import suspendUntilAuthorized from '../Auth/suspendUntilAuthorized';
import withCancelToken from '../CancelToken/withCancelToken';
import { Col, Row } from '../Layout/Layout';
import styles from './Questionnaire.module.css';
import { OfferingWaste } from './OfferingWaste';
import { questionnaireOperations } from '../../state/ducks/questionnaire';
import { OfferingMaterial } from './OfferingMaterial';
import { ReceivingMaterial } from './ReceivingMaterial';
import { OfferingServices } from './OfferingServices';
import { ILMOITUKSET, LISAA_ILMOITUS } from '../../routes';
import { Link, Redirect } from 'react-router-dom';
import * as API_ENDPOINTS from '../../services/endpoints';
import { putJson } from '../../services/ApiService';
import { addSuccess, addWarning } from '../../state/ducks/general/operations';
import { ReceivingServices } from './ReceivingServices';

export class BeginQuestionnaire extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: undefined
    };
  }

  fetchQuestionnaireData = id => {
    this.props.fetchQuestionnaireToView(id);
  };

  idEquals = (questionnaire, id) => {
    return questionnaire !== undefined && questionnaire.id === id;
  }

  fetchQuestionnaireDataIfNeeded = questionnaire => {
    if (
      !this.idEquals(questionnaire, this.props.id)
    ) {
      this.fetchQuestionnaireData(this.props.id);
    }
  };

  componentDidMount() {
    this.fetchQuestionnaireDataIfNeeded(path(['questionnaire', 'payload'], this.props));
  }

  componentWillUnmount() {
    this.props.setQuestionnaireNotFound(false);
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.props.handleChange(name, value);
    
    if (event.target.name === 'serviceName') {      
      this.deleteAllSubServices();
    }

    if (!this.props.formHasChanges) {
      this.props.setQuestionnaireHasChanges(true);
    }
  }

  sendForm = (data) => {    
    const putUrl = API_ENDPOINTS.SUBMIT_QUESTIONNAIRE;

    putJson(putUrl, data)
    .then((res) => {
       this.props.addSuccess({
         content: this.props.t('Kiitos vastauksistasi!'),
       });
        
       this.setState({ redirect: LISAA_ILMOITUS });
     })
     .catch((err) => {
       console.log(err);
       
       this.props.addWarning({
         content: this.props.t('Virhe tallennuksessa.'),
       });
     })  
  }

  handleServiceChange = (event) => {    
    this.handleChange(event);
    if (event.target.name === 'serviceName') {      
      this.deleteAllSubServices();
    }
  };

  addSubService = (subService) => {    
    const subservices = this.props.questionnaire.result.subService
      ? this.props.questionnaire.result.subService.concat([subService.id])
      : [subService.id];
    this.props.handleChange('subService', subservices);
  };

  deleteSubService = (subService) => {
    const subservices = this.props.questionnaire.result.subService
    ? this.props.questionnaire.result.subService.filter((item) => subService.id !== item)
    : [];
    this.props.handleChange('subService', subservices);
  };

  deleteAllSubServices = (serviceId) => {
    this.props.handleChange('subService', []);
  };

  getTitle = (questionnaire) => {
    return questionnaire.rfo ? questionnaire.rfo.Title : '';
  }

  getRfoId = (questionnaire) => {
    return questionnaire.rfo ? questionnaire.rfo.id : '';
  }

  getHeader = (t, questionnaire) => {
    return questionnaire.statusName === 'unanswered' ? t('Kysely sulkeutuneesta ilmoituksesta') : t('Vastaus kyselyyn')
  }

  render () {
    const { t, questionnaire, questionnaireNotFound } = this.props;

    return (
      this.state.redirect ? (
        <Redirect to={this.state.redirect} />
      ) :
      <>
        {(!questionnaire.id) && (
          <>
            {questionnaireNotFound && <h2>{t('Kyselyä ei löytynyt tai se voi olla vanhentunut')}</h2>}
          </>
        )}
        {(questionnaire.id) && (
        <Row className={`${styles.container}`} options={{ center: true }}>
          <Col span={10} xs={12}>
            <h2>{this.getHeader(t, questionnaire)}</h2>
            <p>
              <strong>{t('Ilmoitus')}: {this.getTitle(questionnaire)}</strong>
              <span>
                <Link to={`${ILMOITUKSET}/${this.getRfoId(questionnaire)}`}>
                &nbsp;({t('Ilmoitus')})
                </Link>
              </span>
            </p>
            <form>
              {this.getQuestionnaire()}
            </form>
          </Col>
        </Row>
        )}
      </>
    ); 
  }  

  getQuestionnaire = () => {

    let { t, questionnaire } = this.props

    switch (questionnaire.type) {
      case "offeringWasteQuestionnaire":
        return (<OfferingWaste t={t} viewForm={questionnaire} handleChange={this.handleChange} sendForm={this.sendForm} 
          services={this.props.services}
          addSubService={this.addSubService}
          deleteSubService={this.deleteSubService}/>)
      case "offeringMaterialQuestionnaire":
        return (<OfferingMaterial t={t} viewForm={questionnaire} handleChange={this.handleChange} sendForm={this.sendForm} />)
      case "receivingMaterialQuestionnaire":
        return (<ReceivingMaterial t={t} viewForm={questionnaire} handleChange={this.handleChange} sendForm={this.sendForm} />)        
      case "offeringServicesQuestionnaire":
        return (<OfferingServices t={t} viewForm={questionnaire} handleChange={this.handleChange} sendForm={this.sendForm} />)
      case "receivingServicesQuestionnaire":
        return (<ReceivingServices t={t} viewForm={questionnaire} handleChange={this.handleChange} sendForm={this.sendForm} />)
      default:
        console.log("Invalid questionnaire type: ", questionnaire.type);
        break;
    }
  }
}

const mapStateToProps = (state) => ({
    questionnaire: state.questionnaireState.view.single,
    formHasChanges: state.questionnaireState.status.formHasChanges,
    services: state.generalState.configurations.services,
    questionnaireNotFound: state.questionnaireState.status.questionnaireNotFound
  });
  
  const mapDispatchToProps = (dispatch) => ({
    fetchQuestionnaireToView: id => dispatch(questionnaireOperations.fetchQuestionnaireToView(id)),
    handleChange: (key, value) => dispatch(questionnaireOperations.handleQuestionnaireFormChange(key, value)),
    setQuestionnaireHasChanges: (value) => dispatch(questionnaireOperations.setQuestionnaireHasChanges(value)),
    setQuestionnaireNotFound: value => dispatch(questionnaireOperations.setQuestionnaireNotFound(value)),
    addWarning: (notificationObject) => dispatch(addWarning(notificationObject)),
    addSuccess: (notificationObject) => dispatch(addSuccess(notificationObject))
  });

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withNamespaces(),
    withCancelToken,
    suspendUntilAuthorized
  )(BeginQuestionnaire);