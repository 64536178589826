import React from 'react';

const Archive = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 82">
    <path
      stroke={color || '#000'}
      fill={'none'}
      d="M84.84,16c0-5-5-5-5-5H20.14s-5,0-5,5v5H84.84ZM69.91,1H30.09s-5,0-5,5H74.88C74.88,1,69.91,1,69.91,1ZM94.79,21l-2.95-3v8H8.15V18L5.21,21C2.26,24,.15,24.75,1.34,31S8.23,71.38,9.08,76c.93,5.07,6.08,5,6.08,5H84.84s5.15.07,6.08-5c.85-4.62,6.56-38.77,7.74-45S97.74,24,94.79,21ZM69.91,48s0,5-5,5H35.07c-5,0-5-5-5-5V38h7v8H62.93V38h7Z"
    />
  </svg>
);

export default Archive;
