import { combineReducers } from 'redux';
import * as types from './types';
import produce from 'immer';

const initialFormState = {
  result: {}
};

const single = (state = initialFormState, action) => {
  switch (action.type) {
    case types.HANDLE_QUESTIONNAIRE_FORM_CHANGE:
      const { key, value } = action;

      return produce(state, draft => {
        draft.result[key] = value;
        return draft;
      });
    default:      
      return state;
  }
};

const initialStatusState = {
  loadingQuestionnaires: false,
  loadingQuestionnaire: false,
  loadingRfo: false,
  formHasChanges: false,
  openQuestionnaires: undefined
};

const status = (state = initialStatusState, action) => {  
   switch (action.type) {
    case types.LOADING_QUESTIONNAIRES:
      return produce(state, (draft) => {
        draft.loadingQuestionnaires = action.status;
      });

    case types.QUESTIONNAIRE_FORM_HAS_CHANGES:
      return produce(state, (draft) => {
        draft.formHasChanges = action.status;
      });

    case types.QUESTIONNAIRE_NOT_FOUND:
      return produce(state, (draft) => {        
        draft.questionnaireNotFound = action.value;
      });      

    case types.OPEN_QUESTIONNAIRES:
      return produce(state, (draft) => {            
        draft.openQuestionnaires = action.questionnaires;
        return draft;
      });

    default:
      return state;
  }
};

const initialViewState = {
  form: {},
  single: {}
};

const view = (state = initialViewState, action) => {
  switch (action.type) {
    case types.QUESTIONNAIRE_VIEW:
      return produce(state, (draft) => {
        draft.single = action.payload;
      });  

    case types.HANDLE_QUESTIONNAIRE_FORM_CHANGE:
      const { key, value } = action;      
      
          return produce(state, (draft) => {
        draft.single.result[key] = value;
        
        return draft;        
      });

    default:
      return state;
  }
};

const questionnaireReducer = combineReducers({
  single,
  view,
  status,
});

export default questionnaireReducer;