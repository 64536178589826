import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, find, propEq, clone } from 'ramda';
import Loader from '../Loader/Loader';
import Map from '../Map/Map';
import { Redirect } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import * as rfoTypes from '../RequestForOffer/types';
import { mapRegion, mapMunicipality, getEurefCoordinates } from '../RegionSelect/region-utils';
import { debounce } from '../../utils/common-utils';
import * as styles from '../../styles/colors.module.css';
import rfostyles from './RequestForOffer.module.css';
import { Row, Container, Col } from '../Layout/Layout';
import { searchOperations } from '../../state/ducks/search';
import cx from 'classnames';
import Select, { components } from 'react-select';

const mapRfosToMarkers = (mapRfos) => {
  return mapRfos.reduce((acc, cur) => {
    return acc.concat(
      cur.coords.map((coord, i) => {
        return {
          id: cur.id + '_' + i,
          rfoId: cur.id,
          lon: coord.lon,
          lat: coord.lat,
          cityId: coord.cityId,
          regionId: coord.regionId,
          coordType: coord.type,
          rfoType: cur.rfoType,
          title: cur.title,
        };
      })
    );
  }, []);
};

const groupMarkersBy = (markers, groupSelector, getCoords) => {
  let groupings = {};
  markers.forEach((mark) => {
    const group = groupSelector(mark);
    if (groupings[group]) {
      groupings[group].subMarkers.push(mark);
    } else if (group !== undefined) {
      const m = clone(mark);
      if (getCoords) {
        const coords = getCoords(group);
        m.lon = coords.lon;
        m.lat = coords.lat;
      }
      groupings[group] = m;
      groupings[group].subMarkers = [m];
    }
  });
  return Object.values(groupings);
};

const getCoords = (mapper, list) => (id) => {
  const location = mapper([id], list);
  return Array.isArray(location) && location.length && location[0].coordinates
    ? getEurefCoordinates(location[0].coordinates)
    : null;
};

const getByLocation = (mapRfos) => {
  return getMarkersByLocationType(mapRfos, "location", "region");
}

const getByRegion = (mapRfos) => {
  return getMarkersByLocationType(mapRfos, "region", "location");
}

//get markers by primary location type and with secondary type if it does not have the primary type
const getMarkersByLocationType = (mapRfos, primaryType, secondaryType) => {
  let rfos = mapRfos.filter(item => item.coordType === primaryType);
  let rfoIds = rfos.map(r => r.rfoId);

  return rfos.concat(mapRfos.filter(item => item.coordType === secondaryType && rfoIds.indexOf(item.rfoId) === -1));
}

const getCurrentMarkers = (mapRfos, zoom, municipalities, regions) => {

  let markers = [];
  
  if (Number.isNaN(zoom)) {
    markers = getByLocation(mapRfos);
  } else {
    if (zoom < 2) {
      markers = groupMarkersBy(
        getByRegion(mapRfos),
        (m) => {
          return m.regionId;
        },
        getCoords(mapRegion, regions)
      );
    } else if (zoom < 5) {
      markers = groupMarkersBy(
        getByLocation(mapRfos),
        (m) => {
          return m.cityId;
        },
        getCoords(mapMunicipality, municipalities)
      );
    } else {
      markers = getByLocation(mapRfos);
    }
  }    


  //having only one marker makes the map annoyingly auto-zoom to level 7 when zooming out from level 5 to 4
  //thus create a duplicate marker to avoid the annoying behavior
  return markers.length > 1 ? markers : markers.concat(markers);
};

const infoBoxOptions = {
  colourScheme: {
    bgColour: styles.mtDeepGreen,
    titleColour: '#FFFFFF',
    linkColour: styles.mtDeepBlue,
  },
  hidePrevious: true,
};

const getListInfobox = (markers, marker, t) => {
  const title = t('Useita ilmoituksia');
  return {
    infoBox: [
      'markerInfoBox',
      title,
      [
        {
          html:
            '<div><style>.actionTemplateWrapper { margin-top: 0.5rem; } .infoboxActionLinks a{text-decoration: none;} .infoboxActionLinks:hover a{text-decoration: underline;} </style></div>', // :)
        },
        {
          actions: markers.map((f) => {
            return {
              name: f.title,
              type: 'link',
              action: {
                rfoId: f.rfoId,
              },
            };
          }),
        },
      ],
      { marker: marker.id },
      infoBoxOptions,
    ],
  };
};

const getGroupedByRfoTypInfobox = (markers, marker, t) => {
  const groups = groupMarkersBy(markers, (m) => m.rfoType);
  const typeCountHtml = groups
    .map(
      (f) =>
        '<div>' +
        t(f.rfoType + '-title') +
        ' (' +
        groupMarkersBy(f.subMarkers, (m) => m.rfoId).length +
        ')</div>'
    )
    .join('');
  const style = '<div><style>.contentWrapper-infobox div { margin-top: 0.5rem; }</style></div>';
  const typeCountHtmlWithStyle = style + typeCountHtml;
  const title = t('Useita ilmoituksia');
  return {
    infoBox: [
      'markerInfoBox',
      title,
      [
        {
          html: typeCountHtmlWithStyle,
        },
      ],
      { marker: marker.id },
      infoBoxOptions,
    ],
  };
};

const getSingleItemInfobox = (markerData, marker, t) => {
  let title =
    markerData.rfoType !== rfoTypes.RFO_OFFERING_SERVICES ? t('Materiaali') : t('Palvelu');
  return {
    infoBox: [
      'markerInfoBox',
      title,
      [
        {
          html: `<div>${markerData.title}</div>`,
        },
        {
          actions: [
            {
              name: t('Katso ilmoitus'),
              type: 'link',
              action: {
                rfoId: markerData.rfoId,
              },
            },
          ],
        },
      ],
      { marker: marker.id },
      infoBoxOptions,
    ],
  };
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={require("../Icon/icons/Sort.svg")} alt="" />
    </components.DropdownIndicator>
  );
};

class ListRequestForOfferOnMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMarker: undefined,
      redirectTo: undefined,
      mapState: undefined,
      mapExpanded: false,
      selectedSort: undefined,
    };
  }

  mapStateHandler = (e) => {
    this.setState({ mapState: e });
  };

  currentMapMarkers = [];

  debouncedMarkerClick = debounce((markerData, marker, t, queue) => {
    if (queue.length === 1) {
      this.setState(getSingleItemInfobox(markerData, marker, t));
    } else {
      let clickedMarkers = queue.map((f) => f.args[0]);
      if (clickedMarkers.length > 8) {
        this.setState(getGroupedByRfoTypInfobox(clickedMarkers, marker, t));
      } else {
        this.setState(getListInfobox(clickedMarkers, marker, t));
      }
    }
  }, 50);

  onMarkerClick = (t, marker) => {
    const markerData = find(propEq('id', marker.id))(this.currentMapMarkers);
    if (markerData.subMarkers) {
      // we can assume that when markes are grouped those wont collide and we can set state
      const subMarkers = groupMarkersBy(markerData.subMarkers, (m) => m.rfoId);
      if (subMarkers.length > 8) {
        this.setState(getGroupedByRfoTypInfobox(subMarkers, marker, t));
      } else if (subMarkers.length > 1) {
        this.setState(getListInfobox(subMarkers, marker, t));
      } else {
        this.debouncedMarkerClick(markerData, marker, t);
      }
      return;
    } else {
      // we might get clicks on multiple markers so we debounce clicks and show correct version of popup
      this.debouncedMarkerClick(markerData, marker, t);
    }
  };

  changeSort = (event) => {
    const target = event.target;
    const value = target.value;
    this.props.setSearchSort(value);
  }

  styles = {
    control: (provided, state) => ({
      ...provided,
      border: 0,
      boxShadow: 'none',
      maxWidth: '200px',
      cursor: 'pointer'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
    input: (provided, state) => ({
      ...provided,
    }),
    menuList: (provided, state) => ({
      padding: '1rem',
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#007ec6',//mtDeepBlue
      fontWeight: 600,
      backgroundColor: 'white',
      textAlign: 'left',
      cursor: 'pointer'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#007ec6',//mtDeepBlue
      fontWeight: 600,
      backgroundColor: 'white',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      height: '36px',
      width: '36px'
    })
  };

  onChangeSort = (sort) => {
    this.setState({selectedSort: sort});
    this.props.setSearchSort(sort.value);
  }

  sortDropdown = (t) => {
    const options = [
      { value: 'newest', label: t('Uusimmat ensin') },
      { value: 'oldest', label: t('Vanhimmat ensin') },
      { value: 'expiresFirst', label: t('Ensin vanhenevat') },
      { value: 'expiresLast', label: t('Pisimpään voimassa olevat') },
    ];

    return (
      <Select
        options={options}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        value={this.state.selectedSort ?? options[0]} 
        onChange = {value => this.onChangeSort(value)}
        styles={this.styles}
        isSearchable={false}
      />
    )
  }

  render() {
    const { loading, t, mapRfos } = this.props;

    const onInfoBoxAction = (event) => {
      if (event.actionParams.rfoId) {
        this.setState({ redirectTo: `/ilmoitukset/${event.actionParams.rfoId}` });
      }
    };

    const onInfoBoxClose = () => {
      this.setState({ infoBox: undefined });
    };

    const currentMarkers = getCurrentMarkers(
            mapRfos,
            this.state.mapState ? this.state.mapState.zoom : undefined,
            this.props.municipalities,
            this.props.regions
          );

    this.currentMapMarkers = currentMarkers;

    return this.state.mapExpanded === true ? (
      <>
        <Loader loading={loading}>
          <Container>
            <Row options={{ center: true }}>
              <Col span={10} className={cx(styles.container)} options={{ hiddenMd: true}}>
                <div className={rfostyles['resultsHeaderContainerSm']}>
                  <div>
                    {t('Hakutulokset')}
                  </div>
                  <div className={rfostyles['expandContainer']}>
                    <div className={rfostyles['expandToggle'] + ' ' + rfostyles['collapse']}  onClick={() => { this.setState({mapExpanded: false})}}>
                      <img className={rfostyles['mapPin']} alt="" />
                      <div>
                        {t('Näytä ilmoitukset kartalla')}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={10} className={cx(styles.container)} options={{ hiddenXs: true, hiddenSm: true}}>
                <div className={rfostyles['resultsHeaderContainer']}>
                  <div>
                    {t('Hakutulokset')}
                  </div>
                  <div className={rfostyles['expandContainer']}>
                    <div className={rfostyles['expandToggle'] + ' ' + rfostyles['collapse']}  onClick={() => { this.setState({mapExpanded: false})}}>
                      <img className={rfostyles['mapPin']} alt="" />
                      <div>
                        {t('Näytä ilmoitukset kartalla')}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {Array.isArray(currentMarkers) && currentMarkers.length > 0 && (
            <div style={{ height: '65vh', marginTop: '2rem'}}>
              <Map
                onInfoBoxAction={onInfoBoxAction}
                onMarkerClick={(marker) => this.onMarkerClick(t, marker)}
                onInfoBoxClose={onInfoBoxClose}
                mapState={{ markers: currentMarkers, infoBox: this.state.infoBox }}
                mapEventHandlers={[
                  { eventName: 'AfterMapMoveEvent', eventHandler: this.mapStateHandler },
                  { initialMapPositionCallback: this.mapStateHandler },
                ]}
              />
            </div>
          )}
        </Loader>

        <Col span={10} className={cx(styles.container)} options={{ hiddenMd: true}}>
          <div className={rfostyles['resultsHeaderContainerSm']}>
            <div />
            <div />
            <div className={rfostyles['sortContainer']}>
              {this.sortDropdown(t)}
            </div>
          </div>
        </Col>

        <Col span={10} className={cx(styles.container)} options={{ hiddenXs: true, hiddenSm: true}}>
          <div className={rfostyles['resultsHeaderContainer']}>
            <div />
            <div />
            <div className={cx( rfostyles['sortContainerMd'])}>
              {this.sortDropdown(t)}
            </div>
          </div>
        </Col>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo} push />}
      </>
    ) :
    (
      <>
      <Container>
        <Row options={{ center: true }}>
          <Col span={10} className={cx(styles.container)} options={{ hiddenMd: true}}>
            <div className={rfostyles['resultsHeaderContainerSm']}>
                <div>
                  {t('Hakutulokset')}
                </div>
                <div className={rfostyles['expandContainer']}>
                  <div className={rfostyles['expandToggle'] + ' ' + rfostyles['expand']}  onClick={() => { this.setState({mapExpanded: true})}}>
                    <img className={rfostyles['mapPin']} alt="" />
                    <div>
                      {t('Näytä ilmoitukset kartalla')}
                    </div>
                  </div>
                </div>
                <div className={rfostyles['sortContainer']}>
                  {this.sortDropdown(t)}
                </div>
              </div>
          </Col>
          <Col span={10} className={cx(styles.container)} options={{ hiddenXs: true, hiddenSm: true}}>
            <div className={rfostyles['resultsHeaderContainer']}>
              <div>
                {t('Hakutulokset')}
              </div>
              <div className={rfostyles['expandContainer']}>
                <div className={rfostyles['expandToggle'] + ' ' + rfostyles['expand']}  onClick={() => { this.setState({mapExpanded: true})}}>
                  <img className={rfostyles['mapPin']} alt="" />
                  <div>
                    {t('Näytä ilmoitukset kartalla')}
                  </div>
                </div>
              </div>
              <div className={rfostyles['sortContainerMd']}>
                {this.sortDropdown(t)}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapRfos: mapRfosToMarkers(state.rfoState.view.mapList),
    loading: state.rfoState.status.loadingMapRfos,
    regions: state.generalState.location.regions,
    municipalities: state.generalState.location.municipalities,
    sort: state.searchState.sortOrder
  };
};
const mapDispatchToProps = dispatch => ({
  addSearchTerm: (term, value) => dispatch(searchOperations.addSearchTerm(term, value)),
  setSearchSort: (value) => dispatch(searchOperations.setSearchSort(value)),
});
export default compose(withNamespaces(), connect(mapStateToProps, mapDispatchToProps))(ListRequestForOfferOnMap);
