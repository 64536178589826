import React, { Component } from 'react';
import { compose } from 'ramda';
import styles from './Search.module.css';
import formStyles from '../Layout/Form.module.css';
import cx from 'classnames';
import { withNamespaces } from 'react-i18next';
import RegionSelect from '../RegionSelect/RegionSelect';
import { connect } from 'react-redux';
import { searchOperations } from '../../state/ducks/search';
import { rfoOperations } from '../../state/ducks/rfo';
import AddSavedSearch from './AddSavedSearch';
import SavedSearches from './SavedSearches';
import PrivateComponent from '../Auth/PrivateComponent';
import { without } from 'ramda';
import Select from '../Select/Select';
import { groupSubServices, groupSubMaterials } from './Search.util';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSaveSearchForm: false
    };
    this.service = '';
    this.classification = '';
    this.subClassifications = [];
    this.subMaterials = [];
    this.initialServices = [];
    this.latestParams = {};
  }

  fetchRfos = (presetParams) => {
    const params = {
      text: this.props.searchState.text,
      location: this.props.searchState.location,
      rfoType: this.props.searchState.rfoType,
      service: this.cleanUpSearchServices(this.props.searchState.service),
      classification: this.cleanUpSearchClassifications(this.props.searchState.classification),
      includeExpired: this.props.searchState.includeExpired,
      includeClosed: this.props.searchState.includeClosed,
      sort: this.getSort(this.props.sort),
      descending: this.getDescending(this.props.sort)
    };

    if (presetParams && presetParams.service)
    {
      presetParams.service = this.cleanUpSearchServices(presetParams.service);
    }

    if (presetParams && presetParams.classification)
    {
      presetParams.classification = this.cleanUpSearchClassifications(presetParams.classification);
    }

    this.latestParams = presetParams ? presetParams : params;
    this.props.fetchRfosWithParamas(this.latestParams);
  };

  getSort = (sort) => {
    switch (sort)
    {
      case 'newest': return "10";
      case 'oldest': return "10";
      case 'expiresFirst': return "20";
      case 'expiresLast': return "20";
      default: return "10";
    }
  }

  getDescending = (sort) => {
    switch (sort)
    {
      case 'newest': return true;
      case 'oldest': return false;
      case 'expiresFirst': return false;
      case 'expiresLast': return true;
      default: return true;
    }
  }
  cleanUpSearchClassifications = (currentClassifications) => {
    let classifications = [];
    currentClassifications = Array.isArray(currentClassifications) ? currentClassifications : currentClassifications ? currentClassifications.split(',') : [];
    currentClassifications.forEach(cls => {
      const existingClassification = this.props.materials.find(s => s.id === cls);

      if (existingClassification && existingClassification.subMaterialTypes && existingClassification.subMaterialTypes.length > 0)
      {
        let classificationRemoved = false;
        existingClassification.subMaterialTypes.forEach(subClassification => {
          if (classificationRemoved)
          {
            return;
          }
          if (currentClassifications.indexOf(subClassification.id) > -1)
          {
            if (classifications.indexOf(existingClassification.id) === -1)
            {
              classifications.push(existingClassification.id)            
              classificationRemoved = true;
            }
          }
        })
      }
    });

    return without(classifications, currentClassifications);
}

  cleanUpSearchServices = (currentServices) => {
      let servicesToRemove = [];
      currentServices = Array.isArray(currentServices) ? currentServices : currentServices ? currentServices.split(',') : [];
      currentServices.forEach(srv => {
        const existingService = this.props.services.find(s => s.id === srv);
        if (existingService && existingService.subServices && existingService.subServices.length > 0)
        {
          let serviceRemoved = false;
          existingService.subServices.forEach(subService => {
            if (serviceRemoved)
            {
              return;
            }
            if (currentServices.indexOf(subService.id) > -1)
            {
              if (servicesToRemove.indexOf(existingService.id) === -1)
              {
                servicesToRemove.push(existingService.id)            
                serviceRemoved = true;
              }
            }
          })
        }
      });
  
      return without(servicesToRemove, currentServices);
  }

  shouldComponentUpdate(nextProps) {
    // Rendering the component only if 
    // passed props value is changed
     if (nextProps.sort !== this.props.sort) {
      this.latestParams.sort = this.getSort(nextProps.sort);
      this.latestParams.descending = this.getDescending(nextProps.sort);
      this.fetchRfos(this.latestParams);
     }

     return true;
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search)
    
    let initialDataFetchParams = undefined;
    const paramsToSet = ['text', 'rfoType', 'classification', 'service', 'location'];
    paramsToSet.forEach(param => {
      if (queryParams.has(param))
      {
        const valueForParam = queryParams.get(param);

        valueForParam.split(',').forEach(value => {
          if (value && value.trim() !== '')
          {
            this.props.toggleSearchTerm(param, value);
          }
        });

        if (valueForParam)
        {
          if (!initialDataFetchParams)
          {
            initialDataFetchParams = { };
          }
          initialDataFetchParams[param] = param === 'text' ? valueForParam : valueForParam.split(',');
        }
      }
    });

    if (initialDataFetchParams)
    {
      setTimeout(() => 
      {
        this.fetchRfos(initialDataFetchParams);
      }, 500);    
    } else {
      this.fetchRfos();
    }
  }

  handleTextSearch = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.props.handleGenericChange(name, value);
    this.setQueryParams(name, value);
  };

  handleClosedAndExpiredCheckChange = event => {
    const target = event.target;
    const value = target.checked;

    this.props.handleGenericChange('includeClosed', value);
    this.props.handleGenericChange('includeExpired', value);
  };

  addLocation = location => {
    this.props.toggleSearchTerm('location', location.id || location.regionId);
    this.updateQueryParams('location', location.id || location.regionId, this.props.searchState['location']
      .map(location => location.id || location.regionId ? location.id || location.regionId : location));
  };

  deleteRegion = region => {
    this.props.deleteSearchTerm('location', region.id || region.regionId);
    this.updateQueryParams('location', region.id || region.regionId, this.props.searchState['location']
    .map(location => location.id || location.regionId ? location.id || location.regionId : location));
  };

  addClassification = classification => {
    console.log('add class', classification)
    this.props.toggleSearchTerm('classification', classification);
    this.updateQueryParams('classification', classification, this.getCurrentFiltersFromState('classification'));
  };

  deleteClassification = classification => {
    this.props.deleteSearchTerm('classification', classification);
    this.updateQueryParams('classification', classification, this.getCurrentFiltersFromState('classification'));
  };

  deleteSearchTerm = (term, value) => {
    this.props.deleteSearchTerm(term, value);
    this.updateQueryParams(term, value, this.getCurrentFiltersFromState(term));
  };

  isSelected = (term, value) => this.props.searchState[term].indexOf(value) > -1;

  isCheckSelected = () => {
    return this.props.searchState.includeClosed && this.props.searchState.includeExpired;
  }

  toggleSearchTerm = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.props.toggleSearchTerm(name, value);
    this.updateQueryParams(name, value, this.getCurrentFiltersFromState(name));
  };

  deleteServiceSearchTerm = (term, value) => {
    this.props.deleteSearchTerm(term, value);
    let currentFilters = this.getCurrentFiltersFromState(term);
    currentFilters = this.updateQueryParams(term, value, currentFilters);

    const service = this.props.services.find(service => service.id === value);
    if (service.subServices)
    {

      service.subServices.forEach(sub => 
      {
        currentFilters = Array.isArray(currentFilters) ? currentFilters : currentFilters ? currentFilters.split(',') : [];
        if (currentFilters.indexOf(sub.id) > -1)
        {
          this.props.deleteSearchTerm(term, sub.id);        
          currentFilters = this.updateQueryParams(term, sub.id, currentFilters);
        }
      })      
    }
    this.subServices = []; //delete subservices?
  };

  toggleClassification = value => {
    const name = 'classification';

    this.props.addSearchTerm(name, value);
    let currentClassifications = this.getCurrentFiltersFromState(name);
    this.updateQueryParams(name, value, currentClassifications);

    const classification = this.props.materials.find(material => material.id === value);
    this.subClassifications = classification.subMaterialTypes;

    this.classification = value;
  };

  deleteClassificationSearchTerm = (term, value) => {
    this.props.deleteSearchTerm(term, value);
    let currentFilters = this.getCurrentFiltersFromState(term);
    currentFilters = this.updateQueryParams(term, value, currentFilters);

    const classification = this.props.materials.find(classification => classification.id === value);
    if (classification.subMaterialTypes)
    {

      classification.subMaterialTypes.forEach(sub => 
      {
        currentFilters = Array.isArray(currentFilters) ? currentFilters : currentFilters ? currentFilters.split(',') : [];
        if (currentFilters.indexOf(sub.id) > -1)
        {
          this.props.deleteSearchTerm(term, sub.id);        
          currentFilters = this.updateQueryParams(term, sub.id, currentFilters);
        }
      })      
    }
    this.subClassifications = []; //delete subservices?
  };

  toggleServiceSearchTerm = value => {
    const name = 'service';

    this.props.addSearchTerm(name, value);
    let currentServices = this.getCurrentFiltersFromState(name);
    this.updateQueryParams(name, value, currentServices);

    const service = this.props.services.find(service => service.id === value);
    this.subServices = service.subServices;
    this.service = value;
  };

  getServicesFromFlatList = (services) => {
    let servicesToShow = [];
    this.subServices = [];

    services.forEach(srv => {
      const service = this.props.services.find(s => s.id === srv);

      if(service && service.subServices)
      {
        servicesToShow.push(service.id)
        this.subServices = service.subServices;
      }     
    });

    return servicesToShow;
  }

  getClassificationsFromFlatList = (classifications) => {
    let classificationsToShow = [];
    this.subClassifications = [];

    classifications.forEach(cls => {
      const classification = this.props.materials.find(m => m.id === cls);

      if(classification && classification.subMaterialTypes)
      {
        classificationsToShow.push(classification.id)
        this.subClassifications = classification.subMaterialTypes;
      }       
    });

    return classificationsToShow;
  }

  toggleSubServiceSearchTerm = value => {
    const name = 'service';
    this.props.addSearchTerm(name, value);
    let currentFilters = this.getCurrentFiltersFromState(name);
    this.updateQueryParams(name, value, currentFilters);
  }

  toggleSubClassification = value => {
    const name = 'classification';
    this.props.addSearchTerm(name, value);
    let currentFilters = this.getCurrentFiltersFromState(name);
    this.updateQueryParams(name, value, currentFilters);
  }

  getCurrentFiltersFromState = (name) => 
  {
    return this.props.searchState[name];
  }

  updateQueryParams = (name, value, currentValuesFromState) => {    
    let valueAfterToggle = undefined;

    if (currentValuesFromState && currentValuesFromState.indexOf(value) > -1) {
      valueAfterToggle = without([value], currentValuesFromState).join(',')
    } else {
      let initialValue = currentValuesFromState ? currentValuesFromState : [];
      valueAfterToggle = initialValue.concat([value]).filter(x => x && x.toString().trim() !== '').join(',');
    }

    this.setQueryParams(name, valueAfterToggle);
    
    return valueAfterToggle;
  }

  setQueryParams = (name, value) => {
    const queryParams = new URLSearchParams(this.props.location.search);

    queryParams.set(name, value);
    if (value === '')
    {
      queryParams.delete(name);
    }

    this.props.history.replace({search: queryParams.toString()});
  }

  clearQueryParams = () => {
    this.props.history.replace({search: ''});
  }

  handleSubmit = event => {
    event.preventDefault();
    this.fetchRfos();
  };

  hasSearchTerms = () => {
    return (
      this.props.searchState.text ||
      (Array.isArray(this.props.searchState.rfoType) && this.props.searchState.rfoType.length) ||
      (Array.isArray(this.props.searchState.classification) &&
        this.props.searchState.classification.length) ||
      (Array.isArray(this.props.searchState.service) && this.props.searchState.service.length) ||
      (Array.isArray(this.props.searchState.location) && this.props.searchState.location.length)
    );
  };

  showSaveSearchFrom = () => {
    this.setState({ showSaveSearchForm: true });
  };

  handleSavedSearchDone = res => {
    this.setState({ showSaveSearchForm: false });
  };

  searchFn = (t) => (searchString) => (value) => {
    return (
      value.id.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      t(value.id).toLowerCase().indexOf(searchString.toLowerCase()) > -1
    );
  };
  
  serviceIdsToServiceObject = (
    services,
    selectedServiceIds
  ) => {
    return services.filter((service) =>
      (selectedServiceIds || []).some(
        (selectedClassificationId) => selectedClassificationId === service.id
      )
    );
  };

  render() {
    const { t, searchState, services } = this.props;

    return (
    <>
      <form>
        <div className={styles.searchContainer}>        
          <div>
            <div className={styles.searchBar}>
              <label>
                <input
                  className={styles.searchBar__input}
                  type="text"
                  onChange={this.handleTextSearch}
                  name="text"
                  value={searchState.text}
                  placeholder={t('Hakusana') + '...'}
                />
              </label>
            </div>
          </div>
          <div>
            <div className={styles.centerContent}>
              <div className={styles.heading}>{t('Ilmoitustyyppi')}</div>
            </div>
            <div className={formStyles.formRowHorizontal}>
              <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneFifth)}>
                <input
                  className="checkboxInput"
                  type="checkbox"
                  name="rfoType"
                  id="offeringWaste"
                  value="offeringWaste"
                  checked={this.isSelected('rfoType', 'offeringWaste')}
                  onChange={this.toggleSearchTerm}
                />
                <label className="checkboxLabel" htmlFor="offeringWaste">
                  {t('offering-label')}:<br/>
                  <strong>{t('waste-label')}</strong>
                </label>
              </div>
              <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneFifth)}>
                <input
                  className="checkboxInput"
                  type="checkbox"
                  name="rfoType"
                  id="offeringMaterial"
                  value="offeringMaterial"
                  checked={this.isSelected('rfoType', 'offeringMaterial')}
                  onChange={this.toggleSearchTerm}
                />
                <label className="checkboxLabel" htmlFor="offeringMaterial">
                  {t('offering-label')}:<br/>
                  <strong>{t('nonwaste-label')}</strong>
                </label>
              </div>
              <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneFifth)}>
                <input
                  className="checkboxInput"
                  type="checkbox"
                  name="rfoType"
                  id="receivingMaterial"
                  value="receivingMaterial"
                  checked={this.isSelected('rfoType', 'receivingMaterial')}
                  onChange={this.toggleSearchTerm}
                />
                <label className="checkboxLabel" htmlFor="receivingMaterial">
                  {t('receiving-label')}:<br/>
                  <strong>{t('material-label')}</strong>
                </label>
              </div>
              <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneFifth)}>
                <input
                  className="checkboxInput"
                  type="checkbox"
                  name="rfoType"
                  id="offeringServices"
                  value="offeringServices"
                  checked={this.isSelected('rfoType', 'offeringServices')}
                  onChange={this.toggleSearchTerm}
                />
                <label className="checkboxLabel" htmlFor="offeringServices">
                  {t('offering-label')}:<br/>
                  <strong>{t('wasteservices-label')}</strong>
                </label>
              </div>
              <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneFifth)}>
                <input
                  className="checkboxInput"
                  type="checkbox"
                  name="rfoType"
                  id="receivingServices"
                  value="receivingServices"
                  checked={this.isSelected('rfoType', 'receivingServices')}
                  onChange={this.toggleSearchTerm}
                />
                <label className="checkboxLabel" htmlFor="receivingServices">
                  {t('receiving-label')}:<br/>
                  <strong>{t('professionalservices-label')}</strong>
                </label>
              </div>              
              <PrivateComponent isAdminOrRegionalCoordinator>
                <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneThird)}>
                  <input
                    className="checkboxInput"
                    type="checkbox"
                    name="includeClosedAndExpired"
                    id="includeClosedAndExpired"
                    checked={this.isCheckSelected()}
                    onChange={this.handleClosedAndExpiredCheckChange}
                  />
                  <label className="checkboxLabel" htmlFor="includeClosedAndExpired">
                    {t('includeClosedAndExpired-title')}
                  </label>
                </div>
              </PrivateComponent>
            </div>
          </div>
          <div className={cx(formStyles.formRowHorizontal, formStyles.paddingTopSm)}>
            <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneThird)}>
              <div>
                <label className={styles.noBottomPadding}>
                  <div className={styles.selectLabel}>{t('Materiaali')}</div>
                  <Select
                    values={this.props.materials}
                    onAdd={(classification) => this.toggleClassification(classification.id)}
                    single={this.props.singleMaterial}
                    value={this.serviceIdsToServiceObject(this.props.materials, this.props.materialValue)}
                    displayFn={(value) => t(value.id)}
                    keyFn={(value) => value.id}
                    searchFn={this.searchFn(t)}
                    placeholder={t('Valitse...')}
                    showAllOnFocus={true}
                    showIcon={true}
                    onRemove={(classification) => this.deleteClassification(classification.id)}
                  />         
                </label>
              </div>
              <div className={styles.searchTerms__Container}>
                {this.getClassificationsFromFlatList(searchState.classification).filter(mat => this.props.materials.map(material => material.id).indexOf(mat) > -1).map(classification => {
                  return (
                    <button
                      key={classification}
                      type="button"
                      className={'searchTerms__Button'}
                      onClick={() => this.deleteClassificationSearchTerm('classification', classification)}
                    >
                      {t(classification)}
                      <span className={'searchTerms__Button__x'} aria-label={t('Poista hakuehto')}>
                        &times;
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneThird)}>
              <div>
                <label className={styles.noBottomPadding}>
                  <div className={styles.selectLabel}>{t('Palvelu')}</div>
                  <Select
                    values={services}
                    onAdd={(service) => this.toggleServiceSearchTerm(service.id)}
                    single={this.props.single}
                    value={this.serviceIdsToServiceObject(services, this.props.value)}
                    displayFn={(value) => t(value.id)}
                    keyFn={(value) => value.id}
                    searchFn={this.searchFn(t)}
                    placeholder={t('Valitse...')}
                    showAllOnFocus={true}
                    showIcon={true}
                    onRemove={(service) => this.toggleServiceSearchTerm(service.id)}
                  />
                </label>
              </div>
              <div className={styles.searchTerms__Container}>
                {this.getServicesFromFlatList(searchState.service).filter(serv => this.props.services.map(service => service.id).indexOf(serv) > -1).map(service => {
                  return (
                    <button
                      key={service}
                      type="button"
                      className={'searchTerms__Button'}
                      onClick={() => this.deleteServiceSearchTerm('service', service)}
                    >
                      {t(service)}
                      <span className={'searchTerms__Button__x'} aria-label={t('Poista hakuehto')}>
                        &times;
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneThird)}>
              <div>
                <label>
                  <div className={styles.selectLabel}>{t('Sijainti')}</div>
                  <RegionSelect
                    handleChange={this.addLocation}
                    onRemove={this.deleteRegion}
                    value={searchState.location}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className={cx(formStyles.formRowHorizontal, formStyles.paddingTopSm)}>
            <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneThird)}>
              <div>
                {this.subClassifications && this.subClassifications.length > 0 && (
                  <label className={styles.noBottomPadding}>
                    <div className={styles.selectLabel}>{t('Materiaalin tarkenne')}</div>
                    <Select
                      values={this.subClassifications}
                      onAdd={(classification) => this.toggleSubClassification(classification.id)}
                      single={this.props.single}
                      value={this.serviceIdsToServiceObject(this.subClassifications, this.props.value)}
                      displayFn={(value) => t(value.id)}
                      keyFn={(value) => value.id}
                      searchFn={this.searchFn(t)}
                      placeholder={t('Valitse...')}
                      showAllOnFocus={true}
                      showIcon={true}
                      onRemove={(classification) => this.toggleSubClassification(classification.id)}
                    />
                  </label>
                )}
              </div>
              <div className={styles.searchTerms__Container}>
                {groupSubMaterials(this.props.searchState.classification, this.props.materials, t).map(group => {
                    return (<div key={group.name}>
                      <div className={styles.groupLabel}>{group.name}:</div>
                      {group.subMaterials && group.subMaterials.map(classification => {
                        return (
                          <button
                            key={classification}
                            type="button"
                            className={'searchTerms__Button'}
                            onClick={() => this.deleteSearchTerm('classification', classification)}
                          >
                            {t(classification)}
                            <span className={'searchTerms__Button__x'} aria-label={t('Poista hakuehto')}>
                              &times;
                            </span>
                          </button>
                        );
                        })}
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneThird)}>
              <div>
                {this.subServices && this.subServices.length > 0 && (
                <label className={styles.noBottomPadding}>
                <div className={styles.selectLabel}>{t('Palvelun tarkenne')}</div>
                <Select
                    values={this.subServices}
                    onAdd={(subService) => this.toggleSubServiceSearchTerm(subService.id)}
                    single={this.props.single}
                    value={this.serviceIdsToServiceObject(this.subServices, this.props.value)}
                    displayFn={(value) => t(value.id)}
                    keyFn={(value) => value.id}
                    searchFn={this.searchFn(t)}
                    placeholder={t('Valitse...')}
                    showAllOnFocus={true}
                    showIcon={true}
                    onRemove={(subService) => this.toggleSubServiceSearchTerm(subService.id)}
                  />
              </label>
              )}
              </div>
              <div className={styles.searchTerms__Container}>
                { groupSubServices(this.props.searchState.service, this.props.services, t).map(group => {
                  return (<div key={group.name}>
                    <div className={styles.groupLabel}>{group.name}:</div>
                    {group.subServices && group.subServices.map(service => {
                      return (
                        <button
                          key={service}
                          type="button"
                          className={'searchTerms__Button'}
                          onClick={() => this.deleteSearchTerm('service', service)}
                        >
                          {t(service)}
                          <span className={'searchTerms__Button__x'} aria-label={t('Poista hakuehto')}>
                            &times;
                          </span>
                        </button>
                      );
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={cx(formStyles.formInputContainerNoBottomPadding, formStyles.widthOneThird)}>

            </div>
          </div>
          <div className={cx(styles.removeParams, styles.centerContent)}>
            {this.hasSearchTerms() ? (
              <div className={styles.removeLabel} onClick={() => {this.props.setSearch({}); this.clearQueryParams(); this.subServices = []; this.subClassifications = [];}}>{t('Poista hakukriteerit')}</div>
            ) : (
              <div>&nbsp;</div>
            )}
          </div>          
          </div>
          <PrivateComponent>
            <SavedSearches t={t} />
          </PrivateComponent>
        </form>

        <div>
          {this.props.searchState && this.state.showSaveSearchForm && (
            <AddSavedSearch
              search={this.props.searchState}
              t={t}
              done={this.handleSavedSearchDone}
            />
          )}
        </div>
      

      <div className={styles.searchContainer__controls}>
        <div></div>
        <div>
          <button
            className={styles.searchControls__search}
            type="submit"
            onClick={this.handleSubmit}
          >
            {t('Hae ilmoituksia')}
          </button>
        </div>
        <div>
          <PrivateComponent>
            <button
              className={cx(
                styles.addSearchWatchButton,
                'customButton',
              )}
              type="button"
              disabled={!this.hasSearchTerms()}
              onClick={this.showSaveSearchFrom}
            > {t('Tallenna hakuvahdiksi')}
            </button>
          </PrivateComponent>
        </div>   
      </div>
    </>
    );
  }
}

const mapStateToProps = state => ({
  materials: state.generalState.configurations.materials,
  searchState: state.searchState,
  services: state.generalState.configurations.services,
  sort: state.searchState.sortOrder
});
const mapDispatchToProps = dispatch => ({
  addSearchTerm: (term, value) => dispatch(searchOperations.addSearchTerm(term, value)),
  deleteSearchTerm: (term, value) => dispatch(searchOperations.deleteSearchTerm(term, value)),
  toggleSearchTerm: (term, value) => dispatch(searchOperations.toggleSearchTerm(term, value)),
  setSearch: (searchState) => dispatch(searchOperations.setSearch(searchState)),
  handleGenericChange: (key, value) => dispatch(searchOperations.handleGenericChange(key, value)),
  fetchRfosWithParamas: params => dispatch(rfoOperations.fetchRfos(params))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNamespaces()
)(Search);
