import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import InfoBox from '../InfoBox/InfoBox';
import { isChrome } from './chrome-utils';

class ChromeErrorInfobox extends Component {
  render() {
    const { t } = this.props;

    return isChrome() ? (
      <InfoBox
        infoText={t(
          'Jos kuvan latauksessa on ongelmaa, niin voit ladata kuvan itsellesi klikkaamalla kuvan kohdalla hiiren oikeaa ja valitsemalla "Tallenna kuva nimellä...", tai avaamalla kuvan ja klikkaamalla "Lataa kuva" -painiketta Alt-näppäin pohjassa.'
        )}
      />
    ) : (
      <></>
    );
  }
}

export default withNamespaces()(ChromeErrorInfobox);
