import cx from 'classnames';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Loader from '../Loader/Loader';
import { Col, Container, Row } from '../Layout/Layout';
import { compose } from 'ramda';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { getRaw } from '../../services/ApiService';
import { GET_HILMA_NOTIFICATION } from '../../services/endpoints';
import styles from './Tsv.module.css';
import searchStyles from '../Search/Search.module.css';
import withCancelToken from '../CancelToken/withCancelToken';
import { replace } from 'ramda';
import {LISAA_ILMOITUS_HILMA } from '../../routes';
import { Link } from 'react-router-dom';
import { userSelectors } from '../../state/ducks/user';
import InfoBox from '../InfoBox/InfoBox';
import HilmaTsvSteps from '../TSV/HilmaTsvSteps';

class SearchHilmaNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeNumber: '',
      results: [],
      loading: false,
      continuationToken: '',
      hasSearched: false,
      invalidNoticeNumber: true,
      businessId: ''
    };
  }

  getHilmaNotification = () => {
    //TODO validate

    this.setState({ loading: true, hasSearched: true });

    const config = {
      cancelToken: this.props.cancelTokenSource.token
    };

    const fetchUrl = replace('{0}', this.state.noticeNumber, GET_HILMA_NOTIFICATION);

    getRaw(fetchUrl, config)
      .then(response => {
        const { headers, data } = response;
        const results = data;
        this.setState({
          continuationToken: headers['tietoalusta-continuation-token'],
          results,
          loading: false
        });
      })
      .catch(thrown => {
        if (!this.props.isCancel(thrown)) {
          this.setState({ loading: false });
        }
      });
  };

  validateNoticeNumber = (noticeNumber) => {
    const regExpValidNoticeNumber = '^2[0-9]{3}-[0-9]{6}$';
    const validNoticeNumber = noticeNumber.match(regExpValidNoticeNumber);

    if (validNoticeNumber !== null) {
      this.setState({ invalidNoticeNumber: false });
    } else {
      this.setState({ invalidNoticeNumber: true });
    }
  }

  onChange = event => {
    const { target } = event;
    const { name, value } = target;

    this.validateNoticeNumber(target.value);    
    this.setState({ [name]: value, continuationToken: '' });
  };

  createTsvLink = (t, notice) => {
    if(notice.organisationNationalRegistrationNumber !== this.state.businessId) {
      return <p><strong>{t('Hilma-ilmoitus kuuluu toiselle organisaatiolle')}</strong></p>;
    }
    else if(Date.parse(notice.expirationDate) > Date.now())
    { 
      return <p><strong>{t('Hilma-ilmoitus on vielä voimassa')}</strong></p>;
    }

    return <Link className={cx(styles.tsvLink, 'buttonStyle')} to={ {pathname:`${LISAA_ILMOITUS_HILMA}/${notice.noticeNumber}`}}>{t('Pyydä palvelua')}</Link>;
  };

  componentDidMount() {
    var bId = userSelectors.getOwnBusinessId(this.props.user);
    this.setState({businessId: bId});
  }

  render() {
    const { t } = this.props;
    const { loading, results, continuationToken, hasSearched, invalidNoticeNumber } = this.state;

    return (
        <>
        <Header />
        <Navigation />
        <Container className={cx('flex-grow-1')}>
          <Row options={{ center: true }}>
          <Col span={6} sm={6} xs={12}>
            <HilmaTsvSteps t={t} currentStep={0} />
          </Col>
            <Col span={10} className={cx(styles.container)}>
              <h1 className={'textCenter'}>
                {t('Pyydä kunnan toissijaista jätehuoltopalvelua')}
              </h1>
              <div className={'divider'} />
            </Col>
          </Row>
          <Row options={{ center: true }}>
            <Col span={10} className={cx(styles.searchContainer)}>
              <h2>{`${t('Hae tietoja hankintailmoituksesta Hilmasta')}`}</h2>
              <label>
                  <strong>{`${t('Ilmoitusnumero')}`}</strong>
                  <InfoBox
                    infoText={t(
                      'Hae Hilmasta hankintailmoituksen tietoja kirjoittamalla tähän ilmoituksen numero. Numero löytyy hankintailmoituksen yläreunasta ja alkaa vuosiluvulla, jolloin ilmoitus on luotu. Kirjoita numero huolellisesti, vuosiluvun jälkeen tulee väliviiva.'
                    )}
                  /><br/>
                  <input
                    className={cx(searchStyles.searchBar__input, styles.noticeNumber)}
                    type="text"
                    name="noticeNumber"
                    value={this.state.noticeNumber}
                    onChange={this.onChange}
                    placeholder={t('2020-123456')} />
              </label>
            
              <div className={cx(styles.searchButton)}>
                <button
                  className={cx('buttonStyle', 'textLeft')}
                  onClick={() => this.getHilmaNotification({ continuationToken })}
                  disabled={loading || invalidNoticeNumber}
                >
                  {t('Hae tietoja')}
                </button>
              </div>

            </Col>
          </Row>
          {hasSearched && (
            <Row options={{ center: true }}>
              <Col span={10} className={cx(styles.container)}>
                <h2 className={'textCenter'}>{t('Haulla löytyneet ilmoitukset')}</h2>
                  {loading && (
                    <Loader loading={loading} />
                  )}
                  {!loading && results.length === 0 && (
                    <div className={'textCenter'}>{t('Ei hakutuloksia.')}</div>
                  )}

                  {!loading && results && results.map((notice, idx) => {
                    return (
                      <div className={cx(styles.noticeContainer)}>
                        <table>
                          <tbody>
                            <tr>
                              <th>{t('Hankinnan otsikkotiedot')}</th>
                              <td>{notice.projectTitle}</td>
                            </tr>
                            <tr>
                              <th>{t('Hilma-ilmoitusnumero')}</th>
                              <td>{notice.noticeNumber}</td>
                            </tr>
                            <tr>
                              <th>{t('Hankinnan tyyppi')}</th>
                              <td>{notice.cpvCodes}</td>
                            </tr>
                            <tr>
                              <th>{t('Ilmoittajan Y-tunnus')}</th>
                              <td>{notice.organisationNationalRegistrationNumber}</td>
                            </tr>
                            <tr>
                              <th>{t('Ilmoittaja')}</th>
                              <td>{notice.organisationName}<br/>{notice.organisationAddress}</td>
                            </tr>
                            <tr>
                              <th></th>
                              <td>
                                { this.createTsvLink(t, notice) }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
              </Col>
            </Row>
          )}
        </Container>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userState.user
});

export default compose(
  withCancelToken,
  connect(mapStateToProps),
  withNamespaces()
)(SearchHilmaNotifications);
