import React from 'react';

const Key = ({ color, size }) => (
  <svg
    version="1.1"
    id="Key"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    height={size}
    width={size}
  >
    <path
      fill={color}
      d="M17.691,4.725c-0.503-2.977-3.22-4.967-6.069-4.441C8.772,0.809,6.366,3.1,6.869,6.079 C6.976,6.72,7.277,7.723,7.632,8.444l-5.175,7.723c-0.191,0.285-0.299,0.799-0.242,1.141l0.333,1.971 c0.058,0.342,0.372,0.572,0.7,0.514l1.516-0.281c0.328-0.059,0.744-0.348,0.924-0.639l2.047-3.311l0.018-0.022l1.386-0.256 l2.39-3.879c0.785,0.139,1.912,0.092,2.578-0.031C16.955,10.848,18.194,7.704,17.691,4.725z M15.166,6.252 c-0.784,1.17-1.584,0.346-2.703-0.475c-1.119-0.818-2.135-1.322-1.352-2.492c0.784-1.17,2.326-1.455,3.447-0.635 C15.678,3.469,15.949,5.082,15.166,6.252z"
    />
  </svg>
);

export default Key;
